import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigAssetLoaderService} from '../../configAssetLoader.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnneeCampagneResourceService {

  refFormUrl: string;

  constructor(protected http: HttpClient, private configAssetLoaderService: ConfigAssetLoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.refFormUrl = res.refFormUrl + '/annee-campagne');
  }

  getOpenedYears(objetId: number): Observable<any> {
    return this.http.get<any>(this.refFormUrl + '/objetformation/' + objetId);
  }

  getCurrentYear(): Observable<number> {
    return this.http.get<any>(this.refFormUrl + '/displayed');
  }
}
