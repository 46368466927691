import {Injectable} from '@angular/core';
import {DynamicFlatNode} from '../../model/contenu.model';
import {PeriodeResourceService} from '../resources/periode-resource.service';
import {getListObservable, hasListObservable, setListObservable} from '../../utils/rxjs-cache.utils';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {GofElement} from '../../model/util.model';

@Injectable({
  providedIn: 'root'
})
export class ListeEnseignementsContenuService {

  observablesMap: Map<number, Map<number, any>> = new Map();

  constructor(private periodeResourceService: PeriodeResourceService) {
  }

  /***
   * Récupération des enseignements ou des sous listes
   * <!> node.supId => objet id de la periode <!>
   */
  getChildren(gofElement: GofElement, node: DynamicFlatNode): Promise<DynamicFlatNode[]> {
    return new Promise<DynamicFlatNode[]>(resolve => {
      const children: DynamicFlatNode[] = [];

      if (!hasListObservable(this.observablesMap, gofElement.annee, node.supId)) {
        setListObservable(this.observablesMap, this.periodeResourceService, gofElement.annee, node.supId);
      }

      getListObservable(this.observablesMap, gofElement.annee, node.supId).subscribe(listes => {
        listes = listes
          .filter(l => l.objetId === node.objetId)
          .sort((l1, l2) => {
            if (l1.temporalite < l2.temporalite) {
              return -1;
            } else if (l1.temporalite > l2.temporalite) {
              return 1;
            } else {
              return 0;
            }
          });
        if (listes.length > 0) {
          if (listes[0].enseignements) {
            listes[0].enseignements.forEach(ens => {
              children.push({
                name: ens.ue.lib,
                objetId: ens.ue.objetId,
                supId: node.supId,
                type: TYPES_OBJET.ENSEIGNEMENT,
                description: ens.ue.typeLib,
                ects: ens.ue.ects,
                ensTypologie: ens.typologie != null ?  ens.typologie.lib :ens.ue?.typologie?.lib,
                ensNiveau: ens.niveau != null ?  ens.niveau.lib:ens.ue?.niveau?.lib,
                ensTypologieId: ens.typologie != null ? ens.typologie.id : ens.ue?.typologie?.id,
                ensNiveauId: ens.niveau != null ? ens.niveau.id : ens.ue?.niveau?.id,
                level: node.level + 1,
                clickable: true,
                expandable: false,
                isLoading: false
              });
            });
          }

          if (listes[0].sousListes) {
            listes[0].sousListes
              .forEach(sousliste => {
                let name = sousliste.lib;
                // if (sousliste.type) {
                //   name += ' - ' + sousliste.type?.lib;
                // }
                // if (sousliste.description) {
                //   name += ' - ' + sousliste.description;
                // }

                children.push({
                  name,
                  objetId: sousliste.objetId, // pour récup les enseignements
                  supId: node.objetId, // id periode
                  type: TYPES_OBJET.LISTE_ENSEIGNEMENTS,
                  description: sousliste.description,
                  temporalite: sousliste.temporalite,
                  level: node.level + 1,
                  clickable: false,
                  expandable: true,
                  isLoading: false
                });
              });
          }
        }
        resolve(children);
      });
    });
  }
}
