// paths
import { LANGUES } from "./utils.constants";

export const PATH_ACCUEIL = '';
export const PATH_RESULTATS = 'resultats';
export const PATH_PAR_DIPLOMES = 'par-diplome';
export const PATH_PAR_DISCIPLINES = 'par-discipline';
export const PATH_PAR_COMPOSANTES = 'par-composante';
export const PATH_DETAIL_FORMATION = 'details-formation';
export const PATH_MA_SELECTION = 'ma-selection';
export const PATH_PLAN_DU_SITE = 'plan-du-site';
export const PATH_CATALOGUE_INTERNATIONAL = 'international-course-catalogue';

export const DEFAULT_LANGUAGE = LANGUES.FR.valueOf();

export const PARAM_TYPE = 'type';

// Variables de configuration liées au stockage
export const BREADCRUMB_THREAD = 'breadcrumb-thread';
export const SEARCH_BY_KEYWORDS = 'search';

export const GOF_COOKIES = 'ub-rgpd';
export const GOF_MA_SELECTION = 'ub-my-selection';

export const GOF_INTERNATIONAL_CATALOGUE = 'ub-international-catalogue';
export const GOF_ENSEIGNEMENT_INTERNATIONAL = 'ub-enseignement-international';


export const GOF_LANGUAGE = 'ub-language';
export const GOF_PROFILE = 'ub-profile';
export const GOF_DYSLEXIC = 'ub-dyslexic-friendly';
export const GOF_CONTRAST = 'ub-high-contrast';
export const GOF_SEARCH = 'ub-search';
export const GOF_FILTERS = 'ub-filters';
export const CLOSE_EVENT = 'close_event';

export const EXPIRE_COOKIES = 30 // jours

