import {Injectable} from '@angular/core';
import {PanelCompetences, Competence} from '../../model/competences.model';
import {GofElement} from '../../model/util.model';
import {CompetenceResourceService} from '../resources/competence-resource.service';
import {getVersion} from '../../utils/version.utils';

@Injectable({
  providedIn: 'root'
})
export class PeriodeCompetencesService {

  panelCompetences: PanelCompetences = new PanelCompetences();

  constructor(private competenceResourceService: CompetenceResourceService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelCompetences> {
    return new Promise<PanelCompetences>(resolve => {
      Promise.all([
        this.getCompetences(gofElement)
      ])
        .then(() => {
          resolve(this.panelCompetences);
        });
    });
  }

  private getCompetences(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.competenceResourceService.getFormationCompetences(gofElement).subscribe(res => {
        this.panelCompetences.prerequis = res.filter(c => c.isPrerequis)
          .map(c => new Competence(
              c.id,
              c.competence.lib,
              c.competence.type.id,
              getVersion(gofElement, c.acquisition)
            )
          );


        this.panelCompetences.competences = res.filter(c => !c.isPrerequis)
          .map(c => new Competence(
              c.id,
              c.competence.lib,
              c.competence.type.id,
              getVersion(gofElement, c.acquisition)
            )
          );

        resolve();
      },
        () => resolve());
    });
  }
}
