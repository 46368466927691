<div class="breadcrumb-container" *ngIf="thread">
  <nav class="breadcrumb" aria-label="Fil d'Ariane" role="navigation">
    <ol class="list-unstyled" itemscope itemtype="https://schema.org/BreadcrumbList">
      <ng-container *ngFor="let item of thread; let index = index; let first = first ; let last = last">
        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
          <span class="breadcrumb-separator" *ngIf="!first">{{'/ '}}</span>
          <a class=breadcrumb-link itemtype="https://schema.org/Thing" itemprop="item" href [routerLink]="item.routerlink"
             [queryParams]="item.queryParams" target="_self"
             *ngIf="item.routerlink && !last">
          <span itemprop="name" *ngIf="item.queryParams && item.queryParams[paramType]">
            {{typeObjetTrad.get(item.queryParams[paramType]) | translate  }}{{' : '}}
          </span>{{item.lib | translate}}
          </a>
          <span *ngIf="!item.routerlink || last"><span *ngIf="item.queryParams && item.queryParams[paramType]">
            {{typeObjetTrad.get(item.queryParams[paramType]) | translate  }}{{' : '}}
          </span>{{item.lib | translate}}
        </span>
          <meta itemprop="position" content="{{index + 1}}"/>
        </li>
      </ng-container>
    </ol>
  </nav>
</div>
