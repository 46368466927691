import { Injectable } from '@angular/core';
import {GofElement} from '../../model/util.model';
import {PanelDebouches, TypeDebouche} from '../../model/debouches.model';
import {DebouchesResourceService} from '../resources/debouches-resource.service';
import {getVersion} from '../../utils/version.utils';

@Injectable({
  providedIn: 'root'
})
export class CursusEnrichiDebouchesService {

  panelDebouches: PanelDebouches = new PanelDebouches();

  constructor(private debouchesResourceService: DebouchesResourceService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelDebouches> {
    return new Promise<PanelDebouches>(resolve => {
      this.debouchesResourceService.getDebouches(gofElement).subscribe(res => {
        this.panelDebouches.naf = res.naf.map(b => new TypeDebouche(b.nafId, getVersion(gofElement, b)));
        this.panelDebouches.rome = res.rome.map(b => new TypeDebouche(b.romeId, getVersion(gofElement, b)));
        this.panelDebouches.emploisTypes = res.autres.filter(d => d.type == 1).map(d => d.lib);
        this.panelDebouches.secteursActivites = res.autres.filter(d => d.type == 2).map(d => d.lib);
        resolve(this.panelDebouches);
      },
        () => resolve(new PanelDebouches()));
    });
  }
}
