export class PanelCompetences {
  constructor(public id?: number,
              public prerequis?: Competence[],
              public competences?: Competence[]) {
  }


  isDisplayed(): boolean {
    return this && (
      this.competences?.length > 0
      || this.prerequis?.length > 0
    );
  }
}

export class Competence {
  constructor(public id?: number,
              public lib?: string,
              public typeCompetenceId ?: number,
              public discipline?: string,
              public acquisition?: string) {
  }
}
