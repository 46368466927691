import {ObjectAffichage} from './util.model';

export class PanelPoursuitesEtudes {
  constructor(
    public diplomesInternes?: ObjectAffichage[],
    public diplomesExternes?: string
  ){}


  isDisplayed(): boolean {
    return this && (
      this.diplomesExternes?.length > 0
      || this.diplomesInternes?.length > 0
    );
  }
}
