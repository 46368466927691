import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Menu, PathItem } from "../../model/util.model";
import { GOF_LANGUAGE, PATH_ACCUEIL, PATH_DETAIL_FORMATION, PATH_PAR_COMPOSANTES } from "../../constants/conf.constants";
import { ParComposantesService } from "../../services/par-composantes.service";
import { LazyLoadService } from "../../services/lazy-load.service";
import { StructuresResourceService } from "../../services/resources/structures-resource.service";
import { ElasticsearchService } from "../../services/elasticsearch.service";
import { ES_FIELDS, TYPES_OBJET } from "../../constants/utils.constants";
import { AbstractDocument, FormationDoc } from "../../model/esDoc.model";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from "../../app.component";
import { UtilService } from "../../services/util.service";
import { GlobalStateService } from "../../services/global-state.service";

@Component({
  selector: "app-par-composantes",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./par-composantes.component.html",
  styleUrls: ["./par-composantes.component.scss"]
})
export class ParComposantesComponent implements OnInit {

  thread: PathItem[];
  menu: Menu[] = [];

  pathDetailFormation = PATH_DETAIL_FORMATION;

  constructor(private utilService: UtilService,
              private translateService: TranslateService,
              private parComposantesService: ParComposantesService,
              private structuresResourceService: StructuresResourceService,
              private elasticsearchService: ElasticsearchService,
              private globalStateService: GlobalStateService,
              private lazyLoadScriptServiceService: LazyLoadService) {
    this.globalStateService.subscribe(GOF_LANGUAGE, () => {
      this.utilService.setHtmlHead("page.par_composante", "universite_de_bordeaux_description", PATH_PAR_COMPOSANTES, "");
    });
  }

  ngOnInit(): void {
    this.utilService.setHtmlHead("page.par_composante", "universite_de_bordeaux_description", PATH_PAR_COMPOSANTES, "");

    this.thread = [
      new PathItem("page.accueil", [PATH_ACCUEIL]),
      new PathItem("page.par_composante", [PATH_PAR_COMPOSANTES])
    ];

    this.structuresResourceService.getSnis().subscribe(snis => {
      this.elasticsearchService.search(TYPES_OBJET.FORMATION, ES_FIELDS.TYPE_OBJET).subscribe((formations: FormationDoc[]) => {
        const formComp: string[] = [].concat.apply([], formations.map(f => f.composantes)).filter((elem, index, self) => index === self.indexOf(elem));
        snis
          .sort((s1, s2) => {
            if (s1.lib.toLowerCase() > s2.lib.toLowerCase()) {
              return 1;
            } else if (s1.lib.toLowerCase() < s2.lib.toLowerCase()) {
              return -1;
            } else {
              return 0;
            }
          })
          .forEach(sni => {
            const structures: any[] = sni.structures
              .filter(struct => formComp.indexOf(struct.lib) > -1)
              .sort((s1, s2) => {
                if (s1.lib.toLowerCase() > s2.lib.toLowerCase()) {
                  return 1;
                } else if (s1.lib.toLowerCase() < s2.lib.toLowerCase()) {
                  return -1;
                } else {
                  return 0;
                }
              });

            if (structures.length > 0) {
              this.menu.push(new Menu(sni.lib, null, structures.map(s => new Menu(s.lib, null, []))));
            }
          });

        this.lazyLoadScriptServiceService.injectScript("/assets/js/accordion.js");
      });
    });
  }

  getNiveau3(sni: string, menu: Menu): void {
    this.parComposantesService.getNiveau3(sni, menu.intitule).subscribe(res => {
      menu.sousmenus = res;
      this.lazyLoadScriptServiceService.injectScript("/assets/js/accordion.js");
    });
  }

  getNiveau4(sni: string, composante: string, menu: Menu): void {
    this.parComposantesService.getNiveau4(sni, composante, menu.intitule).subscribe(res => menu.formationDocs = res);
  }

  onSelected(formation: AbstractDocument): void {
    AppComponent.CURRENT_YEAR = formation.annee;
  }
}
