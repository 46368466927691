import { Injectable } from "@angular/core";
import { Competence } from "../../model/competences.model";
import { PanelBlocsCompetence, ListeDescription, Modalite, UE } from "../../model/blocs-competence.model";
import { CompetenceResourceService } from "../resources/competence-resource.service";
import { BlocResourceService } from "../resources/bloc-resource.service";
import { GofElement, ObjectAffichage } from "../../model/util.model";
import { TYPES_OBJET, TYPES_OBJET_MAP } from "../../constants/utils.constants";
import { RefTypeCompetence } from "../../constants/referentiel/ref.constants";
import { getVersion } from "../../utils/version.utils";

@Injectable({
  providedIn: "root"
})
export class FormationBlocsCompetenceService {

  panelBlocsCompetence: PanelBlocsCompetence = new PanelBlocsCompetence();

  constructor(private competenceResourceService: CompetenceResourceService,
              private blocResourceService: BlocResourceService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelBlocsCompetence> {
    return new Promise<PanelBlocsCompetence>(resolve => {
      Promise.all([
        this.getBlocsCompetences(gofElement)
      ])
        .then(() => {
          resolve(this.panelBlocsCompetence);
        });
    });
  }

  private getBlocsCompetences(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.blocResourceService.getBlocsCompetences(gofElement).subscribe(blocsDeCompetence => {
          this.panelBlocsCompetence.blocs = [];
          blocsDeCompetence.forEach(bloc => {
            const competences: Competence[] = bloc.competences
              .filter(c => !c.isPrerequis).map(c => new Competence(
                c.competence.id,
                c.competence.lib,
                c.competence.type.id,
                getVersion(gofElement, c.discipline),
                getVersion(gofElement, c.acquisition)
              ));

            this.panelBlocsCompetence.blocs.push(
              {
                id: bloc.id,
                intitule: bloc.lib,
                modalites: bloc.modalites?.map(m =>
                  new Modalite(
                    m.modalite,
                    m.objetFormation != null ? new ObjectAffichage(
                      m.objetFormation.objetId,
                      m.objetFormation.lib != null ? m.objetFormation.lib : m.objetFormation.typeObjet.lib,
                      TYPES_OBJET_MAP.get(m.objetFormation.typeObjet.id)
                    ) : null
                  )
                ),
                competenceUnique: competences?.length === 1 ? competences[0] : null,
                competencesDisciplinaires: competences?.filter(c => c.typeCompetenceId === RefTypeCompetence.DISCIPLINAIRE),
                competencesTransverses: competences?.filter(c => c.typeCompetenceId === RefTypeCompetence.TRANSVERSALE),
                competencesPreProfessionnelles: competences?.filter(c => c.typeCompetenceId === RefTypeCompetence.PREPROFESSIONNELLE),
                listesEnseignements: bloc.listesEnseignements?.map(ld =>
                  new ListeDescription(
                    ld.id,
                    ld.lib,
                    ld.description,
                    ld.nbEns,
                    ld.listeUE?.map(ue => new UE(
                      new ObjectAffichage(ue.objetId, ue.lib, TYPES_OBJET.ENSEIGNEMENT),
                      ue.typologieId
                    ))
                  )
                )
              }
            );
          });
          resolve();
        },
        () => resolve()
      );
    });
  }
}
