/*
* Pour définir un nouveau filtre :
* 1. créer un nouveau objet filtre et le paramètre "enfants" du filtre parent,
* 2. mettre à jour affichageFiltres (et éventuellement treeFiltres si le filtre créé est un parent)
*/

import {Filtre} from '../model/util.model';
import {ES_FIELDS, TYPES_OBJET} from './utils.constants';

const composantes: Filtre = {
  lib: 'filtre.composantes',
  field: ES_FIELDS.COMPOSANTES,
  options: [],
  enfants: []
};

const localisations: Filtre = {
  lib: 'filtre.localisations',
  field: ES_FIELDS.LOCALISATIONS,
  options: [],
  enfants: []
};

const niveauxEntree: Filtre = {
  lib: 'filtre.niveaux_entree',
  field: ES_FIELDS.NIVEAU_ENTREE,
  options: [],
  enfants: []
};

const disciplines: Filtre = {
  lib: 'filtre.disciplines',
  field: ES_FIELDS.DISCIPLINES,
  options: [],
  enfants: []
};

const domaines: Filtre = {
  lib: 'filtre.domaines',
  field: ES_FIELDS.DOMAINES,
  options: [],
  enfants: []
};

const diplomes: Filtre = {
  lib: 'filtre.types_formation',
  field: ES_FIELDS.TYPE_DIPLOME,
  options: [],
  enfants: []
};

const typesInscription: Filtre = {
  lib: 'filtre.inscriptions',
  field: ES_FIELDS.INSCRIPTIONS,
  options: [],
  enfants: []
};

const langues: Filtre = {
  lib: 'filtre.langues',
  field: ES_FIELDS.LANGUES,
  options: [],
  enfants: []
};

const ouvertureMobilite: Filtre = {
  lib: 'filtre.ouverture_mobilite',
  field: ES_FIELDS.OUVERTURE_MOBILITE,
  options: [],
  enfants: []
};

const typesObjet: Filtre = {
  lib: 'filtre.types_objet',
  field: ES_FIELDS.TYPE_OBJET,
  options: [],
  enfants: []
};


const enlight: Filtre = {
  lib: 'filtre.enlight',
  field: ES_FIELDS.ENLIGHT,
  options: [],
  enfants: []
};

export const TREE_FILTRES: Filtre[] = [
  diplomes,
  niveauxEntree,
  typesInscription,
  disciplines,
  domaines,
  localisations,
  composantes,
  ouvertureMobilite,
  langues,
  enlight,
  typesObjet
];

/***
 // Exemple Filtre avec enfants
 const typesObjet: Filtre = {
  lib: 'filtre.types_objet',
  field: ES_FIELD.TYPE_OBJET,
  options: [],
  enfants: [{
    key: TYPE_OBJET.FORMATION,
    value: [typesFormation, composantes, localisations, niveauxEntree, disciplines, typesInscription]
  }]
};

 export const TREE_FILTRES: Filtre[] = [typesObjet];
 ***/

// Ordre des filtres
export const AFFICHAGE_FILTRES: string[] = [
  ES_FIELDS.TYPE_DIPLOME,
  ES_FIELDS.NIVEAU_ENTREE,
  ES_FIELDS.INSCRIPTIONS,
  ES_FIELDS.DISCIPLINES,
  ES_FIELDS.DOMAINES,
  ES_FIELDS.LOCALISATIONS,
  ES_FIELDS.COMPOSANTES,
  ES_FIELDS.OUVERTURE_MOBILITE,
  ES_FIELDS.LANGUES,
];

export const filtresCatalogueInternational: Filtre[] = [
  {
    field: ES_FIELDS.TYPE_OBJET,
    options: [
      {key: TYPES_OBJET.ENSEIGNEMENT, value: true}
    ],
    enfants: []
  },
  {
    field: ES_FIELDS.OUVERTURE_MOBILITE,
    options: [
      {key: 'Oui', value: true},
      {key: 'Yes', value: true}
    ],
    enfants: []
  }
];

export const INTERNATIONAL_AFFICHAGE_FILTRES: Filtre[] = [
  ouvertureMobilite,
  langues,
  enlight,
];
