import {Injectable} from '@angular/core';
import {PanelInternationalisation} from '../../model/internationalisation.model';
import {DispoEctsResourceService} from '../resources/dispo-ects-resource.service';
import {PartenariatResourceService} from '../resources/partenariat-resource.service';
import {GofElement} from '../../model/util.model';
import {Dispositif, Partenariat} from '../../model/informations.model';
import {RefTypePartenariat} from '../../constants/referentiel/ref.constants';
import {getVersion} from '../../utils/version.utils';

@Injectable({
  providedIn: 'root'
})
export class ParcoursTypeInternationalisationService {

  panelInternationalisation: PanelInternationalisation = new PanelInternationalisation();

  constructor(private dispoEctsResourceService: DispoEctsResourceService,
              private partenariatResourceService: PartenariatResourceService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelInternationalisation> {
    return new Promise<PanelInternationalisation>(resolve => {
      Promise.all([
        this.getPartenariatsInternationaux(gofElement),
        this.getDispositifsInternationaux(gofElement),
      ])
        .then(() => resolve(this.panelInternationalisation));
    });
  }

  private getPartenariatsInternationaux(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.partenariatResourceService.getPartenariats(gofElement, true).subscribe(res => {
        const partenariatsInternationaux: Partenariat[] = res
          .map(p => new Partenariat(
              p.type.id,
              p.modalite,
              p.type,
              p.etablissements?.map(e => getVersion(gofElement, e)),
              p.etatConvention?.id
            )
          );

        this.panelInternationalisation.erasmus = partenariatsInternationaux.filter(p => p.typeId === RefTypePartenariat.ERASMUS);
        this.panelInternationalisation.cooperationsInternationales = partenariatsInternationaux.filter(p => p.typeId === RefTypePartenariat.COOPERATION_INTERNATIONALE);
        this.panelInternationalisation.conventionsPrivees = partenariatsInternationaux.filter(p => p.typeId === RefTypePartenariat.CONVENTION_INSTIT_ETRANGERE);

        resolve();
      },
        () => resolve());
    });
  }

  private getDispositifsInternationaux(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.dispoEctsResourceService.getDispositifsEcts(gofElement, 4).subscribe(res => {
        const dispositifsInternationaux: Dispositif[] = [];
        res.modalites.forEach(m => {
          const dispositif: Dispositif = new Dispositif(m.lib, m.desc, m.type?.lib);

          if (m.traductions?.length > 0) {
            const traductions: any = m.traductions.filter(t => t.langue.code === gofElement.langue);
            if (traductions.length > 0) {
              dispositif.intitule = traductions[0].lib;
              dispositif.details = traductions[0].desc;
            }
          }
          if (m.editos?.length > 0) {
            const editos: any = m.editos.filter(e => e.typePublic.id === gofElement.typePublic);
            if (editos.length > 0) {
              dispositif.intitule = editos[0].lib;
              dispositif.details = editos[0].desc;
            }
          }

          dispositifsInternationaux.push(dispositif);
        });

        this.panelInternationalisation.dispositifsInternationaux = dispositifsInternationaux;
        resolve();
      },
        () => resolve());
    });
  }
}
