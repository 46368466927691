import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from '@angular/core';
import {GofElement, ObjectAffichage} from '../../../../model/util.model';
import {DynamicFlatNode, PanelContenu} from '../../../../model/contenu.model';
import {PATH_DETAIL_FORMATION} from '../../../../constants/conf.constants';
import {TYPES_OBJET} from '../../../../constants/utils.constants';
import {LocalisationContenuService} from '../../../../services/contenu/localisation-contenu.service';
import {ListeEnseignementsContenuService} from '../../../../services/contenu/liste-enseignements-contenu.service';
import {LazyLoadService} from '../../../../services/lazy-load.service';
import {RefTypologieEns} from '../../../../constants/referentiel/ref.constants';
import {ensTypologieTrad} from '../../../../constants/translationMaps/trad.constants';

@Component({
  selector: 'app-programme-arbo',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './programme-arbo.component.html',
  styleUrls: ['./programme-arbo.component.scss']
})
export class ProgrammeArboComponent implements OnChanges, AfterViewInit {

  @Output() selectedNode: EventEmitter<ObjectAffichage> = new EventEmitter<ObjectAffichage>();
  @Input() gofElement: GofElement;
  @Input() contenus: PanelContenu[];
  @Input() typeTemp: string;

  PathDetailFormation = PATH_DETAIL_FORMATION;
  typeObjetEnum = TYPES_OBJET;
  ensTypologieTrad = ensTypologieTrad

  display: boolean;

  constructor(private lazyLoadScriptServiceService: LazyLoadService,
              private localisationContenuService: LocalisationContenuService,
              private listeEnseignementsContenuService: ListeEnseignementsContenuService) {
  }

  ngOnChanges(): void {
    this.display = false;
    this.contenus.forEach(contenu => {
      if (contenu && this.gofElement.annee) {
        const promises = new Array<Promise<void>>();
        contenu.noeuds.forEach(node => {
          promises.push(this._getchildren(node));
        });
        Promise.all(promises).then(() => {
          this.display = true;
          this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
  }

  private _getchildren(node: DynamicFlatNode): Promise<void> {
    return new Promise<void>(resolve => {
      switch (node.type) {
        case TYPES_OBJET.LOCALISATION: {
          this.localisationContenuService.getChildren(this.gofElement, node).then(children => {
            node.children = children;
            const promises = new Array<Promise<void>>();
            node.children.forEach(child => {
              promises.push(this._getchildren(child));
            });
            Promise.all(promises).then(() => resolve());
          });
          break;
        }
        case TYPES_OBJET.LISTE_ENSEIGNEMENTS: {
          this.listeEnseignementsContenuService.getChildren(this.gofElement, node).then(children => {
            node.children = children;
            const promises = new Array<Promise<void>>();
            node.children.forEach(child => {
              promises.push(this._getchildren(child));
            });
            Promise.all(promises).then(() => resolve());
          });
          break;
        }
        case TYPES_OBJET.ENSEIGNEMENT: {
          resolve();
          break;
        }
      }
    });
  }

  getEnsTypologieClass(typologieId:number): string {
    switch (typologieId) {
      case RefTypologieEns.SOCLE :
        return 'typologie-socle';
      case RefTypologieEns.OUVERTURE :
        return 'typologie-ouverture';
      case RefTypologieEns.PERSONNALISATION :
        return 'typologie-personnalisation';
      default :
        return '';
    }
  }

  getPaddingClass(levelNode: number): string {
    return 'padding-left-' + levelNode;
  }
}
