import {Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from '@angular/core';
import {PageEvent} from '../../model/util.model';

@Component({
  selector: 'app-paginateur',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './paginateur.component.html',
  styleUrls: ['./paginateur.component.scss']
})
export class PaginateurComponent implements OnChanges {

  @Output() pageEmitter: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  @Input() length: number;
  @Input() pageSize = 5;
  @Input() pageSizeOptions: number[] = [5, 10, 15, 20];

  pageIndex: number;
  first: number;
  last: number;
  pagesArray: number[];

  constructor() {
  }

  ngOnChanges(): void {
    this.init();
  }

  onOptionsChange(option: number): void {
    this.pageSize = option;
    this.init();
    this.pageEmitter.emit({
      pageIndex: this.pageIndex,
      length: this.length,
      pageSize: this.pageSize
    });
  }

  previous(): void {
    if (this.first > 1) {
      this.first = +this.first - +this.pageSize < 1 ? 1 : +this.first - +this.pageSize;
      this.pageIndex > 0 ? this.pageIndex-- : this.pageIndex = 0;
      this.last = this.last - +this.pageSize < this.pageSize ? this.pageSize : this.last - +this.pageSize;
      this.initPagesArray();
      this.pageEmitter.emit({
        pageIndex: this.pageIndex,
        length: this.length,
        pageSize: this.pageSize
      });
    }
  }

  next(): void {
    if (this.last < this.length) {
      this.pageIndex++;
      this.first += +this.pageSize;
      this.last = +this.last + this.pageSize < this.length ? this.last + this.pageSize : this.length;
      this.initPagesArray();
      this.pageEmitter.emit({
        pageIndex: this.pageIndex,
        length: this.length,
        pageSize: this.pageSize
      });
    }
  }

  private initPagesArray(): void {
    this.pagesArray = [];
    const lastItem: number = this.first + 4 < this.last ? this.last - 1 : this.first + 5;
    for (let i = this.first; i <= lastItem; i++) {
      this.pagesArray.push(i);
    }
  }

  private init(): void {
    this.pageIndex = 0;
    this.first = 1;
    this.last = this.pageSize < this.length ? this.pageSize : this.length;
    this.initPagesArray();
  }
}
