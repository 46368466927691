import {Injectable} from '@angular/core';
import {PanelRebonds} from '../../model/rebonds.model';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {PeriodeResourceService} from '../resources/periode-resource.service';
import {DiplomeResourceService} from '../resources/diplome-resource.service';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ParcoursTypeRebondsService {

  panelRebonds: PanelRebonds = new PanelRebonds();

  constructor(private periodeResourceService: PeriodeResourceService,
              private translateService:TranslateService) {
  }
  getPanel(gofElement: GofElement, parcours: any): Promise<PanelRebonds> {
    return new Promise<PanelRebonds>(resolve => {
      if (parcours.idPeriodePorteuse) {
        const gofElementPeriodePorteuse: GofElement = new GofElement(
          TYPES_OBJET.PERIODE,
          parcours.idPeriodePorteuse,
          gofElement.annee,
         gofElement.typePublic,
          gofElement.langue
        );

        this.periodeResourceService.getPeriode(gofElementPeriodePorteuse).subscribe(per => {
          let formationsPorteuses: ObjectAffichage[] = [];
          if (per.formations) {
             per.formations.forEach(f => {
               this.translateService.get(typesDiplomeTrad.get(f.type.typeDiplome.id))
                 .subscribe(typeDiplome => formationsPorteuses.push(new ObjectAffichage(f.objetId, typeDiplome + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION)));
             });
          }

          let cursusEnrichisPortes: ObjectAffichage[] = [];
          let cmisAssocies: ObjectAffichage[] = [];
          if (per.cursusEnrichi) {
            cursusEnrichisPortes = per.cursusEnrichi.filter(c => !c.isCMI).map(c => new ObjectAffichage(c.objetId, c.lib, c.typeLib));
            cmisAssocies = per.cursusEnrichi.filter(c => c.isCMI).map(c => new ObjectAffichage(c.objetId, c.lib, c.typeLib));
          }

          this.panelRebonds.formationsPorteuses = formationsPorteuses;
          this.panelRebonds.cursusEnrichisPortes = cursusEnrichisPortes;
          this.panelRebonds.cmisAssocies = cmisAssocies;
          resolve(this.panelRebonds);
        },
          () => resolve(this.panelRebonds));
      } else {
        resolve(new PanelRebonds());
      }
    });
  }

}
