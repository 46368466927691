import {ObjectAffichage} from './util.model';

export class PanelPresentation {
  constructor(public objetId?: number,
              public annee?: number,
              public niveauEntreeId?: number,
              public niveauSortieId?: number,
              public typeDiplomeId?: number,
              public intitule?: string,
              public objectif?: string,
              public duree?: string,
              public typeDureeId?: number,
              public credits?: string,
              public domaines?: string[],
              public disciplines?: string[],
              public composanteIds?: number[],
              public sniIds?: number[],
              public typeInscriptionIds?: number[],
              public formationsPorteuses?: ObjectAffichage[],
              public responsables?: string[],
              public siteIds?: number[],
              public suiviADistanceId?: number,
              public competences?: string[],
              public isTransitoire?: boolean,
              public isCMI?: boolean,
              public codeSI?: string,
              public cursusEnrichi?: ObjectAffichage[],
              public parcoursType?: ObjectAffichage[],
              public parcoursLibre?: ParcoursLibre[],
              public ensADistanceId?: number,
              public ensMobilite?: boolean,
              public ensIsTransverse?: boolean,
              public ensCodesSI?: string[],
              public ensNiveauId?: number,
              public ensTypologieId ?: number,
              public ensTypologieIds ?: number[],
              public ensModules?: string[],
              public refObject?: any) {
  }

  isDisplayed(): boolean {
    return this != null;
  }
}

export class ParcoursLibre {
  constructor(
    public objetId?: number,
    public lib?: string,
    public typeObjet?: string,
    public credits?: number,
    public objectif?: string,
    public debut?: number,
    public fin?: number,
    public duree?: string,
    public typeDuree?: any,
  ) {
  }


}
