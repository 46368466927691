import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Menu, PathItem} from '../../model/util.model';
import { GOF_LANGUAGE, PATH_ACCUEIL, PATH_DETAIL_FORMATION, PATH_PAR_DISCIPLINES } from "../../constants/conf.constants";
import {ParDisciplineService} from '../../services/par-discipline.service';
import {LazyLoadService} from '../../services/lazy-load.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractDocument} from '../../model/esDoc.model';
import {AppComponent} from '../../app.component';
import {UtilService} from '../../services/util.service';
import { GlobalStateService } from "../../services/global-state.service";

@Component({
  selector: 'app-par-secteurs-activite',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './par-disciplines.component.html',
  styleUrls: ['./par-disciplines.component.scss']
})
export class ParDisciplinesComponent implements OnInit {

  thread: PathItem[];
  menu: Menu[];

  pathDetailFormation = PATH_DETAIL_FORMATION;

  constructor(private parDisciplineService: ParDisciplineService,
              private lazyLoadScriptServiceService: LazyLoadService,
              private translateService: TranslateService,
              private globalStateService: GlobalStateService,
              private utilService: UtilService) {
    this.globalStateService.subscribe(GOF_LANGUAGE, () => {
      this.utilService.setHtmlHead("page.par_discipline", "universite_de_bordeaux_description", PATH_PAR_DISCIPLINES, "");
    });
  }

  ngOnInit(): void {
    this.utilService.setHtmlHead("page.par_discipline", "universite_de_bordeaux_description", PATH_PAR_DISCIPLINES, "");

    this.thread = [
      new PathItem('page.accueil', [PATH_ACCUEIL]),
      new PathItem('page.par_discipline', [PATH_PAR_DISCIPLINES])
    ];

    this.parDisciplineService.getNiveau1()
      .subscribe(res => {
        this.menu = res;
        this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
      });
  }

  getNiveau2(menu: Menu): void {
    this.parDisciplineService.getNiveau2(menu.intitule)
      .subscribe(res => {
        menu.sousmenus = res;
        this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
      });
  }

  getNiveau3(discipline: string, menu: Menu): void {
    this.parDisciplineService.getNiveau3(discipline, menu.intitule)
      .subscribe(res => menu.formationDocs = res);
  }

  onSelected(formation: AbstractDocument): void {
    AppComponent.CURRENT_YEAR = formation.annee;
  }
}
