import {Injectable} from '@angular/core';
import {PanelContenuHisto} from '../../model/contenu-histo.model';
import {GofElement} from '../../model/util.model';
import {FormationSchemaService} from './formation-schema.service';
import {TYPES_OBJET} from '../../constants/utils.constants';

@Injectable({
  providedIn: 'root'
})
export class ParcoursTypeSchemaService {
  constructor(private formationSchemaService: FormationSchemaService) {
  }

  public getPanel(gofElement: GofElement, parcours: any): Promise<PanelContenuHisto> {
    return new Promise<PanelContenuHisto>(resolve => {
      const gofElementFormation: GofElement = Object.assign({}, gofElement);
      gofElementFormation.typeObjet = TYPES_OBJET.FORMATION;
      gofElementFormation.id = parcours.formations[0].objetId;
      this.formationSchemaService.getPanel(gofElementFormation, gofElement.id).then(res => {
        resolve(res);
      });
    });
  }
}
