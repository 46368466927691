import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {GofElement} from '../../../model/util.model';
import {PanelPresentation} from '../../../model/presentation.model';
import {PanelBlocsCompetence} from '../../../model/blocs-competence.model';
import {PanelDebouches} from '../../../model/debouches.model';
import {PanelInformations} from '../../../model/informations.model';
import {PanelAcces} from '../../../model/acces.model';
import {PanelContacts} from '../../../model/contacts.model';
import {Document} from '../../../model/document.model';
import {DocumentResourceService} from '../../../services/resources/document-resource.service';
import {FormationSchemaService} from '../../../services/schema/formation-schema.service';
import {PanelContenuHisto} from '../../../model/contenu-histo.model';
import {GOF_COOKIES, PATH_DETAIL_FORMATION} from '../../../constants/conf.constants';
import {LazyLoadService} from '../../../services/lazy-load.service';
import {PanelPoursuitesEtudes} from '../../../model/poursuites-etudes.model';
import {PanelInternationalisation} from '../../../model/internationalisation.model';
import {RgpdDialogComponent} from '../../rgpd/rgpd-dialog/rgpd-dialog.component';
import {FormationAccesService} from '../../../services/acces/formation-acces.service';
import {FormationContactsService} from '../../../services/contacts/formation-contacts.service';
import {FormationDebouchesService} from '../../../services/debouches/formation-debouches.service';
import {FormationBlocsCompetenceService} from '../../../services/blocsCompetence/formation-blocs-competence.service';
import {FormationInformationsService} from '../../../services/informations/formation-informations.service';
import {FormationInternationalisationService} from '../../../services/internalionalisation/formation-internationalisation.service';
import {FormationPoursuitesEtudesService} from '../../../services/poursuites-etudes/formation-poursuites-etudes.service';
import {CookieService} from 'ngx-cookie-service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-fiche-formation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './fiche-formation.component.html',
  styleUrls: ['./fiche-formation.component.scss']
})
export class FicheFormationComponent implements AfterViewInit, OnChanges {

  @Input() gofElement: GofElement;
  @Input() presentation: PanelPresentation;

  @Output() loaded: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('rubriques') rubriques: ElementRef;

  acces: PanelAcces;
  blocsCompetence: PanelBlocsCompetence;
  contacts: PanelContacts;
  contenuHisto: PanelContenuHisto;
  debouches: PanelDebouches;
  informations: PanelInformations;
  internationalisation: PanelInternationalisation;
  poursuitesEtudes: PanelPoursuitesEtudes;

  documents: Document[];
  video: Document;
  statistiquesRecrutement: Document;

  schemaLoading: boolean;
  display: boolean;
  displayMore = false;

  innerWidth: number;
  videoHeight: number;

  pathDetailFormation = PATH_DETAIL_FORMATION;

  constructor(
    private accesService: FormationAccesService,
    private blocsCompetenceService: FormationBlocsCompetenceService,
    private contactsService: FormationContactsService,
    private schemaService: FormationSchemaService,
    private debouchesService: FormationDebouchesService,
    private documentService: DocumentResourceService,
    private informationsService: FormationInformationsService,
    private internationalisationService: FormationInternationalisationService,
    private poursuitesEtudesService: FormationPoursuitesEtudesService,
    private lazyLoadScriptServiceService: LazyLoadService,
    private cookieService: CookieService,
    private matDialog: MatDialog) {
    this.getVideoWindow();
  }

  ngAfterViewInit(): void {
    this.getContenuHisto();
  }

  ngOnChanges(): void {
    Promise.all([
     this.getAcces(),
      this.getBlocsCompetence(),
      this.getContacts(),
      this.getDebouches(),
      this.getDocuments(),
      this.getInformations(),
      this.getInternationalisation(),
      this.getPoursuitesEtudes()
    ]).then(() => {
      this.display = true;
      this.lazyLoadScriptServiceService.injectScript('/assets/js/course-detail.js');
      this.loaded.emit();
    });
  }

  getVideoId(): string {
    if (this.video) {
      const arr = this.video.url.split(/[\s/|=]+/);
      return arr[arr.length - 1];
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.getVideoWindow();
  }

  private getVideoWindow(): void {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth > 767) {
      this.innerWidth = window.innerWidth - 150;
    }
    this.videoHeight = this.innerWidth * 350 / 500;
  }

  private getDocuments(): Promise<void> {
    return new Promise<void>(resolve => {
      this.documentService.getDocuments(this.gofElement).subscribe(res => {
        this.documents = res.filter(d => d.affichage && d.storageName);

        const videos: Document[] = res.filter(d => d.affichage && !d.storageName && d.description !== 'insertion_pro');
        if (videos.length > 0) {
          this.video = videos[0];
        }

        const insertionsPro: Document[] = res.filter(d => d.affichage && !d.storageName && d.description === 'insertion_pro');
        if (insertionsPro.length > 0) {
          this.statistiquesRecrutement = insertionsPro[0];
        }

        resolve();
      });
    });
  }

  private getAcces(): Promise<void> {
    return new Promise<void>(resolve => {
      this.accesService.getPanel(this.gofElement).then(res => {
        this.acces = res;
        resolve();
      });
    });
  }

  private getBlocsCompetence(): Promise<void> {
    return new Promise<void>(resolve => {
      this.blocsCompetenceService.getPanel(this.gofElement).then(res => {
        this.blocsCompetence = res;
        resolve();
      });
    });
  }

  private getContacts(): Promise<void> {
    return new Promise<void>(resolve => {
      this.contactsService.getPanel(this.gofElement).then(res => {
        this.contacts = res;
        resolve();
      });
    });
  }

  private getContenuHisto(): Promise<void> {
    return new Promise<void>(resolve => {
      this.schemaLoading = true;
      this.schemaService.getPanel(this.gofElement, null).then(res => {
        this.schemaLoading = false;
        this.contenuHisto = res;
        resolve();
      });
    });
  }

  private getDebouches(): Promise<void> {
    return new Promise<void>(resolve => {
      this.debouchesService.getPanel(this.gofElement).then(res => {
        this.debouches = res;
        resolve();
      });
    });
  }

  private getInformations(): Promise<void> {
    return new Promise<void>(resolve => {
      this.informationsService.getPanel(this.gofElement).then(res => {
        this.informations = res;
        resolve();
      });
    });
  }

  private getInternationalisation(): Promise<void> {
    return new Promise<void>(resolve => {
      this.internationalisationService.getPanel(this.gofElement).then(res => {
        this.internationalisation = res;
        resolve();
      });
    });
  }

  private getPoursuitesEtudes(): Promise<void> {
    return new Promise<void>(resolve => {
      this.poursuitesEtudesService.getPanel(this.gofElement).then(res => {
        this.poursuitesEtudes = res;
        resolve();
      });
    });
  }

  cookieYoutube(): boolean {
    const cookies = this.cookieService.get(GOF_COOKIES);
    if (cookies) {
      return JSON.parse(cookies)?.youtube;
    }
    return false;
  }

  manageCookie(): void {
    this.matDialog.open(RgpdDialogComponent, {
      width: '850px'
    });
  }
}
