import { Injectable } from "@angular/core";
import { FormationResourceService } from "../resources/formation-resource.service";
import { GofElement, ObjectAffichage } from "../../model/util.model";
import { PanelContenuHisto, Colonne, Cellule } from "../../model/contenu-histo.model";
import { ObjetFormationResourceService } from "../resources/objet-formation-resource.service";
import { PeriodeResourceService } from "../resources/periode-resource.service";
import { TYPES_OBJET } from "../../constants/utils.constants";
import { RefTypePeriode } from "../../constants/referentiel/ref.constants";
import { from } from "rxjs";
import { groupBy, mergeMap, reduce, toArray } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class FormationSchemaService {
  panelContenuHisto: PanelContenuHisto;
  gofElement: GofElement;
  nbDuree: number;
  colonnes: Colonne[];

  constructor(private formationResourceService: FormationResourceService,
              private objetFormationResourceService: ObjetFormationResourceService,
              private periodeResourceService: PeriodeResourceService) {
  }

  public getPanel(gofElement: GofElement, filtreParcoursId: number): Promise<PanelContenuHisto> {
    this.panelContenuHisto = new PanelContenuHisto();
    this.gofElement = gofElement;
    this.colonnes = [];

    return new Promise<PanelContenuHisto>(PanelResolve => {
      this.formationResourceService.getFormation(this.gofElement).subscribe(formation => {
          this.panelContenuHisto.typeFormation = formation.type.lib;
          this.panelContenuHisto.duree = formation.duree;
          this.panelContenuHisto.typeDuree = formation.typeDuree.id;
          this.nbDuree = formation.duree;
          Promise.all([
            this.getAllParcoursType(formation),
            this.getAllCursus(),
            this.getAllParcoursLibres(formation)
          ])
            .then(() => {
              // filtre pour afficher le schéma pour la fiche parcours
              if(filtreParcoursId != null){
                this.colonnes = this.colonnes.filter(p => p.objectAffichage.id === filtreParcoursId);
              }

              // séparer les parcours par colspan
              let colColspanSup1 = this.colonnes.filter(c => c.colspan > 1);
              let colColspanEgale1 = this.colonnes.filter(c => c.colspan === 1);

              // trier les parcours colspan 1 par l'id de la periode la plus basse
              colColspanEgale1 = colColspanEgale1.sort((a, b) => {
                if (a.cellules[a.cellules.length - 1].objectAffichage.id < b.cellules[b.cellules.length - 1].objectAffichage.id) {
                  return 1;
                } else {
                  return -1;
                }
              });
              // formater les cellules en supprimant et adaptant les colspan des parcours triés
              this.formatColonnesWithColSpan(colColspanEgale1);
              // récuperer tous les parcours à afficher
              this.colonnes = colColspanEgale1;
              this.colonnes = this.colonnes.concat(colColspanSup1);
              this.panelContenuHisto.colonnes = this.colonnes;
              PanelResolve(this.panelContenuHisto);
            });
        },
        () => PanelResolve(this.panelContenuHisto));
    });
  }

  private getAllCursus(): Promise<void> {
    return new Promise<void>(allCursusResolve => {
      const promises = new Array<Promise<any>>();
      this.objetFormationResourceService.getCursus(this.gofElement).subscribe(cursusList => {
          cursusList.forEach((cursus, index) => promises.push(this.getCursus(cursus, index, cursusList.periodes)));
          Promise.all(promises).then(cursusCols => {
            this.colonnes = this.colonnes.concat(cursusCols);
            allCursusResolve();
          });
        },
        () => allCursusResolve());
    });
  }

  private getAllParcoursType(formation: any): Promise<void> {
    return new Promise<void>(allParcoursTypeResolve => {
      const promises = new Array<Promise<any>>();
      if (formation.parcoursType && formation.parcoursType.length > 0) {
        formation.parcoursType.forEach((pt, index) => promises.push(this.getParcoursTypes(pt, formation.objetId, index)));
        promises.push(this.simulateParcours(formation));
        Promise.all(promises).then(ptCols => {
          ptCols = ptCols.flatMap(ptCols => ptCols).filter(col => col);
          this.colonnes = this.colonnes.concat(ptCols);
          // console.log("this.colonnes", this.colonnes);
          allParcoursTypeResolve();
        });

      } else { // si aucun parcours-type défini (ex. DU)
        this.simulateParcours(formation).then(ptCols => {
          if (ptCols) {
            ptCols = ptCols.filter(col => col);
            this.colonnes = this.colonnes.concat(ptCols);
          }
          allParcoursTypeResolve();
        });
      }
    });
  }

  private getAllParcoursLibres(formation: any): Promise<void> {
    return new Promise<void>(allParcoursLibresResolve => {
      const promises = new Array<Promise<any>>();
      formation.parcoursLibre.forEach((pt, index) => promises.push(this.getParcoursLibre(pt, index)));
      Promise.all(promises).then(ptCols => {
        this.colonnes = this.colonnes.concat(ptCols);
        allParcoursLibresResolve();
      });
    });
  }

  private getParcoursLibre(parcours: any, index: number): Promise<Colonne> {
    return new Promise<Colonne>(parcoursLibreResolve => {
      const objectAffichage: ObjectAffichage = new ObjectAffichage(parcours.objetId, parcours.lib, TYPES_OBJET.PARCOURS_LIBRE);
      const cellules: Cellule[] = [];

      this.getCellules(objectAffichage, cellules, 1, [parcours], 0, index + this.colonnes.length - 1).then(() => {
        parcoursLibreResolve({
          style: TYPES_OBJET.PARCOURS_LIBRE,
          isCMI: false,
          colspan: 1,
          objectAffichage,
          cellules
        });
      });
    });
  }

  private getCursus(cursus: any, index: number, periodes: any): Promise<Colonne> {
    return new Promise<Colonne>(cursusResolve => {
      const objectAffichage: ObjectAffichage = new ObjectAffichage(cursus.objetId, cursus.lib, TYPES_OBJET.CURSUS_ENRICHI);
      const cellules: Cellule[] = [];

      this.getCellules(objectAffichage, cellules, 1, periodes, 0, index + this.colonnes.length - 1).then(() => {
        cursusResolve({
          style: cursus.cmi ? TYPES_OBJET.CMI : TYPES_OBJET.CURSUS_ENRICHI,
          isCMI: cursus.cmi,
          colspan: this.getParcoursColspan(periodes),
          objectAffichage,
          cellules
        });
      });
    });
  }

  private getParcoursTypes(pt: any, formationObjetId: number, index: number): Promise<Colonne[]> {
    return new Promise<Colonne[]>(parcoursTypeResolve => {
      const parcours: ObjectAffichage = new ObjectAffichage(pt.objetId, pt.lib, TYPES_OBJET.PARCOURS_TYPE);
      let ptColSpan = 1;

      if (pt.idsPeriodesPorteuses?.length > 0) {
        const promises: Promise<Colonne>[] = [];
        pt.idsPeriodesPorteuses.forEach(idPeriodePorteuse => promises.push(this.getColonneParcours(idPeriodePorteuse, formationObjetId, parcours, ptColSpan, index)));
        Promise.all(promises).then(res => parcoursTypeResolve(res));
      } else {
        const cellules: Cellule[] = [];
        this.getCellules(parcours, cellules, ptColSpan, [], 0, index).then(() => {
          parcoursTypeResolve([{
            style: TYPES_OBJET.PARCOURS_TYPE,
            isCMI: false,
            colspan: 1,
            objectAffichage: parcours,
            cellules
          }]);
        });
      }
    });
  }

  private getColonneParcours(idPeriodePorteuse: number, formationObjetId: number, parcours: ObjectAffichage, ptColSpan: number, index: number): Promise<Colonne> {
    return new Promise<Colonne>(resolve => {
      const cellules: Cellule[] = [];
      this.periodeResourceService.getPeriodesPrecFromPeriodePorteuse(idPeriodePorteuse, formationObjetId, this.gofElement.annee)
        .subscribe(periodes => {
            ptColSpan = this.getParcoursColspan(periodes);
            this.getCellules(parcours, cellules, ptColSpan, periodes, 0, index).then(() => {
              resolve({
                isCMI: false,
                style: TYPES_OBJET.PARCOURS_TYPE,
                colspan: ptColSpan,
                objectAffichage: parcours,
                cellules,
                periodes
              });
            });
          },
          () => resolve(null));
    });

  }

  private getCellules(parcours: ObjectAffichage, cellules: Cellule[], ptColSpan: number, periodes: any[], rowIndex: number, colIndex: number): Promise<void> {
    return new Promise<void>(cellulesResolve => {
      if (periodes?.length > 0) {
        periodes.forEach(periode => {
          // si la dernière periode du parcours se termine avant dernier semestre
          if (periode.fin < this.nbDuree - rowIndex) {
            cellules.push({
              objectAffichage: new ObjectAffichage(null, "", null),
              debut: periode.fin + 1,
              fin: this.nbDuree,
              type: null,
              rowIndex,
              rowspan: (this.nbDuree - rowIndex) - periode.fin,
              colIndex,
              colspan: ptColSpan,
              style: "display-none"
            });
          }

          rowIndex += (this.nbDuree - rowIndex) - periode.fin;

          let colspan = this._getPeriodColspan(periodes, periode);
          cellules.push({
            objectAffichage: new ObjectAffichage(periode.objetId, periode.lib, TYPES_OBJET.PERIODE),
            type: periode.type?.id,
            debut: periode.debut,
            fin: periode.fin,
            rowIndex,
            rowspan: periode.duree,
            colIndex,
            colspan,
            style: ""
          });

          rowIndex += periode.duree;
          cellulesResolve();
        });

        if (rowIndex < this.nbDuree) {
          cellules.push({
            objectAffichage: new ObjectAffichage(null, "", null),
            type: null,
            debut: null,
            fin: null,
            rowIndex,
            rowspan: this.nbDuree - rowIndex,
            colIndex,
            colspan: ptColSpan,
            style: "display-none"
          });
        }

        // Si pas de périodes définies
      } else if (cellules?.length === 0) {
        cellules.push({
          objectAffichage: parcours,
          type: null,
          debut: 1,
          fin: this.nbDuree,
          rowIndex: 0,
          rowspan: this.nbDuree,
          colIndex,
          colspan: ptColSpan,
          style: ""
        });

        cellulesResolve();

      } else { // si seulement la periode porteuse
        if (rowIndex < this.nbDuree && parcours.typeObjet !== TYPES_OBJET.PARCOURS_LIBRE) {
          cellules.push({
            objectAffichage: new ObjectAffichage(null, "", null),
            type: null,
            debut: null,
            fin: null,
            rowIndex,
            rowspan: this.nbDuree - rowIndex,
            colIndex,
            colspan: ptColSpan,
            style: "display-none"
          });
        }

        cellulesResolve();
      }
    });
  }

  private _getPeriodColspan(periodes: any[], periode: any): number {
    if (periode.periodesPrecedentes?.length > 0) {
      let colspan = 0;
      periodes = periodes.reduce((unique, item) => (unique.map(u => u.objetId).includes(item.objetId) ? unique : [...unique, item]), []);
      periodes
        .filter(p => periode.periodesPrecedentes.map(p => p.objetId).includes(p.objetId))
        .forEach(p => {
          colspan += this._getPeriodColspan(periodes, p);
        });
      return colspan;
    } else {
      return 1;
    }
  }

  private getParcoursColspan(periodes: any[]): number {
    let res = 1;

    if (periodes) {
      let tmp = 1;
      let fin;
      let debut;
      periodes.forEach(p => {
        if ((!fin && !debut) || (debut > p.fin)) {
          res = tmp;
          fin = p.fin;
          debut = p.debut;
        } else {
          tmp++;
          fin = debut;
          debut = p.debut;
        }
      });

      if (tmp > res) {
        res = tmp;
      }
    }
    return res;
  }

  private simulateParcours(formation: any): Promise<any> {
    return new Promise<any>(parcoursResolve => {
      this.formationResourceService.getPeriodes(this.gofElement).subscribe(allPeriodes => {
          const periodesFinalesSansParcours: any[] = allPeriodes.filter(periode =>
            [RefTypePeriode.PERIODE_DE_SPECIALISATION, RefTypePeriode.CONTENU].includes(periode.type.id) &&
            !formation.parcoursType
              .flatMap(parcours => parcours.idsPeriodesPorteuses)
              .includes(periode.objetId) &&
            (!periode.periodesSuivantes || periode.periodesSuivantes?.length === 0)
          );

          if (periodesFinalesSansParcours?.length > 0) {
            const promisesPeriodesLists = new Array<Promise<any>>();
            periodesFinalesSansParcours.forEach(p => promisesPeriodesLists.push(this.getPeriodesFromPeriodeFinale(p.objetId, formation.objetId, this.gofElement.annee)));
            Promise.all(promisesPeriodesLists).then(periodes => {
              const promisesParcours = new Array<Promise<any>>();
              periodes.forEach(periodesList => promisesParcours.push(this.getParcoursFromPeriodes(periodesList)));
              Promise.all(promisesParcours).then(allParcours => {
                parcoursResolve(allParcours);
              });
            });

          } else {
            parcoursResolve(null);
          }
        },
        () => parcoursResolve(null));
    });
  }

  private getPeriodesFromPeriodeFinale(idObjetPeriodePorteuse: number, idObjetFormation: number, annee: number): Promise<any[]> {
    return new Promise<any[]>(resolve => {
      this.periodeResourceService.getPeriodesPrecFromPeriodePorteuse(idObjetPeriodePorteuse, idObjetFormation, annee)
        .subscribe(res => {
            resolve(res);
          },
          () => resolve(null));
    });
  }

  private getParcoursFromPeriodes(periodes: any[]): Promise<any> {
    return new Promise<any>(resolve => {
      const objectAffichage: ObjectAffichage = new ObjectAffichage(null, "", null);
      const cellules: Cellule[] = [];
      const ptColSpan = this.getParcoursColspan(periodes);
      this.getCellules(objectAffichage, cellules, ptColSpan, periodes, 0, 0).then(() => {
        resolve({
          style: TYPES_OBJET.PARCOURS_TYPE,
          isCMI: false,
          colspan: 1,
          objectAffichage,
          periodes: periodes,
          cellules
        });
      });
    });
  }

  private formatColonnesWithColSpan(colonnes: Colonne[]) {
    let cellIdTmp: number;
    let indexColDeb: number = 0;
    let indexColFin: number = 0;
    let maptmp: Map<number, number[]> = new Map();
    for (let i = 0; i < Math.max(...colonnes.map(col => col.cellules.length)); i++) {
      colonnes.forEach((c, index) => {
        let cellules: Cellule[] = c.cellules.map((e, i, a) => a[(a.length - 1) - i]);
        if (cellules[i]) {
          if (cellules[i].objectAffichage.id !== cellIdTmp) {

            if (indexColFin > indexColDeb) {
              colonnes[indexColDeb].cellules.filter(c => c.objectAffichage.id === cellIdTmp)[0].colspan = indexColFin + 1 - indexColDeb;
              for (let colIndex = indexColDeb + 1; colIndex <= indexColFin; colIndex++) {
                if (maptmp.get(colIndex)) {
                  maptmp.get(colIndex).push(cellIdTmp);
                } else {
                  maptmp.set(colIndex, [cellIdTmp]);
                }
              }
            }
            cellIdTmp = cellules[i].objectAffichage.id;
            indexColFin = index;
            indexColDeb = index;

          } else {
            indexColFin++;
          }
        }
      });
    }
    // remove cells
    maptmp.forEach((value, key) => {
      if (colonnes[key]) {
        colonnes[key].cellules = colonnes[key].cellules.filter(c => !value.includes(c.objectAffichage.id));
      }
    });
  }

  private sort(colonnes: Colonne[]): void {
    let colArraySimpleColspan = colonnes.filter(c => c.colspan === 1);
    let colArrayMultipleColspan = colonnes.filter(c => c.colspan > 1);
    colArraySimpleColspan = this._sort(colArraySimpleColspan, 0, Math.max(...colonnes.map(pt => pt.cellules.length)));

    colonnes = colArrayMultipleColspan;
    colonnes.concat(colArraySimpleColspan);
  }

  private _sort(colonnes: Colonne[], index: number, maxLength: number): any {
    let res: Colonne[] = [];
    from(colonnes)
      .pipe(
        groupBy((colonne) => colonne.cellules.reverse()[index]?.objectAffichage.id),
        mergeMap(group => group.pipe(
            reduce((acc: { key: number, values: Colonne[] }, cur) => {
                acc.values.push(cur);
                return acc;
              }, { key: group.key, values: [] }
            )
          )
        ), toArray()
      ).subscribe(keyValueList => {
      keyValueList
        .sort((a: { key: number, values: Colonne[] }, b: { key: number, values: Colonne[] }) => {
          return a.values?.length > b.values?.length ? 1 : -1;
        })
        .forEach(keyValue => {
          if (keyValue.key) {
            res = res.concat(this._sort(keyValue.values, index + 1, maxLength));
          } else {
            return res;
          }
        });
    });
  }

}


