import {Component, Input, OnChanges, ViewEncapsulation} from '@angular/core';
import {DynamicFlatNode, PanelContenu} from '../../../../model/contenu.model';
import {AppComponent} from '../../../../app.component';
import {GOF_PROFILE, PATH_DETAIL_FORMATION} from '../../../../constants/conf.constants';
import {TranslateService} from '@ngx-translate/core';
import {ParcoursLibre} from '../../../../model/presentation.model';
import {TYPES_OBJET} from '../../../../constants/utils.constants';
import {LazyLoadService} from '../../../../services/lazy-load.service';
import {ContenuService} from '../../../../services/contenu.service';
import {CookieService} from 'ngx-cookie-service';
import {RefTypologieEns} from '../../../../constants/referentiel/ref.constants';
import {ensTypologieTrad} from '../../../../constants/translationMaps/trad.constants';

@Component({
  selector: 'app-programme-parcours-libre',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './programme-parcours-libre.component.html',
  styleUrls: ['./programme-parcours-libre.component.scss']
})
export class ProgrammeParcoursLibreComponent implements OnChanges {

  @Input() parcoursLibre: ParcoursLibre;

  contenu: PanelContenu;
  annee = AppComponent.CURRENT_YEAR;

  typeObjet = TYPES_OBJET;
  pathDetailFormation = PATH_DETAIL_FORMATION;

  ensTypologieTrad= ensTypologieTrad;

  constructor(private contenuService: ContenuService,
              private lazyLoadScriptServiceService: LazyLoadService,
              private cookiesService: CookieService,
              private translateService: TranslateService) {
  }

  ngOnChanges(): void {
    this.contenuService.getPanel({
      id: this.parcoursLibre.objetId,
      typeObjet: this.parcoursLibre.typeObjet,
      typePublic: this.cookiesService.get(GOF_PROFILE),
      annee: this.annee,
      langue: this.translateService.currentLang
    }, this.parcoursLibre).then(res => {
      this.contenu = res;
      this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
    });
  }


  getChildren(node: DynamicFlatNode): void {
    this.contenuService.getChildren({
      id: this.parcoursLibre.objetId,
      typeObjet: this.parcoursLibre.typeObjet,
      typePublic: this.cookiesService.get(GOF_PROFILE),
      annee: this.annee,
      langue: this.translateService.currentLang
    }, node).then(res => {
      node.children = res;
      this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
    });
  }

  getEnsTypologieClass(ensTypologieId: number): string {
    switch (ensTypologieId) {
      case RefTypologieEns.SOCLE :
        return 'typologie-socle';
      case RefTypologieEns.OUVERTURE :
        return 'typologie-ouverture';
      case RefTypologieEns.PERSONNALISATION :
        return 'typologie-personnalisation';
      default :
        return '';
    }
  }

  getEnsNiveauClass(ensNiveauId: number): string {
    switch (ensNiveauId) {
      case 1 :
        return 'level level-1';
      case 2:
        return 'level level-2';
      case 3 :
        return 'level level-3';
      default :
        return '';
    }
  }

  getEnsNiveauCharCode(EnsNiveauId: number): string {
    switch (EnsNiveauId) {
      case 1 :
        return String.fromCharCode(9312);
      case 2:
        return String.fromCharCode(9313);
      case 3 :
        return String.fromCharCode(9314);
      default :
        return '';
    }
  }


}
