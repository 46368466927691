<app-loader *ngIf="!display"></app-loader>

<div *ngIf="display">
  <div id="course-detail-nav-container"
       class="content-part content-part-bg content-part-bg-light course-detail-nav-container">
    <nav class="course-detail-nav" aria-label="Navigation interne" role="navigation">
      <div class="course-detail-nav-inner">
        <ul class="list-unstyled">
          <li
            *ngIf="presentation.objectif?.length > 0 || competences?.isDisplayed() || internationalisation?.isDisplayed()">
            <a appAnchor
               id="#presentation"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.presentation.title' | translate}}"
               target="#presentationAnchor">{{'fiche.presentation.title' | translate}}</a>
          </li>
          <li *ngIf="contenus[0]?.isDisplayed() || contenuHisto?.isDisplayed()">
            <a appAnchor
               id="#programme"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.programme.title' | translate}}"
               target="#programmeAnchor">{{'fiche.programme.title' | translate}}</a>
          </li>
          <li *ngIf="debouches?.isDisplayed() || poursuitesEtudes?.isDisplayed()">
            <a appAnchor
               id="#et-apres"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.orientation_poursuite_etudes_reussite.title' | translate}}"
               target="#debouchesAnchor">{{'fiche.orientation_poursuite_etudes_reussite.title' | translate}}</a>
          </li>
          <li *ngIf="acces?.isDisplayed()">
            <a appAnchor
               id="#admission"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.admission.title' | translate}}"
               target="#admissionAnchor">{{'fiche.admission.title' | translate}}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <!-- PRESENTATION -->
  <div class="anchor" id=presentationAnchor></div>
  <div id="presentation" class="content-part content-part-no-pad-top"
       *ngIf="presentation.objectif?.length > 0 || competences?.isDisplayed() || internationalisation?.isDisplayed()">
    <div class="course-detail-top margin-section">

      <div class="row row-flex row-gutters-xlarge margin-container-large">
        <div
          class="layout-col-sm-large layout-col-lg-medium col-xs-12 col-lg-8 margin-large course-detail-top-main course-detail-top-main-expandable">

          <div class="course-detail-top-main-outer">
            <div class="course-detail-top-main-inner">

              <div class="margin-large margin-lg-xlarge">
                <h2>{{'fiche.presentation.title' | translate}}</h2>
              </div>

              <!-- objectifs -->
              <div class="margin-large margin-lg-xlarge" *ngIf="presentation.objectif?.length > 0">
                <h3>{{'fiche.presentation.objectifs' | translate}}</h3>
                <div [innerHTML]="presentation.objectif"></div>
              </div>

              <!-- compétences -->
              <app-competences [competences]="competences" *ngIf="competences?.isDisplayed()"></app-competences>

              <!-- internationalisation -->
              <div class="margin-large margin-lg-xlarge" *ngIf="internationalisation?.isDisplayed()">
                <h3>{{'fiche.international.title' | translate}}</h3>
                <app-internationalisation [internationalisation]="internationalisation"></app-internationalisation>
              </div>

            </div>
          </div>


          <p class="course-detail-top-main-expand-controls">
            <button type="button" class="course-detail-top-main-expand-button">
                      <span class="course-detail-top-main-expand-button-text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                             aria-hidden="true">
                          <path
                            d="M16 2.267c-7.585 0-13.733 6.149-13.733 13.733s6.149 13.733 13.733 13.733c7.585 0 13.733-6.149 13.733-13.733v0c-0.009-7.581-6.152-13.724-13.732-13.733h-0.001zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16v0c0-8.837 7.163-16 16-16v0z"></path>
                          <path d="M17.133 24h-2.267v-16h2.267z"></path>
                          <path d="M8 17.133v-2.267h16v2.267z"></path>
                        </svg>
                        {{'fiche.presentation.afficher_plus' | translate}}
                      </span>
              <span
                class="course-detail-top-main-expand-button-text course-detail-top-main-expand-button-text-expanded">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                             aria-hidden="true">
                          <path
                            d="M16 2.267c-7.585 0-13.733 6.149-13.733 13.733s6.149 13.733 13.733 13.733c7.585 0 13.733-6.149 13.733-13.733v0c-0.009-7.581-6.152-13.724-13.732-13.733h-0.001zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16v0c0-8.837 7.163-16 16-16v0z"></path>
                          <path d="M8 17.133v-2.267h16v2.267z"></path>
                        </svg>
                {{'fiche.presentation.afficher_moins' | translate}}
                      </span>
            </button>
          </p>
        </div>

        <div class="layout-col-sm-large layout-col-lg-small col-xs-12 col-lg-4 margin-large course-detail-top-side">
          <app-fiche-column [gofElement]="gofElement"
                            [presentation]="presentation"
                            [contacts]="contacts"
                            [rebonds]="rebonds">
          </app-fiche-column>
        </div>
      </div>

    </div>
  </div>

  <!-- PROGRAMME -->
  <div class="anchor" id="programmeAnchor"></div>
  <div id="programme" class="content-part content-part-no-pad-top"
       *ngIf="contenus[0]?.isDisplayed() || contenuHisto?.isDisplayed()">
    <div class="margin-section">
      <h2>{{'fiche.programme.title' | translate}}</h2>
    </div>

    <!--  arbo  -->
    <div class="margin-large margin-lg-xlarge">
      <app-loader *ngIf="contenuLoading"></app-loader>
      <app-programme-arbo [contenus]="contenus"
                          [typeTemp]="typeTemp"
                          [gofElement]="gofElement"
                          *ngIf="!contenuLoading"></app-programme-arbo>
    </div>

    <!-- schéma -->
    <div class="margin-large margin-lg-xlarge">
      <h3>{{'fiche.programme.schema_parcours' | translate}}</h3>
      <app-loader *ngIf="schemaLoading"></app-loader>
      <app-programme-schema [libelleMention]="presentation.formationsPorteuses[0].intitule"
                            [contenuHisto]="contenuHisto"
                            *ngIf="!schemaLoading">
      </app-programme-schema>
    </div>
  </div>

  <!-- ORIENTATION ET DEBOUCHES -->
  <div class="anchor" id="debouchesAnchor"></div>
  <div id="et-apres" class="content-part content-part-no-pad-top"
       *ngIf="debouches?.isDisplayed() || poursuitesEtudes?.isDisplayed()">
    <div class="margin-section">
      <h2>{{'fiche.orientation_poursuite_etudes_debouches_pro.title' | translate}}</h2>
    </div>

    <div class="margin-large margin-lg-xlarge" *ngIf="debouches?.isDisplayed()">
      <h3>{{'fiche.debouches_pro.title' | translate}}</h3>
      <app-debouches [debouches]="debouches"></app-debouches>
    </div>

    <div class="margin-large margin-lg-xlarge" *ngIf="poursuitesEtudes?.isDisplayed()">
      <h3>{{'fiche.poursuites_etudes.title' | translate}}</h3>
      <app-poursuites-etudes [poursuitesEtudes]="poursuitesEtudes"></app-poursuites-etudes>
    </div>
  </div>

  <!-- ADMISSION -->
  <div class="anchor" id="admissionAnchor"></div>
  <div id="admission" class="content-part content-part-no-pad-top" *ngIf="acces?.isDisplayed()">
    <div class="margin-section">
      <h2>{{'fiche.admission.title' | translate}}</h2>
    </div>

    <app-acces [acces]="acces"></app-acces>
  </div>
</div>
