import {Injectable} from '@angular/core';
import {PanelContenu, DynamicFlatNode} from '../../model/contenu.model';
import {GofElement} from '../../model/util.model';
import {ObjetFormationResourceService} from '../resources/objet-formation-resource.service';
import {TYPES_OBJET} from '../../constants/utils.constants';

@Injectable({
  providedIn: 'root'
})
export class CursusEnrichiContenuService {

  panelContenu: PanelContenu = new PanelContenu();

  constructor(private objetFormationResourceService: ObjetFormationResourceService) {
  }

  getContenu(gofElement: GofElement, cursus: any): Promise<PanelContenu> {
    return new Promise<PanelContenu>(resolve => {
      const node: DynamicFlatNode = {
        name: cursus.lib,
        objetId: cursus.objetId,
        supId: gofElement.id,
        type: TYPES_OBJET.CURSUS_ENRICHI,
        ects: cursus.ects,
        level: 1,
        expandable: cursus.periodes?.length > 0,
        clickable: true,
        isLoading: false
      };
      this.getChildren(gofElement, node).then(res => {
        this.panelContenu.objetFormation = node;
        this.panelContenu.noeuds = res;
        resolve(this.panelContenu);
      });
    });
  }

  /***
   * Récupération des périodes
   */
  getChildren(ge: GofElement, node: DynamicFlatNode): Promise<DynamicFlatNode[]> {
    const cursusId = node.objetId;
    const gofElement: GofElement = new GofElement(
      node.type,
      node.objetId,
      ge.annee,
      ge.typePublic,
      ge.langue
    );

    return new Promise<DynamicFlatNode[]>(resolve => {
      const children: DynamicFlatNode[] = [];

      this.objetFormationResourceService.getObjetFormation(gofElement).subscribe(obj => {

        if (obj.formations && obj.formations.length > 0) {
          gofElement.id = obj.formations[0].objetId;

          this.objetFormationResourceService.getCursus(gofElement).subscribe(cursusList => {
            cursusList = cursusList.filter(c => c.objetId = cursusId);
            if (cursusList.length > 0) {
              cursusList[0].periodes.forEach(per => {
                children.push({
                  name: per.lib,
                  objetId: per.objetId,
                  supId: obj.formations[0].objetId, // objetId de la formation
                  type: TYPES_OBJET.PERIODE,
                  ects: per.ects,
                  level: node.level + 1,
                  expandable: true,
                  clickable: true,
                  isLoading: false
                });
              });
            }
            resolve(children);
          },
            () => resolve(children));
        }
      });
    });
  }
}
