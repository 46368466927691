import {Acquis} from './acquis.model';

export class PanelModalitesAcquisition {
  constructor(public id?: number,
              public modalitesAffichage?: ModaliteBlocAffichage[],
              public mea?: Mea[],
              public regleObtEns?: RegleObtEns[]) {
  }

  isDisplayed(): boolean {
    return this && (this.modalitesAffichage?.length > 0 || this.mea?.length > 0 || this.regleObtEns?.length > 0);
  }
}

export class ModaliteBlocAffichage {
  constructor(public id?: number,
              public intitule?: string,
              public modalite?: string) {
  }
}

export class EvalEns {
  constructor(public id?: number,
              public nbSession?: number,
              public noEvalMotif?: string,
              public dateConseil?: Date,
              public hasEval?: boolean,
              public hasModule?: boolean,
  ) {
  }
}

export class Mea {
  constructor(public id?: number,
              public typeExamen?: string,
              public numSession?: number,
              public coefficient?: number,
              public coefficient2?: number,
              public detail?: string,
              public report2?: boolean,
              public dateValidation?: Date,
              public natureExamen?: string,
              public duree?: number,
              public acquis?: Acquis[]
  ) {
  }
}

export class RegleObtEns {
  constructor(public id?: number,
              public evalEns?: EvalEns,
              public regle?: string,
              public numSession?: number
  ) {
  }
}
