import {AfterViewInit, Component, Input, ViewEncapsulation} from '@angular/core';
import {PanelBlocsCompetence} from '../../../model/blocs-competence.model';
import {PATH_DETAIL_FORMATION} from '../../../constants/conf.constants';
import {LazyLoadService} from '../../../services/lazy-load.service';
import {ensTypologieTrad, typesCompetenceTrad} from '../../../constants/translationMaps/trad.constants';

@Component({
  selector: 'app-blocs-competence',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './blocs-competence.component.html',
  styleUrls: ['./blocs-competence.component.scss']
})
export class BlocsCompetenceComponent implements AfterViewInit {

  @Input() blocsCompetence: PanelBlocsCompetence;

  pathDetailFormation = PATH_DETAIL_FORMATION;

  typesCompetenceTrad = typesCompetenceTrad;
  ensTypologieTrad = ensTypologieTrad;

  constructor(private lazyLoadScriptServiceService: LazyLoadService) {
  }

  ngAfterViewInit(): void {
    this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
  }
}
