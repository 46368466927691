<div id="gof">
  <app-header #header
              [isNavExpanded]="isNavExpanded"
              [dyslexicFriendlyToggler]="dyslexicFriendlyToggler"
              [highContrastToggler]="highContrastToggler"
              [currentProfile]="currentProfile"></app-header>

  <main id="main" class="main" role="main">
    <div [class.main-inner]="displaySearch">
      <div [class.container]="displaySearch">
        <app-recherche-par-mots-cles #rechercheParMotsClesComponent id="search-module" [hidden]="!displaySearch"></app-recherche-par-mots-cles>
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>

  <app-footer id="footer"></app-footer>
</div>
