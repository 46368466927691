import {Injectable} from '@angular/core';
import {GofElement} from '../../model/util.model';
import {PanelInformations, EnseignementPro, Recherche, Dispositif} from '../../model/informations.model';
import {FormationResourceService} from '../resources/formation-resource.service';
import {DispositifInsertionResourceService} from '../resources/dispositif-insertion-resource.service';
import {EnseignementProResourceService} from '../resources/enseignement-pro-resource.service';
import {StageResourceService} from '../resources/stage-resource.service';
import {DispoEctsResourceService} from '../resources/dispo-ects-resource.service';
import {getVersion} from '../../utils/version.utils';

@Injectable({
  providedIn: 'root'
})
export class FormationInformationsService {

  panelInformations: PanelInformations = new PanelInformations();

  constructor(private formationResourceService: FormationResourceService,
              private dispositifInsertionResourceService: DispositifInsertionResourceService,
              private enseignementProResourceService: EnseignementProResourceService,
              private stageResourceService: StageResourceService,
              private dispoEctsResourceService: DispoEctsResourceService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelInformations> {
    return new Promise<PanelInformations>(resolve => {
      Promise.all([
        this.getAdossementRecherche(gofElement),
        this.getDispositifAideReussite(gofElement),
        this.getStage(gofElement),
        this.getEnseignementsPro(gofElement),
        this.getEngagementEtudiant(gofElement),
      ])
        .then(() => {
          resolve(this.panelInformations);
        });
    });
  }

  private getAdossementRecherche(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.formationResourceService.getRecherche(gofElement).subscribe(res => {
        let recherche: Recherche = null;
        if (res) {
          recherche = new Recherche(
            res.modalite,
            res.laboratoires?.map(l => getVersion(gofElement, l.structure))
          );

          if (res.traductions?.length > 0) {
            const traductions: any = res.traductions.filter(t => t.langue.code === gofElement.langue);
            if (traductions.length > 0) {
              recherche.modalite = traductions[0].modalite;
            }
          }
          if (res.editos?.length > 0) {
            const editos: any = res.editos.filter(e => e.typePublic.id === gofElement.typePublic);
            if (editos.length > 0) {
              recherche.modalite = editos[0].modalite;
            }
          }
        }
        this.panelInformations.adossementRecherche = recherche;
        resolve();
      },
        () => resolve());
    });
  }

  private getDispositifAideReussite(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.dispositifInsertionResourceService.getDispositifsInsertion(gofElement).subscribe(res => {
        const dispositifsAideReussite: Dispositif[] = [];
        res.dispositifs?.forEach(d => {
          const dispositif = new Dispositif(d.intitule, d.details, d.type?.lib);
          if (d.traductions?.length > 0) {
            const traductions: any = d.traductions.filter(t => t.langue.code === gofElement.langue);
            if (traductions.length > 0) {
              dispositif.details = traductions[0].details;
            }
          }
          if (d.editos?.length > 0) {
            const editos: any = d.editos.filter(e => e.typePublic.id === gofElement.typePublic);
            if (editos.length > 0) {
              dispositif.details = editos[0].details;
            }
          }

          dispositifsAideReussite.push(dispositif);
        });
        this.panelInformations.dispositifsAideReussite = dispositifsAideReussite;
        resolve();
      },
        () => resolve());
    });
  }


  private getEnseignementsPro(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.enseignementProResourceService.getEnseignementPro(gofElement).subscribe(res => {
        this.panelInformations.enseignementPro = new EnseignementPro();
        this.panelInformations.enseignementPro.partPro = res?.partPro;
        this.panelInformations.enseignementPro.rolePro = res?.rolePro;
        this.panelInformations.enseignementPro.entreprises = res?.entreprises?.map(ent => ent.lib);
        resolve();
      },
        () => resolve());
    });
  }

  private getStage(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.stageResourceService.getStages(gofElement).subscribe(stage => {
        this.panelInformations.stage = stage?.modalite;

        if (stage?.traductions?.length > 0) {
          const traductions: any = stage.traductions.filter(t => t.langue.code === gofElement.langue);
          if (traductions.length > 0) {
            this.panelInformations.stage = traductions[0].modalite;
          }
        }
        if (stage?.editos?.length > 0) {
          const editos: any = stage.editos.filter(e => e.typePublic.id === gofElement.typePublic);
          if (editos.length > 0) {
            this.panelInformations.stage = editos[0].modalite;
          }
        }
        resolve();
      },
        () => resolve());
    });
  }

  private getEngagementEtudiant(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.dispoEctsResourceService.getDispositifsEcts(gofElement, 1).subscribe(res => {
        const dispositifsEngagementEtudiant: Dispositif[] = [];
        res.modalites?.forEach(d => {
          const dispo = new Dispositif(d.lib, d.desc, getVersion(gofElement, d.type), d.nbEcts);
          if (d.traductions?.length > 0) {
            const traductions: any = d.traductions.filter(t => t.langue.code === gofElement.langue);
            if (traductions.length > 0) {
              dispo.intitule = traductions[0].lib;
              dispo.details = traductions[0].desc;
            }
          }
          if (d.editos?.length > 0) {
            const editos: any = d.editos.filter(e => e.typePublic.id === gofElement.typePublic);
            if (editos.length > 0) {
              dispo.intitule = editos[0].lib;
              dispo.details = editos[0].desc;
            }
          }
          dispositifsEngagementEtudiant.push(dispo);
        });

        this.panelInformations.dispositifsEngagementEtudiant = dispositifsEngagementEtudiant;
        resolve();
      },
        () => resolve());
    });
  }
}
