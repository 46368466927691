export class PanelDebouches {
  constructor(public id?: number,
              public rome?: TypeDebouche[],
              public naf?: TypeDebouche[],
              public secteursActivites?: string[],
              public emploisTypes?: string[]) {
  }

  isDisplayed(): boolean {
    return this && (
      this.secteursActivites?.length > 0
      || this.emploisTypes?.length > 0
      || this.rome?.length > 0
      || this.naf?.length > 0
    );
  }

}

export class TypeDebouche {
  constructor(public code: string,
              public libelle: string) {
  }
}
