import {Injectable} from '@angular/core';
import {Contact, PanelContacts} from '../../model/contacts.model';
import {GofElement} from '../../model/util.model';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {FormationResourceService} from '../resources/formation-resource.service';

@Injectable({
  providedIn: 'root'
})
export class ParcoursTypeContactsService {

  panelContacts: PanelContacts = new PanelContacts();

  constructor(private formationResourceService: FormationResourceService) {
  }

  getPanel(gofElement: GofElement, parcours: any): Promise<PanelContacts> {
    return new Promise<PanelContacts>(resolve => {
      if (parcours.idPeriodePorteuse) {
        this.formationResourceService.getContacts({
          id: parcours.idPeriodePorteuse,
          typeObjet: TYPES_OBJET.PERIODE,
          annee: gofElement.annee,
          typePublic: gofElement.typePublic,
          langue: gofElement.langue
        }).subscribe(res => {
          const contacts: Contact[] = [];
          res?.forEach(c => {
            contacts.push({
              id: c.id,
              lib: c.lib,
              nom: c.nom,
              mail: c.mail,
              telephone: c.telephone,
              adresse: c.adresse,
              ville: c.ville,
              cp: c.cp
            });
          });
          this.panelContacts.contacts = contacts;
          resolve(this.panelContacts);
        },
          () => resolve(this.panelContacts));
      } else {
        resolve(new PanelContacts());
      }
    });
  }
}
