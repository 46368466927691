import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ES_FIELDS } from "../../constants/utils.constants";
import {
  BREADCRUMB_THREAD,
  GOF_LANGUAGE,
  GOF_SEARCH, PATH_CATALOGUE_INTERNATIONAL,
  SEARCH_BY_KEYWORDS
} from "../../constants/conf.constants";
import { Filtre, GofElement, KeyValue, PageEvent, SearchItem } from "../../model/util.model";
import {
  filtresCatalogueInternational,
  INTERNATIONAL_AFFICHAGE_FILTRES
} from "../../constants/filtres.constants";
import { TranslateService } from "@ngx-translate/core";
import { ElasticsearchService } from "../../services/elasticsearch.service";
import { GlobalStateService } from "../../services/global-state.service";
import { displayFooter, hideFooter } from "../../utils/style.utils";
import { getLastSearch } from "../../utils/storage.utils";
import { FiltresComponent } from "../../components/results/filtres/filtres.component";
import { UtilService } from "../../services/util.service";

@Component({
  selector: "app-international-courses-catalogue",
  templateUrl: "./international-courses-catalogue.component.html",
  styleUrls: ["./international-courses-catalogue.component.scss"]
})
export class InternationalCoursesCatalogueComponent implements OnInit {

  @ViewChild(FiltresComponent) advancedFiltersComponent: FiltresComponent;
  @ViewChild("results") results: ElementRef;

  filtres: Filtre[] = INTERNATIONAL_AFFICHAGE_FILTRES;

  gofElement: GofElement;
  lastsearch: SearchItem;

  documents: any[] = [];
  filteredDocs: any[];
  paginedDocs: any[];

  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 15, 20];

  constructor(private elasticsearchService: ElasticsearchService,
              private translateService: TranslateService,
              private utilService: UtilService,
              private globalStateService: GlobalStateService) {

    this.globalStateService.subscribe(SEARCH_BY_KEYWORDS, documents => {
      hideFooter();
      this.lastsearch = getLastSearch();
      this.loadResults(documents);
    });

    this.globalStateService.subscribe(GOF_LANGUAGE, () => {
      this.utilService.setHtmlHead("page.international_courses_catalogue", "universite_de_bordeaux_description", PATH_CATALOGUE_INTERNATIONAL, "");
      const docIds: number[] = this.documents.map(d => d.id);
      this.elasticsearchService
        .getDocuments(docIds)
        .subscribe(documents => this.loadResults(documents));
    });
  }

  ngOnInit(): void {
    this.utilService.setHtmlHead("page.international_courses_catalogue", "universite_de_bordeaux_description", PATH_CATALOGUE_INTERNATIONAL, "");

    this.lastsearch = new SearchItem(null, null, filtresCatalogueInternational);
    sessionStorage.setItem(GOF_SEARCH, JSON.stringify([this.lastsearch]));
    this.elasticsearchService
      .search(null, null, filtresCatalogueInternational)
      .subscribe(documents => this.loadResults(documents));
    sessionStorage.setItem(BREADCRUMB_THREAD, JSON.stringify([]));
  }

  onPageEvent(pageEvent: PageEvent): void {
    setTimeout(() => {
      this.results.nativeElement.scrollIntoView({ behavior: "smooth" });
    }, 100);

    const start = pageEvent.pageIndex * pageEvent.pageSize;
    this.paginedDocs = [];
    if (this.filteredDocs) {
      this.paginedDocs = this.filteredDocs.slice(start, start + pageEvent.pageSize);
    }
  }

  onFilterEvent(filteredDocs: any[]): void {
    this.filteredDocs = filteredDocs;
    this.loadPaginedDocs();
  }

  loadResults(documents: any[]): void {
    this.initFiltres().then(() => {
      this.documents = documents;
      this.filteredDocs = documents;
      this.loadPaginedDocs();
      this.results.nativeElement.scrollIntoView({ behavior: "smooth" });
      displayFooter();
    });
  }

  private loadPaginedDocs(): void {
    if (this.filteredDocs) {
      this.paginedDocs = this.filteredDocs.slice(0, this.pageSize);
    }
  }

  private initFiltres(): Promise<void> {
    return new Promise<void>(resolve => {
      const promises: Promise<any>[] = [];
      this.filtres.forEach(filtre => promises.push(this.getFiltreOptions(filtre)));
      Promise.all(promises).then(() => {
        // supprimer l'option non du filtre Enlight
        this.translateService.get("global.yes").subscribe(res => {
          this.filtres.filter(f => f.field === ES_FIELDS.ENLIGHT)[0].options = this.filtres.filter(f => f.field === ES_FIELDS.ENLIGHT)[0].options
            .filter(o => o.key === res);
        });
        resolve();
      });
    });
  }

  private getFiltreOptions(filtre: Filtre): Promise<void> {
    return new Promise<void>(resolve => {
      const lastSearchFilters: Filtre = this.lastsearch.filters?.filter(f => f.field === filtre.field)[0];
      this.elasticsearchService.getOptions(this.lastsearch.value, this.lastsearch.field, filtre.field, this.lastsearch.filters)
        .subscribe(options => {
          filtre.options = options.map(o =>
            new KeyValue(
              o,
              lastSearchFilters?.options?.filter(opt => opt.key === o).length > 0 ?
                lastSearchFilters.options.filter(opt => opt.key === o)[0].value : false
            )
          );
          resolve();
        });
    });
  }
}
