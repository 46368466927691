<article class="search-results-list-item" *ngIf="document">
  <div class="card-item search-results-list-item-inner">
    <div class="card-item-text search-results-list-item-text">

      <div class="card-item-text-inner search-results-list-item-text-inner">
        <h3 [id]="getDomId(document)" class="card-item-title search-results-list-item-title">{{document.intitule}}</h3>

        <div class="search-results-list-item-description">
          <p>{{getResume(document.objectif)}}</p>
        </div>

        <p class="search-results-list-item-info-line">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true"
               class="search-results-list-item-info-line-icon">
            <path
              d="M30.317 10.549l-13.904-7.415c-0.127-0.070-0.278-0.11-0.439-0.11s-0.312 0.041-0.444 0.113l0.005-0.002-13.904 7.415c-0.292 0.16-0.487 0.465-0.487 0.816s0.195 0.656 0.482 0.813l0.005 0.002 2.756 1.471v5.759c0.005 0.431 0.153 0.826 0.399 1.141l-0.003-0.004c2.56 3.159 6.431 5.168 10.773 5.191l0.004 0h0.42c0.11 0.003 0.239 0.005 0.368 0.005 2.063 0 4.019-0.457 5.772-1.276l-0.084 0.035v3.547c0 0.512 0.415 0.927 0.927 0.927s0.927-0.415 0.927-0.927v0-4.536c1.288-0.838 2.389-1.828 3.317-2.965l0.020-0.026c0.231-0.303 0.371-0.687 0.371-1.104 0-0.003 0-0.006-0-0.009v0-5.759l2.756-1.471c0.3-0.154 0.501-0.461 0.501-0.816s-0.201-0.661-0.496-0.813l-0.005-0.002zM15.981 23.872c-0.104 0.003-0.226 0.005-0.349 0.005-3.768 0-7.13-1.733-9.335-4.444l-0.017-0.022v-4.771l9.294 4.944c0.129 0.070 0.282 0.111 0.445 0.111h0c0.159-0 0.308-0.041 0.437-0.114l-0.005 0.002 5.586-2.979v5.883c-1.624 0.877-3.554 1.393-5.605 1.393-0.159 0-0.316-0.003-0.473-0.009l0.023 0.001zM25.769 19.386c-0.57 0.697-1.198 1.31-1.889 1.847l-0.026 0.020v-5.623l1.866-0.989zM22.963 14.022l-6.513-3.473c-0.125-0.069-0.273-0.11-0.432-0.11-0.349 0-0.652 0.198-0.802 0.488l-0.002 0.005c-0.068 0.125-0.108 0.274-0.108 0.432 0 0.345 0.192 0.646 0.474 0.802l0.005 0.002 5.339 2.904-4.944 2.657-11.939-6.365 11.939-6.365 11.926 6.365z"></path>
          </svg>
          <span class="search-results-list-item-info-line-text">{{getTraductionCmi() | translate}} {{document.annee + '-' + (document.annee + 1)}}</span>
        </p>

        <p class="search-results-list-item-info-line" *ngIf="document.formations?.length > 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true"
               class="search-results-list-item-info-line-icon">
            <path
              d="M21.677 15.452h-7.225c-0.552 0-1-0.448-1-1v0c0-0.552 0.448-1 1-1v0h7.225c0.552 0 1 0.448 1 1v0c0 0.552-0.448 1-1 1v0z"></path>
            <path
              d="M21.677 18.548h-7.225c-0.552 0-1-0.448-1-1v0c0-0.552 0.448-1 1-1v0h7.225c0.552 0 1 0.448 1 1v0c0 0.552-0.448 1-1 1v0z"></path>
            <path
              d="M6.495 4.161h19.011c1.288 0.002 2.332 1.045 2.333 2.333v19.011c-0.002 1.288-1.045 2.332-2.333 2.333h-19.011c-1.288-0.002-2.332-1.045-2.333-2.333v-19.011c0.002-1.288 1.045-2.332 2.333-2.333h0zM25.505 25.839c0.184 0 0.333-0.149 0.333-0.333v0-19.011c0-0.184-0.149-0.333-0.333-0.333v0h-19.011c-0.184 0-0.333 0.149-0.333 0.333v0 19.011c0 0.184 0.149 0.333 0.333 0.333v0z"></path>
            <path
              d="M10.323 27.839c-0.552 0-1-0.448-1-1v0-21.677c0-0.552 0.448-1 1-1v0c0.552 0 1 0.448 1 1v0 21.677c0 0.552-0.448 1-1 1v0z"></path>
          </svg>
          <span class="search-results-list-item-info-line-text">
             <ng-container *ngFor="let formation of document.formations; let last = last">
              <a href
                 [routerLink]="'/' + pathDetailFormation"
                 [queryParams]="{type: formation.typeObjet, id: formation.id}">{{formation.intitule}}</a><br/>
             </ng-container>
          </span>
        </p>
      </div>

      <div class="search-results-list-item-actions">
        <app-ajouter-favori [class]="'search-results-list-item-action'" [document]="document"></app-ajouter-favori>
      </div>

      <p class="card-item-link search-results-list-item-link">
        <a class="delegate-hover" href
           (click)="onCardSelected()"
           [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: typeObjet.CURSUS_ENRICHI, id: document.id}"
           [attr.aria-label]="'Découvrir le cursus ' + document.intitule">
          <span class="link-btn">
            <span class="link-btn-text">{{'resultats.carte.decouvrir' | translate}}</span>
            <span class="link-btn-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
              <path
                d="M23.335 7.828l-2.143 2.268 4.567 4.317h-25.759v3.128h25.806l-4.614 4.364 2.143 2.268 8.665-8.164-8.665-8.18z"></path>
            </svg>
            </span>
          </span>
        </a>
      </p>

    </div>
  </div>
</article>
