<div class="course-detail-top-side-inner">
  <!--  <p class="btns" *ngIf="gofElement.typeObjet === typeObjet.FORMATION">-->
  <!--    <a href="#" class="btn btn-brand">{{'fiche.column.candidater' | translate}}</a>-->
  <!--  </p>-->

  <div class="course-detail-card" *ngIf="presentation">
    <h2 class="course-detail-card-title">{{ 'fiche.column.carte_identite.title' | translate }}</h2>

    <div class="course-detail-card-data">
      <ul class="list-unstyled margin-container-xsmall">
        <li class="margin-xsmall">{{ 'fiche.column.carte_identite.offre' | translate }}&nbsp;:
          <strong>{{ gofElement.annee + '-' + (gofElement.annee + 1) }}</strong>
        </li>
        <li class="margin-xsmall"
            *ngIf="presentation.ensTypologieIds?.length > 0">{{ 'fiche.column.carte_identite.ens_typologies' | translate }}&nbsp;:
          <ng-container *ngFor="let typoId of presentation.ensTypologieIds; let last = last">
            <strong>{{ ensTypologieTrad.get(typoId) | translate }}</strong><span *ngIf="!last">{{ ', ' }}</span>
          </ng-container>
        </li>
        <li class="margin-xsmall"
            *ngIf="presentation.ensNiveauId">{{ 'fiche.column.carte_identite.ens_niveau' | translate }}&nbsp;:
          <strong>{{ ensNiveauTrad.get(presentation.ensNiveauId) | translate }}</strong>
        </li>
        <li class="margin-xsmall"
            *ngIf="presentation.niveauEntreeId">{{ 'fiche.column.carte_identite.niveau_entree' | translate }}&nbsp;:
          <strong>{{ niveauxTrad.get(presentation.niveauEntreeId) | translate }}</strong></li>
        <li class="margin-xsmall" *ngIf="presentation.credits">{{ 'fiche.column.carte_identite.ects' | translate }} :
          <strong *ngIf="presentation.credits === '0'">{{ 'fiche.presentation.non_creditant' | translate }}</strong>
          <strong *ngIf="presentation.credits !== '0'">{{ presentation.credits }}</strong>
        </li>
        <li class="margin-xsmall"
            *ngIf="presentation.ensMobilite">{{ 'fiche.column.carte_identite.ens_mobilite' | translate }}&nbsp;:
          <strong *ngIf="presentation.ensMobilite">{{ 'global.yes' | translate }}</strong>
          <strong *ngIf="!presentation.ensMobilite">{{ 'global.no' | translate }}</strong>
        </li>
        <li class="margin-xsmall"
            *ngIf="presentation.ensADistanceId">{{ 'fiche.column.carte_identite.ens_accessibilite_a_distance' | translate }}&nbsp;:
          <strong>{{ typeADistanceTrad.get(presentation.ensADistanceId) | translate }}</strong></li>
        <li class="margin-xsmall"
            *ngIf="gofElement.typeObjet === typeObjet.ENSEIGNEMENT && presentation.disciplines?.length > 0">
          {{ 'fiche.column.carte_identite.disciplines' | translate }}&nbsp;:
          <ng-container *ngFor="let discipline of presentation.disciplines; let last = last">
            <strong>{{ discipline }}</strong><span *ngIf="!last">{{ ', ' }}</span>
          </ng-container>
        </li>

        <li class="margin-xsmall"
            *ngIf="presentation.siteIds?.length > 0">{{ 'fiche.column.carte_identite.lieu_formation' | translate }}&nbsp;:
          <ng-container *ngFor="let siteId of presentation.siteIds; let last = last">
            <strong>{{ sitesTrad.get(siteId) | translate }}</strong><span *ngIf="!last">{{ ', ' }}</span>
          </ng-container>
        </li>
        <li class="margin-xsmall"
            *ngIf="presentation.typeInscriptionIds?.length > 0">{{ 'fiche.column.carte_identite.régime_études' | translate }}&nbsp;:
          <ng-container *ngFor="let typeInscriptionId of presentation.typeInscriptionIds; let last = last">
            <strong>{{ typesInscriptionTrad.get(typeInscriptionId) | translate }}</strong><span
            *ngIf="!last">{{ ', ' }}</span>
          </ng-container>
        </li>
        <li class="margin-xsmall"
            *ngIf="presentation.composanteIds?.length > 0 || presentation.sniIds?.length > 0">{{ 'fiche.column.carte_identite.composantes' | translate }}&nbsp;:
          <ng-container *ngFor="let structureId of presentation.composanteIds; let last = last">
            <strong>{{ structuresTrad.get(structureId) | translate }}</strong><span
            *ngIf="!last || (last &&  presentation.sniIds.length > 0)">{{ ', ' }}</span>
          </ng-container>
          <ng-container *ngFor="let structureId of presentation.sniIds; let last = last">
            <strong>{{ structuresTrad.get(structureId) | translate }}</strong><span *ngIf="!last">{{ ', ' }}</span>
          </ng-container>
        </li>
        <li class="margin-xsmall"
            *ngIf="presentation.ensCodesSI?.length > 0">{{ 'fiche.column.carte_identite.ens_codes_si' | translate }}&nbsp;:
          <ng-container *ngFor="let codeSI of presentation.ensCodesSI; let last = last">
            <strong>{{ codeSI }}</strong><span *ngIf="!last">{{ ', ' }}</span>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>

  <div class="course-detail-card" *ngIf="rebonds?.isDisplayed()">
    <app-rebonds [rebonds]="rebonds"
                 [fromCatalogueInternational]="fromInternationalCatalogue"></app-rebonds>
  </div>

  <div class="course-detail-card" *ngIf="documents?.length > 0 || statistiquesRecrutement">
    <h2 class="course-detail-card-title">{{ 'fiche.column.en_savoir_plus.title' | translate }}</h2>
    <app-document [documents]="documents" [statistiquesRecrutement]="statistiquesRecrutement"></app-document>
  </div>

  <div class="course-detail-contacts" *ngIf="contacts?.contacts?.length > 0">
    <h2 class="course-detail-card-title">{{ 'fiche.column.contacts.title' | translate }}</h2>
    <app-contacts [contacts]="contacts"></app-contacts>
  </div>

</div>
