import {Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from "@angular/core";
import {PanelPresentation} from "../../model/presentation.model";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {PresentationService} from "../../services/presentation.service";
import {PathItem, GofElement} from "../../model/util.model";
import {
  BREADCRUMB_THREAD, GOF_ENSEIGNEMENT_INTERNATIONAL, GOF_INTERNATIONAL_CATALOGUE,
  GOF_LANGUAGE,
  GOF_PROFILE,
  PARAM_TYPE,
  PATH_ACCUEIL, PATH_CATALOGUE_INTERNATIONAL,
  PATH_DETAIL_FORMATION
} from "../../constants/conf.constants";
import {GlobalStateService} from "../../services/global-state.service";
import {AppComponent} from "../../app.component";
import {UtilService} from "../../services/util.service";
import {AnneeCampagneResourceService} from "../../services/resources/annee-campagne-resource.service";
import {FormControl} from "@angular/forms";
import {TYPES_OBJET} from "../../constants/utils.constants";
import {AbstractDocument, EnseignementDoc} from "../../model/esDoc.model";
import {ElasticsearchService} from "../../services/elasticsearch.service";
import {adaptNavContainer, displayFooter, hideFooter} from "../../utils/style.utils";
import {CookieService} from "ngx-cookie-service";
import {getDOMId, getDOMId_Bis, getResume} from "../../utils/document.utils";
import {refTypeDiplome} from "../../constants/referentiel/ref.constants";
import {typesDureeTrad, typesObjetTrad} from "../../constants/translationMaps/trad.constants";
import * as moment from "moment";
import {structuresTrad} from "../../constants/translationMaps/trad_structure.constants";
import {isPlatformBrowser} from "@angular/common";


@Component({
  selector: "app-fiche",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./fiche.component.html",
  styleUrls: ["./fiche.component.scss"]
})
export class FicheComponent implements OnInit {

  display: boolean;
  displayBanner: boolean;

  fromInternationalCatalogue: boolean;

  thread: PathItem[];
  gofElement: GofElement;
  document: AbstractDocument;
  yearFormControl: FormControl;
  openYears: number[];

  presentation: PanelPresentation;

  typesObjet = TYPES_OBJET;
  typesDiplome = refTypeDiplome;
  typesObjetTrad = typesObjetTrad;
  structuresTrad = structuresTrad;
  typesDureeTrad = typesDureeTrad;

  pathDetailFormation = PATH_DETAIL_FORMATION;

  constructor(
    private anneeCampagneResourceService: AnneeCampagneResourceService,
    private globalStateService: GlobalStateService,
    private elasticsearchService: ElasticsearchService,
    private presentationService: PresentationService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private utilService: UtilService,
    @Inject(PLATFORM_ID) private platformId: Object) {


    this.route.queryParams.subscribe(params => {
      this.gofElement = new GofElement();
      this.presentation = null;
      hideFooter();

      const typeObjet: string = params.type;
      const objetId: number = +params.id || -1;
      // test params URL valides
      if (![
        TYPES_OBJET.PERIODE.valueOf(),
        TYPES_OBJET.FORMATION.valueOf(),
        TYPES_OBJET.PARCOURS_TYPE.valueOf(),
        TYPES_OBJET.CURSUS_ENRICHI.valueOf(),
        TYPES_OBJET.ENSEIGNEMENT.valueOf()
      ].includes(typeObjet) || objetId === -1) {
        this.router.navigate([PATH_ACCUEIL]);
        return;
      }

      this.utilService.setCurrentYear(objetId).then(() => {
        this.yearFormControl = new FormControl(AppComponent.CURRENT_YEAR);
        this.gofElement = new GofElement(
          typeObjet,
          objetId,
          AppComponent.CURRENT_YEAR,
          this.cookieService.get(GOF_PROFILE),
          this.translateService.currentLang
        );

        this.setBannerDisplay()

        if (typeObjet !== TYPES_OBJET.PERIODE.valueOf()) {
          this.getDocument().then(() => {
            if (this.document) {
              this.checkEnseigenementInternational().then(() => this.loadFiche());
            }
          });

        } else {
          this.loadFiche();
        }
      });
    });

    this.globalStateService.subscribe(GOF_PROFILE, (profile: string) => {
      this.gofElement.typePublic = profile;
    });

    this.globalStateService.subscribe(GOF_LANGUAGE, (langue: string) => {
      this.gofElement.langue = langue;
      this.loadFiche();
    });
  }

  ngOnInit(): void {
    const breadcrumbThread = JSON.parse(sessionStorage.getItem(BREADCRUMB_THREAD));
    if (!breadcrumbThread) {
      sessionStorage.setItem(BREADCRUMB_THREAD, JSON.stringify([]));
    }
    this.yearFormControl = new FormControl(AppComponent.CURRENT_YEAR);
  }

  onLoaded(): void {
    displayFooter();
  }

  onAnneeChanged(): void {
    this.gofElement.annee = this.yearFormControl.value;
    AppComponent.CURRENT_YEAR = this.yearFormControl.value;
    this.utilService.setCurrentYear(this.gofElement.id).then(() => {
      this.yearFormControl = new FormControl(AppComponent.CURRENT_YEAR);
      this.setBannerDisplay();
      this.loadFiche();
    });
  }

  getDomId(document: AbstractDocument): string {
    if (document) {
      return getDOMId(document);
    } else {
      return getDOMId_Bis(this.gofElement.typeObjet, this.gofElement.id);
    }
  }

  private loadFiche(): void {
    this.display = false;

    Promise.all([
      this.getPresentation(),
      this.getOpenedYears()])
      .then(() => {

        if (!this.presentation) {
          this.router.navigate([PATH_ACCUEIL]);
        }
        this.utilService.setHtmlHead(
          this.presentation.intitule,
          getResume(this.presentation.objectif),
          PATH_DETAIL_FORMATION,
          "?type=" + (this.document && this.document?.type_objet ? this.document.type_objet : TYPES_OBJET.PERIODE.valueOf()) + "&id=" + this.presentation.objetId
        )
          .then(() => {
            this.initThread();
            adaptNavContainer(window.innerWidth);
            this.display = true;
          });
      });
  }

  private checkEnseigenementInternational(): Promise<void> {
    return new Promise<void>(resolve => {
      this.translateService.get("global.yes").subscribe(res => {
        if (isPlatformBrowser(this.platformId)) {
          this.fromInternationalCatalogue = this.document.type_objet === TYPES_OBJET.ENSEIGNEMENT
            && (this.document as EnseignementDoc).ouverture_mobilite === res
            && JSON.parse(localStorage.getItem(GOF_INTERNATIONAL_CATALOGUE));
          this.globalStateService.notifyDataChanged(GOF_ENSEIGNEMENT_INTERNATIONAL, this.fromInternationalCatalogue);
          resolve();
        }
      });
    });
  }

  private getPresentation(): Promise<void> {
    return new Promise<void>(resolve => {
      this.presentationService.getPanel(this.gofElement).then(res => {
        this.presentation = res;
        resolve();
      });
    });
  }

  private getDocument(): Promise<void> {
    return new Promise<void>(resolve => {
      this.elasticsearchService.getDocuments([this.gofElement.id]).subscribe(res => {
        this.document = res[0];
        resolve();
      });
    });
  }

  private getOpenedYears(): Promise<void> {
    return new Promise<void>(resolve => {
      this.anneeCampagneResourceService.getOpenedYears(this.gofElement.id).subscribe(res => {
        this.openYears = [...new Set<number>(res)];
        this.yearFormControl = new FormControl(AppComponent.CURRENT_YEAR);
        resolve();
      });
    });
  }

  private initThread(): void {
    let storedPaths: PathItem[] = JSON.parse(sessionStorage.getItem(BREADCRUMB_THREAD));
    if (!storedPaths) {
      storedPaths = [];
    }

    const storedItem: PathItem = storedPaths.filter(sp => sp.lib === this.presentation.intitule && sp.queryParams[PARAM_TYPE] === this.gofElement.typeObjet)[0];
    const index: number = storedPaths.indexOf(storedItem);
    if (index !== -1) {
      if (index + 1 < storedPaths.length) {
        storedPaths = storedPaths.splice(0, index + 1);
      }

    } else {
      storedPaths.push(
        new PathItem(
          this.presentation.intitule,
          ["/" + PATH_DETAIL_FORMATION],
          {type: this.gofElement.typeObjet, id: this.gofElement.id})
      );
    }
    sessionStorage.setItem(BREADCRUMB_THREAD, JSON.stringify(storedPaths));
    let homePath = PATH_ACCUEIL;
    if (this.fromInternationalCatalogue) {
      homePath = "/" + PATH_CATALOGUE_INTERNATIONAL;
    }
    this.thread = [
      new PathItem("page.accueil", [homePath])
    ];
    storedPaths.forEach(sp => this.thread.push(sp));
  }

  private setBannerDisplay() {
    this.displayBanner = AppComponent.CURRENT_YEAR === 2025 && moment(new Date()).isAfter(moment("2024-12-05", "YYYY-MM-DD")) && moment(new Date()).isBefore(moment("2025-06-30", "YYYY-MM-DD"));
  }
}
