import {LANGUES, TYPES_OBJET} from '../utils.constants';
import {
  RefEtatConvention, RefLangue,
  RefNiveau, RefNiveauEns,
  RefSite,
  RefTypeAcquis,
  RefTypeADistance,
  RefTypeCompetence,
  refTypeDiplome, RefTypeDuree,
  RefTypeEnseignement,
  RefTypeInscription,
  RefTypePartenariat,
  RefTypePublic,
  RefTypologieEns
} from '../referentiel/ref.constants';

export const languesTrad = new Map([
  [LANGUES.FR, 'languages.fr'],
  [LANGUES.EN, 'languages.en']
]);

export const typesObjetTrad: Map<string, string> = new Map([
  [TYPES_OBJET.FORMATION, 'referentiel.type_objet.formation'],
  [TYPES_OBJET.ENSEIGNEMENT, 'referentiel.type_objet.enseignement'],
  [TYPES_OBJET.CURSUS_ENRICHI, 'referentiel.type_objet.cursus_enrichi'],
  [TYPES_OBJET.PARCOURS_TYPE, 'referentiel.type_objet.parcours_type'],
  [TYPES_OBJET.PERIODE, 'referentiel.type_objet.periode'],
]);

export const typesPublicTrad = new Map([
  [RefTypePublic.LYCEEN_FUTUR_ETUDIANT, 'referentiel.type_public.lyceen_futur_etudiant'],
  [RefTypePublic.ETUDIANT_UB, 'referentiel.type_public.etudiant_ub'],
  [RefTypePublic.ENTREPRISE, 'referentiel.type_public.entreprise']
]);

export const niveauxTrad = new Map([
  [RefNiveau.NIVEAU_PRE_BAC, 'referentiel.niveau.pre_bac'],
  [RefNiveau.NIVEAU_BAC, 'referentiel.niveau.bac'],
  [RefNiveau.NIVEAU_BAC_120, 'referentiel.niveau.bac_120'],
  [RefNiveau.NIVEAU_BAC_4, 'referentiel.niveau.bac_4'],
  [RefNiveau.NIVEAU_BAC_8, 'referentiel.niveau.bac_8'],
  [RefNiveau.NIVEAU_LICENCE, 'referentiel.niveau.licence'],
  [RefNiveau.NIVEAU_MASTER, 'referentiel.niveau.master']
]);

export const typesDiplomeTrad = new Map([
  [refTypeDiplome.ATTEST_ETUDES, 'referentiel.type_diplome.attest_etudes'],
  [refTypeDiplome.BUT, 'referentiel.type_diplome.but'],
  [refTypeDiplome.CES, 'referentiel.type_diplome.ces'],
  [refTypeDiplome.CC, 'referentiel.type_diplome.cc'],
  [refTypeDiplome.CC_DROIT, 'referentiel.type_diplome.cc_droit'],
  [refTypeDiplome.DUT, 'referentiel.type_diplome.dut'],
  [refTypeDiplome.DE, 'referentiel.type_diplome.de'],
  [refTypeDiplome.DE_CYCLE_LONG, 'referentiel.type_diplome.de_cycle_long'],
  [refTypeDiplome.DE_DOCTEUR, 'referentiel.type_diplome.de_docteur'],
  [refTypeDiplome.DAEU, 'referentiel.type_diplome.daeu'],
  [refTypeDiplome.DU, 'referentiel.type_diplome.du'],
  [refTypeDiplome.DES, 'referentiel.type_diplome.des'],
  [refTypeDiplome.DES_CHIRURGIE, 'referentiel.type_diplome.des_chirurgie'],
  [refTypeDiplome.DES_MEDICAL, 'referentiel.type_diplome.des_medical'],
  [refTypeDiplome.DESC_MG_I, 'referentiel.type_diplome.desc_mg_i'],
  [refTypeDiplome.DES_BIOLOGIE, 'referentiel.type_diplome.des_biologie'],
  [refTypeDiplome.DESC_MG_II, 'referentiel.type_diplome.desc_mg_ii'],
  [refTypeDiplome.DCG, 'referentiel.type_diplome.dcg'],
  [refTypeDiplome.DFA, 'referentiel.type_diplome.dfa'],
  [refTypeDiplome.DFG, 'referentiel.type_diplome.dgf'],
  [refTypeDiplome.DNO, 'referentiel.type_diplome.dno'],
  [refTypeDiplome.DSCG, 'referentiel.type_diplome.dscg'],
  [refTypeDiplome.DSN, 'referentiel.type_diplome.dsn'],
  [refTypeDiplome.DEUST, 'referentiel.type_diplome.deust'],
  [refTypeDiplome.DOCTORAT, 'referentiel.type_diplome.doctorat'],
  [refTypeDiplome.HDR, 'referentiel.type_diplome.hdr'],
  [refTypeDiplome.LICENCE, 'referentiel.type_diplome.licence'],
  [refTypeDiplome.LICENCE_PRO, 'referentiel.type_diplome.licence_pro'],
  [refTypeDiplome.MASTER, 'referentiel.type_diplome.master'],
  [refTypeDiplome.PASS, 'referentiel.type_diplome.pass'],
  [refTypeDiplome.PREPA, 'referentiel.type_diplome.prepa'],
  [refTypeDiplome.FORMATION_COURTE, 'referentiel.type_diplome.formation_courte']
]);

export const typesCompetenceTrad = new Map([
  [RefTypeCompetence.PREPROFESSIONNELLE, 'referentiel.type_competence.preprofesionnelle'],
  [RefTypeCompetence.TRANSVERSALE, 'referentiel.type_competence.transversale'],
  [RefTypeCompetence.DISCIPLINAIRE, 'referentiel.type_competence.disciplinaire']
]);

export const typesInscriptionTrad = new Map([
  [RefTypeInscription.FORMATION_INITIALE, 'referentiel.type_inscription.formation_initiale'],
  [RefTypeInscription.FORMATION_CONTINUE, 'referentiel.type_inscription.formation_continue'],
  [RefTypeInscription.CONTRAT_APPRENTISSAGE, 'referentiel.type_inscription.contrat_apprentissage'],
  [RefTypeInscription.CONTRAT_PROFESSIONNALISATION, 'referentiel.type_inscription.contrat_professionnalisation'],
]);

export const typesPartenariatTrad = new Map([
  [RefTypePartenariat.CO_ACCREDIATION, 'referentiel.type_partenariat.co_accreditation'],
  [RefTypePartenariat.ERASMUS, 'referentiel.type_partenariat.erasmus'],
  [RefTypePartenariat.CONVENTION_PARTENARIAT_FRANCAIS, 'referentiel.type_partenariat.convention_partenariat_francais'],
  [RefTypePartenariat.COOPERATION_INTERNATIONALE, 'referentiel.type_partenariat.cooperation_internationale'],
  [RefTypePartenariat.CONVENTION_INSTIT_FRANCAIS, 'referentiel.type_partenariat.convention_instit_privee_francaise'],
  [RefTypePartenariat.CONVENTION_INSTIT_ETRANGERE, 'referentiel.type_partenariat.convention_instit_privee_etrangere']
]);

export const sitesTrad = new Map([
  [RefSite.BORDEAUX_ENVIRONS, 'referentiel.site.bordeaux_environs'],
  [RefSite.AGEN, 'referentiel.site.agen'],
  [RefSite.ARCACHON, 'referentiel.site.arcachon'],
  [RefSite.BAYONNE, 'referentiel.site.bayonne'],
  [RefSite.BERGERAC, 'referentiel.site.bergerac'],
  [RefSite.DAX, 'referentiel.site.dax'],
  [RefSite.MONT_DE_MARSAN, 'referentiel.site.mont_de_marsan'],
  [RefSite.EUROPE, 'referentiel.site.europe'],
  [RefSite.PERIGUEUX, 'referentiel.site.perigueux'],
  [RefSite.PAU, 'referentiel.site.pau'],
  [RefSite.AMERIQUE_NORD, 'referentiel.site.amerique_nord'],
  [RefSite.AMERIQUE_CENTRAL, 'referentiel.site.amerique_central'],
  [RefSite.AMERIQUE_SUD, 'referentiel.site.amerique_sud'],
  [RefSite.AFRIQUE, 'referentiel.site.afrique'],
  [RefSite.PROCHE_ORIENT, 'referentiel.site.proche_orient'],
  [RefSite.MOYEN_ORIENT, 'referentiel.site.moyen_orient'],
  [RefSite.DOM_TOM, 'referentiel.site.dom_tom'],
  [RefSite.EXTREME_ORIENT, 'referentiel.site.extreme_orient'],
  [RefSite.VIETNAM, 'referentiel.site.vietnam'],
  [RefSite.LIBAN, 'referentiel.site.liban'],
  [RefSite.MAROC, 'referentiel.site.maroc'],
  [RefSite.BELGIQUE, 'referentiel.site.belgique'],
  [RefSite.ROYAUME_UNI, 'referentiel.site.royaume_uni'],
  [RefSite.ALLEMAGNE, 'referentiel.site.allemagne'],
  [RefSite.ESPAGNE, 'referentiel.site.espagne']
]);

export const typesADistanceTrad = new Map([
  [RefTypeADistance.FORMATION_NON_ACCESSIBLE, 'referentiel.a_distance.formation_non_accessible'],
  [RefTypeADistance.FORMATION_PARTIELLEMENT_ACCESSIBLE_A_DISTANCE, 'referentiel.a_distance.formation_partiellement_accessible_a_distance'],
  [RefTypeADistance.FORMATION_EGALEMENT_POSSIBLE_A_DISTANCE, 'referentiel.a_distance.formation_egalement_possible_a_distance'],
  [RefTypeADistance.FORMATION_UNIQUEMENT_ACCESSIBLE_A_DISTANCE, 'referentiel.a_distance.formation_uniquement_accessible_a_distance']
]);

export const typesEnseignementTrad = new Map([
  [RefTypeEnseignement.CM, 'referentiel.type_enseignement.cm'],
  [RefTypeEnseignement.TD, 'referentiel.type_enseignement.td'],
  [RefTypeEnseignement.CI, 'referentiel.type_enseignement.ci'],
  [RefTypeEnseignement.STAGE, 'referentiel.type_enseignement.stage'],
  [RefTypeEnseignement.TP, 'referentiel.type_enseignement.tp'],
  [RefTypeEnseignement.MISE_EN_SITUATION, 'referentiel.type_enseignement.mise_en_situation'],
  [RefTypeEnseignement.REH, 'referentiel.type_enseignement.reh'],
  [RefTypeEnseignement.MIXTE, 'referentiel.type_enseignement.mixte'],
  [RefTypeEnseignement.ENS_DIR, 'referentiel.type_enseignement.ens_dir'],
  [RefTypeEnseignement.PREPA_PERSO, 'referentiel.type_enseignement.prepa_perso'],
  [RefTypeEnseignement.A_DEFINIR, 'referentiel.type_enseignement.a_definir'],
  [RefTypeEnseignement.TD_MACHINE, 'referentiel.type_enseignement.td_machine']
]);

export const typesAcquisTrad = new Map([
  [RefTypeAcquis.SAVOIR, 'referentiel.type_acquis.savoir'],
  [RefTypeAcquis.SAVOIR_ETRE, 'referentiel.type_acquis.savoir_etre'],
  [RefTypeAcquis.SAVOIR_FAIRE, 'referentiel.type_acquis.savoir_faire']
]);

export const etatsConventionTrad = new Map([
  [RefEtatConvention.PAS_CONVENTION, 'referentiel.etat_convention.pas_convention'],
  [RefEtatConvention.CONVENTION_A_ETABLIR, 'referentiel.etat_convention.convention_a_etablir'],
  [RefEtatConvention.CONVENTION_ASSOCIEE, 'referentiel.etat_convention.convention_en_cours']
]);

export const ensTypologieTrad = new Map([
  [RefTypologieEns.SOCLE, 'referentiel.ens_typologie.socle'],
  [RefTypologieEns.OUVERTURE, 'referentiel.ens_typologie.ouverture'],
  [RefTypologieEns.PERSONNALISATION, 'referentiel.ens_typologie.personnalisation']
]);

export const ensNiveauTrad = new Map([
  [RefNiveauEns.DEBUTANT, 'referentiel.ens_niveau.debutant'],
  [RefNiveauEns.INTERMEDIAIRE, 'referentiel.ens_niveau.intermediaire'],
  [RefNiveauEns.AVANCE, 'referentiel.ens_niveau.avance']
]);

export const typesDureeTrad = new Map([
  [RefTypeDuree.HEURE, 'referentiel.typeDuree.heure'],
  [RefTypeDuree.SEMESTRE, 'referentiel.typeDuree.semestre'],
  [RefTypeDuree.ANNEE, 'referentiel.typeDuree.annee'],
  [RefTypeDuree.A_DEFINIR, 'referentiel.typeDuree.a_definir']
]);


export const refLanguesTrad = new Map([
  [RefLangue.FR, 'referentiel.langue.francais'],
  [RefLangue.ES, 'referentiel.langue.espagnol'],
  [RefLangue.EN, 'referentiel.langue.anglais'],
]);
