import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PeriodeResourceService} from '../resources/periode-resource.service';
import {DynamicFlatNode} from '../../model/contenu.model';
import {getListObservable, hasListObservable, setListObservable} from '../../utils/rxjs-cache.utils';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {GofElement} from '../../model/util.model';
import {getVersion} from '../../utils/version.utils';

@Injectable({
  providedIn: 'root'
})
export class LocalisationContenuService {

  observablesMap: Map<number, Map<number, any>> = new Map();

  constructor(private translateService: TranslateService,
              private periodeResourceService: PeriodeResourceService) {
  }

  /***
   * Récupération des enseignements ou des sous listes
   * <!> node.debut => objet id de la periode <!>
   */
  getChildren(gofElement: GofElement, node: DynamicFlatNode): Promise<DynamicFlatNode[]> {
    return new Promise<DynamicFlatNode[]>(resolve => {
      const children: DynamicFlatNode[] = [];
      if (!hasListObservable(this.observablesMap, gofElement.annee, node.supId)) {
        setListObservable(this.observablesMap, this.periodeResourceService, gofElement.annee, node.supId);
      }
      getListObservable(this.observablesMap, gofElement.annee, node.supId).subscribe(listes => {
        listes
          .filter(liste => getVersion(gofElement, liste.site) === node.name || liste.lib === node.name) // <!> liste peut être sans site <!>
          .sort((l1, l2) => {
            if (l1.temporalite < l2.temporalite) {
              return -1;
            } else if (l1.temporalite > l2.temporalite) {
              return 1;
            } else {
              return 0;
            }
          })
          .forEach(liste => {
            children.push({
              name: liste.lib,
              objetId: liste.objetId, // pour récup les enseignements
              supId: node.supId, // id periode
              type: TYPES_OBJET.LISTE_ENSEIGNEMENTS,
              description: liste.description,
              temporalite: liste.temporalite,
              level: node.level + 1,
              clickable: false,
              expandable: true,
              isLoading: false
            });
          });
        resolve(children);
      });
    });
  }
}

