import {Injectable} from '@angular/core';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {PanelRebonds} from '../../model/rebonds.model';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CursusEnrichiRebondsService {

  panelRebonds: PanelRebonds = new PanelRebonds();

  constructor(private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement, cursus: any): Promise<PanelRebonds> {
    return new Promise<PanelRebonds>(resolve => {
      let formationsPorteuses: ObjectAffichage[] = [];
      let autresParcours: ObjectAffichage[] = [];


      if (cursus.formations) {
        cursus.formations.forEach(f => {
          this.translateService.get(typesDiplomeTrad.get(f.type.typeDiplome.id))
            .subscribe(typeDiplome => formationsPorteuses.push(new ObjectAffichage(f.objetId, typeDiplome + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION)));
        });
      }

      if (cursus.periodes) {
        autresParcours = cursus.periodes.map(p => new ObjectAffichage(p.objetId, p.lib, TYPES_OBJET.PARCOURS_TYPE));
      }

      this.panelRebonds.parcoursTypeAssocies = autresParcours;
      this.panelRebonds.formationsPorteuses = formationsPorteuses;

      resolve(this.panelRebonds);
    });
  }
}
