import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PanelActivites} from '../../../model/activites.model';
import {refLanguesTrad, typesEnseignementTrad} from '../../../constants/translationMaps/trad.constants';

@Component({
  selector: 'app-activites-pedagogiques',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './activites-pedagogiques.component.html',
  styleUrls: ['./activites-pedagogiques.component.scss']
})
export class ActivitesPedagogiquesComponent {

  @Input() activitesPedagogiques: PanelActivites;

  typeEnseignementTrad = typesEnseignementTrad;
  refLanguesTrad = refLanguesTrad

  constructor() {
  }

}
