<mat-dialog-content id="rgpd" role="dialog" aria-modal="true" aria-labelledby="rgpd_title" aria-describedby="rgpd_desc">
  <div class="container" *ngIf="!custom">
    <button #refuse id="refuse-all-cookies" class="continue" (click)="refuseAll()"
            aria-label="Refuser tous les cookies">
      <span>{{'rgpd.button.continuer_sans_accepter' | translate}}</span>
      <mat-icon>arrow_right_alt</mat-icon>
    </button>
    <div class="content">
      <img src="assets/img/logo-universite-bordeaux-270x80.png" alt="">
      <h1 id="rgpd_title" class="title">{{'rgpd.title' | translate}}</h1>
      <p id="rgpd_desc" class="description">{{'rgpd.description' | translate}}</p>
      <div class="buttons">
        <button id="personalyze-cookies" class="left-button" (click)="custom = true"
                aria-label="Personnaliser votre choix de cookies">
          {{'rgpd.button.personnaliser' | translate}}</button>
        <button id="accept-all-cookies" class="right-button" (click)="acceptAll()"
                aria-label="Accepter tous les cookies">
          <mat-icon>check</mat-icon>
          <span>{{'rgpd.button.tout_accepter' | translate}}</span>
        </button>
      </div>
    </div>
  </div>

  <div id="custom-cookies" class="container" *ngIf="custom">
    <div class="content">
      <img src="assets/img/logo-universite-bordeaux-270x80.png" alt="logo-ub">
      <h1 class="title">{{'rgpd.personnalisation_cookies' | translate}}</h1>
      <div class="cookies">
        <div class="cookie">
          <div class="description">
            <div class="info">
              <b>{{'rgpd.youtube.title' | translate | uppercase }}</b>
              <small>{{'rgpd.youtube.description' | translate}}</small>
            </div>
            <div class="choice">
              <label for="youtube" class="switch">
                <input type="checkbox" id="youtube" [(ngModel)]="checkboxYoutube"
                       (keyup.enter)="checkboxYoutube=!checkboxYoutube">
                <span class="slider"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="cookie">
          <div class="description">
            <div class="info">
              <b>{{'rgpd.matomo.title' | translate | uppercase}}</b>
              <small>{{'rgpd.matomo.description' | translate}}</small>
            </div>
            <div class="choice">
              <label for="matomo" class="switch">
                <input type="checkbox" id="matomo" [(ngModel)]="checkboxMatomo"
                       (keyup.enter)="checkboxMatomo=!checkboxMatomo">
                <span class="slider"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="left-button" (click)="custom = false"
                aria-label="Annuler votre choix de cookies">{{'rgpd.button.retour' | translate}}</button>
        <button class="right-button" (click)="acceptCustom()"
                aria-label="Valider votre choix de cookies">
          <mat-icon>check</mat-icon>
          <span>{{'rgpd.button.valider' | translate}}</span></button>
      </div>
    </div>
  </div>
</mat-dialog-content>
