<!-- PREREQUIS -->
<div class="margin-large margin-lg-xlarge" *ngIf="competences.prerequis?.length > 0">
  <h3>{{'fiche.competences.prerequis.title' | translate}}</h3>

  <table role="presentation">
    <tbody>
    <tr *ngFor="let prerequis of competences.prerequis">
      <td><strong>{{prerequis.lib}}</strong></td>
      <td *ngIf="prerequis.typeCompetenceId"> {{ typesCompetenceTrad.get(prerequis.typeCompetenceId) | translate }}</td>
      <td *ngIf="prerequis.discipline">{{ prerequis.discipline }}</td>
      <td *ngIf="prerequis.acquisition">{{ prerequis.acquisition }}</td>
    </tr>
    </tbody>
  </table>
</div>

<!-- COMPETENCES -->
<div id="competences-container" class="margin-large margin-lg-xlarge" *ngIf="competences.competences?.length > 0">
  <h3>{{'fiche.competences.competence.title' | translate}}</h3>

  <table role="presentation">
    <tbody>
    <tr *ngFor="let competence of competences.competences">
      <td><strong>{{competence.lib}}</strong></td>
      <td *ngIf="competence.typeCompetenceId"> {{ typesCompetenceTrad.get(competence.typeCompetenceId) | translate }}</td>
      <td *ngIf="competence.discipline">{{ competence.discipline }}</td>
      <td *ngIf="competence.acquisition">{{ competence.acquisition }}</td>
    </tr>
    </tbody>
  </table>
</div>
