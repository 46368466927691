import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PanelPresentation} from '../../../model/presentation.model';
import {GofElement} from '../../../model/util.model';
import {TYPES_OBJET} from '../../../constants/utils.constants';
import {PanelContacts} from '../../../model/contacts.model';
import {PanelRebonds} from '../../../model/rebonds.model';
import {
  ensNiveauTrad,
  ensTypologieTrad, niveauxTrad,
  sitesTrad,
  typesADistanceTrad,
  typesInscriptionTrad
} from '../../../constants/translationMaps/trad.constants';
import {structuresTrad} from '../../../constants/translationMaps/trad_structure.constants';
import {Document} from "../../../model/document.model";
import {DocumentResourceService} from "../../../services/resources/document-resource.service";


@Component({
  selector: 'app-fiche-column',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './fiche-column.component.html',
  styleUrls: ['./fiche-column.component.scss']
})
export class FicheColumnComponent {

  @Input() gofElement: GofElement;
  @Input() presentation: PanelPresentation;
  @Input() contacts: PanelContacts;
  @Input() rebonds: PanelRebonds;
  @Input() documents:Document[];
  @Input() statistiquesRecrutement:Document;
  @Input() fromInternationalCatalogue: boolean;

  typeObjet = TYPES_OBJET;
  typesInscriptionTrad = typesInscriptionTrad;
  ensTypologieTrad = ensTypologieTrad;
  ensNiveauTrad = ensNiveauTrad;
  typeADistanceTrad = typesADistanceTrad;
  sitesTrad = sitesTrad;
  niveauxTrad = niveauxTrad;
  structuresTrad = structuresTrad;

  constructor() {
  }


}
