import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ConfigAssetLoaderService, Configuration} from './configAssetLoader.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {PATH_ACCUEIL} from './constants/conf.constants';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

  conf: Configuration;

  constructor(private translateService: TranslateService,
              private configAssetLoaderService: ConfigAssetLoaderService,
              private router: Router) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.conf = res);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    if (this.conf && !(request.url).includes(this.conf.refFormUrl) && !(request.url).includes(this.conf.editoUrl)) {
      request = Object.assign(request,
        new HttpRequest(
          request.method as any,
          this.conf.apiUrl + request.url,
          request.body,
          {
            headers: request.headers,
            reportProgress: request.reportProgress,
            params: request.params,
            responseType: request.responseType,
            withCredentials: request.withCredentials
          }
        )
      );
    }

    return next.handle(request).pipe(
      tap(event => {
          if (event instanceof HttpResponse) {
            const elapsed = Date.now() - started;
            //  console.log(`Request for ${request.urlWithParams} took ${elapsed} ms.`);
          }

        }, error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 417) {
              this.router.navigate([PATH_ACCUEIL]);
            }
          }
        }
      ));
  }
}
