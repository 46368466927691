import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Contact, PanelContacts} from '../../../model/contacts.model';
import {decodedEmail, encodedEmail} from '../../../utils/string.utils';

@Component({
  selector: 'app-contacts',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent {

  @Input() contacts: PanelContacts;

  constructor() {
  }

  getName(mail: string): string {
    return mail.split(/[@]+/)[0];
  }

  getDomain(mail: string): string {
    const rightPart: string = mail.split(/[@]+/)[1];
    const split = rightPart.split(/[.]+/);
    return rightPart.substring(0, rightPart.lastIndexOf(split[split.length - 1]));
  }

  getTld(mail: string): string {
    const rightPart: string = mail.split(/[@]+/)[1];
    const split = rightPart.split(/[.]+/);
    return split[split.length - 1];
  }

  setEncodedHref(contact: Contact): void {
    contact.href = encodedEmail(contact.mail);
  }

  setDecodedHref(contact: Contact): void {
    contact.href = decodedEmail(contact.mail);
  }

  isValidMail(email: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

}
