import {Injectable} from '@angular/core';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {PanelRebonds} from '../../model/rebonds.model';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {TranslateService} from '@ngx-translate/core';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';

@Injectable({
  providedIn: 'root'
})
export class EnseignementRebondsService {

  panelRebonds: PanelRebonds = new PanelRebonds();

  constructor(private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement, enseignement: any): Promise<PanelRebonds> {
    return new Promise<PanelRebonds>(resolve => {
      let formationsPorteuses: ObjectAffichage[] = [];
      let formationsMutualisees: ObjectAffichage[] = [];
      let enseignementsPrerequis: ObjectAffichage[] = [];
      let enseignementsPostrequis: ObjectAffichage[] = [];
      let periodesPortees: ObjectAffichage[] = [];

      if (enseignement.instancesFormations) {
        enseignement.instancesFormations.forEach(f => {
          this.translateService.get(typesDiplomeTrad.get(f.type.typeDiplome.id))
            .subscribe(typeDiplome => formationsPorteuses.push(new ObjectAffichage(f.objetId, typeDiplome + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION)));
        });
      }

      if (enseignement.formationsMutualisees) {
        enseignement.formationsMutualisees.forEach(f => {
          this.translateService.get(typesDiplomeTrad.get(f.type.typeDiplome.id))
              .subscribe(typeDiplome => formationsMutualisees.push(new ObjectAffichage(f.objetId, typeDiplome + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION)))
          });
      }

      if (enseignement.prerequis) {
        enseignementsPrerequis = enseignement.prerequis.map(ue => new ObjectAffichage(ue.objetId, ue.lib, TYPES_OBJET.ENSEIGNEMENT));
      }

      if (enseignement.prerequisPosterieur) {
        enseignementsPostrequis = enseignement.postrequis.map(ue => new ObjectAffichage(ue.objetId, ue.lib, TYPES_OBJET.ENSEIGNEMENT));
      }

      if (enseignement.periodes) {
        periodesPortees = enseignement.periodes.map(ue => new ObjectAffichage(ue.objetId, ue.lib, TYPES_OBJET.PERIODE));
      }

      this.panelRebonds.formationsPorteuses = formationsPorteuses;
      this.panelRebonds.formationsMutualiseesPorteuses = formationsMutualisees;
      this.panelRebonds.periodesPortees = periodesPortees;
      this.panelRebonds.enseignementsPrequis = enseignementsPrerequis;
      this.panelRebonds.enseignementsPostrequis = enseignementsPostrequis;
      resolve(this.panelRebonds);
    });
  }


}
