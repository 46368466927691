<div class="margin-large margin-lg-xlarge">
  <ng-container *ngFor="let el of activitesPedagogiques.elementsPedagogiques">
    <h3>{{el.lib}}</h3>
    <table role="presentation">
      <tbody>
      <tr *ngIf="el.typeEnseignementId">
        <td>{{'fiche.activites_pedagogiques.type' | translate}}</td>
        <td>{{typeEnseignementTrad.get(el.typeEnseignementId) | translate}}</td>
      </tr>
      <tr *ngIf="el.objectif">
        <td>{{'fiche.activites_pedagogiques.objectifs' | translate}}</td>
        <td>{{el.objectif}}</td>
      </tr>
      <tr *ngIf="el.langueEnseignementId">
        <td>{{'fiche.activites_pedagogiques.langue' | translate}}</td>
        <td>{{refLanguesTrad.get(el.langueEnseignementId) | translate}}</td>
      </tr>
      <tr *ngIf="el.nbSemaine">
        <td>{{'fiche.activites_pedagogiques.nb_sem_ens' | translate}}</td>
        <td>{{el.nbSemaine}} {{'referentiel.typeDuree.semaine' | translate}}{{el.nbSemaine > 1 ? 's' : ''}}</td>
      </tr>
      <tr *ngIf="el.nbHeuresEnseignees">
        <td>{{'fiche.activites_pedagogiques.nb_heure_ens' | translate}}</td>
        <td>{{el.nbHeuresEnseignees}} {{'referentiel.typeDuree.heure' | translate}}{{el.nbHeuresEnseignees > 1 ? 's' : ''}}</td>
      </tr>
      <tr *ngIf="el.tempsTravailPersonnel">
        <td>{{'fiche.activites_pedagogiques.temps_travail_personnel' | translate}}</td>
        <td>{{el.tempsTravailPersonnel}} {{'referentiel.typeDuree.heure' | translate}}{{el.tempsTravailPersonnel > 1 ? 's' : ''}}</td>
      </tr>
      <tr *ngIf="el.modaliteSuivi">
        <td>{{'fiche.activites_pedagogiques.modalites_suivi' | translate}}</td>
        <td>{{el.modaliteSuivi}}</td>
      </tr>
      <tr *ngIf="el.aDistance">
        <td>{{'fiche.activites_pedagogiques.distance' | translate}}</td>
        <td>
          <span *ngIf="el.aDistance">{{ 'global.yes' | translate }}</span>
          <span *ngIf="!el.aDistance">{{'global.no' | translate}}</span>
        </td>
      </tr>
      <tr *ngIf="el.module">
        <td>{{'fiche.activites_pedagogiques.module' | translate}}</td>
        <td>{{el.module}}</td>
      </tr>
<!--      <tr *ngIf="el.sectionCnu || el.groupeCnu">-->
<!--        <td>{{'fiche.activites_pedagogiques.cnu' | translate}}</td>-->
<!--        <td>-->
<!--          <span>{{el.sectionCnu}}</span>-->
<!--          <span *ngIf="el.groupeCnu && el.sectionCnu">{{' / '}}</span>-->
<!--          <span>{{el.groupeCnu}}</span></td>-->
<!--      </tr>-->
      </tbody>
    </table>
  </ng-container>
</div>

<ng-container *ngIf="activitesPedagogiques.modulesEnseignement?.length > 0">
  <div class="margin-large margin-lg-xlarge" *ngFor="let module of activitesPedagogiques.modulesEnseignement">
    <h3>{{module.lib}}</h3>

    <ng-container *ngFor="let el of module.elementsPedagogiques">
      <h4>{{el.lib}}</h4>

      <table role="presentation">
        <tbody>
        <tr *ngIf="el.typeEnseignementId">
          <td>{{'fiche.activites_pedagogiques.type' | translate}}</td>
          <td>{{el.typeEnseignementId}}</td>
        </tr>
        <tr *ngIf="el.objectif">
          <td>{{'fiche.activites_pedagogiques.objectifs' | translate}}</td>
          <td>{{el.objectif}}</td>
        </tr>
        <tr *ngIf="el.langueEnseignementId">
          <td>{{'fiche.activites_pedagogiques.langue' | translate}}</td>
          <td>{{el.langueEnseignementId}}</td>
        </tr>
        <tr *ngIf="el.nbSemaine">
          <td>{{'fiche.activites_pedagogiques.nb_sem_ens' | translate}}</td>
          <td>{{el.nbSemaine}} {{'referentiel.typeDuree.semaine' | translate}}{{el.nbSemaine > 1 ? 's' : ''}}</td>
        </tr>
        <tr *ngIf="el.nbHeuresEnseignees">
          <td>{{'fiche.activites_pedagogiques.nb_heure_ens' | translate}}</td>
          <td>{{el.nbHeuresEnseignees}} {{'referentiel.typeDuree.heure' | translate}}{{el.nbHeuresEnseignees > 1 ? 's' : ''}}</td>
        </tr>
        <tr *ngIf="el.tempsTravailPersonnel">
          <td>{{'fiche.activites_pedagogiques.temps_travail_personnel' | translate}}</td>
          <td>{{el.tempsTravailPersonnel}} {{'referentiel.typeDuree.heure' | translate}}{{el.tempsTravailPersonnel > 1 ? 's' : ''}}</td>
        </tr>
        <tr *ngIf="el.modaliteSuivi">
          <td>{{'fiche.activites_pedagogiques.modalites_suivi' | translate}}</td>
          <td>{{el.modaliteSuivi}}</td>
        </tr>
        <tr *ngIf="el.aDistance">
          <td>{{'panel.activites_pedagogiques.distance' | translate}}</td>
          <td>
            <span *ngIf="el.aDistance">{{'global.yes'  | translate }}</span>
            <span *ngIf="!el.aDistance">{{'global.no' | translate }}</span>
          </td>
        </tr>
        <tr *ngIf="el.module">
          <td>{{'fiche.activites_pedagogiques.module' | translate}}</td>
          <td>{{el.module}}</td>
        </tr>
<!--        <tr *ngIf="el.sectionCnu || el.groupeCnu">-->
<!--          <td>{{'fiche.activites_pedagogiques.cnu' | translate}}</td>-->
<!--          <td>-->
<!--            <span>{{el.sectionCnu}}</span>-->
<!--            <span *ngIf="el.groupeCnu && el.sectionCnu">{{' / '}}</span>-->
<!--            <span>{{el.groupeCnu}}</span></td>-->
<!--        </tr>-->
        </tbody>
      </table>
    </ng-container>
  </div>
</ng-container>
