import {Injectable} from '@angular/core';
import {PanelPresentation} from '../model/presentation.model';
import {GofElement} from '../model/util.model';
import {FormationPresentationService} from './presentation/formation-presentation.service';
import {EnseignementPresentationService} from './presentation/enseignement-presentation.service';
import {CursusEnrichiPresentationService} from './presentation/cursus-enrichi-presentation.service';
import {ParcoursTypePresentationService} from './presentation/parcours-type-presentation.service';
import {PeriodePresentationService} from './presentation/periode-presentation.service';
import {TYPES_OBJET} from '../constants/utils.constants';

@Injectable({
  providedIn: 'root'
})
export class PresentationService {

  constructor(private formationPresentationService: FormationPresentationService,
              private enseignementPresentationService: EnseignementPresentationService,
              private cursusEnrichiPresentationService: CursusEnrichiPresentationService,
              private parcoursTypePresentationService: ParcoursTypePresentationService,
              private periodePresentationService: PeriodePresentationService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelPresentation> {
    switch (gofElement.typeObjet) {
      case TYPES_OBJET.FORMATION :
        return this.formationPresentationService.getPanel(gofElement);
      case TYPES_OBJET.ENSEIGNEMENT :
        return this.enseignementPresentationService.getPanel(gofElement);
      case TYPES_OBJET.CURSUS_ENRICHI :
        return this.cursusEnrichiPresentationService.getPanel(gofElement);
      case TYPES_OBJET.PARCOURS_TYPE :
        return this.parcoursTypePresentationService.getPanel(gofElement);
      case TYPES_OBJET.PERIODE :
        return this.periodePresentationService.getPanel(gofElement);
      default:
        return new Promise<null>(resolve => resolve(null));
    }
  }
}
