import {ObjectAffichage} from './util.model';

export class PanelContenuHisto {
  public typeFormation: string;
  public typeDuree: number;
  public duree: number;
  public colonnes: Colonne[];

  isDisplayed(): boolean {
    return this != null
      && (
        (this.colonnes.length === 1 && !this.colonnes[0].objectAffichage.id)
        || (this.colonnes.length === 1 && this.colonnes[0].cellules.length > 1)
        || this.colonnes.length > 1
      );
  }
}

export class Colonne {
  constructor(
    public isCMI: boolean,
    public objectAffichage: ObjectAffichage,
    public colspan: number,
    public style: string,
    public cellules?: Cellule[],
    public periodes?: any[]
  ) {
  }
}

export class Cellule {
  constructor(
    public objectAffichage: ObjectAffichage,
    public debut: number,
    public fin: number,
    public type: string,
    public style: string,
    public rowIndex: number,
    public colIndex: number,
    public rowspan: number,
    public colspan: number
  ) {
  }
}
