import {Injectable} from '@angular/core';
import {Lien, PanelRebonds} from '../../model/rebonds.model';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {getVersion} from '../../utils/version.utils';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PeriodeRebondsService {

  panelRebonds: PanelRebonds = new PanelRebonds();

  constructor(private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement, periode: any): Promise<PanelRebonds> {
    return new Promise<PanelRebonds>(resolve => {
      const autreParcours: ObjectAffichage[] = [];
      if (periode.parcoursType) {
        autreParcours.push(new ObjectAffichage(periode.parcoursType.objetId, periode.parcoursType.lib, TYPES_OBJET.PARCOURS_TYPE));
      }

      let formationsPorteuses: ObjectAffichage[] = [];
      if (periode.formations) {
        periode.formations.forEach(f => {
          this.translateService.get(typesDiplomeTrad.get(f.type.typeDiplome.id))
            .subscribe(typeDiplome => formationsPorteuses.push(new ObjectAffichage(f.objetId, typeDiplome + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION)));
        });
      }

      let cursusEnrichisPortes: ObjectAffichage[] = [];
      let cmisAssocies: ObjectAffichage[] = [];
      if (periode.cursusEnrichi) {
        cursusEnrichisPortes = periode.cursusEnrichi.filter(c => !c.isCMI).map(c => new ObjectAffichage(c.objetId, c.lib, c.typeLib));
        cmisAssocies = periode.cursusEnrichi.filter(c => c.isCMI).map(c => new ObjectAffichage(c.objetId, c.lib, c.typeLib));
      }

      let periodesSuivantes: Lien[] = [];
      if (periode.periodesSuivantes?.length > 0) {
        periodesSuivantes = periode.periodesSuivantes.map(p => new Lien(
            {id: p.objetId, intitule: p.lib, typeObjet: TYPES_OBJET.PERIODE},
            p.formations?.map(f => new ObjectAffichage(f.objetId, getVersion(gofElement, f.type.typeDiplome) + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION))
          )
        );
      }

      let periodesPrecedentes: Lien[] = [];
      if (periode.periodesPrecedentes?.length > 0) {
        periodesPrecedentes = periode.periodesPrecedentes.map(p => new Lien(
            {id: p.objetId, intitule: p.lib, typeObjet: TYPES_OBJET.PERIODE},
            p.formations?.map(f => new ObjectAffichage(f.objetId, getVersion(gofElement, f.type.typeDiplome) + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION))
          )
        );
      }

      this.panelRebonds.periodesPrecedentes = periodesPrecedentes;
      this.panelRebonds.periodesSuivantes = periodesSuivantes;
      this.panelRebonds.parcoursTypeAssocies = autreParcours;
      this.panelRebonds.formationsPorteuses = formationsPorteuses;
      this.panelRebonds.cursusEnrichisPortes = cursusEnrichisPortes;
      this.panelRebonds.cmisAssocies = cmisAssocies;

      resolve(this.panelRebonds);
    });
  }
}
