<div class="accordion-panels">
  <div class="accordion-panel" *ngFor="let bloc of blocsCompetence.blocs ;  let index1 = index">
    <div class="accordion-panel-header">
      <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
        <h3 [id]="'label_bloc_' + index1" class="accordion-panel-title">{{bloc.intitule}}</h3>
      </div>
      <button data-toggle="collapse" type="button"
              [attr.data-target]="'#bloc-' + index1"
              [attr.aria-controls]="'bloc-' + index1"
              [attr.aria-describedby]="'label_bloc_' + index1"
              class="accordion-panel-toggler collapsed"
              aria-expanded="false">
        <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
      </button>
    </div>

    <div [id]="'bloc-' + index1" class="accordion-panel-body collapse">
      <div class="accordion-panel-body-inner">
        <div class="margin-large margin-lg-xlarge">
          <div class="accordion-panels">
            <!-- compétences associées -->
            <div class="accordion-panel">
              <div class="accordion-panel-header">
                <h3 [id]="'label_bc_competences_' + index1"
                    class="accordion-panel-title">{{'fiche.blocs_competence.competences_associees' | translate}}</h3>
                <button data-toggle="collapse"
                        type="button"
                        [attr.data-target]="'#competences-' + index1"
                        [attr.aria-controls]="'competences-' + index1"
                        [attr.aria-describedby]="'label_bc_competences_' + index1"
                        class="accordion-panel-toggler collapsed"
                        aria-expanded="false">
                  <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                </button>
              </div>

              <div class="accordion-panel-body collapse" [id]="'competences-' + index1">
                <div class="accordion-panel-body-inner">
                  <div class="accordion-panels">
                    <!-- competences disciplinaires   -->
                    <div class="accordion-panel accordion-panel-open"
                         *ngIf="bloc.competencesDisciplinaires?.length > 0">
                      <div class="accordion-panel-header">
                        <h3 class="accordion-panel-title" [id]="'label_bc_competences_disciplinaires_' + index1">
                          {{'fiche.blocs_competence.competences_disciplinaires' | translate}}
                        </h3>
                        <button data-toggle="collapse"
                                type="button"
                                [attr.data-target]="'#bloc-competences-disciplinaires-' + index1"
                                [attr.aria-controls]="'bloc-competences-disciplinaires-' + index1"
                                [attr.aria-describedby]="'label_bc_competences_disciplinaires_' + index1"
                                class="accordion-panel-toggler"
                                aria-expanded="true">
                          <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                        </button>
                      </div>

                      <div class="accordion-panel-body" [id]="'bloc-competences-disciplinaires-' + index1">
                        <div class="accordion-panel-body-inner">
                          <ul>
                            <li *ngFor="let competence of bloc.competencesDisciplinaires">{{ competence.lib }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <!-- competences transverses  -->
                    <div class="accordion-panel accordion-panel-open" *ngIf="bloc.competencesTransverses?.length > 0">
                      <div class="accordion-panel-header">
                        <h3 class="accordion-panel-title" [id]="'label_bc_competences_transverses_' + index1">
                          {{'fiche.blocs_competence.competences_transverses' | translate}}
                        </h3>
                        <button data-toggle="collapse"
                                type="button"
                                [attr.data-target]="'#bloc-competences-transverses-'  + index1"
                                [attr.aria-controls]="'bloc-competences-transverses-' + index1"
                                [attr.aria-describedby]="'label_bc_competences_transverses_' + index1"
                                class="accordion-panel-toggler"
                                aria-expanded="true">
                          <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                        </button>
                      </div>

                      <div class="accordion-panel-body" [id]="'bloc-competences-transverses-' + index1">
                        <div class="accordion-panel-body-inner">
                          <ul>
                            <li *ngFor="let competence of bloc.competencesTransverses">{{ competence.lib }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <!-- competences pré-professionnelles  -->
                    <div class="accordion-panel accordion-panel-open"
                         *ngIf="bloc.competencesPreProfessionnelles?.length > 0">
                      <div class="accordion-panel-header">
                        <h3 class="accordion-panel-title" [id]="'label_bc_competences_preprofessionnelles_' + index1">
                          {{'fiche.blocs_competence.competences_preprofessionnelles' | translate}}
                        </h3>
                        <button data-toggle="collapse"
                                type="button"
                                [attr.data-target]="'#bloc-competences-preprofessionnelles-'  + index1"
                                [attr.aria-controls]="'bloc-competences-preprofessionnelles-' + index1"
                                [attr.aria-describedby]="'label_bc_competences_preprofessionnelles_' + index1"
                                class="accordion-panel-toggler"
                                aria-expanded="true">
                          <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                        </button>
                      </div>

                      <div class="accordion-panel-body"
                           [id]="'bloc-competences-preprofessionnelles-' + index1">
                        <div class="accordion-panel-body-inner">
                          <ul>
                            <li *ngFor="let competence of bloc.competencesPreProfessionnelles">{{ competence.lib }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- enseignements -->
            <div class="accordion-panel" *ngIf="bloc.listesEnseignements?.length > 0">
              <div class="accordion-panel-header">
                <h3 class="accordion-panel-title" [id]="'label_enseignements_' + index1"
                >{{'fiche.blocs_competence.enseignements' | translate}}</h3>
                <button data-toggle="collapse"
                        type="button"
                        [attr.data-target]="'#enseignements-' + index1"
                        [attr.aria-controls]="'enseignements-' + index1"
                        [attr.aria-describedby]="'label_enseignements_' + index1"
                        class="accordion-panel-toggler collapsed"
                        aria-expanded="false">
                  <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                </button>
              </div>

              <div class="accordion-panel-body collapse" [id]="'enseignements-' + index1">
                <div class="accordion-panel-body-inner">
                  <div class="accordion-panels">
                    <div class="accordion-panel accordion-panel-open"
                         *ngFor="let le of bloc.listesEnseignements ; let index2= index">
                      <div class="accordion-panel-header">
                        <h3 class="accordion-panel-title"
                            [id]="'label_liste-enseignements_' + index1 + '-' + index2">{{le.lib}}</h3>
                        <button data-toggle="collapse"
                                type="button"
                                aria-expanded="true"
                                [attr.data-target]="'#bloc-enseignements-liste-'  + index1 + '-' + index2"
                                [attr.aria-controls]="'bloc-enseignements-liste-'  + index1 + '-' + index2"
                                [attr.aria-describedby]="'label_liste-enseignements_' + index1 + '-' + index2"
                                class="accordion-panel-toggler">
                          <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                        </button>
                      </div>

                      <div class="accordion-panel-body"
                           [id]="'bloc-enseignements-liste-'  + index1 + '-' + index2">
                        <div class="accordion-panel-body-inner">
                          <ul *ngIf="le.listeUE?.length > 0">
                            <li *ngFor="let ue of le.listeUE">
                              <a href [routerLink]="'/' + pathDetailFormation"
                                 [queryParams]="{type: ue.objectAffichage.typeObjet, id: ue.objectAffichage.id}">
                                {{ue.objectAffichage.intitule}}
                                <span
                                  *ngIf="ue.ensTypologieId">{{' - ' }} {{ensTypologieTrad.get(ue.ensTypologieId) | translate}}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- modalités acquisition -->
            <div class="accordion-panel" *ngIf="bloc.modalites.length > 0">
              <div class="accordion-panel-header">
                <h3 class="accordion-panel-title"
                    [id]="'label_modalites_' + index1">{{'fiche.blocs_competence.modalites.title' | translate}}</h3>
                <button data-toggle="collapse"
                        type="button"
                        aria-expanded="false"
                        [attr.data-target]="'#bloc-modalites-' + index1"
                        [attr.aria-controls]="'bloc-modalites-' + index1"
                        [attr.aria-describedby]="'label_modalites_' + index1"
                        class="accordion-panel-toggler collapsed">
                  <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                </button>
              </div>

              <div class="accordion-panel-body collapse" [id]="'bloc-modalites-' + index1">
                <div class="accordion-panel-body-inner">
                  <div class="accordion-panels">
                    <ng-container *ngFor="let modalite of bloc.modalites ; let index2= index">
                      <div class="accordion-panel accordion-panel-open">
                        <div class="accordion-panel-header">
                          <h3 class="accordion-panel-title"
                              [id]="'label_modalites_' + index1  + '-' + index2">{{modalite.objectAffichage?.intitule}}</h3>
                          <button data-toggle="collapse"
                                  type="button"
                                  aria-expanded="true"
                                  [attr.data-target]="'#bloc-modalites-'  + index1 + '-' + index2"
                                  [attr.aria-controls]="'bloc-modalites-'  + index1 + '-' + index2"
                                  [attr.aria-describedby]="'label_modalites_' + index1  + '-' + index2"
                                  class="accordion-panel-toggler">
                            <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                          </button>
                        </div>

                        <div class="accordion-panel-body"
                             [id]="'bloc-modalites-'  + index1 + '-' + index2">
                          <div class="accordion-panel-body-inner">
                            <div class="margin-large margin-lg-xlarge" *ngIf="modalite.objectAffichage">
                              <div class="course-detail-career-title-container">
                                <div class="course-detail-career-title-side">
                                  <a href [routerLink]="'/' + pathDetailFormation"
                                     [queryParams]="{type: modalite.objectAffichage.typeObjet, id: modalite.objectAffichage.id}">
                                    {{ 'fiche.blocs_competence.modalites.parcours' | translate }}</a>
                                </div>
                              </div>
                            </div>
                            <div [innerHTML]="modalite.desc"></div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
