<div class="accordion-panels search-results-filters">
  <h2 class="contact-item-title">{{'filtre.title' | translate}}</h2>
  <ng-container *ngFor="let filtre of filtres, let i1=index">
    <ng-container *ngIf="AFFICHAGE_FILTRES.includes(filtre.field)">
    <div class="accordion-panel" *ngIf="filtre.options.length > 0"
         [hidden]="fromCatalogueInternational && filtre.field === field.OUVERTURE_MOBILITE">
      <div class="accordion-panel-header">
        <p [id]="'search-results-filters-' + i1 + '-title'"
           class="accordion-panel-title">{{ filtre.lib | translate }}</p>
        <button data-toggle="collapse"
                type="button"
                aria-expanded="false"
                [attr.data-target]="'#search-results-filters-' + i1"
                [attr.aria-controls]="'search-results-filters-' + i1"
                [attr.aria-describedby]="'search-results-filters-' + i1 + '-title'"
                class="accordion-panel-toggler collapsed">
          <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
        </button>
      </div>
      <div [id]="'search-results-filters-' + i1" class="accordion-panel-body collapse">
        <div class="accordion-panel-body-inner">
          <div class="form-row" role="region" [attr.aria-labelledby]="'search-results-filters-' + i1 + '-title'">
            <fieldset class="controls checkrads checkboxes">

              <div class="field checkrad checkbox" *ngFor="let checkbox of filtre.options, let i2 = index">
                <input [id]="'search-filters-field-' + i1 + '-' + i2"
                       [name]="'search-filters-field-' + i1 + '-' + i2"
                       type="checkbox"
                       [(ngModel)]="checkbox.value"
                       (ngModelChange)="checkbox.value = $event; onFilterChange($event)"/>
                <label [for]="'search-filters-field-' + i1 + '-' + i2">
                  <span class="checkrad-icon checkbox-icon">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="32"
                         height="32"
                         viewBox="0 0 32 32"
                         aria-hidden="true">
                    <path
                      d="M11.967 28.105l-11.966-10.078 4.414-5.243 7.244 6.106 15.588-14.995 4.753 4.938z"></path>
                    </svg>
                  </span>
                  <span class="checkrad-text">{{ checkbox.key }}</span>
                </label>
              </div>

            </fieldset>
          </div>
        </div>
      </div>
    </div>
    </ng-container>
  </ng-container>
</div>
