<ul class="contacts-list list-unstyled margin-container-large">
  <li *ngFor="let contact of contacts.contacts">
    <article class="contact-item">
      <p class="course-detail-top-side-title">{{contact.lib}}</p>
      <p>
        <span *ngIf="contact.nom">{{ contact.nom }}<br/></span>
        <span *ngIf="contact.adresse">{{ contact.adresse }}<br/></span>
        <span *ngIf="contact.cp || contact.ville">{{ contact.cp }} {{ contact.ville }}</span>
      </p>

      <p class="contact-item-line" *ngIf="contact.telephone">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
             aria-hidden="true">
          <path
            d="M18.973 25.413c-0.907-0.2-4.133-1.253-7.293-6.28s-2.76-8.467-2.573-9.4c0.57-1.481 1.719-2.63 3.162-3.187l0.038-0.013 1.093 0.253c0.154 0.028 0.272 0.151 0.293 0.305l0 0.002 0.8 4.267c0.004 0.020 0.005 0.042 0.005 0.065 0 0.152-0.088 0.284-0.217 0.347l-0.002 0.001c-1.773 0.84-2.347 1.093-2.48 1.68-0.267 1.267 3.733 7.64 4.973 7.92 0.587 0.12 1.080-0.307 2.547-1.613 0.062-0.053 0.144-0.086 0.233-0.086s0.171 0.032 0.234 0.086l-0-0 3.427 2.48c0.096 0.071 0.157 0.184 0.157 0.311 0 0.032-0.004 0.062-0.011 0.092l-0.226 1.131c-0.975 1.032-2.352 1.674-3.88 1.674-0.099 0-0.196-0.003-0.294-0.008l0.014 0.001zM16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-8.837-7.163-16-16-16v0zM16 2.28c7.577 0 13.72 6.143 13.72 13.72s-6.143 13.72-13.72 13.72v0 0c-7.577 0-13.72-6.143-13.72-13.72s6.143-13.72 13.72-13.72v0z"></path>
        </svg>
        <span class="contact-item-line-text">{{ contact.telephone }}</span>
      </p>

      <p class="contact-item-line" *ngIf="isValidMail(contact.mail)">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
             aria-hidden="true">
          <path
            d="M7.198 6.979h17.604c0.004-0 0.008-0 0.012-0 0.601 0 1.145 0.24 1.544 0.628l-0-0c0.396 0.383 0.642 0.919 0.642 1.512 0 0.020-0 0.040-0.001 0.060l0-0.003v0.573l-8.57 5.977c-0.676 0.473-1.516 0.756-2.422 0.756s-1.746-0.283-2.436-0.765l0.014 0.009-8.597-6.032v-0.519c-0-0.004-0-0.009-0-0.014 0-1.206 0.978-2.184 2.184-2.184 0.010 0 0.019 0 0.029 0l-0.001-0zM24.802 25.021h-17.604c-0.008 0-0.018 0-0.027 0-1.198 0-2.17-0.971-2.17-2.17 0-0.010 0-0.019 0-0.029l-0 0.001v-9.962l7.042 4.913c1.104 0.776 2.477 1.241 3.958 1.241s2.853-0.464 3.98-1.255l-0.022 0.015 7.042-4.913v9.962c0 0.001 0 0.002 0 0.003 0 0.593-0.246 1.129-0.641 1.511l-0.001 0.001c-0.404 0.386-0.952 0.625-1.555 0.628h-0zM7.198 27.6h17.604c0.016 0 0.035 0 0.055 0 2.641 0 4.782-2.137 4.79-4.776v-13.648c-0.008-2.64-2.149-4.777-4.79-4.777-0.019 0-0.038 0-0.058 0l0.003-0h-17.604c-0.016-0-0.035-0-0.055-0-2.641 0-4.782 2.137-4.79 4.776v13.648c0.008 2.64 2.149 4.777 4.79 4.777 0.019 0 0.038-0 0.058-0l-0.003 0z"></path>
          <path
            d="M7.198 6.979h17.604c0.004-0 0.008-0 0.012-0 0.601 0 1.145 0.24 1.544 0.628l-0-0c0.396 0.383 0.642 0.919 0.642 1.512 0 0.020-0 0.040-0.001 0.060l0-0.003v0.573l-8.57 5.977c-0.676 0.473-1.516 0.756-2.422 0.756s-1.746-0.283-2.436-0.765l0.014 0.009-8.597-6.032v-0.519c-0-0.004-0-0.009-0-0.014 0-1.206 0.978-2.184 2.184-2.184 0.010 0 0.019 0 0.029 0l-0.001-0zM24.802 25.021h-17.604c-0.008 0-0.018 0-0.027 0-1.198 0-2.17-0.971-2.17-2.17 0-0.010 0-0.019 0-0.029l-0 0.001v-9.962l7.042 4.913c1.104 0.776 2.477 1.241 3.958 1.241s2.853-0.464 3.98-1.255l-0.022 0.015 7.042-4.913v9.962c0 0.001 0 0.002 0 0.003 0 0.593-0.246 1.129-0.641 1.511l-0.001 0.001c-0.404 0.386-0.952 0.625-1.555 0.628h-0zM7.198 27.6h17.604c0.016 0 0.035 0 0.055 0 2.641 0 4.782-2.137 4.79-4.776v-13.648c-0.008-2.64-2.149-4.777-4.79-4.777-0.019 0-0.038 0-0.058 0l0.003-0h-17.604c-0.016-0-0.035-0-0.055-0-2.641 0-4.782 2.137-4.79 4.776v13.648c0.008 2.64 2.149 4.777 4.79 4.777 0.019 0 0.038-0 0.058-0l-0.003 0z"></path>
        </svg>
        <a class="contact-item-line-text cryptedmail"
           [attr.data-name]="getName(contact.mail)"
           [attr.data-domain]="getDomain(contact.mail)"
           [attr.data-tld]="getTld(contact.mail)"
           [href]="contact.href"
           (mouseout)="setEncodedHref(contact)"
           (mouseover)="setDecodedHref(contact)"></a>
      </p>
    </article>
  </li>
</ul>
