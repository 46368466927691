import {Injectable} from '@angular/core';
import {PanelPoursuitesEtudes} from '../../model/poursuites-etudes.model';
import {DiplomeResourceService} from '../resources/diplome-resource.service';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {PanelRebonds} from '../../model/rebonds.model';
import {PanelAcces} from '../../model/acces.model';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ParcoursTypePoursuitesEtudesService {
  panelPoursuitesEtudes: PanelPoursuitesEtudes = new PanelPoursuitesEtudes();

  constructor(private diplomeResourceService: DiplomeResourceService,
              private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelRebonds> {
    return new Promise<PanelAcces>(resolve => {
      this.diplomeResourceService.getDiplomes(gofElement, true).subscribe(res => {
        this.panelPoursuitesEtudes.diplomesInternes = [];
        res.accesInternes.forEach(d => {
          this.translateService.get(typesDiplomeTrad.get(d.type.typeDiplome.id)).subscribe(typeDiplome => {
            this.panelPoursuitesEtudes.diplomesInternes.push(new ObjectAffichage(d.objetId, typeDiplome + ' : ' + d.diplome.lib, TYPES_OBJET.FORMATION));
          });
        });
        this.panelPoursuitesEtudes.diplomesExternes = res.accesExternes.map(d => d.lib);
        resolve(this.panelPoursuitesEtudes);
      },
        () => resolve(new PanelPoursuitesEtudes()));
    });
  }
}
