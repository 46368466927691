import {Injectable} from '@angular/core';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {PanelPresentation} from '../../model/presentation.model';
import {EnseignementResourceService} from '../resources/enseignement-resource.service';
import {RoleResourceService} from '../resources/role-resource.service';
import {TranslateService} from '@ngx-translate/core';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {Router} from '@angular/router';
import {GOF_PROFILE} from '../../constants/conf.constants';
import {CookieService} from 'ngx-cookie-service';
import {RefTypePortage} from '../../constants/referentiel/ref.constants';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';

@Injectable({
  providedIn: 'root'
})
export class EnseignementPresentationService {

  panelPresentation: PanelPresentation = new PanelPresentation();

  constructor(private enseignementResourceService: EnseignementResourceService,
              private roleResourceService: RoleResourceService,
              private router: Router,
              private cookieService: CookieService,
              private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelPresentation> {
    return new Promise<PanelPresentation>(resolve => {
      Promise.all([
        this.getEnseignement(gofElement),
        this.getResponsables(gofElement)
      ])
        .then(() => resolve(this.panelPresentation));
    });
  }

  private getEnseignement(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.enseignementResourceService.getEnseignement(gofElement).subscribe(enseignement => {
        if (enseignement !== null) {
          this.panelPresentation.refObject = enseignement;
          this.getCurrentVersion(enseignement);
          this.panelPresentation.objetId = enseignement.objetId;
          this.panelPresentation.annee = enseignement.annee;
          if (enseignement.duree) {
            this.panelPresentation.duree = enseignement.duree;
            this.panelPresentation.typeDureeId = enseignement.typeDuree.id;
          }
          this.panelPresentation.ensNiveauId = enseignement.niveau?.id;
          this.panelPresentation.ensTypologieId = enseignement.typologie?.id;
          if (enseignement.instances) {
            this.panelPresentation.ensTypologieIds = enseignement.instances
              .filter(i => i.typologie)
              .map(i => i.typologie.id)
              .filter((elem, index, self) => index === self.indexOf(elem));
          }
          this.panelPresentation.credits = enseignement.ects;
          this.panelPresentation.ensIsTransverse = enseignement.isTransverse;

          if (enseignement.formationPorteuse) {
            this.translateService.get(typesDiplomeTrad.get(enseignement.formationPorteuse.type.typeDiplome.id))
              .subscribe(typeDiplome => {
                this.panelPresentation.formationsPorteuses = enseignement.formationPorteuse ?
                  [new ObjectAffichage(enseignement.formationPorteuse.objetId,
                    typeDiplome + ' : ' + enseignement.formationPorteuse.diplome.lib,
                    TYPES_OBJET.FORMATION)
                  ] : [];
              });
          }
          this.panelPresentation.composanteIds = enseignement.structures?.filter(s => s.typePortage.id === RefTypePortage.COMPOSANTE_PEDAGOGIQUE).map(s => s.structure.id);
          this.panelPresentation.sniIds = enseignement.structures?.filter(s => s.typePortage.id === RefTypePortage.SNI_PORTEUSE).map(s => s.structure.id);
          this.panelPresentation.ensModules = enseignement.modules ? enseignement.modules.map(m => m.lib) : [];
          this.panelPresentation.ensMobilite = enseignement.mobilite;
          this.panelPresentation.ensADistanceId = enseignement.adistance?.type.id;
          this.panelPresentation.ensCodesSI = Array.from(new Set(enseignement.instances?.filter(i => i.codeSI).map(i => i.codeSI)));
        }
        resolve();
      },
        () => resolve());
    });
  }

  private getCurrentVersion(enseignement: any): void {
    this.panelPresentation.intitule = enseignement.lib;
    this.panelPresentation.objectif = enseignement.objectif;

    if (enseignement.editos?.length > 0) {
      if (enseignement.editos.filter(e => e.typePublic.id === +this.cookieService.get(GOF_PROFILE)).length > 0) {
        this.panelPresentation.intitule = enseignement.editos.filter(e => e.typePublic.id === +this.cookieService.get(GOF_PROFILE))[0].lib;
        this.panelPresentation.objectif = enseignement.editos.filter(e => e.typePublic.id === +this.cookieService.get(GOF_PROFILE))[0].objectif;
      }
    } else if (enseignement.traductions?.length > 0) {
      if (enseignement.traductions.filter(t => t.langue.code === this.translateService.currentLang).length > 0) {
        this.panelPresentation.intitule = enseignement.traductions.filter(t => t.langue.code === this.translateService.currentLang)[0].lib;
        this.panelPresentation.objectif = enseignement.traductions.filter(t => t.langue.code === this.translateService.currentLang)[0].objectif;
      }
    }
  }

  private getResponsables(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.roleResourceService.getRolesForObjet(gofElement).subscribe(res => {
        this.panelPresentation.responsables = res.map(r => r.user.prenom + ' ' + r.user.nom);
        resolve();
      },
        () => resolve());
    });
  }
}
