import {LANGUES} from '../constants/utils.constants';
import {GofElement} from '../model/util.model';

export function getVersion(gofElement: GofElement, type: any): string {
  let result = type?.lib;

  if (gofElement.langue !== LANGUES.FR && type) {
    const traductions: any[] = type.traductions?.filter(t => t.langue.code === gofElement.langue);
    if (traductions?.length > 0) {
      result = traductions[0].traductionNomenclatureLib;
    }
  }
  return result;
}
