<div class="layout-with-left-col">

  <div class="layout-left-col">
    <div class="layout-left-col-inner">
      <div class="layout-left-col-text">
        {{ 'page.plan_du_site' | translate }}
      </div>
    </div>
  </div>

  <div class="layout-main">
    <section class="main-inner">
      <div class="container">

        <app-breadcrumb-thread [thread]="thread"></app-breadcrumb-thread>

        <div class="content-part">
          <h1> {{ 'page.plan_du_site' | translate }}</h1>
        </div>

        <app-loader *ngIf="!display"></app-loader>

        <div *ngIf="display">
          <div class="content-part">
            <h2>{{ 'plan_du_site.rechercher_une_formation' | translate }}</h2>
            <ul>
              <li><a href [routerLink]="'/' + pathAccueil">{{ 'page.accueil' | translate }}</a></li>
              <li><a href [routerLink]="'/' + pathResultats">{{ 'page.resultats' | translate }}</a></li>
              <li><a href [routerLink]="'/' + pathParDiplomes">{{ 'page.par_diplome' | translate }}</a></li>
              <li><a href [routerLink]="'/' + pathParComposantes">{{ 'page.par_composante' | translate }}</a></li>
              <li><a href [routerLink]="'/' + pathParDisciplines">{{ 'page.par_discipline' | translate }}</a></li>
              <li><a href
                     [routerLink]="'/' + pathCatalogueInternational">{{ 'page.international_courses_catalogue' | translate }}</a>
              </li>
              <li><a href [routerLink]="'/' + pathMaSelection">{{ 'page.ma_selection' | translate }}</a></li>
            </ul>
          </div>

          <div class="content-part">
            <h2>{{ 'plan_du_site.fiche' | translate }}</h2>

            <div class="margin-large margin-lg-xlarge" *ngIf="formations.length > 0">
              <h3>{{ 'plan_du_site.formation' | translate }}</h3>
              <ul>
                <li *ngFor="let el of formations">
                  <a href [routerLink]="'/' + pathDetailFormation"
                     [queryParams]="{type: typeobjetEnum.FORMATION, id: el.id}">
                    {{ el.intitule }}</a>
                </li>
              </ul>
            </div>

            <div class="margin-large margin-lg-xlarge" *ngIf="cursusEnrichis.length > 0">
              <h3>{{ 'plan_du_site.cursus_enrichi' | translate }}</h3>
              <ul>
                <li *ngFor="let el of cursusEnrichis">
                  <a href [routerLink]="'/' + pathDetailFormation"
                     [queryParams]="{type: typeobjetEnum.CURSUS_ENRICHI, id: el.id}">
                    {{ el.intitule }}</a>
                </li>
              </ul>
            </div>

            <div class="margin-large margin-lg-xlarge" *ngIf="parcoursTypes.length > 0">
              <h3>{{ 'plan_du_site.parcours-type' | translate }}</h3>
              <ul>
                <li *ngFor="let el of parcoursTypes">
                  <a href [routerLink]="'/' + pathDetailFormation"
                     [queryParams]="{type: typeobjetEnum.PARCOURS_TYPE, id: el.id}">
                    {{ el.intitule }}</a>
                </li>
              </ul>
            </div>

            <div class="margin-large margin-lg-xlarge" *ngIf="enseignements.length > 0">
              <h3>{{ 'plan_du_site.enseignement' | translate }}</h3>
              <ul>
                <li *ngFor="let el of enseignements">
                  <a href [routerLink]="'/' + pathDetailFormation"
                     [queryParams]="{type: typeobjetEnum.ENSEIGNEMENT, id: el.id}">
                    {{ el.intitule }}</a>
                </li>
              </ul>
            </div>

            <div class="margin-large margin-lg-xlarge" *ngIf="periodes.length > 0">
              <h3>{{ 'plan_du_site.periode' | translate }}</h3>
              <ul>
                <li *ngFor="let el of periodes">
                  <a href [routerLink]="'/' + pathDetailFormation"
                     [queryParams]="{type: typeobjetEnum.PERIODE, id: el.objetId}">
                    {{ el.lib }}</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="content-part">
            <h2>{{ 'plan_du_site.annexes' | translate }}</h2>
            <ul>
              <li><a href="https://www.u-bordeaux.fr/accessibilite">{{ 'footer.accessibilite' | translate }}</a></li>
              <li><a href="https://www.u-bordeaux.fr/donnees-personnelles">{{ 'footer.rgpd' | translate }}</a></li>
              <li><a href="https://www.u-bordeaux.fr/mentions-legales">{{ 'footer.mentions_legales' | translate }}</a>
              </li>
              <li><a href [routerLink]="'/' + pathPlanDuSite">{{ 'footer.plan_du_site' | translate }}</a></li>
            </ul>
          </div>
        </div>

      </div>
    </section>
  </div>
</div>

