import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PanelCompetences} from '../../../model/competences.model';
import {typesCompetenceTrad} from '../../../constants/translationMaps/trad.constants';

@Component({
  selector: 'app-competences',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './competences.component.html',
  styleUrls: ['./competences.component.scss']
})
export class CompetencesComponent {

  @Input() competences: PanelCompetences;

  typesCompetenceTrad = typesCompetenceTrad;

  constructor() {
  }
}
