import { Injectable } from "@angular/core";
import { PanelContenu, DynamicFlatNode } from "../../model/contenu.model";
import { PeriodeResourceService } from "../resources/periode-resource.service";
import { TYPES_OBJET } from "../../constants/utils.constants";
import { from } from "rxjs";
import { groupBy, mergeMap, reduce, toArray } from "rxjs/operators";
import { getListObservable, hasListObservable, setListObservable } from "../../utils/rxjs-cache.utils";
import { GofElement } from "../../model/util.model";
import { getVersion } from "../../utils/version.utils";

@Injectable({
  providedIn: "root"
})
export class PeriodeContenuService {

  observablesMap: Map<number, Map<number, any>> = new Map();

  constructor(private periodeResourceService: PeriodeResourceService) {
  }

  getContenu(gofElement: GofElement, periode: any): Promise<PanelContenu> {
    return new Promise<PanelContenu>(resolve => {
      const node: DynamicFlatNode = {
        name: periode.lib,
        objetId: periode.objetId,
        type: TYPES_OBJET.PERIODE,
        description: periode.typeLib,
        ects: periode.ects,
        clickable: true,
        level: 0,
        expandable: true,
        isLoading: false
      };


      this.getChildren(gofElement, node).then(noeuds => {
        let panelContenu: PanelContenu = new PanelContenu();
        panelContenu.noeuds = noeuds;
        panelContenu.objetFormation = node;

        resolve(panelContenu);
      });
    });
  }

  /***
   * Récupération des listes d'enseignements ou des localisations
   */
  getChildren(gofElement: GofElement, node: DynamicFlatNode): Promise<DynamicFlatNode[]> {
    return new Promise<DynamicFlatNode[]>(resolve => {
      const children: DynamicFlatNode[] = [];
      if (!hasListObservable(this.observablesMap, gofElement.annee, node.objetId)) {
        setListObservable(this.observablesMap, this.periodeResourceService, gofElement.annee, node.objetId);
      }
      getListObservable(this.observablesMap, gofElement.annee, node.objetId).subscribe(listes => {
        from(listes)
          .pipe(
            groupBy((list: any) => list.site ? getVersion(gofElement, list.site) : list.lib), // <!> liste peut être sans site <!>
            mergeMap(group => group.pipe(
                reduce((acc, cur) => {
                    acc.values.push(cur);
                    return acc;
                  },
                  { key: group.key, values: [] }
                )
              )
            ), toArray()
          ).subscribe(res => {
          if (res.length > 1) {
            res.forEach(loc => {
              children.push({
                name: loc.key,
                supId: node.objetId, // id periode
                siteId: loc.values?.filter(v => v.site)[0]?.id,
                site: loc.values?.filter(v => v.site)[0],
                type: TYPES_OBJET.LOCALISATION,
                level: node.level + 1,
                clickable: false,
                expandable: loc.values?.length > 0,
                isLoading: false
              });
            });
            resolve(children);

          } else {
            listes
              .sort((l1, l2) => {
                if (l1.temporalite < l2.temporalite) {
                  return -1;
                } else if (l1.temporalite > l2.temporalite) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .forEach(liste => {
                children.push({
                  name: liste.lib,
                  objetId: liste.objetId, // pour récup les enseignements
                  supId: node.objetId, // id periode
                  type: TYPES_OBJET.LISTE_ENSEIGNEMENTS,
                  description: liste.description,
                  temporalite: liste.temporalite,
                  level: node.level + 1,
                  clickable: false,
                  expandable: liste.enseignements?.length > 0 || liste.sousListes?.length > 0,
                  isLoading: false
                });
              });
            resolve(children);
          }
        });
      });
    });
  }
}

