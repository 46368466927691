import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {GOF_COOKIES} from '../../../constants/conf.constants';
import {GestionCookiesService} from '../../../services/gestion-cookies.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-rgpd-dialog',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './rgpd-dialog.component.html',
  styleUrls: ['./rgpd-dialog.component.scss']
})
export class RgpdDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('refuse') buttonRefuse: ElementRef;

  custom = false;

  checkboxYoutube = false;
  checkboxMatomo = false;

  constructor(private dialogRef: MatDialogRef<RgpdDialogComponent>,
              private cookieService: CookieService,
              private gestionCookiesService: GestionCookiesService) {
  }

  ngOnInit(): void {
    const cookies = this.cookieService.get(GOF_COOKIES);

    if (cookies) {
      this.checkboxYoutube = JSON.parse(cookies)?.youtube;
      this.checkboxMatomo = JSON.parse(cookies)?.matomo;
    }
  }

  ngAfterViewInit() {
    this.buttonRefuse.nativeElement.focus();
  }


  acceptAll(): void {
    this.checkboxYoutube = true;
    this.checkboxMatomo = true;
    this.init();

    this.gestionCookiesService.storeCookies({
      matomo: this.checkboxMatomo,
      youtube: this.checkboxYoutube,
    });

    this.dialogRef.close();
  }

  refuseAll(): void {
    this.checkboxYoutube = false;
    this.checkboxMatomo = false;
    this.init();

    this.gestionCookiesService.refuseAllCookies();

    this.dialogRef.close();
  }

  acceptCustom(): void {
    this.init();

    this.gestionCookiesService.storeCookies({
      matomo: this.checkboxMatomo,
      youtube: this.checkboxYoutube,
    });

    this.dialogRef.close();
  }


  private init(): void {
    if (this.checkboxMatomo) {
      this.gestionCookiesService.acceptMatomo();
    } else {
      this.gestionCookiesService.refuseMatomo();
    }
  }

}



