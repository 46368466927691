<div *ngIf="statistiquesRecrutement">
  <a [href]="statistiquesRecrutement.url" target="_blank">{{ statistiquesRecrutement.fileName }}</a>
</div>

<ul class="list-unstyled" *ngIf="documents && documents.length > 0">
  @for (doc of documents; track doc) {
    <li class="content-file-list-item">
      <a role="button" tabindex="0" appAnchor target="#en-savoir-plus"
         (keyup.enter)="download(doc)"
         (keyup.space)="download(doc)"
         (click)="download(doc)"
         attr.aria-label="{{'action.telecharger_document' | translate}} {{doc.description}}  - {{doc.size / 1000 | number : '1.2-2'}}&nbsp;ko">
        <div class="content-file-list-item-inner">
          <p class="content-file-list-item-text">
            <strong>{{ doc.description }}</strong> - {{ doc.size / 1000 | number : '1.2-2' }}&nbsp;ko
          </p>
          <p class="content-file-list-item-download" aria-hidden="true">{{ 'action.telecharger' | translate }}
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                 aria-hidden="true">
              <path class="path-arrow"
                    d="M15.261 22.109c0.098 0.078 0.213 0.141 0.337 0.183l0.008 0.002c0.118 0.046 0.255 0.072 0.397 0.072s0.279-0.027 0.405-0.075l-0.008 0.003c0.132-0.044 0.247-0.107 0.347-0.187l-0.002 0.002 9.408-8.050c0.188-0.15 0.307-0.379 0.307-0.635s-0.119-0.486-0.305-0.634l-0.002-0.001c-0.201-0.164-0.461-0.263-0.744-0.263-0.262 0-0.505 0.086-0.701 0.23l0.003-0.002-0.043 0.035-7.622 6.529v-18.422c-0.043-0.504-0.463-0.897-0.975-0.897-0.025 0-0.049 0.001-0.073 0.003l0.003-0c-0.021-0.002-0.045-0.003-0.070-0.003-0.512 0-0.932 0.393-0.975 0.894l-0 0.004v18.415l-7.622-6.529c-0.202-0.164-0.461-0.263-0.744-0.263-0.264 0-0.507 0.086-0.704 0.232l0.003-0.002-0.041 0.033c-0.188 0.15-0.307 0.379-0.307 0.635s0.119 0.486 0.305 0.634l0.002 0.001z"></path>
              <path
                d="M29.894 27.531v3.577c-0.003 0.252-0.114 0.477-0.29 0.631l-0.001 0.001c-0.186 0.162-0.43 0.261-0.698 0.261-0.001 0-0.002 0-0.002 0h-25.807c-0.013 0.001-0.029 0.001-0.044 0.001-0.505 0-0.918-0.395-0.947-0.893l-0-0.003v-3.577c0.027-0.5 0.439-0.896 0.944-0.896 0.017 0 0.033 0 0.050 0.001l-0.002-0c0.014-0.001 0.031-0.001 0.048-0.001 0.505 0 0.917 0.396 0.943 0.894l0 0.002v2.683h23.823v-2.683c0.027-0.5 0.439-0.896 0.944-0.896 0.017 0 0.033 0 0.050 0.001l-0.002-0c0.014-0.001 0.031-0.001 0.048-0.001 0.505 0 0.917 0.396 0.943 0.894l0 0.002z"></path>
            </svg>
          </p>
        </div>
      </a>
    </li>
  }
</ul>
