<!-- TITRES ACCES -->
<div class="margin-large margin-lg-xlarge" *ngIf="acces.diplomeAcces?.length > 0">
  <h3>{{'fiche.acces.titres_acces' | translate}}</h3>
  <ul>
    <li *ngFor="let diplome of acces.diplomeAcces">
      <a href [routerLink]="'/' + pathDetailFormation" [queryParams]="{type: diplome.typeObjet, id: diplome.id}"
         *ngIf="diplome.id">{{diplome.intitule}}</a>
      <span *ngIf="!diplome.id">{{diplome.intitule}}</span>
    </li>
  </ul>
</div>

<!-- TYPES INSCRIPTIONS -->
<div class="margin-large margin-lg-xlarge" *ngIf="acces.inscriptions?.size > 0">
  <h3>{{'fiche.acces.modalites_inscription' | translate}}</h3>

  <div class="accordion-panels">

    <div class="accordion-panel" *ngFor="let inscription of acces.inscriptions | keyvalue ; let index = index">
      <div class="accordion-panel-header">
        <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
          <h4 [id]="'label_inscription_' + index" class="accordion-panel-title">{{typesInscriptionTrad.get(inscription.key) | translate}}</h4>
        </div>
        <button data-toggle="collapse" type="button"
                [attr.data-target]="'#inscription' + index"
                [attr.aria-controls]="'inscription' + index"
                [attr.aria-describedby]="'label_inscription_' + index"
                class="accordion-panel-toggler collapsed"
                aria-expanded="false"
                *ngIf="inscription.value.length > 0">
          <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
        </button>
      </div>

      <div [id]="'inscription' + index" class="accordion-panel-body collapse" [hidden]="inscription.value.length === 0">
        <div class="accordion-panel-body-inner">

          <ng-container *ngFor="let modalite of inscription.value">
            <h5>{{modalite.libelle}}</h5>

            <table role="presentation">
              <tbody>
              <tr *ngIf="modalite.titresAcces">
                <td>{{'fiche.acces.inscriptions.titres_acces' | translate}}</td>
                <td> {{ modalite.titresAcces }}</td>
              </tr>
              <tr *ngIf="modalite.modalite">
                <td>{{'fiche.acces.inscriptions.modalite' | translate}}</td>
                <td><span [innerHTML]="modalite.modalite"></span></td>
              </tr>
              <tr *ngIf="modalite.seuil">
                <td class="cell-title">{{'fiche.acces.inscriptions.seuil' | translate}}</td>
                <td>{{ modalite.seuil }}</td>
              </tr>
              <tr *ngIf="modalite.url">
                <td>{{'fiche.acces.inscriptions.url' | translate}}</td>
                <td><a [href]="modalite.url" target="_blank">{{ modalite.url }}</a></td>
              </tr>
              <tr *ngIf="modalite.infosComplementaires">
                <td>{{'fiche.acces.inscriptions.infos_complementaires' | translate}}</td>
                <td [innerHTML]="modalite.infosComplementaires"></td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

</div>
