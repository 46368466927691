import {Injectable} from '@angular/core';
import {PanelContenu, DynamicFlatNode} from '../../model/contenu.model';
import {GofElement} from '../../model/util.model';
import {FormationResourceService} from '../resources/formation-resource.service';
import {PeriodeResourceService} from '../resources/periode-resource.service';
import {ObjetFormationResourceService} from '../resources/objet-formation-resource.service';
import {TYPES_OBJET} from '../../constants/utils.constants';

@Injectable({
  providedIn: 'root'
})
export class FormationContenuService {

  panelContenu: PanelContenu = new PanelContenu();

  constructor(private formationResourceService: FormationResourceService,
              private objetFormationResourceService: ObjetFormationResourceService,
              private periodeResourceService: PeriodeResourceService) {
  }

  getContenu(gofElement, formation: any): Promise<PanelContenu> {
    return new Promise<PanelContenu>(resolve => {
      const node: DynamicFlatNode = {
        name: formation.lib,
        objetId: formation.objetId,
        type: TYPES_OBJET.FORMATION,
        ects: formation.ects,
        clickable: true,
        level: 1,
        expandable: (formation.parcoursType?.length + formation.parcoursLibre?.length) > 0,
        isLoading: false
      };
      this.getChildren(gofElement, node).then(res => {
        this.panelContenu.noeuds = res;
        this.panelContenu.objetFormation = node;
        resolve(this.panelContenu);
      });
    });
  }

  /***
   * Récupération des parcours-type, des periodes (parcours libres) et des cursus
   */
  getChildren(ge: GofElement, node: DynamicFlatNode): Promise<DynamicFlatNode[]> {
    const gofElement: GofElement = new GofElement(
      node.type,
      node.objetId,
      ge.annee,
      ge.typePublic,
      ge.langue
    );
    const children: DynamicFlatNode[] = [];

    return new Promise<DynamicFlatNode[]>(resolve => {
      Promise.all([
        this.setPeriodes(node, gofElement, children),
        this.setCursus(node, gofElement, children),
        this.setParcoursLibres(node, gofElement, children)
      ]).then(() => resolve(children));
    });
  }

  private setPeriodes(node: DynamicFlatNode, gofElement: GofElement, children: DynamicFlatNode[]): Promise<DynamicFlatNode[]> {
    return new Promise<DynamicFlatNode[]>(resolve => {

      this.formationResourceService.getPeriodes(gofElement).subscribe(periodes => {
        periodes.forEach(per => {
          children.push({
            name: per.lib,
            objetId: per.objetId,
            supId: gofElement.id, // objetId formation
            type: TYPES_OBJET.PERIODE,
            ects: per.ects,
            clickable: true,
            level: node.level + 1,
            expandable: true,
            isLoading: false
          });
        });

        resolve(children);
      },
        () => resolve(children));
    });
  }

  private setTroncCommun(node: DynamicFlatNode, gofElement: GofElement, children: DynamicFlatNode[]): Promise<DynamicFlatNode[]> {
    return new Promise<DynamicFlatNode[]>(resolve => {

      this.formationResourceService.getPeriodes(gofElement).subscribe(periodes => {
        periodes.filter(per => per.type.isTransverse).forEach(per => {
          children.push({
            name: per.lib,
            objetId: per.objetId,
            supId: gofElement.id, // objetId formation
            type: TYPES_OBJET.PERIODE,
            ects: per.ects,
            clickable: true,
            level: node.level + 1,
            expandable: true,
            isLoading: false
          });
        });

        this.setParcoursType(node, gofElement, children).then(() => resolve(children));
      },
        () => resolve(children));
    });
  }

  private setParcoursType(node: DynamicFlatNode, gofElement: GofElement, children: DynamicFlatNode[]): Promise<DynamicFlatNode[]> {
    return new Promise<DynamicFlatNode[]>(resolve => {

      this.formationResourceService.getFormation(gofElement).subscribe(res => {
        res.parcoursType?.forEach(pt => {
          let nbCredits = 0;
          let duree = 0;
          res.periodesPorteuses?.forEach(p => {
            nbCredits += p.ects;
            duree += p.duree;
          });

          children.push({
            name: pt.lib,
            objetId: pt.objetId,
            supId: gofElement.id, // objetId formation
            type: TYPES_OBJET.PARCOURS_TYPE,
            ects: nbCredits,
            clickable: true,
            level: node.level + 1,
            expandable: pt.periodesPorteuses ? pt.periodesPorteuses.length > 0 : false,
            isLoading: false
          });
        });

        resolve(children);
      },
        () => resolve(children));
    });
  }

  private setCursus(node: DynamicFlatNode, gofElement: GofElement, children: DynamicFlatNode[]): Promise<DynamicFlatNode[]> {
    return new Promise<DynamicFlatNode[]>(resolve => {
      this.objetFormationResourceService.getCursus(gofElement).subscribe(res => {

        res.forEach(cursus => {
          let duree = null;
          if (res.periodes) {
            duree = 0;
            res.periodes?.forEach(p => {
              duree += p.duree;
            });
          }

          children.push({
            name: cursus.lib,
            objetId: cursus.objetId,
            supId: gofElement.id, // objetId formation
            type: TYPES_OBJET.CURSUS_ENRICHI,
            ects: cursus.ects,
            level: node.level + 1,
            clickable: true,
            expandable: cursus.periodes.length > 0,
            isLoading: false
          });
        });

        resolve(children);
      });
    });
  }

  private setParcoursLibres(node: DynamicFlatNode, gofElement: GofElement, children: DynamicFlatNode[]): Promise<DynamicFlatNode[]> {
    return new Promise<DynamicFlatNode[]>(resolve => {
      this.formationResourceService.getFormation(gofElement).subscribe(res => {
        res.parcoursLibre?.forEach(per => {
          this.isExpandable(per.objetId, gofElement.annee).then(isExpandable => {
            children.push({
              name: per.lib,
              objetId: per.objetId,
              supId: gofElement.id, // objetId formation
              type: TYPES_OBJET.PERIODE,
              ects: per.ects,
              level: node.level + 1,
              clickable: true,
              expandable: isExpandable,
              isLoading: false
            });
          });
        });
      });
      resolve(children);
    });
  }

  private isExpandable(periodeObjetId: number, annee: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.periodeResourceService.getListes(periodeObjetId, annee).subscribe(res => {
        resolve(res.enseignements?.length > 0 || res.sousListes?.length > 0);
      },
        () => resolve(false));
    });
  }
}
