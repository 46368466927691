import {refStructure} from '../referentiel/ref_structure.constants';

export const structuresTrad = new Map([
  [refStructure.universite_de_bordeaux, 'referentiel.structure.universite_de_bordeaux'],
  [refStructure.college_sciences_et_technologies, 'referentiel.structure.college_sciences_et_technologies'],
  [refStructure.unite_de_formation_de_biologie, 'referentiel.structure.unite_de_formation_de_biologie'],
  [refStructure.universite_bordeaux_montaigne, 'referentiel.structure.universite_bordeaux_montaigne'],
  [refStructure.college_sciences_de_la_sante, 'referentiel.structure.college_sciences_de_la_sante'],
  [refStructure.college_droit_science_politique_economie_et_gestion, 'referentiel.structure.college_droit_science_politique_economie_et_gestion'],
  [refStructure.college_sciences_de_l_homme, 'referentiel.structure.college_sciences_de_l_homme '],
  [refStructure.faculte_d_anthropologie_sociale_ethnologie, 'referentiel.structure.faculte_d_anthropologie_sociale_ethnologie'],
  [refStructure.faculte_de_psychologie, 'referentiel.structure.faculte_de_psychologie '],
  [refStructure.faculte_des_sciences_de_l_education_et_de_la_formation, 'referentiel.structure.faculte_des_sciences_de_l_education_et_de_la_formation'],
  [refStructure.faculte_des_sciences_et_techniques_des_activites_physiques_et_sportives, 'referentiel.structure.faculte_des_sciences_et_techniques_des_activites_physiques_et_sportives'],
  [refStructure.faculte_de_sociologie, 'referentiel.structure.faculte_de_sociologie'],
  [refStructure.unite_de_formation_de_sciences_chimiques, 'referentiel.structure.unite_de_formation_de_sciences_chimiques'],
  [refStructure.unite_de_formation_d_informatique, 'referentiel.structure.unite_de_formation_d_informatique'],
  [refStructure.unite_de_formation_de_mathematiques_et_interactions, 'referentiel.structure.unite_de_formation_de_mathematiques_et_interactions'],
  [refStructure.unite_de_formation_de_physique, 'referentiel.structure.unite_de_formation_de_physique'],
  [refStructure.unite_de_formation_des_sciences_de_l_ingenieur, 'referentiel.structure.unite_de_formation_des_sciences_de_l_ingenieur'],
  [refStructure.unite_de_formation_des_sciences_de_la_terre_et_environnement, 'referentiel.structure.unite_de_formation_des_sciences_de_la_terre_et_environnement'],
  [refStructure.departement_licence, 'referentiel.structure.departement_licence'],
  [refStructure.departement_langues_lettres_et_communication, 'referentiel.structure.departement_langues_lettres_et_communication'],
  [refStructure.departement_universitaire_des_sciences_d_agen, 'referentiel.structure.departement_universitaire_des_sciences_d_agen'],
  [refStructure.ufr_des_sciences_medicales, 'referentiel.structure.ufr_des_sciences_medicales'],
  [refStructure.ufr_des_sciences_pharmaceutiques, 'referentiel.structure.ufr_des_sciences_pharmaceutiques'],
  [refStructure.ufr_des_sciences_odontologiques, 'referentiel.structure.ufr_des_sciences_odontologiques'],
  [refStructure.isped, 'referentiel.structure.isped'],
  [refStructure.institut_du_thermalisme, 'referentiel.structure.institut_du_thermalisme'],
  [refStructure.institut_de_formation_en_psychomotricite, 'referentiel.structure.institut_de_formation_en_psychomotricite'],
  [refStructure.ecole_d_orthoptie, 'referentiel.structure.ecole_d_orthoptie'],
  [refStructure.institut_de_formation_en_soins_infirmiers, 'referentiel.structure.institut_de_formation_en_soins_infirmiers'],
  [refStructure.faculte_de_droit_et_science_politique, 'referentiel.structure.faculte_de_droit_et_science_politique'],
  [refStructure.faculte_d_economie_gestion_et_aes, 'referentiel.structure.faculte_d_economie_gestion_et_aes'],
  [refStructure.institut_du_travail, 'referentiel.structure.institut_du_travail'],
  [refStructure.iae_bordeaux_ecole_universitaire_de_management, 'referentiel.structure.iae_bordeaux_ecole_universitaire_de_management'],
  [refStructure.bordeaux_inp, 'referentiel.structure.bordeaux_inp'],
  [refStructure.ecole_nationale_superieure_de_cognitique_ensc, 'referentiel.structure.ecole_nationale_superieure_de_cognitique_ensc'],
  [refStructure.enseirb_matmeca, 'referentiel.structure.enseirb_matmeca'],
  [refStructure.ensegid, 'referentiel.structure.ensegid'],
  [refStructure.enscbp, 'referentiel.structure.enscbp'],
  [refStructure.enstbb, 'referentiel.structure.enstbb'],
  [refStructure.bordeaux_sciences_agro, 'referentiel.structure.bordeaux_sciences_agro'],
  [refStructure.institut_de_mathematiques_de_bordeaux_imb_umr_5251, 'referentiel.structure.institut_de_mathematiques_de_bordeaux_imb_umr_5251'],
  [refStructure.institut_des_sciences_moleculaires_ism, 'referentiel.structure.institut_des_sciences_moleculaires_ism'],
  [refStructure.iut_de_bordeaux_2, 'referentiel.structure.iut_de_bordeaux_2'],
  [refStructure.college_des_ecoles_doctorales, 'referentiel.structure.college_des_ecoles_doctorales'],
  [refStructure.ecole_doctorale_de_mathematiques_et_informatique, 'referentiel.structure.ecole_doctorale_de_mathematiques_et_informatique'],
  [refStructure.ecole_doctorale_des_sciences_chimiques, 'referentiel.structure.ecole_doctorale_des_sciences_chimiques'],
  [refStructure.ecole_doctorale_des_sciences_de_la_vie_et_de_la_sante, 'referentiel.structure.ecole_doctorale_des_sciences_de_la_vie_et_de_la_sante'],
  [refStructure.ecole_doctorale_des_sciences_physiques_et_de_l_ingenieur, 'referentiel.structure.ecole_doctorale_des_sciences_physiques_et_de_l_ingenieur'],
  [refStructure.ecole_doctorale_droit_sciences_politiques, 'referentiel.structure.ecole_doctorale_droit_sciences_politiques'],
  [refStructure.ecole_doctorale_entreprise_economie_et_societe, 'referentiel.structure.ecole_doctorale_entreprise_economie_et_societe'],
  [refStructure.ecole_doctorale_sciences_et_environnements, 'referentiel.structure.ecole_doctorale_sciences_et_environnements'],
  [refStructure.ecole_doctorale_societes_politique_sante_publique, 'referentiel.structure.ecole_doctorale_societes_politique_sante_publique'],
  [refStructure.centre_de_recherche_appliquees_en_methodes_educative_crame, 'referentiel.structure.centre_de_recherche_appliquees_en_methodes_educative_crame'],
  [refStructure.unite_de_formation_de_biologie_college_sante, 'referentiel.structure.unite_de_formation_de_biologie_college_sante'],
  [refStructure.biodiversite_genes_et_communautes_biogeco_umr_1202, 'referentiel.structure.biodiversite_genes_et_communautes_biogeco_umr_1202'],
  [refStructure.centre_de_recherche_paul_pascal_crpp, 'referentiel.structure.centre_de_recherche_paul_pascal_crpp'],
  [refStructure.centre_d_etudes_nucleaires_de_bordeaux_gradignan_cenbg, 'referentiel.structure.centre_d_etudes_nucleaires_de_bordeaux_gradignan_cenbg'],
  [refStructure.centre_lasers_intenses_et_applications_celia, 'referentiel.structure.centre_lasers_intenses_et_applications_celia'],
  [refStructure.chimie_et_biologie_des_membranes_et_des_nanoobjets_cbmn, 'referentiel.structure.chimie_et_biologie_des_membranes_et_des_nanoobjets_cbmn'],
  [refStructure.de_la_prehistoire_a_l_actuel_culture_environnement_et_anthropologie_pacea, 'referentiel.structure.de_la_prehistoire_a_l_actuel_culture_environnement_et_anthropologie_pacea'],
  [refStructure.environnements_et_paleoenvironnements_oceaniques_et_continentaux_epoc_umr_5805, 'referentiel.structure.environnements_et_paleoenvironnements_oceaniques_et_continentaux_epoc_umr_5805'],
  [refStructure.federation_de_recherche_lasers_et_plasmas_fr_207, 'referentiel.structure.federation_de_recherche_lasers_et_plasmas_fr_207'],
  [refStructure.federation_matiere_molle_fed_3297, 'referentiel.structure.federation_matiere_molle_fed_3297'],
  [refStructure.federation_sciences_archeologiques_bordeaux, 'referentiel.structure.federation_sciences_archeologiques_bordeaux'],
  [refStructure.ingenierie_des_organisations_distribuees, 'referentiel.structure.ingenierie_des_organisations_distribuees'],
  [refStructure.institut_de_chimie_de_la_matiere_condensee_de_bordeaux_icmcb, 'referentiel.structure.institut_de_chimie_de_la_matiere_condensee_de_bordeaux_icmcb'],
  [refStructure.institut_de_mecanique_et_d_ingenierie_i2m, 'referentiel.structure.institut_de_mecanique_et_d_ingenierie_i2m'],
  [refStructure.laboratoire_bordelais_de_recherche_en_informatique_labri_umr_5800, 'referentiel.structure.laboratoire_bordelais_de_recherche_en_informatique_labri_umr_5800'],
  [refStructure.laboratoire_d_astrophysique_de_bordeaux_lab_umr_5804, 'referentiel.structure.laboratoire_d_astrophysique_de_bordeaux_lab_umr_5804'],
  [refStructure.laboratoire_de_chimie_des_polymeres_organique_lcpo, 'referentiel.structure.laboratoire_de_chimie_des_polymeres_organique_lcpo'],
  [refStructure.laboratoire_des_composites_thermostructuraux_lcts, 'referentiel.structure.laboratoire_des_composites_thermostructuraux_lcts'],
  [refStructure.laboratoire_d_integration_du_materiau_au_systeme_ims, 'referentiel.structure.laboratoire_d_integration_du_materiau_au_systeme_ims'],
  [refStructure.laboratoire_ondes_et_matiere_d_aquitaine_loma, 'referentiel.structure.laboratoire_ondes_et_matiere_d_aquitaine_loma'],
  [refStructure.laboratoire_photonique_numerique_et_nanosciences_lp2n, 'referentiel.structure.laboratoire_photonique_numerique_et_nanosciences_lp2n'],
  [refStructure.laboratory_of_the_future_lof_umr_5258, 'referentiel.structure.laboratory_of_the_future_lof_umr_5258'],
  [refStructure.sciences_philosophie_humanites_sph_ea_4574, 'referentiel.structure.sciences_philosophie_humanites_sph_ea_4574'],
  [refStructure.centre_de_recherche_etudes_et_developpement_cred, 'referentiel.structure.centre_de_recherche_etudes_et_developpement_cred'],
  [refStructure.cohabit_fab_lab_techoshop, 'referentiel.structure.cohabit_fab_lab_techoshop'],
  [refStructure.iut_de_bordeaux, 'referentiel.structure.iut_de_bordeaux'],
  [refStructure.departement_carrieres_sociales_villes_et_territoires_durables, 'referentiel.structure.departement_carrieres_sociales_villes_et_territoires_durables'],
  [refStructure.departement_genie_biologique, 'referentiel.structure.departement_genie_biologique'],
  [refStructure.departement_genie_chimique_et_genie_des_procedes, 'referentiel.structure.departement_genie_chimique_et_genie_des_procedes'],
  [refStructure.departement_genie_civil, 'referentiel.structure.departement_genie_civil'],
  [refStructure.departement_genie_electronique_et_informatique_industrielle, 'referentiel.structure.departement_genie_electronique_et_informatique_industrielle'],
  [refStructure.departement_genie_mecanique_et_productique, 'referentiel.structure.departement_genie_mecanique_et_productique'],
  [refStructure.departement_gestion_administrative_et_commerciale_des_organisations, 'referentiel.structure.departement_gestion_administrative_et_commerciale_des_organisations'],
  [refStructure.departement_gestion_des_entreprises_et_des_administrations, 'referentiel.structure.departement_gestion_des_entreprises_et_des_administrations'],
  [refStructure.departement_management_de_la_logistique_et_des_transports, 'referentiel.structure.departement_management_de_la_logistique_et_des_transports'],
  [refStructure.departement_hygiene_securite_environnement, 'referentiel.structure.departement_hygiene_securite_environnement'],
  [refStructure.departement_informatique, 'referentiel.structure.departement_informatique'],
  [refStructure.departement_mesures_physiques, 'referentiel.structure.departement_mesures_physiques'],
  [refStructure.departement_qualite_logistique_industrielle_et_organisation, 'referentiel.structure.departement_qualite_logistique_industrielle_et_organisation'],
  [refStructure.departement_sciences_et_genie_des_materiaux, 'referentiel.structure.departement_sciences_et_genie_des_materiaux'],
  [refStructure.departement_techniques_de_commercialisation_perigueux, 'referentiel.structure.departement_techniques_de_commercialisation_perigueux'],
  [refStructure.departement_techniques_de_commercialisation_bordeaux, 'referentiel.structure.departement_techniques_de_commercialisation_bordeaux'],
  [refStructure.institut_des_sciences_de_la_vigne_et_du_vin_isvv, 'referentiel.structure.institut_des_sciences_de_la_vigne_et_du_vin_isvv'],
  [refStructure.inspe, 'referentiel.structure.inspe'],
  [refStructure.inspe_24, 'referentiel.structure.inspe_24'],
  [refStructure.inspe_33, 'referentiel.structure.inspe_33'],
  [refStructure.inspe_40, 'referentiel.structure.inspe_40'],
  [refStructure.inspe_47, 'referentiel.structure.inspe_47'],
  [refStructure.inspe_64, 'referentiel.structure.inspe_64'],
  [refStructure.archeovision_shs_3d, 'referentiel.structure.archeovision_shs_3d'],
  [refStructure.centre_d_etudes_et_de_recherches_comparatives_sur_les_constitutions_les_libertes_et_l_etat_cerccle, 'referentiel.structure.centre_d_etudes_et_de_recherches_comparatives_sur_les_constitutions_les_libertes_et_l_etat_cerccle'],
  [refStructure.centre_emile_durkheim_ced_umr_5116, 'referentiel.structure.centre_emile_durkheim_ced_umr_5116'],
  [refStructure.centre_europeen_de_recherche_en_droit_des_familles_des_assurances_des_personnes_et_de_la_sante_cerfaps, 'referentiel.structure.centre_europeen_de_recherche_en_droit_des_familles_des_assurances_des_personnes_et_de_la_sante_cerfaps'],
  [refStructure.centre_de_droit_compare_du_travail_et_de_la_securite_sociale_comptrasec, 'referentiel.structure.centre_de_droit_compare_du_travail_et_de_la_securite_sociale_comptrasec'],
  [refStructure.centre_de_recherche_et_de_documentation_europeenne_et_internationale_crdei, 'referentiel.structure.centre_de_recherche_et_de_documentation_europeenne_et_internationale_crdei'],
  [refStructure.groupe_de_recherche_en_economie_theorique_et_appliquee_gretha, 'referentiel.structure.groupe_de_recherche_en_economie_theorique_et_appliquee_gretha'],
  [refStructure.institut_de_recherches_montesquieu_irm, 'referentiel.structure.institut_de_recherches_montesquieu_irm'],
  [refStructure.institut_du_droit_des_personnes_et_de_l_etat_leon_duguit_ild_ea_7439, 'referentiel.structure.institut_du_droit_des_personnes_et_de_l_etat_leon_duguit_ild_ea_7439'],
  [refStructure.institut_de_recherche_en_droit_des_affaires_et_du_patrimoine_irdap, 'referentiel.structure.institut_de_recherche_en_droit_des_affaires_et_du_patrimoine_irdap'],
  [refStructure.institut_de_recherche_en_gestion_des_organisations_irgo, 'referentiel.structure.institut_de_recherche_en_gestion_des_organisations_irgo'],
  [refStructure.institut_de_sciences_criminelles_et_de_la_justice_iscj, 'referentiel.structure.institut_de_sciences_criminelles_et_de_la_justice_iscj'],
  [refStructure.laboratoire_cultures_education_societes_laces, 'referentiel.structure.laboratoire_cultures_education_societes_laces'],
  [refStructure.laboratoire_cultures_et_diffusion_des_savoirs_ceds, 'referentiel.structure.laboratoire_cultures_et_diffusion_des_savoirs_ceds'],
  [refStructure.laboratoire_d_epistemologie_et_de_didactiques_disciplinaires_professionnelle_et_comparee_de_bordeaux_lab_e3d, 'referentiel.structure.laboratoire_d_epistemologie_et_de_didactiques_disciplinaires_professionnelle_et_comparee_de_bordeaux_lab_e3d'],
  [refStructure.laboratoire_de_psychologie_ea_4139, 'referentiel.structure.laboratoire_de_psychologie_ea_4139'],
  [refStructure.laboratoire_d_analyse_et_de_recherche_en_economie_et_finance_internationales_larefi, 'referentiel.structure.laboratoire_d_analyse_et_de_recherche_en_economie_et_finance_internationales_larefi'],
  [refStructure.les_afriques_dans_le_monde_lam, 'referentiel.structure.les_afriques_dans_le_monde_lam'],
  [refStructure.passages, 'referentiel.structure.passages'],
  [refStructure.acides_nucleiques_regulations_naturelles_et_artificielles_arna_inserm_u1212, 'referentiel.structure.acides_nucleiques_regulations_naturelles_et_artificielles_arna_inserm_u1212'],
  [refStructure.actions_for_oncogenesis_understanding_and_target_identification_in_oncology_action_umr_1218, 'referentiel.structure.actions_for_oncogenesis_understanding_and_target_identification_in_oncology_action_umr_1218'],
  [refStructure.bioingenierie_tissulaire_biotis_u1026, 'referentiel.structure.bioingenierie_tissulaire_biotis_u1026'],
  [refStructure.biologie_des_maladies_cardiovasculaires_bmc_umr_1034, 'referentiel.structure.biologie_des_maladies_cardiovasculaires_bmc_umr_1034'],
  [refStructure.biologie_du_fruit_et_pathologie_bfp_umr_1332, 'referentiel.structure.biologie_du_fruit_et_pathologie_bfp_umr_1332'],
  [refStructure.tbm_core_ums_3427, 'referentiel.structure.tbm_core_ums_3427'],
  [refStructure.biotherapies_des_maladies_genetiques_inflammatoires_et_du_cancer_bmgic_umr_1035, 'referentiel.structure.biotherapies_des_maladies_genetiques_inflammatoires_et_du_cancer_bmgic_umr_1035'],
  [refStructure.departement_bordeaux_neurocampus, 'referentiel.structure.departement_bordeaux_neurocampus'],
  [refStructure.bordeaux_research_center_for_population_health_bph_umr_1219, 'referentiel.structure.bordeaux_research_center_for_population_health_bph_umr_1219'],
  [refStructure.bordeaux_research_in_translational_oncology_bariton_umr_1053, 'referentiel.structure.bordeaux_research_in_translational_oncology_bariton_umr_1053'],
  [refStructure.centre_de_recherche_cardio_thoracique_de_bordeaux_crctb_umr_1045, 'referentiel.structure.centre_de_recherche_cardio_thoracique_de_bordeaux_crctb_umr_1045'],
  [refStructure.centre_de_resonance_magnetique_des_systemes_biologiques_crmsb, 'referentiel.structure.centre_de_resonance_magnetique_des_systemes_biologiques_crmsb'],
  [refStructure.centre_d_investigation_clinique_de_bordeaux, 'referentiel.structure.centre_d_investigation_clinique_de_bordeaux'],
  [refStructure.centre_genomique_fontionnelle_de_bordeaux, 'referentiel.structure.centre_genomique_fontionnelle_de_bordeaux'],
  [refStructure.ecophysiologie_et_genomique_fonctionnelle_de_la_vigne_egfv, 'referentiel.structure.ecophysiologie_et_genomique_fonctionnelle_de_la_vigne_egfv'],
  [refStructure.handicap_activite_cognition_sante, 'referentiel.structure.handicap_activite_cognition_sante'],
  [refStructure.iins_institut_interdisciplinaire_de_neuroscience, 'referentiel.structure.iins_institut_interdisciplinaire_de_neuroscience'],
  [refStructure.imagerie_moleculaire_et_therapies_inovantes_en_oncologie_imotion, 'referentiel.structure.imagerie_moleculaire_et_therapies_inovantes_en_oncologie_imotion'],
  [refStructure.immunologie_conceptuelle_experimentale_et_translationnelle_immuno_concept, 'referentiel.structure.immunologie_conceptuelle_experimentale_et_translationnelle_immuno_concept'],
  [refStructure.institut_des_maladies_neurodegeneratives_imn, 'referentiel.structure.institut_des_maladies_neurodegeneratives_imn'],
  [refStructure.infections_humaines_a_mycoplasmes_et_a_chlamydiae_ihmc, 'referentiel.structure.infections_humaines_a_mycoplasmes_et_a_chlamydiae_ihmc'],
  [refStructure.institut_de_biochimie_et_genetique_cellulaires_ibgc, 'referentiel.structure.institut_de_biochimie_et_genetique_cellulaires_ibgc'],
  [refStructure.institut_de_bioimagerie_ibio, 'referentiel.structure.institut_de_bioimagerie_ibio'],
  [refStructure.institut_de_neurosciences_cognitives_et_integratives_d_aquitaine_incia, 'referentiel.structure.institut_de_neurosciences_cognitives_et_integratives_d_aquitaine_incia'],
  [refStructure.institut_europeen_de_chimie_et_biologie_iecb, 'referentiel.structure.institut_europeen_de_chimie_et_biologie_iecb'],
  [refStructure.laboratoire_de_biogenese_membranaire_lbm_umr_5200, 'referentiel.structure.laboratoire_de_biogenese_membranaire_lbm_umr_5200'],
  [refStructure.laboratoire_de_l_angiogenese_et_du_microenvironnement_des_cancers_lamc, 'referentiel.structure.laboratoire_de_l_angiogenese_et_du_microenvironnement_des_cancers_lamc'],
  [refStructure.maladies_rares_genetique_et_metabolisme_mrgm, 'referentiel.structure.maladies_rares_genetique_et_metabolisme_mrgm'],
  [refStructure.microbiologie_fondamentale_et_pathogenicite_mfp, 'referentiel.structure.microbiologie_fondamentale_et_pathogenicite_mfp'],
  [refStructure.neurophysiologie_de_la_synapse_auditive, 'referentiel.structure.neurophysiologie_de_la_synapse_auditive'],
  [refStructure.nutrition_et_neurobiologie_integree_nutrineuro, 'referentiel.structure.nutrition_et_neurobiologie_integree_nutrineuro'],
  [refStructure.sommeil_attention_et_neuropsychiatrie_sanpsy, 'referentiel.structure.sommeil_attention_et_neuropsychiatrie_sanpsy'],
  [refStructure.unite_de_recherche_oenologie, 'referentiel.structure.unite_de_recherche_oenologie'],
  [refStructure.departement_sciences_et_technologies_pour_la_sante, 'referentiel.structure.departement_sciences_et_technologies_pour_la_sante'],
  [refStructure.departement_sciences_biologiques_et_medicales, 'referentiel.structure.departement_sciences_biologiques_et_medicales'],
  [refStructure.departement_sciences_archeologiques, 'referentiel.structure.departement_sciences_archeologiques'],
  [refStructure.departement_sciences_de_l_environnement, 'referentiel.structure.departement_sciences_de_l_environnement'],
  [refStructure.departement_sante_publique, 'referentiel.structure.departement_sante_publique'],
  [refStructure.departement_droit_et_transformations_sociales, 'referentiel.structure.departement_droit_et_transformations_sociales'],
  [refStructure.departement_sciences_de_la_matiere_et_du_rayonnement, 'referentiel.structure.departement_sciences_de_la_matiere_et_du_rayonnement'],
  [refStructure.departement_changes_sciences_sociales_des_changements_contemporains, 'referentiel.structure.departement_changes_sciences_sociales_des_changements_contemporains'],
  [refStructure.departement_sciences_de_l_ingenierie_et_du_numerique, 'referentiel.structure.departement_sciences_de_l_ingenierie_et_du_numerique'],
  [refStructure.departement_evaluation_comportements_organisations_ecor, 'referentiel.structure.departement_evaluation_comportements_organisations_ecor'],
  [refStructure.bordeaux_imaging_center_bic_uar_3420_us_004, 'referentiel.structure.bordeaux_imaging_center_bic_uar_3420_us_004'],
  [refStructure.ecole_des_neurosciences_de_bordeaux, 'referentiel.structure.ecole_des_neurosciences_de_bordeaux'],
  [refStructure.neurocentre_magendie_umrs_1215, 'referentiel.structure.neurocentre_magendie_umrs_1215'],
  [refStructure.pole_in_vivo_experimentation, 'referentiel.structure.pole_in_vivo_experimentation'],
  [refStructure.intertryp_umr_177_site_de_bordeaux, 'referentiel.structure.intertryp_umr_177_site_de_bordeaux'],
  [refStructure.placamat, 'referentiel.structure.placamat'],
  [refStructure.maison_des_sciences_de_l_homme, 'referentiel.structure.maison_des_sciences_de_l_homme'],
  [refStructure.centre_de_formation_d_apprentis_de_l_universite_de_bordeaux, 'referentiel.structure.centre_de_formation_d_apprentis_de_l_universite_de_bordeaux'],
  [refStructure.unite_d_apprentissage_institut_d_administration_des_entreprises, 'referentiel.structure.unite_d_apprentissage_institut_d_administration_des_entreprises'],
  [refStructure.unite_d_apprentissage_institut_universitaire_de_technologie, 'referentiel.structure.unite_d_apprentissage_institut_universitaire_de_technologie'],
  [refStructure.unite_d_apprentissage_metiers_du_droit_de_l_economie_de_la_gestion_et_de_l_administration, 'referentiel.structure.unite_d_apprentissage_metiers_du_droit_de_l_economie_de_la_gestion_et_de_l_administration'],
  [refStructure.unite_d_apprentissage_sante, 'referentiel.structure.unite_d_apprentissage_sante'],
  [refStructure.unite_d_apprentissage_sciences_et_technologies, 'referentiel.structure.unite_d_apprentissage_sciences_et_technologies'],
  [refStructure.isvv_institut_des_sciences_de_la_vigne_et_du_vin, 'referentiel.structure.isvv_institut_des_sciences_de_la_vigne_et_du_vin'],
  [refStructure.centre_d_etudes_et_de_recherche_sur_le_droit_administratif_et_la_reforme_d_etat_cerdare, 'referentiel.structure.centre_d_etudes_et_de_recherche_sur_le_droit_administratif_et_la_reforme_d_etat_cerdare'],
  [refStructure.groupement_de_recherches_comparatives_en_droit_constitutionnel_administratif_et_politique_greccap, 'referentiel.structure.groupement_de_recherches_comparatives_en_droit_constitutionnel_administratif_et_politique_greccap'],
  [refStructure.etablissements_internationaux, 'referentiel.structure.etablissements_internationaux'],
  [refStructure.universite_mykolas_romeris, 'referentiel.structure.universite_mykolas_romeris'],
  [refStructure.universite_mohammed_v, 'referentiel.structure.universite_mohammed_v'],
  [refStructure.structures_privees, 'referentiel.structure.structures_privees'],
  [refStructure.es_banque, 'referentiel.structure.es_banque'],
  [refStructure.cfp_beausejour, 'referentiel.structure.cfp_beausejour'],
  [refStructure.universite_d_antananarivo, 'referentiel.structure.universite_d_antananarivo'],
  [refStructure.institut_universitaire_des_sciences_de_readaptation_iusr, 'referentiel.structure.institut_universitaire_des_sciences_de_readaptation_iusr'],
  [refStructure.archeosciences_bordeaux_materiaux_temps_images_et_societes_umr_6034, 'referentiel.structure.archeosciences_bordeaux_materiaux_temps_images_et_societes_umr_6034'],
  [refStructure.bordeaux_institute_of_oncology_umr1312, 'referentiel.structure.bordeaux_institute_of_oncology_umr1312'],
  [refStructure.bordeaux_sciences_economiques_umr_6060, 'referentiel.structure.bordeaux_sciences_economiques_umr_6060'],
  [refStructure.infranalytics_fr2054, 'referentiel.structure.infranalytics_fr2054'],
  [refStructure.sante_et_agroecologie_du_vignoble_umr_1065_save, 'referentiel.structure.sante_et_agroecologie_du_vignoble_umr_1065_save'],
  [refStructure.mycologie_et_securite_des_aliments_ur_1264_mycsa, 'referentiel.structure.mycologie_et_securite_des_aliments_ur_1264_mycsa'],
  [refStructure.college_sante, 'referentiel.structure.college_sante'],
  [refStructure.sph_sciences_philosophie_humanites_ea_4574, 'referentiel.structure.sph_sciences_philosophie_humanites_ea_4574'],
  [refStructure.mica_mediation_information_communication_arts_ea_4426, 'referentiel.structure.mica_mediation_information_communication_arts_ea_4426'],
  [refStructure.departement_langues_et_culture, 'referentiel.structure.departement_langues_et_culture'],
  [refStructure.college_sciences_de_l_homme_2, 'referentiel.structure.college_sciences_de_l_homme_2'],
  [refStructure.bureau_des_sports_du_college_sh, 'referentiel.structure.bureau_des_sports_du_college_sh'],
  [refStructure.etablissements_nationaux, 'referentiel.structure.etablissements_nationaux'],
  [refStructure.mediaquitaine, 'referentiel.structure.mediaquitaine'],
  [refStructure.crfcb_mediaquitaine, 'referentiel.structure.crfcb_mediaquitaine']
]);
