<div class="layout-with-left-col">

<div class="layout-left-col">
    <div class="layout-left-col-inner">
      <div class="layout-left-col-text">
        {{'page.ma_selection' | translate}}
      </div>
    </div>
  </div>

  <div class="layout-main">
    <section class="main-inner">
      <div class="container">

        <app-breadcrumb-thread [thread]="thread"></app-breadcrumb-thread>

        <div class="content-part">
          <h1> {{'page.ma_selection' | translate}}</h1>
        </div>

        <div class="content-part" *ngIf="selectionIds.length === 0">
          <div class="my-selection-list">
            <h2 class="my-selection-list-title">{{ 'ma_selection.no_content' | translate}}</h2>
          </div>
        </div>

        <ng-container *ngFor="let favori of favoris | keyvalue">
          <div class="content-part" *ngIf="favori.value.length > 0">
            <div class="my-selection-list">
              <h2 class="my-selection-list-title">{{ typeObjetTrad.get(favori.key) | translate}}</h2>

              <ul class="list-unstyled margin-container-large">

                <li class="margin-large" *ngFor="let obj of favori.value">
                  <article class="my-selection-list-item">

                    <div class="my-selection-list-item-main">
                      <h3 class="my-selection-list-item-title">
                        <a href [routerLink]="'/' + pathDetailFormation"
                          [queryParams]="{type: obj.type_objet, id: obj.id}"
                           (click)="onSelected(obj)">
                        {{obj.intitule}}</a></h3>
                    </div>

                    <div class="my-selection-list-item-actions">
<!--                      <a class="my-selection-list-item-action" href="#" title="Télécharger" aria-label="Télécharger">-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"-->
<!--                             aria-hidden="true">-->
<!--                          <path-->
<!--                            d="M11.191 14.814c-0.133-0.11-0.217-0.274-0.217-0.458 0-0.004 0-0.009 0-0.013v0.001c0-0.001-0-0.003-0-0.005 0-0.188 0.088-0.356 0.225-0.465l0.001-0.001c0.131-0.094 0.294-0.151 0.471-0.151s0.34 0.057 0.473 0.152l-0.002-0.002 3.614 3.068v-8.96c0.030-0.36 0.329-0.641 0.694-0.641 0.014 0 0.028 0 0.042 0.001l-0.002-0c0.006-0 0.013-0 0.020-0 0.358 0 0.651 0.277 0.676 0.629l0 0.002v8.969l3.558-3.049c0.136-0.107 0.31-0.172 0.499-0.172s0.363 0.065 0.501 0.173l-0.002-0.001c0.142 0.109 0.232 0.279 0.232 0.471s-0.090 0.361-0.231 0.47l-0.001 0.001-4.8 4.075c-0.067 0.054-0.145 0.099-0.23 0.13l-0.006 0.002c-0.078 0.029-0.169 0.046-0.263 0.047h-0.001c-0.096-0.001-0.186-0.021-0.268-0.058l0.004 0.002c-0.091-0.025-0.169-0.067-0.236-0.123l0.001 0.001zM23.718 21.176v0c-0.055-0.311-0.319-0.546-0.639-0.555l-0.001-0h-0.066c-0.361 0.005-0.653 0.291-0.668 0.648l-0 0.001v1.167h-11.736v-1.186c-0.025-0.359-0.323-0.64-0.685-0.64-0.001 0-0.001 0-0.002 0h0c-0.006-0-0.013-0-0.019-0-0.364 0-0.662 0.288-0.677 0.648l-0 0.001v1.826c0.020 0.35 0.307 0.626 0.658 0.631h13.177c0.181-0.009 0.344-0.079 0.472-0.189l-0.001 0.001c0.127-0.114 0.206-0.278 0.207-0.461v-0zM32 16c0-8.837-7.163-16-16-16s-16 7.163-16 16c0 8.837 7.163 16 16 16v0c8.837 0 16-7.163 16-16v0zM30.588 16c0 8.057-6.531 14.588-14.588 14.588s-14.588-6.531-14.588-14.588c0-8.057 6.531-14.588 14.588-14.588v0c8.055 0.005 14.583 6.534 14.588 14.588v0.001z"></path>-->
<!--                        </svg>-->
<!--                      </a>-->

                      <app-ajouter-favori [class]="'my-selection-list-item-action'" [document]="obj" (actionEmitter)="addRemoveToFavorites(obj)"></app-ajouter-favori>
                    </div>

                  </article>
                </li>

              </ul>

            </div>
          </div>
        </ng-container>

      </div>
    </section>
  </div>
</div>
