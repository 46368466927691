<div #scroll id="search-results" class="content-part search-results-main">

  <div class="row row-flex margin-container-section">

    <div class="layout-col-sm-large layout-col-lg-small col-xs-12 col-lg-3 margin-section">
      <app-filtres
        [documents]="documents"
        [filtres]="filtres"
        (filterEmitter)="onFilterEvent($event)"
        *ngIf="documents?.length > 0">
      </app-filtres>
    </div>

    <div class="layout-col-sm-large layout-col-lg-medium col-xs-12 col-lg-9 margin-section">

      <div class="margin-container-section">
        <div class="margin-section">
          <div class="margin-large">
            <p role="status" tabindex="-1" *ngIf="filteredDocs">
              {{ 'resultats.notif.resultats_trouves' | translate:{count: filteredDocs?.length} }}
            </p>

            <!-- FILTRES TYPOLOGIES-->
            <mat-chip-set aria-label="Selections des typologies">
              <mat-chip *ngIf="displayFormation"
                        [class.active-chip]="formationToggle"
                        (click)="toggleTypologieFilter('formation')">
                {{ formationCount }} {{ 'referentiel.type_objet.formation' | translate }}<span
                *ngIf="formationCount > 1">{{ 's' }}</span>
                <span style="margin-left: 10px">{{ formationToggle ? '-' : '+' }}</span>
              </mat-chip>
              <mat-chip *ngIf="displayParcoursType"
                        [class.active-chip]="parcoursTypeToggle"
                        (click)="toggleTypologieFilter('parcours-type')">
                {{ parcoursTypeCount }} {{ 'referentiel.type_objet.parcours_type' | translate }}
                <span style="margin-left: 10px">{{ parcoursTypeToggle ? '-' : '+' }}</span>
              </mat-chip>
              <mat-chip *ngIf="displayCursusEnrichi"
                        [class.active-chip]="cursusEnrichiToggle"
                        (click)="toggleTypologieFilter('cursus-enrichi')">
                {{ cursusEnrichiCount }} {{ 'referentiel.type_objet.cursus_enrichi' | translate }}<span
                *ngIf="cursusEnrichiCount > 1">{{ 's' }}</span>
                <span style="margin-left: 10px">{{ cursusEnrichiToggle ? '-' : '+' }}</span>
              </mat-chip>
              <mat-chip *ngIf="displayEnseignement"
                        [class.active-chip]="enseignementToggle"
                        (click)="toggleTypologieFilter('enseignement')">
                {{ enseignementCount }} {{ 'referentiel.type_objet.enseignement' | translate }}<span
                *ngIf="enseignementCount > 1">{{ 's' }}</span>
                <span style="margin-left: 10px">{{ enseignementToggle ? '-' : '+' }}</span>
              </mat-chip>
            </mat-chip-set>

            <!-- FILTRES CRITERES RECHERCHE-->
            <mat-chip-set aria-label="Sélection des critères de recherche">
              <ng-container *ngFor="let filter of filtres">
                <ng-container *ngIf="filter.field !== 'type_objet'">
                  <ng-container *ngFor="let option of filter.options">
                    <mat-chip *ngIf="option.value">{{ option.key }} <span class="pointer" style="margin-left: 10px"
                                                                          (click)="removeSearchCriteria (option)">X</span>
                    </mat-chip>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-chip-set>
          </div>

          <div *ngIf="lastsearch && filteredDocs?.length === 0">
            <p role="status" tabindex="-1"><strong>{{ 'resultats.notif.aucun_resultats' | translate }}</strong></p>
            <ng-container *ngFor="let filtre of noResultFiltres | keyvalue">
              <p>{{ filtre.key | translate }}
                <ng-container *ngFor="let value of filtre.value; let last = last">
                  {{ value }}<span *ngIf="!last">{{ ', ' }}</span>
                </ng-container>
              </p>
            </ng-container>
          </div>

          <a class="anchor-results" #results></a>
          <div class="search-results-list">
            <ul class="list-unstyled margin-container-large margin-container-lg-xlarge">
              <li class="margin-large margin-lg-xlarge" *ngFor="let document of paginedDocs">
                <ng-container [ngSwitch]="document.type_objet">
                  <app-carte-formation *ngSwitchCase="typeObjet.FORMATION"
                                       [document]="document"></app-carte-formation>
                  <app-carte-parcours-type *ngSwitchCase="typeObjet.PARCOURS_TYPE"
                                           [document]="document"></app-carte-parcours-type>
                  <app-carte-cursus-enrichi *ngSwitchCase="typeObjet.CURSUS_ENRICHI"
                                            [document]="document"></app-carte-cursus-enrichi>
                  <app-carte-enseignement *ngSwitchCase="typeObjet.ENSEIGNEMENT"
                                          [document]="document"></app-carte-enseignement>
                </ng-container>
              </li>
            </ul>
          </div>
        </div>

        <div class="margin-section">
          <app-paginateur [length]="filteredDocs.length"
                          [pageSize]="pageSize"
                          [pageSizeOptions]="pageSizeOptions"
                          (pageEmitter)="onPageEvent($event)"
                          *ngIf="paginedDocs?.length > 0"></app-paginateur>
        </div>

      </div>
    </div>

  </div>
</div>
