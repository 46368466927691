import {Directive, ElementRef, Input, OnChanges, Renderer2} from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements OnChanges {

  @Input() focus = true;

  constructor(private hostElement: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges() {
    if (this.focus) {
      this.hostElement.nativeElement.focus();
    }
  }
}
