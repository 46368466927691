import { Injectable } from "@angular/core";
import { GofElement, ObjectAffichage } from "../../model/util.model";
import { PanelAcquis, Acquis } from "../../model/acquis.model";
import { EnseignementResourceService } from "../resources/enseignement-resource.service";
import { Competence } from "../../model/competences.model";
import { ElementPedagogique } from "../../model/activites.model";
import { TYPES_OBJET } from "../../constants/utils.constants";
import { getVersion } from "../../utils/version.utils";
import { typesDiplomeTrad } from "../../constants/translationMaps/trad.constants";
import { TranslateService } from "@ngx-translate/core";


@Injectable({
  providedIn: "root"
})
export class EnseignementAcquisService {

  panelAcquis: PanelAcquis = new PanelAcquis();

  constructor(private enseignementResourceService: EnseignementResourceService,
              private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelAcquis> {
    return new Promise<PanelAcquis>(resolve => {
      this.enseignementResourceService.getAcquis(gofElement).subscribe(acqList => {
          const acquis: Acquis[] = [];
          acqList.forEach(acq => {
              const competence: Competence = {
                id: acq.acquis.competence?.id,
                lib: acq.acquis.competence?.lib,
                typeCompetenceId: acq.acquis.competence?.type.id,
                discipline: getVersion(gofElement, acq.acquis.discipline)
              };

              const activitesPedagogiques: ElementPedagogique[] = [];
              acq.activites.forEach(e => {
                let formationsMutualisees: ObjectAffichage[] = [];
                e.formationsMutualisation?.forEach(f => {
                  this.translateService.get(typesDiplomeTrad.get(f.type.typeDiplome.id)).subscribe(typeDiplome => {
                    formationsMutualisees.push(new ObjectAffichage(f.objetId, typeDiplome + " : " + f.diplome.lib, TYPES_OBJET.FORMATION));
                  });
                });

                activitesPedagogiques.push(new ElementPedagogique(
                  e.objetId,
                  e.lib,
                  e.typeEnseignement.id,
                  e.diplome?.objectif,
                  e.nbHeureEns,
                  e.duree,
                  e.nbSemaine,
                  e.aDistance,
                  e.modaliteSuivi,
                  e.langueEnseignement?.id,
                  new ObjectAffichage(e.objetId, e.lib, TYPES_OBJET.ENSEIGNEMENT),
                  e.unitesEnseignement?.map(ue => new ObjectAffichage(ue.objetId, ue.lib, TYPES_OBJET.ENSEIGNEMENT)),
                  formationsMutualisees,
                  e.module?.lib,
                  getVersion(gofElement, e.sectionCnu),
                  getVersion(gofElement, e.groupeCnu)
                ));
              });

              let lib: string = acq.acquis.lib;
              if (acq.acquis.traductions?.length > 0) {
                if (acq.acquis.traductions.filter(t => t.langue.code === gofElement.langue).length > 0) {
                  lib = acq.acquis.traductions.filter(t => t.langue.code === gofElement.langue)[0].lib;
                }
              }

              acquis.push({
                id: acq.acquis.id,
                lib,
                typeAcquisId: acq.acquis.type.id,
                competence,
                activitesPedagogiques
              });
            }
          )
          ;
          this.panelAcquis.acquis = acquis;

          resolve(this.panelAcquis);
        },
        () => resolve(new PanelAcquis()));
    });
  }
}
