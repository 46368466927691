<div class="margin-large margin-lg-xlarge" *ngIf="modalitesAcquisition.modalitesAffichage?.length > 0">
  <ul>
    <li *ngFor="let modaliteAffichage of modalitesAcquisition.modalitesAffichage">
      {{modaliteAffichage.intitule}}
      <span *ngIf="modaliteAffichage.modalite">{{' : ' + modaliteAffichage.modalite }}</span>
    </li>
  </ul>
</div>

<div class="margin-large margin-lg-xlarge" *ngIf="modalitesAcquisition.mea.length > 0">
  <h3>{{'fiche.modalites_acquisition.mea.title' | translate}}</h3>

  <ng-container *ngFor="let el of modalitesAcquisition.mea">
    <h4>{{el.typeExamen}}</h4>

    <table role="presentation">
      <tbody>
      <tr *ngIf="el.typeExamen">
        <td>{{'fiche.modalites_acquisition.mea.type' | translate}}</td>
        <td>{{el.typeExamen}}</td>
      </tr>
      <tr *ngIf="el.numSession">
        <td>{{'fiche.modalites_acquisition.mea.num_session' | translate}}</td>
        <td>{{el.numSession}}</td>
      </tr>
      <tr *ngIf="el.coefficient">
        <td>{{'fiche.modalites_acquisition.mea.coefficient' | translate}}</td>
        <td>{{el.coefficient}}</td>
      </tr>
      <tr *ngIf="el.coefficient2">
        <td>{{'fiche.modalites_acquisition.mea.coefficient_2' | translate}}</td>
        <td>{{el.coefficient2}}</td>
      </tr>
      <tr *ngIf="el.detail">
        <td>{{'fiche.modalites_acquisition.mea.detail' | translate}}</td>
        <td>{{el.detail}}</td>
      </tr>
      <tr *ngIf="el.natureExamen">
        <td>{{'fiche.modalites_acquisition.mea.nature_examen' | translate}}</td>
        <td>{{el.natureExamen}}</td>
      </tr>
      <tr *ngIf="el.duree">
        <td>{{'fiche.modalites_acquisition.mea.duree' | translate}}</td>
        <td>{{el.duree}}</td>
      </tr>
      </tbody>
    </table>
  </ng-container>
</div>

<div class="margin-large margin-lg-xlarge" *ngIf="modalitesAcquisition.regleObtEns.length > 0">
  <h3>{{'fiche.modalites_acquisition.regle_obtention.title' | translate}}</h3>

  <div *ngFor="let r of modalitesAcquisition.regleObtEns">
    <strong>{{'fiche.modalites_acquisition.regle_obtention.session' | translate}} {{r.numSession}} :</strong>{{r.regle}}
  </div>
</div>
