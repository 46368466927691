import {Injectable} from '@angular/core';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {ElementPedagogique, ModuleEnseignement, PanelActivites} from '../../model/activites.model';
import {ObjetFormationResourceService} from '../resources/objet-formation-resource.service';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {getVersion} from '../../utils/version.utils';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class EnseignementActivitesPedagogiquesService {

  panelActivites: PanelActivites = new PanelActivites();

  constructor(private objetFormationResourceService: ObjetFormationResourceService,
              private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelActivites> {
    return new Promise<PanelActivites>(resolve => {
      this.objetFormationResourceService.getObjetFormation(gofElement).subscribe(objetFormation => {
        let elementsPedagogiques: ElementPedagogique[] = [];
        const modulesEnseignement: ModuleEnseignement[] = [];

        if (objetFormation.modules?.length === 0) {
          elementsPedagogiques = objetFormation.elementPedagogiques.map(e => this.getElementPedagogique(e, gofElement));
        }

        objetFormation.modules?.forEach(m => {
          const module: ModuleEnseignement = new ModuleEnseignement();
          module.lib = m.lib;
          module.elementsPedagogiques = m.activites.map(e => this.getElementPedagogique(e, gofElement));
          modulesEnseignement.push(module);
        });

        this.panelActivites.elementsPedagogiques = elementsPedagogiques;
        this.panelActivites.modulesEnseignement = modulesEnseignement;

        resolve(this.panelActivites);
      },
        () => resolve(new PanelActivites()));
    });
  }

  private getElementPedagogique(e: any, gofElement: GofElement): ElementPedagogique {
    let formationsMutualisation: ObjectAffichage[] = [];
    e.formationsMutualisation?.forEach(f => {
      this.translateService.get(typesDiplomeTrad.get(f.type.typeDiplome.id)).subscribe(typeDiplome => {
        formationsMutualisation.push(new ObjectAffichage(f.objetId, typeDiplome + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION));
      });
    });

    const elementPedago: ElementPedagogique = {
      id: e.objetId,
      lib: e.lib,
      typeEnseignementId: e.typeEnseignement.id,
      objectif: e.objectif,
      nbHeuresEnseignees: e.nbHeureEns,
      tempsTravailPersonnel: e.duree,
      nbSemaine: e.nbSemaine,
      aDistance: e.aDistance,
      modaliteSuivi: e.modaliteSuivi,
      langueEnseignementId: e.langueEnseignement?.id,
      enseignementPorteur: new ObjectAffichage(e.objetId, e.lib, TYPES_OBJET.ENSEIGNEMENT),
      unitesEnseignement: e.unitesEnseignement?.map(ue => new ObjectAffichage(ue.objetId, ue.lib, TYPES_OBJET.ENSEIGNEMENT)),
      formationsMutualisation,
      module: e.module?.lib,
      sectionCnu: getVersion(gofElement, e.sectionCnu),
      groupeCnu: getVersion(gofElement, e.groupeCnu)
    };

    if (e.traductions?.length > 0) {
      const traductions: any = e.traductions.filter(t => t.langue.code === gofElement.langue);
      if (traductions.length > 0) {
        elementPedago.lib = traductions[0].lib;
        elementPedago.objectif = traductions[0].objectif;
      }
    }
    if (e.editos?.length > 0) {
      const editos: any = e.editos.filter(e => e.typePublic.id === gofElement.typePublic);
      if (editos.length > 0) {
        elementPedago.lib = editos[0].lib;
        elementPedago.objectif = editos[0].objectif;
      }
    }

    return elementPedago;
  }
}
