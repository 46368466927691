import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {GofElement} from '../../../../model/util.model';
import {ConfigAssetLoaderService, Configuration} from '../../../../configAssetLoader.service';
import {getDOMId, getResume} from '../../../../utils/document.utils';
import {AbstractDocument, FormationDoc} from '../../../../model/esDoc.model';
import {PATH_DETAIL_FORMATION} from '../../../../constants/conf.constants';
import {TYPES_OBJET} from '../../../../constants/utils.constants';
import {AppComponent} from '../../../../app.component';

@Component({
  selector: 'app-carte-formation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './carte-formation.component.html',
  styleUrls: ['./carte-formation.component.scss']
})
export class CarteFormationComponent {

  @Output() cardSelected: EventEmitter<GofElement> = new EventEmitter<GofElement>();
  @Input() document: FormationDoc;

  conf: Configuration;

  pathDetailFormation = PATH_DETAIL_FORMATION;
  typeObjet = TYPES_OBJET;

  constructor(private configAssetLoaderService: ConfigAssetLoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.conf = res);
  }

  getResume(html: string): string {
    return getResume(html);
  }

  getDomId(document: AbstractDocument): string {
    return getDOMId(document);
  }

  onCardSelected(): void {
   AppComponent.CURRENT_YEAR = this.document.annee;
  }
}
