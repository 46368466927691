import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigAssetLoaderService} from '../../configAssetLoader.service';
import {GofElement} from '../../model/util.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PeriodeResourceService {

  refFormUrl: string;

  constructor(protected http: HttpClient,
              private configAssetLoaderService: ConfigAssetLoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.refFormUrl = res.refFormUrl + '/periodes');
  }

  getAllPeriodes(): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('year', 2022);
    params = params.append('limit', -1);
    return this.http.get<any[]>(this.refFormUrl + '', {params});
  }

  getPeriode(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/' + gofElement.id, {params});
  }

  getPeriodesPrecFromPeriodePorteuse(idObjetPeriodePorteuse: number, idObjetFormation: number, annee: number): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('year', annee);
    return this.http.get<any>(this.refFormUrl + '/' + idObjetPeriodePorteuse + '/formation/' + idObjetFormation, {params});
  }

  getListes(periodeObjetId: number, annee: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', annee);
    return this.http.get<any>(this.refFormUrl + '/' + periodeObjetId + '/listes', {params});
  }
}
