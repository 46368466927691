export enum RefNiveau {
  NIVEAU_PRE_BAC = 7,
  NIVEAU_BAC = 2,
  NIVEAU_BAC_120 = 3,
  NIVEAU_BAC_4 = 4,
  NIVEAU_BAC_8 = 6,
  NIVEAU_LICENCE = 1,
  NIVEAU_MASTER = 5
}

export enum refTypeDiplome {
  ATTEST_ETUDES = 28,
  BUT = 30,
  CES = 29,
  CC = 23,
  CC_DROIT = 18,
  DUT = 5,
  DE = 26,
  DE_CYCLE_LONG = 27,
  DE_DOCTEUR = 20,
  DAEU = 16,
  DU = 2,
  DES = 31,
  DES_CHIRURGIE = 7,
  DES_MEDICAL = 6,
  DESC_MG_I = 9,
  DES_BIOLOGIE = 8,
  DESC_MG_II = 10,
  DCG = 12,
  DFA = 25,
  DFG = 24,
  DNO = 15,
  DSCG = 13,
  DSN = 21,
  DEUST = 17,
  DOCTORAT = 11,
  HDR = 14,
  LICENCE = 1,
  LICENCE_PRO = 3,
  MASTER = 4,
  PASS = 19,
  PREPA = 22,
  FORMATION_COURTE = 32
}

export enum RefTypePeriode {
  PORTAIL = 2,
  TRONC_COMMUN_INTER_MENTIONS = 3,
  TRONC_COMMUN_INTERNE_A_LA_FORMATION = 4,
  PERIODE_DE_SPECIALISATION = 5,
  PARCOURS_UNIQUE = 6,
  CONTENU = 7,
  PARCOURS_LIBRE = 23,
}

export enum RefTypeObjet {
  FORMATION = 1,
  PORTAIL = 2,
  TRONC_COMMUN_INTER_MENTIOSN = 3,
  TRONC_COMMUN_INTERNE_A_LA_FORMATION = 4,
  PERIODE_DE_SPECIALISATION = 5,
  PARCOURS_UNIQUE = 6,
  CONTENU = 7,
  LISTE_ENS_OBLIGATOIRES = 8,
  LISTE_ENS_OBLIGATOIRE_A_CHOIX = 9,
  LISTE_ENS_FACULT = 10,
  LISTE_ENS_OBLIG_CURSUS_ENRICHI = 11,
  LISTE_ENS_OBLIG_A_CHOIX_CURSUS_ENRICHI = 12,
  LISTE_DE_LISTES = 13,
  SOUS_LISTE = 14,
  CURSUS_ENRICHI = 15,
  UE = 16,
  ENS_NON_CREDITANT = 17,
  ELEMENT_PEDAGOGIQUE = 18,
  INSTANCE_ENSEIGNEMENT = 19,
  UE_TRANSVERSE = 20,
  ENS_NON_CREDITANT_TRANSVERSE = 21,
  SEMESTRE_MOBILITE = 22,
  PARCOURS_LIBRE = 23,
  LISTE_LIBRE = 24,
  LISTE_ENS_OBLIG_2 = 25,
  LISTE_ENS_OBLIG_A_CHOIX_2 = 26,
  LISTE_ENS_FACULT_2 = 27,
  BLOC_DE_COMPETENCES = 28,
  PARCOURS_TYPE = 29,
  MODULE = 30,
  LISTE_ENS_OBLIG_A_CHOIX_AVEC_ETCS_DIFF = 31
}

export enum RefTypeDuree {
  SEMESTRE = 1,
  ANNEE = 2,
  HEURE = 3,
  A_DEFINIR = 4
}

export enum RefTypePortage {
  COMPOSANTE_PEDAGOGIQUE = 1,
  SNI_PORTEUSE = 2
}

export enum RefTypologieEns {
  SOCLE = 1,
  PERSONNALISATION = 2,
  OUVERTURE = 3
}

export enum RefNiveauEns {
  DEBUTANT = 3,
  INTERMEDIAIRE = 4,
  AVANCE = 5
}

export enum RefTypeCompetence {
  PREPROFESSIONNELLE = 1,
  TRANSVERSALE = 2,
  DISCIPLINAIRE = 3,
}

export enum RefTypeInscription {
  FORMATION_INITIALE = 1,
  FORMATION_CONTINUE = 2,
  CONTRAT_APPRENTISSAGE = 3,
  CONTRAT_PROFESSIONNALISATION = 4
}

export enum RefTypePartenariat {
  CO_ACCREDIATION = 1,
  CONVENTION_PARTENARIAT_FRANCAIS = 2,
  ERASMUS = 3,
  COOPERATION_INTERNATIONALE = 4,
  CONVENTION_INSTIT_FRANCAIS = 5,
  CONVENTION_INSTIT_ETRANGERE = 6
}

export enum RefTypePublic {
  DEFAULT = '0',
  LYCEEN_FUTUR_ETUDIANT = '1',
  ETUDIANT_UB = '2',
  ENTREPRISE = '3',
}

export enum RefSite {
  BORDEAUX_ENVIRONS = 1,
  AGEN = 2,
  ARCACHON = 3,
  BAYONNE = 4,
  BERGERAC = 5,
  DAX = 6,
  MONT_DE_MARSAN = 7,
  EUROPE = 8,
  PERIGUEUX = 9,
  PAU = 10,
  AMERIQUE_NORD = 11,
  AMERIQUE_CENTRAL = 12,
  AMERIQUE_SUD = 13,
  AFRIQUE = 14,
  PROCHE_ORIENT = 15,
  MOYEN_ORIENT = 16,
  DOM_TOM = 17,
  EXTREME_ORIENT = 18,
  VIETNAM = 19,
  LIBAN = 20,
  MAROC = 21,
  BELGIQUE = 22,
  ROYAUME_UNI = 23,
  ALLEMAGNE = 24,
  ESPAGNE = 25
}

export enum RefTypeADistance {
  FORMATION_NON_ACCESSIBLE = 1,
  FORMATION_PARTIELLEMENT_ACCESSIBLE_A_DISTANCE = 2,
  FORMATION_EGALEMENT_POSSIBLE_A_DISTANCE = 3,
  FORMATION_UNIQUEMENT_ACCESSIBLE_A_DISTANCE = 4
}

export enum RefDomaine {
  ALA = 1,
  DEG = 2,
  SHS = 3,
  STS = 4,
  STAPS = 5
}

export enum RefTypeEnseignement {
  CM = 7,
  TD = 8,
  CI = 9,
  STAGE = 10,
  TP = 11,
  MISE_EN_SITUATION = 12,
  REH = 13,
  MIXTE = 14,
  ENS_DIR = 15,
  PREPA_PERSO = 16,
  A_DEFINIR = 17,
  TD_MACHINE = 18,
}

export enum RefTypeAcquis {
  SAVOIR = 1,
  SAVOIR_ETRE = 2,
  SAVOIR_FAIRE = 3
}

export enum RefEtatConvention {
  PAS_CONVENTION = 1,
  CONVENTION_A_ETABLIR = 2,
  CONVENTION_ASSOCIEE = 3
}

export enum RefTypeExamen {
  TERMINAL = 4,
  CONTROLE_CONTINU = 5,
  DEVOIR_MAISON = 6,
  AUTOFORMATION_EN_LIGNE = 7,
  ORAL = 8,
  PROJET = 9,
  SOUTENANCE = 10,
  LIVRABLE = 11,
  DOSSIER = 12,
  INVESTISSEMENT = 13,
  ECRIT_ORAL = 14,
  EXAMENT_TP = 15,
  RAPPORT = 16
}

export enum RefNatureExamen {
  ORAL = 3,
  RAPPORT = 4,
  SOUTENANCE = 5,
  QCM = 6,
  DEVOIR_SUR_TABLE = 7,
  MEMOIRE = 8,
  EXAMEN_COURS = 9,
  SOUTENANCE_STAGE = 10
}

export enum RefLangue {
  FR = 28,
  EN = 21,
  ES = 22
}
