import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractDocument} from '../model/esDoc.model';
import {Filtre} from '../model/util.model';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class ElasticsearchService {

  constructor(protected http: HttpClient,
              private translateService: TranslateService) {
  }

  getOptions(value?: string, field?: string, aggField?: string, filtres?:Filtre[]): Observable<string[]> {
    let params = new HttpParams();
    params = params.append('aggField', aggField);

    if (field) {
      params = params.append('field', field);
    }
    if (value) {
      params = params.append('value', value);
    }

    return this.http.post<string[]>('/elasticsearch/options/' + this.translateService.currentLang, filtres,{params});
  }

  search(value?: string, field?: string, filtres?: Filtre[]): Observable<AbstractDocument[]> {
    let params = new HttpParams();
    if (field) {
      params = params.append('field', field);
    }
    if (value) {
      params = params.append('value', value);
    }

    return this.http.post<AbstractDocument[]>('/elasticsearch/search/' + this.translateService.currentLang, filtres, {params});
  }

  getDocuments(objetIdList: number[]): Observable<any[]> {
    return this.http.post<AbstractDocument[]>('/elasticsearch/documents/' + this.translateService.currentLang, objetIdList);
  }
}
