import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigAssetLoaderService} from '../../configAssetLoader.service';
import {GofElement} from '../../model/util.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InscriptionResourceService {

  refFormUrl: string;

  constructor(protected http: HttpClient, private configAssetLoaderService: ConfigAssetLoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.refFormUrl = res.refFormUrl + '/inscriptions');
  }

  getModalitesInscription(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('public', String(gofElement.typePublic));
    params = params.append('lang', gofElement.langue);
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/objetformation/' + gofElement.id + '/references', {params});
  }

  getTypesInscription(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/objetformation/' + gofElement.id + '/types', {params});
  }
}
