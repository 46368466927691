import { Component, HostListener, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ConfigAssetLoaderService, Configuration } from "./configAssetLoader.service";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "./services/util.service";
import {
  CLOSE_EVENT, DEFAULT_LANGUAGE,
  EXPIRE_COOKIES,
  GOF_CONTRAST,
  GOF_COOKIES, GOF_DYSLEXIC, GOF_INTERNATIONAL_CATALOGUE, GOF_LANGUAGE,
  GOF_MA_SELECTION, GOF_PROFILE,
  PATH_ACCUEIL, PATH_CATALOGUE_INTERNATIONAL,
  PATH_RESULTATS
} from "./constants/conf.constants";
import { NavigationEnd, Router } from "@angular/router";
import { adaptNavContainer, closeAllExpandedNav, fixHeader } from "./utils/style.utils";
import { RgpdDialogComponent } from "./components/rgpd/rgpd-dialog/rgpd-dialog.component";
import { CookieService } from "ngx-cookie-service";
import { GlobalStateService } from "./services/global-state.service";
import { RefTypePublic } from "./constants/referentiel/ref.constants";
import { DOCUMENT } from "@angular/common";
import { RechercheParMotsClesComponent } from "./components/recherche/recherche-par-mots-cles/recherche-par-mots-cles.component";
import { HeaderComponent } from "./layout/header/header.component";
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: "app-root",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

  @ViewChild("rechercheParMotsClesComponent") rechercheParMotsClesComponent: RechercheParMotsClesComponent;
  @ViewChild("header") header: HeaderComponent;

  static CURRENT_YEAR: number;

  displaySearch: boolean;
  // displayBanner: boolean;

  isNavExpanded = false;

  dyslexicFriendlyToggler = false;
  highContrastToggler = false;
  currentProfile: string;

  configuration: Configuration;

  constructor(
    private translateService: TranslateService,
    private utilService: UtilService,
    public router: Router,
    private configAssetLoaderService: ConfigAssetLoaderService,
    private globalStateService: GlobalStateService,
    private cookieService: CookieService,
    private matDialog: MatDialog,
    @Inject(DOCUMENT) private document: Document) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.configuration = res);

    this.router.events.subscribe(res => {
      if (res instanceof NavigationEnd) {
        const currentPath = res.url.substring(1).split("?")[0];
        this.displaySearch = [PATH_RESULTATS, PATH_ACCUEIL, PATH_CATALOGUE_INTERNATIONAL].includes(currentPath);

        // Catalogue international
        if ([PATH_CATALOGUE_INTERNATIONAL].includes(currentPath)) {
          localStorage.setItem(GOF_INTERNATIONAL_CATALOGUE, "true");
          this.globalStateService.notifyDataChanged(GOF_INTERNATIONAL_CATALOGUE, true);

        } else if ([PATH_ACCUEIL, PATH_RESULTATS].includes(currentPath)) {
          this.translateService.setDefaultLang(DEFAULT_LANGUAGE);
          this.utilService.setLanguage(DEFAULT_LANGUAGE).then(() => {
            localStorage.setItem(GOF_INTERNATIONAL_CATALOGUE, "false");
            this.globalStateService.notifyDataChanged(GOF_LANGUAGE, DEFAULT_LANGUAGE);
            this.globalStateService.notifyDataChanged(GOF_INTERNATIONAL_CATALOGUE, false);

            if ([PATH_ACCUEIL].includes(currentPath)) {
              this.rechercheParMotsClesComponent.init();
            }
          });
        }
      }
    });
  }

  ngOnInit() {
    this.manageCookies();
  }

  private manageCookies(): void {
    /* RGPD */
    if (!this.cookieService.check(GOF_COOKIES)) {
      this.matDialog.open(RgpdDialogComponent, {
        maxWidth: "850px",
        minWidth: "320px",
        disableClose: true,
        restoreFocus: true,
        ariaLabel: "Fenêtre de gestion des cookies"
      });
    }

    /* ACCESSIBILITE */
    if (this.cookieService.check(GOF_DYSLEXIC) || this.cookieService.check(GOF_CONTRAST)) {
      this.dyslexicFriendlyToggler = this.cookieService.get(GOF_DYSLEXIC) === "1";
      this.highContrastToggler = this.cookieService.get(GOF_CONTRAST) === "1";
    }

    /* MA SELECTION */
    if (!this.cookieService.check(GOF_MA_SELECTION)) {
      this.cookieService.set(GOF_MA_SELECTION, JSON.stringify([]),
        {
          expires: EXPIRE_COOKIES,
          sameSite: "Strict"
        });
    } else {
      this.globalStateService.notifyDataChanged(GOF_MA_SELECTION, JSON.parse(this.cookieService.get(GOF_MA_SELECTION)).length);
    }

    /* PROFIL */
    if (!this.cookieService.check(GOF_PROFILE)) {
      this.cookieService.set(GOF_PROFILE, RefTypePublic.DEFAULT.valueOf(),
        {
          expires: EXPIRE_COOKIES,
          sameSite: "Strict"
        });
    }
    this.currentProfile = this.cookieService.get(GOF_PROFILE);
  }

  @HostListener("window:scroll", ["$event"])
  onScroll(): void {
    fixHeader();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event): void {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");
    adaptNavContainer(event.target.innerWidth);
  }

  @HostListener("window:keyup", ["$event"])
  onKeyEvent(event): void {
    if (event.keyCode === "Escape" || event.keyCode === 27) {
      this.isNavExpanded = false;
      this.globalStateService.notifyDataChanged(CLOSE_EVENT, new Date());
      closeAllExpandedNav();
    }
  }

  @HostListener("window:click", ["$event"])
  onClick(event): void {
    const secondaryNav = document.getElementById("header-secondary-nav");
    const searchBlockForm = document.getElementById("search-block-form");

    if (!secondaryNav?.contains(event.target) && !searchBlockForm.contains(event.target)) {
      this.isNavExpanded = false;
      this.globalStateService.notifyDataChanged(CLOSE_EVENT, new Date());
      closeAllExpandedNav();
    }
  }
}
