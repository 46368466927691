import {Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from '@angular/core';
import {AbstractDocument} from '../../../model/esDoc.model';
import {addRemoveFavorites} from '../../../utils/storage.utils';
import {GOF_MA_SELECTION} from '../../../constants/conf.constants';
import {CookieService} from 'ngx-cookie-service';
import {GlobalStateService} from '../../../services/global-state.service';
import {getDOMId} from '../../../utils/document.utils';

@Component({
  selector: 'app-ajouter-favori',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './ajouter-favori.component.html',
  styleUrls: ['./ajouter-favori.component.scss']
})
export class AjouterFavoriComponent implements OnChanges {

  @Output() actionEmitter:EventEmitter<void> = new EventEmitter<void>();
  @Input() document: AbstractDocument;
  @Input() class: string;

  isFavori: boolean;

  constructor(private cookieService: CookieService,
              private globalStateService: GlobalStateService) {
  }

  ngOnChanges(): void {
    if (this.document) {
      let selectionIds: number[] = JSON.parse(this.cookieService.get(GOF_MA_SELECTION));
      this.isFavori = selectionIds?.filter(id => id === this.document.id).length > 0;
    }
  }

  addRemoveToFavorites(): void {
      this.isFavori = addRemoveFavorites(this.document, this.cookieService);
      this.globalStateService.notifyDataChanged(GOF_MA_SELECTION, JSON.parse(this.cookieService.get(GOF_MA_SELECTION)).length);
      this.actionEmitter.emit();
  }

  getDOMId():string{
    return getDOMId(this.document);
  }

}
