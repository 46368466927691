import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appAnchor]'
})
export class AnchorDirective {
  @Input() target: string;

  constructor(el: ElementRef) {
    el.nativeElement.style.cursor = 'pointer';
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    document.querySelector(this.target).scrollIntoView();
  }

  @HostListener('keyup.space', ['$event'])
  onSpace(): void {
    document.querySelector(this.target).scrollIntoView();
  }

  @HostListener('keyup.enter', ['$event'])
  onEnter(): void {
    document.querySelector(this.target).scrollIntoView();
    document.querySelector(this.target)[0].focus();
  }
}
