import {Injectable} from '@angular/core';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {PanelPresentation} from '../../model/presentation.model';
import {ObjetFormationResourceService} from '../resources/objet-formation-resource.service';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {TranslateService} from '@ngx-translate/core';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';

@Injectable({
  providedIn: 'root'
})
export class CursusEnrichiPresentationService {

  panelPresentation: PanelPresentation = new PanelPresentation();

  constructor(private objetFormationResourceService: ObjetFormationResourceService,
              private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelPresentation> {
    return new Promise<PanelPresentation>(resolve => {
      Promise.all([
        this.getCursus(gofElement),
      ])
        .then(() => resolve(this.panelPresentation));
    });
  }

  private getCursus(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.objetFormationResourceService.getObjetFormation(gofElement).subscribe(cursus => {
        if (cursus !== null) {
          this.panelPresentation.refObject = cursus;
          this.panelPresentation.objetId = cursus.objetId;
          this.panelPresentation.intitule = cursus.lib;
          this.panelPresentation.annee = cursus.annee;
          this.panelPresentation.objectif = cursus.objectif;
          this.panelPresentation.formationsPorteuses = [];
          cursus.formations?.forEach(f => {
            this.translateService.get(typesDiplomeTrad.get(f.type.typeDiplome.id))
              .subscribe(typeDiplome => this.panelPresentation.formationsPorteuses.push(new ObjectAffichage(f.objetId, typeDiplome + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION)));
          });
          if (cursus.duree) {
            this.panelPresentation.duree = cursus.duree;
            this.panelPresentation.typeDureeId = cursus.typeDuree.id;
          }
          this.panelPresentation.credits = cursus.ects;
          this.panelPresentation.isCMI = cursus.cmi;
        }
        resolve();
      },
        () => resolve());
    });
  }
}

