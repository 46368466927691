import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { PathItem } from "../../model/util.model";
import { GOF_MA_SELECTION, PATH_DETAIL_FORMATION, PATH_MA_SELECTION, PATH_ACCUEIL, GOF_INTERNATIONAL_CATALOGUE, PATH_CATALOGUE_INTERNATIONAL, GOF_LANGUAGE } from "../../constants/conf.constants";
import { AbstractDocument } from "../../model/esDoc.model";
import { TYPES_OBJET } from "../../constants/utils.constants";
import { CookieService } from "ngx-cookie-service";
import { ElasticsearchService } from "../../services/elasticsearch.service";
import { TranslateService } from "@ngx-translate/core";
import { typesObjetTrad } from "../../constants/translationMaps/trad.constants";
import { AppComponent } from "../../app.component";
import { UtilService } from "../../services/util.service";
import { GlobalStateService } from "../../services/global-state.service";

@Component({
  selector: "app-ma-selection",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./ma-selection.component.html",
  styleUrls: ["./ma-selection.component.scss"]
})
export class MaSelectionComponent implements OnInit {

  thread: PathItem[];

  typeObjetTrad = typesObjetTrad;
  pathDetailFormation = PATH_DETAIL_FORMATION;

  selectionIds: number[];
  favoris: Map<string, AbstractDocument[]>;

  constructor(private utilService: UtilService,
              private translateService: TranslateService,
              private elasticsearchService: ElasticsearchService,
              private globalStateService: GlobalStateService,
              private cookieService: CookieService) {
    this.globalStateService.subscribe(GOF_LANGUAGE, () => {
      this.utilService.setHtmlHead("page.ma_selection", "universite_de_bordeaux_description", PATH_MA_SELECTION, "");
    });
  }

  ngOnInit(): void {
    this.utilService.setHtmlHead("page.ma_selection", "universite_de_bordeaux_description", PATH_MA_SELECTION, "");
    this.selectionIds = JSON.parse(this.cookieService.get(GOF_MA_SELECTION));

    let homePath = PATH_ACCUEIL;
    if (JSON.parse(localStorage.getItem(GOF_INTERNATIONAL_CATALOGUE))) {
      homePath = "/" + PATH_CATALOGUE_INTERNATIONAL;
    }
    this.thread = [
      new PathItem("page.accueil", [homePath]),
      new PathItem("page.ma_selection", [PATH_MA_SELECTION])
    ];

    this.loadFavoris();
  }

  addRemoveToFavorites(document: AbstractDocument): void {
    const index = this.favoris.get(document.type_objet).findIndex(f => f.id === document.id);
    if (index > -1) {
      this.favoris.get(document.type_objet).splice(index, 1);
      this.selectionIds = JSON.parse(this.cookieService.get(GOF_MA_SELECTION));
    }
  }

  loadFavoris(): void {
    this.favoris = new Map([
      [TYPES_OBJET.FORMATION, []],
      [TYPES_OBJET.CURSUS_ENRICHI, []],
      [TYPES_OBJET.PARCOURS_TYPE, []],
      [TYPES_OBJET.ENSEIGNEMENT, []]
    ]);

    if (this.selectionIds?.length > 0) {
      this.elasticsearchService.getDocuments(this.selectionIds).subscribe(documents => {
        documents.forEach(f => this.favoris.get(f.type_objet).push(f));
      });
    }
  }


  onSelected(formation: AbstractDocument): void {
    AppComponent.CURRENT_YEAR = formation.annee;
  }

}
