<div id="internationalisation-container" class="accordion-panels">

  <!-- DISPOSITIFS INTERNATIONAUX-->
  <div class="accordion-panel" *ngIf="internationalisation.dispositifsInternationaux.length > 0">
    <div class="accordion-panel-header">
      <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
        <h4 id="label_dispo_internationaux" class="accordion-panel-title">{{'fiche.international.dispositifs_internationaux' | translate}}</h4>
      </div>
      <button data-toggle="collapse" type="button"
              data-target="#dispo-internationaux"
              aria-controls="dispo-internationaux"
              class="accordion-panel-toggler collapsed"
              aria-expanded="false"
              aria-describedby="dispo_internationaux">
        <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
      </button>
    </div>

    <div id="dispo-internationaux" class="accordion-panel-body collapse">
      <div class="accordion-panel-body-inner">
        <div class="margin-large margin-lg-xlarge">
          <div class="accordion-panels">
            <div class="accordion-panel"
                 *ngFor="let dispositif of internationalisation.dispositifsInternationaux ;  let index = index">
              <div class="accordion-panel-header">
                <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
                  <h4 [id]="'label_dispo_inter_' + index" class="accordion-panel-title">{{dispositif.intitule}}</h4>
                </div>
                <button data-toggle="collapse" type="button"
                        [attr.data-target]="'#dispo-internationaux-' + index"
                        [attr.aria-controls]="'dispo-internationaux-' + index"
                        class="accordion-panel-toggler collapsed"
                        aria-expanded="false"
                        [attr.aria-describedby]="'label_dispo_inter_' + index">
                  <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                </button>
              </div>

              <div [id]="'dispo-internationaux-' + index" class="accordion-panel-body collapse">
                <div class="accordion-panel-body-inner">
                  <div class="margin-large margin-lg-xlarge">
                    <div [innerHTML]="dispositif.details" *ngIf="dispositif.details"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- PARTENARIATS INTERNATIONAUX-->
  <div class="accordion-panel" *ngIf="internationalisation.erasmus?.length > 0 ||
                                      internationalisation.cooperationsInternationales?.length > 0  ||
                                      internationalisation.conventionsPrivees?.length > 0">
    <div class="accordion-panel-header">
      <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
        <h3 id="label_part_inter" class="accordion-panel-title">{{'fiche.international.partenariats_internationaux.title' | translate}}</h3>
      </div>
      <button data-toggle="collapse" type="button"
              data-target="#part-internationaux"
              aria-controls="part-internationaux"
              class="accordion-panel-toggler collapsed"
              aria-expanded="false"
              aria-describedby="label_part_inter">
      <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
      </button>
    </div>

    <div id="part-internationaux" class="accordion-panel-body collapse">
      <div class="accordion-panel-body-inner">

        <div class="accordion-panels">

          <!--  erasmus+-->
          <div class="accordion-panel" *ngIf="internationalisation.erasmus?.length > 0">
            <div class="accordion-panel-header">
              <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
                <h3 id="label_erasmus" class="accordion-panel-title">
                  {{'fiche.international.partenariats_internationaux.erasmus' | translate}}</h3>
              </div>
              <button data-toggle="collapse" type="button"
                      data-target="#part_internationaux-erasmus"
                      aria-controls="part_internationaux-erasmus"
                      class="accordion-panel-toggler collapsed"
                      aria-expanded="false"
                      aria-describedby="label_erasmus">
                <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
              </button>
            </div>

            <div id="part_internationaux-erasmus" class="accordion-panel-body collapse">
              <div class="accordion-panel-body-inner">
                <ng-container *ngFor="let part of internationalisation.erasmus ; let index = index">
                <table role="presentation">
                  <tbody>
                  <tr>
                    <td>{{'fiche.international.partenariats_internationaux.convention_associee' | translate}}</td>
                    <td><strong>{{ etatsConventionTrad.get(part.etatConventionId) | translate }}</strong></td>
                  </tr>
                  <tr>
                    <td>{{'fiche.international.partenariats_internationaux.modalites' | translate}}</td>
                    <td><strong>{{ part.modalite }}</strong></td>
                  </tr>
                  <tr>
                    <td>{{'fiche.international.partenariats_internationaux.etablissements' | translate}}</td>
                    <td>
                      <ng-container *ngFor="let etab of part.etablissements; let last = last">
                        <strong>{{ etab }}</strong><span *ngIf="!last">{{', '}}</span>
                      </ng-container>
                    </td>
                  </tr>
                  </tbody>
                </table>
                </ng-container>
              </div>
            </div>
          </div>

          <!-- coopérations internationales -->
          <div class="accordion-panel" *ngIf="internationalisation.cooperationsInternationales?.length > 0">
            <div class="accordion-panel-header">
              <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
                <h3 id="label_coop_inter"
                  class="accordion-panel-title">{{'fiche.international.partenariats_internationaux.cooperation_internationale' | translate}}</h3>
              </div>
              <button data-toggle="collapse" type="button"
                      data-target="#part_internationaux-cooperation-internationale"
                      aria-controls="part_internationaux-cooperation-internationale"
                      class="accordion-panel-toggler collapsed"
                      aria-expanded="false"
                      aria-describedby="label_coop_inter">
              <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
              </button>
            </div>

            <div id="part_internationaux-cooperation-internationale" class="accordion-panel-body collapse">
              <div class="accordion-panel-body-inner">
                <table *ngFor="let part of internationalisation.cooperationsInternationales ; let index = index" role="presentation">
                  <tbody>
                  <tr>
                    <td>{{'fiche.international.partenariats_internationaux.convention_associee' | translate}}</td>
                    <td><strong>{{ etatsConventionTrad.get(part.etatConventionId) | translate }}</strong></td>
                  </tr>
                  <tr>
                    <td>{{'fiche.international.partenariats_internationaux.modalites' | translate}}</td>
                    <td><strong>{{ part.modalite }}</strong></td>
                  </tr>
                  <tr>
                    <td>{{'fiche.international.partenariats_internationaux.etablissements' | translate}}</td>
                    <td>
                      <ng-container *ngFor="let etab of part.etablissements; let last = last">
                        <strong>{{ etab }}</strong><span *ngIf="!last">{{', '}}</span>
                      </ng-container>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Convention avec institutions privées -->
          <div class="accordion-panel" *ngIf="internationalisation.conventionsPrivees?.length > 0">
            <div class="accordion-panel-header">
              <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
                <h3 id="label_conv_priv"
                  class="accordion-panel-title">{{'fiche.international.partenariats_internationaux.convention_institution_privee' | translate}}</h3>
              </div>
              <button data-toggle="collapse" type="button"
                      data-target="#part_internationaux-convention-institution-privee"
                      aria-controls="part_internationaux-convention-institution-privee"
                      class="accordion-panel-toggler collapsed"
                      aria-expanded="false"
                      aria-describedby="label_conv_priv">
              <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
              </button>
            </div>

            <div id="part_internationaux-convention-institution-privee" class="accordion-panel-body collapse">
              <div class="accordion-panel-body-inner">
                <table *ngFor="let part of internationalisation.conventionsPrivees ; let index = index" role="presentation">
                  <tbody>
                  <tr>
                    <td>{{'fiche.international.partenariats_internationaux.convention_associee' | translate}}</td>
                    <td><strong>{{ etatsConventionTrad.get(part.etatConventionId) | translate }}</strong></td>
                  </tr>
                  <tr>
                    <td>{{'fiche.international.partenariats_internationaux.modalites' | translate}}</td>
                    <td><strong>{{ part.modalite }}</strong></td>
                  </tr>
                  <tr>
                    <td>{{'fiche.international.partenariats_internationaux.etablissements' | translate}}</td>
                    <td>
                      <ng-container *ngFor="let etab of part.etablissements; let last = last">
                        <strong>{{ etab }}</strong><span *ngIf="!last">{{', '}}</span>
                      </ng-container>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
