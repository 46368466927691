import {Competence} from './competences.model';
import {ElementPedagogique} from './activites.model';

export class PanelAcquis {
  constructor(public acquis?: Acquis[]) {
  }

  isDisplayed(): boolean {
    return this && this.acquis?.length > 0;
  }
}

export class Acquis {
  constructor(public id?: number,
              public lib?: string,
              public typeAcquisId?: number,
              public competence?: Competence,
              public activitesPedagogiques?: ElementPedagogique[]) {
  }
}
