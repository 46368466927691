import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from '@angular/core';
import {Filtre, KeyValue, SearchItem} from '../../../model/util.model';
import {ElasticsearchService} from '../../../services/elasticsearch.service';
import {AFFICHAGE_FILTRES, TREE_FILTRES} from '../../../constants/filtres.constants';
import {getLastSearch} from '../../../utils/storage.utils';
import {LazyLoadService} from '../../../services/lazy-load.service';
import {PATH_ACCUEIL} from '../../../constants/conf.constants';
import {Router} from '@angular/router';
import {ES_FIELDS} from '../../../constants/utils.constants';

@Component({
  selector: 'app-filtres',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './filtres.component.html',
  styleUrls: ['./filtres.component.scss']
})
export class FiltresComponent implements AfterViewInit {

  @Output() filterEmitter: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() filtres: Filtre[];
  @Input() documents: any[];
  @Input() fromCatalogueInternational = false;

  field = ES_FIELDS;

  constructor(private router: Router,
              private lazyLoadScriptServiceService: LazyLoadService,
              private elasticsearchService: ElasticsearchService) {
  }

  static uncheckFiltre(filtre: Filtre): void {
    filtre.options.forEach(opt => opt.value = false);
  }

  ngAfterViewInit(): void {
    this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
  }

  onFilterChange(event: boolean): void {
    if (event) {
      this.checkFiltreParent();
    } else {
      this.uncheckFiltreEnfant();
    }

    const lastSearch: SearchItem = getLastSearch();
    if (!lastSearch) {
      this.router.navigate([PATH_ACCUEIL]);
    }
    this.elasticsearchService.search(lastSearch.value, lastSearch.field, TREE_FILTRES)
      .subscribe(res => this.filterEmitter.emit(res));
  }

  private checkFiltreParent(): void {
    TREE_FILTRES.forEach(f => {
      this._checkFiltreParent(f);
    });
  }

  private _checkFiltreParent(filtre: Filtre): void {
    filtre.enfants.forEach(e => {
      if (this.hasActivedFilters(e.value)) {
        filtre.options.forEach(opt => {
          if (opt.key === e.key) {
            opt.value = true;
            return;
          }
        });

      } else {
        e.value.forEach(f => {
          this._checkFiltreParent(f);
        });
      }
    });
  }

  private uncheckFiltreEnfant(): void {
    TREE_FILTRES.forEach(f => {
      this._uncheckFiltreEnfants(f);
    });
  }

  private _uncheckFiltreEnfants(filtre: Filtre): void {
    filtre.options.forEach(opt => {
      if (!opt.value) {
        filtre.enfants.forEach(e => {
          if (e.key === opt.key) {
            e.value.forEach(f => {
              this._uncheckFiltreEnfants(f);
              FiltresComponent.uncheckFiltre(f);
            });
          }
        });
      }
    });
  }

  hasActivedFilters(filtres: Filtre[]): boolean {
    let res = false;
    filtres.forEach(f => {
      if (this.hasCheckedOptions(f.options)) {
        res = true;
        return;
      }
    });
    return res;
  }

  hasCheckedOptions(options: KeyValue[]): boolean {
    return options.filter(kv => kv.value).length > 0;
  }

  protected readonly AFFICHAGE_FILTRES = AFFICHAGE_FILTRES;
}
