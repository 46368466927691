<div class="list-footer">
  <div class="list-footer-nb-results">
      <select class="select-like" id="list-footer-nb-results-field" (change)="onOptionsChange($event.target.value)">
        <option *ngFor="let option of pageSizeOptions" [value]="option">{{option}}</option>
      </select>
      <label for="list-footer-nb-results-field">{{'paginateur.options.label' | translate}}</label>
  </div>

  <div class="list-footer-pagination">
    <nav class="pagination" aria-label="Pagination" role="navigation">
      <ul class="list-unstyled">
        <li class="prev">
          <a aria-label="Page précédente" title="Page précédente" (click)="previous()">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
              <path
                d="M8.665 7.828l2.143 2.268-4.567 4.317h25.759v3.128h-25.806l4.614 4.364-2.143 2.268-8.665-8.164 8.665-8.18z"></path>
            </svg>
          </a>
        </li>

        <li>{{'paginateur.results.label' | translate:{first, last, length} }}</li>
        <!--        <li *ngFor="let pageNumber of pagesArray" [class.active]="pageNumber === pageIndex" (click)="selectPage(pageNumber)">{{pageNumber}}</li>-->
        <!--        <li class="sep" *ngIf="last > pagesArray[pagesArray.length -1] + 1"><span>...</span></li>-->
        <!--        <li [class.active]="last === pageIndex" (click)="selectPage(last)">{{last}}</li>-->

        <li class="next">
          <a aria-label="Page suivante" title="Page suivante" (click)="next()">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
              <path
                d="M23.335 7.828l-2.143 2.268 4.567 4.317h-25.759v3.128h25.806l-4.614 4.364 2.143 2.268 8.665-8.164-8.665-8.18z"></path>
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
