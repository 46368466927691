import {Injectable} from '@angular/core';
import {GofElement} from '../model/util.model';
import {FormationContenuService} from './contenu/formation-contenu.service';
import {CursusEnrichiContenuService} from './contenu/cursus-enrichi-contenu.service';
import {PanelContenu, DynamicFlatNode} from '../model/contenu.model';
import {ListeEnseignementsContenuService} from './contenu/liste-enseignements-contenu.service';
import {TYPES_OBJET} from '../constants/utils.constants';
import {LocalisationContenuService} from './contenu/localisation-contenu.service';
import {PeriodeContenuService} from './contenu/periode-contenu.service';

@Injectable({
  providedIn: 'root'
})
export class ContenuService {

  constructor(private formationContenuService: FormationContenuService,
              private cursusEnrichiContenuService: CursusEnrichiContenuService,
              private periodeContenuService: PeriodeContenuService,
              private listeEnseignementsContenuService: ListeEnseignementsContenuService,
              private localisationContenuService: LocalisationContenuService) {
  }

  getPanel(gofElement: GofElement, refObject: any): Promise<PanelContenu> {
    switch (gofElement.typeObjet) {
      case TYPES_OBJET.FORMATION :
        return this.formationContenuService.getContenu(gofElement, refObject);
      case TYPES_OBJET.CURSUS_ENRICHI :
        return this.cursusEnrichiContenuService.getContenu(gofElement, refObject);
      case TYPES_OBJET.PERIODE :
        return this.periodeContenuService.getContenu(gofElement, refObject);
      default:
        return new Promise<null>(resolve => resolve(null));
    }
  }

  getChildren(gofElement: GofElement, node: DynamicFlatNode): Promise<DynamicFlatNode[]> {
    switch (node.type) {
      case TYPES_OBJET.FORMATION :
        return this.formationContenuService.getChildren(gofElement, node);
      case TYPES_OBJET.CURSUS_ENRICHI :
        return this.cursusEnrichiContenuService.getChildren(gofElement, node);
      case TYPES_OBJET.PERIODE :
        return this.periodeContenuService.getChildren(gofElement, node);
      case TYPES_OBJET.LISTE_ENSEIGNEMENTS :
        return this.listeEnseignementsContenuService.getChildren(gofElement, node);
      case TYPES_OBJET.LOCALISATION :
        return this.localisationContenuService.getChildren(gofElement, node);
      default:
        return new Promise<DynamicFlatNode[]>(resolve => resolve(null));
    }
  }

}
