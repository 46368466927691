import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PathItem} from '../../model/util.model';
import {
  GOF_INTERNATIONAL_CATALOGUE, GOF_LANGUAGE,
  PATH_ACCUEIL, PATH_CATALOGUE_INTERNATIONAL, PATH_DETAIL_FORMATION,
  PATH_MA_SELECTION,
  PATH_PAR_COMPOSANTES,
  PATH_PAR_DIPLOMES,
  PATH_PAR_DISCIPLINES, PATH_PLAN_DU_SITE,
  PATH_RESULTATS
} from '../../constants/conf.constants';
import {TranslateService} from '@ngx-translate/core';
import {compare} from '../../utils/string.utils';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {AbstractDocument} from '../../model/esDoc.model';
import {ElasticsearchService} from '../../services/elasticsearch.service';
import {PeriodeResourceService} from '../../services/resources/periode-resource.service';
import {GlobalStateService} from '../../services/global-state.service';
import {UtilService} from '../../services/util.service';

@Component({
  selector: 'app-plan-du-site',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './plan-du-site.component.html',
  styleUrls: ['./plan-du-site.component.scss']
})
export class PlanDuSiteComponent implements OnInit {

  thread: PathItem[];

  display = false;

  formations: AbstractDocument[] = [];
  enseignements: AbstractDocument[] = [];
  parcoursTypes: AbstractDocument[] = [];
  cursusEnrichis: AbstractDocument[] = [];
  periodes: any[] = [];

  pathAccueil = PATH_ACCUEIL;
  pathResultats = PATH_RESULTATS;
  pathMaSelection = PATH_MA_SELECTION;
  pathParDiplomes = PATH_PAR_DIPLOMES;
  pathParDisciplines = PATH_PAR_DISCIPLINES;
  pathParComposantes = PATH_PAR_COMPOSANTES;
  pathCatalogueInternational = PATH_CATALOGUE_INTERNATIONAL;
  pathPlanDuSite = PATH_PLAN_DU_SITE;
  pathDetailFormation = PATH_DETAIL_FORMATION;

  typeobjetEnum = TYPES_OBJET;

  constructor(private periodeResourceService: PeriodeResourceService,
              private translateService: TranslateService,
              private elasticsearchService: ElasticsearchService,
              private globalStateService: GlobalStateService,
              private utilService: UtilService) {
    this.globalStateService.subscribe(GOF_LANGUAGE, () => {
      this.utilService.setHtmlHead("page.plan_du_site", "universite_de_bordeaux_description", PATH_PLAN_DU_SITE, "");
    });
  }

  ngOnInit(): void {
    this.utilService.setHtmlHead("page.plan_du_site", "universite_de_bordeaux_description", PATH_PLAN_DU_SITE, "");

    let homePath = PATH_ACCUEIL;
    if (JSON.parse(localStorage.getItem(GOF_INTERNATIONAL_CATALOGUE))) {
      homePath = '/' + PATH_CATALOGUE_INTERNATIONAL;
    }
    this.thread = [
      new PathItem('page.accueil', [homePath]),
      new PathItem('page.plan_du_site', [PATH_MA_SELECTION])
    ];
    Promise.all([this.getFromES(), this.getAllPeriodes()]).then(() => this.display = true);

    this.globalStateService.subscribe(GOF_LANGUAGE, () => {
      this.display = false;
      this.getFromES().then(() => this.display = true);
    });

  }

  getFromES(): Promise<void> {
    return new Promise<void>(resolve => {
      this.elasticsearchService.search().subscribe(res => {
        this.formations = res
          .filter(e => e.type_objet === this.typeobjetEnum.FORMATION)
          .sort((a, b) => compare(a.intitule, b.intitule));
        this.cursusEnrichis = res
          .filter(e => e.type_objet === this.typeobjetEnum.CURSUS_ENRICHI)
          .sort((a, b) => compare(a.intitule, b.intitule));

        this.parcoursTypes = res
          .filter(e => e.type_objet === this.typeobjetEnum.PARCOURS_TYPE)
          .sort((a, b) => compare(a.intitule, b.intitule));

        this.enseignements = res
          .filter(e => e.type_objet === this.typeobjetEnum.ENSEIGNEMENT)
          .sort((a, b) => compare(a.intitule, b.intitule));

        resolve();
      });
    });
  }


  getAllPeriodes(): Promise<void> {
    return new Promise<void>(resolve => {
      this.periodeResourceService.getAllPeriodes().subscribe(res => {
        this.periodes = res;
        resolve();
      });
    });
  }

}
