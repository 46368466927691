import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigAssetLoaderService} from '../../configAssetLoader.service';
import {Observable} from 'rxjs';
import {GofElement} from '../../model/util.model';
import {Document} from '../../model/document.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentResourceService {

  refFormUrl: string;

  constructor(protected http: HttpClient, private configAssetLoaderService: ConfigAssetLoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.refFormUrl = res.refFormUrl);
  }

  public getDocuments(gofElement: GofElement): Observable<Document[]> {
    return this.http.get<Document[]>(this.refFormUrl + '/documents/objetformation/' + gofElement.id + '?year=' + gofElement.annee);
  }

  public download(documentId: number): void {
    this.getKeyByDocumentId(documentId).subscribe((timedKey: any) => {
      window.open(this.refFormUrl + '/downloads/public/' + timedKey.key, '_self');
    });
  }

  private getKeyByDocumentId(documentId: number): Observable<string> {
    return this.http.get<string>(this.refFormUrl + '/downloads/document/' + documentId);
  }
}
