import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PanelModalitesAcquisition} from '../../../model/modalites-acquisition.model';

@Component({
  selector: 'app-modalites-acquisition',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './modalites-acquisition.component.html',
  styleUrls: ['./modalites-acquisition.component.scss']
})
export class ModalitesAcquisitionComponent {

  @Input() modalitesAcquisition: PanelModalitesAcquisition;

  constructor() {
  }

}
