import {Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from '@angular/core';
import {GofElement} from '../../../model/util.model';
import {PanelContacts} from '../../../model/contacts.model';
import {PanelContenu} from '../../../model/contenu.model';
import {PanelCompetences} from '../../../model/competences.model';
import {PanelPresentation} from '../../../model/presentation.model';
import {PanelRebonds} from '../../../model/rebonds.model';
import {PanelDebouches} from '../../../model/debouches.model';
import {LazyLoadService} from '../../../services/lazy-load.service';
import {PeriodeContactsService} from '../../../services/contacts/periode-contacts.service';
import {PeriodeCompetencesService} from '../../../services/competences/periode-competences.service';
import {PeriodeRebondsService} from '../../../services/rebonds/periode-rebonds.service';
import {ContenuService} from '../../../services/contenu.service';
import {PanelInternationalisation} from '../../../model/internationalisation.model';
import {PanelAcces} from '../../../model/acces.model';
import {PeriodeAccesService} from '../../../services/acces/periode-acces.service';
import {PeriodeInternationalisationService} from '../../../services/internalionalisation/periode-internationalisation.service';
import {typesDureeTrad} from '../../../constants/translationMaps/trad.constants';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-fiche-periode',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './fiche-periode.component.html',
  styleUrls: ['./fiche-periode.component.scss']
})
export class FichePeriodeComponent implements OnChanges {

  @Input() gofElement: GofElement;
  @Input() presentation: PanelPresentation;

  @Output() loaded: EventEmitter<void> = new EventEmitter<void>();


  acces: PanelAcces;
  contacts: PanelContacts;
  contenus: PanelContenu[];
  competences: PanelCompetences;
  debouches: PanelDebouches;
  internationalisation: PanelInternationalisation;
  rebonds: PanelRebonds;

  contenuLoading: boolean;
  display = false;
  typeTemp:string;

  constructor(
    private accesService: PeriodeAccesService,
    private contactsService: PeriodeContactsService,
    private contenuService: ContenuService,
    private competencesService: PeriodeCompetencesService,
    private internationalisationService: PeriodeInternationalisationService,
    private rebondsService: PeriodeRebondsService,
    private lazyLoadScriptServiceService: LazyLoadService,
    private translateService: TranslateService
  ) {
  }

  ngOnChanges(): void {
    Promise.all([
      this.getTypeTemp(),
      this.getAcces(),
      this.getCompetences(),
      this.getContacts(),
      this.getContenu(),
      this.getInternationalisation(),
      this.getRebonds()
    ]).then(() => {
      this.display = true;
      this.lazyLoadScriptServiceService.injectScript('/assets/js/course-detail.js');
      this.loaded.emit();
    });
  }


  getTypeTemp(): Promise<void>{
    return new Promise<void>(resolve => {
      this.translateService.get(typesDureeTrad.get(this.presentation.typeDureeId)).subscribe(res => {
        this.typeTemp = res?.charAt(0).toUpperCase();
        resolve();
      })
    });
  }

  private getAcces(): Promise<void> {
    return new Promise<void>(resolve => {
      this.accesService.getPanel(this.gofElement).then(res => {
        this.acces = res;
        resolve();
      });
    });
  }

  private getCompetences(): Promise<void> {
    return new Promise<void>(resolve => {
      this.competencesService.getPanel(this.gofElement).then(res => {
        this.competences = res;
        resolve();
      });
    });
  }

  private getContacts(): Promise<void> {
    return new Promise<void>(resolve => {
      this.contactsService.getPanel(this.gofElement).then(res => {
        this.contacts = res;
        resolve();
      });
    });
  }

  private getContenu(): Promise<void> {
    return new Promise<void>(resolve => {
      this.contenuLoading = true;
      this.contenuService.getPanel(this.gofElement, this.presentation.refObject).then(res => {
        this.contenuLoading = false;
        this.contenus = [res];
        resolve();
      });
    });
  }

  private getInternationalisation(): Promise<void> {
    return new Promise<void>(resolve => {
      this.internationalisationService.getPanel(this.gofElement).then(res => {
        this.internationalisation = res;
        resolve();
      });
    });
  }

  private getRebonds(): Promise<void> {
    return new Promise<void>(resolve => {
      this.rebondsService.getPanel(this.gofElement, this.presentation.refObject).then(res => {
        this.rebonds = res;
        resolve();
      });
    });
  }

}
