import {Dispositif, Partenariat} from './informations.model';

export class PanelInternationalisation {
  constructor(public erasmus?: Partenariat[],
              public cooperationsInternationales?: Partenariat[],
              public conventionsPrivees?: Partenariat[],
              public dispositifsInternationaux?: Dispositif[]) {
  }

  isDisplayed(): boolean {
    return this && (
      this.cooperationsInternationales?.length > 0 ||
      this.erasmus?.length > 0 ||
      this.conventionsPrivees?.length > 0 ||
      this.dispositifsInternationaux?.length > 0);
  }
}
