import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

export interface Configuration {
  production: boolean;
  name: string;
  ihmUrl: string;
  apiUrl: string;
  editoUrl: string;
  refFormUrl: string;
  matomoUrl: string;
  matomoSiteId: string;
}

export class MatomoConfig {
  constructor(private trackerUrl: string,
              private siteId: string,
              private scriptUrl: string,
              disabled: boolean) {
  }
}

@Injectable({providedIn: 'root'})
export class ConfigAssetLoaderService {

  private readonly CONFIG_URL = 'assets/environments/environment.json';
  private configuration: Observable<Configuration>;

  constructor(private http: HttpClient) {
  }

  public loadConfigurations(): Observable<Configuration> {
    if (!this.configuration) {
      this.configuration = this.http.get<Configuration>(this.CONFIG_URL).pipe(
        shareReplay(1)
      );
    }
    return this.configuration;
  }

  public getMatomoConfig(): Observable<MatomoConfig> {
    return this.loadConfigurations().pipe(map(config => new MatomoConfig(
        config.matomoUrl,
        config.matomoSiteId,
        config.matomoUrl + '/matomo.js',
        !config.production
      )
    ));
  }

}
