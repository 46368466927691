import { Injectable } from "@angular/core";
import { PanelDebouches, TypeDebouche } from "../../model/debouches.model";
import { GofElement } from "../../model/util.model";
import { DebouchesResourceService } from "../resources/debouches-resource.service";
import { getVersion } from "../../utils/version.utils";

@Injectable({
  providedIn: "root"
})
export class FormationDebouchesService {

  panelDebouches: PanelDebouches = new PanelDebouches();

  constructor(private debouchesResourceService: DebouchesResourceService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelDebouches> {
    return new Promise<PanelDebouches>(resolve => {
      this.debouchesResourceService.getDebouches(gofElement).subscribe(res => {
        this.panelDebouches.naf = res.naf.map(d => new TypeDebouche(d.nafId, getVersion(gofElement, d)));
        this.panelDebouches.rome = res.rome.map(d => new TypeDebouche(d.romeId, getVersion(gofElement, d)));
        this.panelDebouches.emploisTypes = res.autres.filter(d => d.type == 1).map(d => d.lib);
        this.panelDebouches.secteursActivites = res.autres.filter(d => d.type == 2).map(d => d.lib);

        resolve(this.panelDebouches);
      },
        () => resolve(new PanelDebouches()));
    });
  }
}
