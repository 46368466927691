import {Injectable} from '@angular/core';
import {PanelInformations, Partenariat} from '../../model/informations.model';
import {PartenariatResourceService} from '../resources/partenariat-resource.service';
import {GofElement} from '../../model/util.model';
import {getVersion} from '../../utils/version.utils';

@Injectable({
  providedIn: 'root'
})
export class CursusEnrichiInformationsService {

  panelInformations: PanelInformations = new PanelInformations();

  constructor(private partenariatResourceService: PartenariatResourceService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelInformations> {
    return new Promise<PanelInformations>(resolve => {
      this.partenariatResourceService.getPartenariats(gofElement, false).subscribe(res => {
        const partenariats: Partenariat[] = [];
        res.forEach(p => {
          partenariats.push({
            typeId: p.type.id,
            modalite: p.modalite,
            typeConvention: p.convention?.type,
            etablissements: p.etablissements.map(e => getVersion(gofElement,e.lib)),
            etatConventionId: p.etatConvention?.id
          });
        });
        this.panelInformations.partenariats = partenariats;
        resolve(this.panelInformations);
      },
        () => resolve(new PanelInformations()));
    });
  }
}
