import {Competence} from './competences.model';
import {ObjectAffichage} from './util.model';

export class PanelBlocsCompetence {
  constructor(public blocs?: BlocDeCompetence[]) {
  }

  isDisplayed(): boolean {
    return this && this.blocs?.length > 0;
  }
}

export class BlocDeCompetence {
  constructor(public id?: number,
              public intitule?: string,
              public desc?: string,
              public modalites?: Modalite[],
              public competenceUnique?: Competence,
              public competencesDisciplinaires?: Competence[],
              public competencesTransverses?: Competence[],
              public competencesPreProfessionnelles?: Competence[],
              public listesEnseignements?: ListeDescription[]) {
  }
}

export class Modalite {
  constructor(public desc?: number,
              public objectAffichage?: ObjectAffichage) {
  }
}

export class ListeDescription {
  constructor(public id?: number,
              public lib?: string,
              public description?: string,
              public nbEns?: number,
              public listeUE?: UE[]) {
  }
}

export class UE {
  constructor(public objectAffichage?: ObjectAffichage,
              public ensTypologieId?: number) {
  }
}
