import {Component, Input, OnChanges, ViewEncapsulation} from '@angular/core';
import {PanelAcquis} from '../../../model/acquis.model';
import {typesAcquisTrad} from '../../../constants/translationMaps/trad.constants';

@Component({
  selector: 'app-acquis',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './acquis.component.html',
  styleUrls: ['./acquis.component.scss']
})
export class AcquisComponent{

  @Input() acquis: PanelAcquis;

  constructor() {
  }

}
