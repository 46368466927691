import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PanelRebonds} from '../../../model/rebonds.model';
import {PATH_DETAIL_FORMATION} from '../../../constants/conf.constants';

@Component({
  selector: 'app-rebonds',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './rebonds.component.html',
  styleUrls: ['./rebonds.component.scss']
})
export class RebondsComponent {

  @Input() rebonds: PanelRebonds;
  @Input() fromCatalogueInternational: boolean;

  pathDetailFormation = PATH_DETAIL_FORMATION;

  constructor() {
  }

}
