import {Injectable} from '@angular/core';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {PanelPresentation} from '../../model/presentation.model';
import {PeriodeResourceService} from '../resources/periode-resource.service';
import {RoleResourceService} from '../resources/role-resource.service';
import {LocalisationResourceService} from '../resources/localisation-resource.service';
import {StructuresResourceService} from '../resources/structures-resource.service';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {RefTypePortage} from '../../constants/referentiel/ref.constants';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PeriodePresentationService {

  panelPresentation: PanelPresentation = new PanelPresentation();

  constructor(private periodeResourceService: PeriodeResourceService,
              private roleResourceService: RoleResourceService,
              private localisationResourceService: LocalisationResourceService,
              private structuresResourceService: StructuresResourceService,
              private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelPresentation> {
    return new Promise<PanelPresentation>(resolve => {
      Promise.all([
        this.getPeriode(gofElement),
        this.getResponsables(gofElement),
        this.getlocalisations(gofElement),
        this.getComposantesPedagogiques(gofElement)
      ])
        .then(() => {
          resolve(this.panelPresentation)
        });
    });
  }

  getPeriode(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.periodeResourceService.getPeriode(gofElement).subscribe(periode => {
        if (periode !== null) {
          this.panelPresentation.refObject = periode;
          this.getCurrentVersion(periode, gofElement);
          this.panelPresentation.objetId = periode.objetId;
          this.panelPresentation.objectif = periode.objectif ? periode.objectif : periode.formations[0]?.objectif
          this.panelPresentation.annee = periode.annee;
          this.panelPresentation.duree = periode.duree;
          this.panelPresentation.typeDureeId = periode.typeDuree.id;
          this.panelPresentation.credits = periode.ects;
          this.panelPresentation.isTransitoire = periode.isTransitoire;
          this.panelPresentation.formationsPorteuses = [];
          periode.formations?.forEach(f => {
            this.translateService.get(typesDiplomeTrad.get(f.type.typeDiplome.id))
              .subscribe(typeDiplome => this.panelPresentation.formationsPorteuses.push(new ObjectAffichage(f.objetId, typeDiplome + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION)));
          });
        }
        resolve();
      },
        () => resolve());
    });
  }

  private getCurrentVersion(periode: any, gofElement: GofElement): void {
    this.panelPresentation.intitule = periode.lib;
    this.panelPresentation.objectif = periode.objectif;

    if (!this.panelPresentation.objectif || this.panelPresentation.objectif.trim() === '') {
      this.panelPresentation.objectif = periode.formations[0].objectif;
    }

    if (periode.editos?.length > 0) {
      if (periode.editos.filter(e => e.typePublic.id === gofElement.typePublic).length > 0) {
        this.panelPresentation.intitule = periode.editos.filter(e => e.typePublic.id === gofElement.typePublic)[0].lib;
        this.panelPresentation.objectif = periode.editos.filter(e => e.typePublic.id === gofElement.typePublic)[0].objectif;
      }
    } else if (periode.traductions?.length > 0) {
      if (periode.traductions.filter(t => t.langue.code === gofElement.langue).length > 0) {
        this.panelPresentation.intitule = periode.traductions.filter(t => t.langue.code === gofElement.langue)[0].lib;
        this.panelPresentation.objectif = periode.traductions.filter(t => t.langue.code === gofElement.langue)[0].objectif;
      }
    }
  }


  private getResponsables(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.roleResourceService.getRolesForObjet(gofElement).subscribe(res => {
        this.panelPresentation.responsables = res.map(r => r.user.prenom + ' ' + r.user.nom);
        resolve();
      },
        () => resolve());
    });
  }

  private getlocalisations(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.localisationResourceService.getLocalisationsPeriode(gofElement).subscribe(res => {
        this.panelPresentation.siteIds = res.filter(l => l.isValid).map(c => c.site.id).filter((elem, index, self) => index === self.indexOf(elem));
        resolve();
      },
        () => resolve());
    });
  }

  private getComposantesPedagogiques(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.structuresResourceService.getStructures(gofElement).subscribe(res => {
        this.panelPresentation.composanteIds = res.structures?.filter(s => s.typePortage.id === RefTypePortage.COMPOSANTE_PEDAGOGIQUE).map(s => s.structure.id);
        this.panelPresentation.sniIds = res.structures?.filter(s => s.typePortage.id === RefTypePortage.SNI_PORTEUSE).map(s => s.structure.id);
        resolve();
      },
        () => resolve());
    });
  }
}

