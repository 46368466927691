import {Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from '@angular/core';
import {GofElement} from '../../../model/util.model';
import {PanelPresentation} from '../../../model/presentation.model';
import {PanelDebouches} from '../../../model/debouches.model';
import {PanelAcces} from '../../../model/acces.model';
import {PanelContacts} from '../../../model/contacts.model';
import {PanelCompetences} from '../../../model/competences.model';
import {PanelRebonds} from '../../../model/rebonds.model';
import {PanelInformations} from '../../../model/informations.model';
import {LazyLoadService} from '../../../services/lazy-load.service';
import {PanelPoursuitesEtudes} from '../../../model/poursuites-etudes.model';
import {PanelInternationalisation} from '../../../model/internationalisation.model';
import {CursusEnrichiAccesService} from '../../../services/acces/cursus-enrichi-acces.service';
import {CursusEnrichiCompetencesService} from '../../../services/competences/cursus-enrichi-competences.service';
import {CursusEnrichiContactsService} from '../../../services/contacts/cursus-enrichi-contacts.service';
import {CursusEnrichiDebouchesService} from '../../../services/debouches/cursus-enrichi-debouches.service';
import {CursusEnrichiPoursuitesEtudesService} from '../../../services/poursuites-etudes/cursus-enrichi-poursuites-etudes.service';
import {CursusEnrichiRebondsService} from '../../../services/rebonds/cursus-enrichi-rebonds.service';
import {CursusEnrichiInformationsService} from '../../../services/informations/cursus-enrichi-informations.service';
import {CursusEnrichiInternationalisationService} from '../../../services/internalionalisation/cursus-enrichi-internationalisation.service';

@Component({
  selector: 'app-fiche-cursus-enrichi',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './fiche-cursus-enrichi.component.html',
  styleUrls: ['./fiche-cursus-enrichi.component.scss']
})
export class FicheCursusEnrichiComponent implements OnChanges {

  @Input() gofElement: GofElement;
  @Input() presentation: PanelPresentation;

  @Output() loaded: EventEmitter<void> = new EventEmitter<void>();

  acces: PanelAcces;
  competences: PanelCompetences;
  contacts: PanelContacts;
  debouches: PanelDebouches;
  informations: PanelInformations;
  internationalisation: PanelInternationalisation;
  poursuitesEtudes: PanelPoursuitesEtudes;
  rebonds: PanelRebonds;

  display = false;

  constructor(private accesService: CursusEnrichiAccesService,
              private competencesService: CursusEnrichiCompetencesService,
              private contactsService: CursusEnrichiContactsService,
              private debouchesService: CursusEnrichiDebouchesService,
              private informationsService: CursusEnrichiInformationsService,
              private internationalisationService: CursusEnrichiInternationalisationService,
              private poursuitesEtudesService: CursusEnrichiPoursuitesEtudesService,
              private rebondsService: CursusEnrichiRebondsService,
              private lazyLoadScriptServiceService: LazyLoadService) {
  }

  ngOnChanges(): void {
    Promise.all([
      this.getAcces(),
      this.getCompetences(),
      this.getContacts(),
      this.getDebouches(),
      this.getInformations(),
      this.getInternationalisation(),
      this.getPoursuitesEtudes(),
      this.getRebonds()
    ]).then(() => {
      this.display = true;
      this.lazyLoadScriptServiceService.injectScript('/assets/js/course-detail.js');
      this.loaded.emit();
    });
  }

  private getAcces(): Promise<void> {
    return new Promise<void>(resolve => {
      this.accesService.getPanel(this.gofElement).then(res => {
        this.acces = res;
        resolve();
      });
    });
  }

  private getCompetences(): Promise<void> {
    return new Promise<void>(resolve => {
      this.competencesService.getPanel(this.gofElement).then(res => {
        this.competences = res;
        resolve();
      });
    });
  }

  private getContacts(): Promise<void> {
    return new Promise<void>(resolve => {
      this.contactsService.getPanel(this.gofElement).then(res => {
        this.contacts = res;
        resolve();
      });
    });
  }

  private getDebouches(): Promise<void> {
    return new Promise<void>(resolve => {
      this.debouchesService.getPanel(this.gofElement).then(res => {
        this.debouches = res;
        resolve();
      });
    });
  }

  private getInformations(): Promise<void> {
    return new Promise<void>(resolve => {
      this.informationsService.getPanel(this.gofElement).then(res => {
        this.informations = res;
        resolve();
      });
    });
  }

  private getInternationalisation(): Promise<void> {
    return new Promise<void>(resolve => {
      this.internationalisationService.getPanel(this.gofElement).then(res => {
        this.internationalisation = res;
        resolve();
      });
    });
  }

  private getPoursuitesEtudes(): Promise<void> {
    return new Promise<void>(resolve => {
      this.poursuitesEtudesService.getPanel(this.gofElement).then(res => {
        this.poursuitesEtudes = res;
        resolve();
      });
    });
  }


  private getRebonds(): Promise<void> {
    return new Promise<void>(resolve => {
      this.rebondsService.getPanel(this.gofElement, this.presentation.refObject).then(res => {
        this.rebonds = res;
        resolve();
      });
    });
  }
}
