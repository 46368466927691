import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PATH_DETAIL_FORMATION} from '../../../constants/conf.constants';
import {PanelPoursuitesEtudes} from '../../../model/poursuites-etudes.model';

@Component({
  selector: 'app-poursuites-etudes',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './poursuites-etudes.component.html',
  styleUrls: ['./poursuites-etudes.component.scss']
})
export class PoursuitesEtudesComponent {

  @Input() poursuitesEtudes: PanelPoursuitesEtudes;

  pathDetailFormation = PATH_DETAIL_FORMATION;

  constructor() {
  }
}
