import {AfterViewInit, Component, Input, ViewEncapsulation} from '@angular/core';
import {PanelInternationalisation} from '../../../model/internationalisation.model';
import {LazyLoadService} from '../../../services/lazy-load.service';
import {etatsConventionTrad} from '../../../constants/translationMaps/trad.constants';

@Component({
  selector: 'app-internationalisation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './internationalisation.component.html',
  styleUrls: ['./internationalisation.component.scss']
})
export class InternationalisationComponent implements AfterViewInit {

  @Input() internationalisation: PanelInternationalisation;

  etatsConventionTrad = etatsConventionTrad;

  constructor(private lazyLoadScriptServiceService: LazyLoadService) {
  }

  ngAfterViewInit(): void {
    this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
  }
}
