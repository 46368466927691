import {PeriodeResourceService} from '../services/resources/periode-resource.service';
import {shareReplay} from 'rxjs/operators';

export function hasListObservable(observablesMap: Map<number, Map<number, any>>, annee: number, objetId: number): boolean {
  return observablesMap?.get(annee)?.get(objetId) !== undefined;
}

export function getListObservable(observablesMap: Map<number, Map<number, any>>, annee: number, objetId: number): any {
  return observablesMap.get(annee).get(objetId);
}

export function setListObservable(observablesMap: Map<number, Map<number, any>>, periodeResourceService: PeriodeResourceService, annee: number, objetId: number) {
  if (observablesMap.get(annee)) {
    observablesMap.get(annee).set(objetId, periodeResourceService.getListes(objetId, annee).pipe(shareReplay(1)));
  } else {
    const map: Map<number, any> = new Map<number, any>;
    map.set(objetId, periodeResourceService.getListes(objetId, annee).pipe(shareReplay(1)));
    observablesMap.set(annee, map);
  }
}
