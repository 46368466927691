// langue
import {RefTypeObjet} from './referentiel/ref.constants';

export enum LANGUES {
  FR = 'fr',
  EN = 'en'
}

// type_objet
export enum TYPES_OBJET {
  FORMATION = 'formation',
  LISTE_ENSEIGNEMENTS = 'liste-enseignement',
  CURSUS_ENRICHI = 'cursus-enrichi',
  CMI = 'cmi',
  PARCOURS_TYPE = 'parcours-type',
  PARCOURS_LIBRE = 'parcours-libre',
  PERIODE = 'periode',
  ENSEIGNEMENT = 'enseignement',
  LOCALISATION = 'localisation',
}

// fields
export enum ES_FIELDS {
  COMPOSANTES = 'composantes',
  DEBOUCHES_PRO = 'debouches_pro',
  DIPLOMES_ACCES = 'diplomes_acces',
  DISCIPLINES = 'disciplines',
  DOMAINES = 'domaines',
  LOCALISATIONS = 'localisations',
  NIVEAU_ENTREE = 'niveau_entree',
  INSCRIPTIONS = 'inscriptions',
  LANGUES = 'langues',
  OUVERTURE_MOBILITE = 'ouverture_mobilite',
  ENLIGHT = 'enlight',
  TYPE_DIPLOME = 'type_diplome',
  TYPE_OBJET = 'type_objet',
}

// Mapping typeObjetId - TypeObjet
export const TYPES_OBJET_MAP:Map<number, string> = new Map([
  [RefTypeObjet.PARCOURS_TYPE, TYPES_OBJET.PARCOURS_TYPE],
  [RefTypeObjet.FORMATION, TYPES_OBJET.FORMATION],
  [RefTypeObjet.CURSUS_ENRICHI, TYPES_OBJET.CURSUS_ENRICHI]
]);
