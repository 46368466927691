import {AfterViewInit, Component, Input, ViewEncapsulation} from '@angular/core';
import {PanelDebouches} from '../../../model/debouches.model';
import {LazyLoadService} from '../../../services/lazy-load.service';

@Component({
  selector: 'app-debouches',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './debouches.component.html',
  styleUrls: ['./debouches.component.scss']
})
export class DebouchesComponent implements AfterViewInit {

  @Input() debouches: PanelDebouches;

  constructor(private lazyLoadScriptServiceService: LazyLoadService) {
  }

  ngAfterViewInit(): void {
    this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
  }

}
