import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadService {

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
  }

  injectScript(url: string): void {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
