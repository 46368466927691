export function fixHeader(): void {
  const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  if (document.getElementById("gof")) {
    if (verticalOffset > 0) {
      document.getElementById("gof").classList.add("header-is-fixed");
    } else {
      document.getElementById("gof").classList.remove("header-is-fixed");
    }
  }
}

export function displayFooter(): void {
  document.getElementById("footer").classList.remove("footer-is-hidden");
}

export function hideFooter(): void {
  document.getElementById("footer").classList.add("footer-is-hidden");
}

export function adaptNavContainer(innerWidth: number): void {
  if (document.getElementById("course-detail-nav-container")) {
    if (innerWidth > 768 && innerWidth < 1200) {
      document.getElementById("course-detail-nav-container").classList.add("course-detail-nav-container-tablet");
      document.getElementById("course-detail-nav-container").classList.remove("course-detail-nav-container");
    } else {
      document.getElementById("course-detail-nav-container").classList.add("course-detail-nav-container");
      document.getElementById("course-detail-nav-container").classList.remove("course-detail-nav-container-tablet");
    }
  }
}

export function closeAllFilters(): void {
  if (
    document.getElementById("niveaux-entree-details")?.classList.contains("search-block-form-field-details-expanded") ||
    document.getElementById("disciplines-details")?.classList.contains("search-block-form-field-details-expanded") ||
    document.getElementById("diplomes-details")?.classList.contains("search-block-form-field-details-expanded")
  ) {
    document.getElementById("niveaux-entree-details").classList.remove("search-block-form-field-details-expanded");
    document.getElementById("niveaux-entree").setAttribute("aria-expanded", "false");
    document.getElementById("disciplines-details").classList.remove("search-block-form-field-details-expanded");
    document.getElementById("disciplines").setAttribute("aria-expanded", "false");
    document.getElementById("diplomes-details").classList.remove("search-block-form-field-details-expanded");
    document.getElementById("diplomes").setAttribute("aria-expanded", "false");
  }
}

export function closeSecondaryHeaderNav(): void {
  if (document.documentElement.classList.contains("header-profile-nav-is-open")) {
    document.documentElement.classList.remove("header-profile-nav-is-open");
    document.getElementById("header-profile-nav").setAttribute("aria-expanded", "false");
  }
  if (document.documentElement.classList.contains("header-accessibility-nav-is-open")) {
    document.documentElement.classList.remove("header-accessibility-nav-is-open");
    document.getElementById("header-accessibility-nav").setAttribute("aria-expanded", "false");
  }
  if (document.documentElement.classList.contains("header-lang-nav-is-open")) {
    document.documentElement.classList.remove("header-lang-nav-is-open");
    document.getElementById("header-lang-nav").setAttribute("aria-expanded", "false");
  }
}

export function closeAllExpandedNav(): void {
  closeSecondaryHeaderNav();
  closeAllFilters();
}
