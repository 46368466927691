<div class="accordion-panels">

  <div class="accordion-panel" *ngIf="debouches.secteursActivites?.length > 0">
    <div class="accordion-panel-header">
      <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
        <h3 class="accordion-panel-title" id="label_secteursActivite">{{'fiche.debouches_pro.secteurs_activite' | translate}}</h3>
      </div>
      <button data-toggle="collapse" type="button"
              aria-expanded="false"
              [attr.data-target]="'#debouches-secteursActivite'"
              [attr.aria-controls]="'debouches-secteursActivite'"
              aria-describedby="label_secteursActivite"
              class="accordion-panel-toggler collapsed">
        <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
      </button>
    </div>

    <div [id]="'debouches-secteursActivite'" class="accordion-panel-body collapse">
      <div class="accordion-panel-body-inner">
        <ul>
          <li *ngFor="let secteur of debouches.secteursActivites">{{secteur}}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="accordion-panel" *ngIf="debouches.emploisTypes?.length > 0">
    <div class="accordion-panel-header">
      <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
        <h3 class="accordion-panel-title" id="label_emploisTypes">{{'fiche.debouches_pro.emplois_types' | translate}}</h3>
      </div>
      <button data-toggle="collapse" type="button"
              aria-expanded="false"
              [attr.data-target]="'#debouches-emploisTypes'"
              [attr.aria-controls]="'debouches-emploisTypes'"
              aria-describedby="label_emploisTypes"
              class="accordion-panel-toggler collapsed">
        <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
      </button>
    </div>

    <div [id]="'debouches-emploisTypes'" class="accordion-panel-body collapse">
      <div class="accordion-panel-body-inner">
        <ul>
            <li *ngFor="let emploiType of debouches.emploisTypes">{{emploiType}}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="accordion-panel" *ngIf="debouches.rome?.length > 0 || debouches.naf?.length > 0">
    <div class="accordion-panel-header">
      <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
        <h3 class="accordion-panel-title" id="label_nomenclatures_nationales">{{'fiche.debouches_pro.nomenclatures_nationales' | translate}}</h3>
      </div>
      <button data-toggle="collapse" type="button"
              [attr.data-target]="'#nomenclatures_nationales'"
              [attr.aria-controls]="'nomenclatures_nationales'"
              class="accordion-panel-toggler collapsed"
              aria-expanded="false"
              aria-describedby="label_nomenclatures_nationales">
        <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
      </button>
    </div>

    <div [id]="'nomenclatures_nationales'" class="accordion-panel-body collapse">
      <div class="accordion-panel-body-inner">
        <ul>
          <ng-container *ngIf="debouches.rome?.length > 0">
            <li *ngFor="let rome of debouches.rome">{{rome.libelle}}</li>
          </ng-container>
          <ng-container *ngIf="debouches.naf?.length > 0">
            <li *ngFor="let naf of debouches.naf">{{naf.libelle}}</li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

</div>

