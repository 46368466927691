import {AfterViewInit, Component, Input, ViewEncapsulation} from '@angular/core';
import {PanelAcces} from '../../../model/acces.model';
import {PATH_DETAIL_FORMATION} from '../../../constants/conf.constants';
import {LazyLoadService} from '../../../services/lazy-load.service';
import {typesInscriptionTrad} from '../../../constants/translationMaps/trad.constants';

@Component({
  selector: 'app-acces',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './acces.component.html',
  styleUrls: ['./acces.component.scss']
})
export class AccesComponent implements AfterViewInit {

  @Input() acces: PanelAcces;

  pathDetailFormation = PATH_DETAIL_FORMATION;
  typesInscriptionTrad = typesInscriptionTrad;

  constructor(private lazyLoadScriptServiceService: LazyLoadService) {
  }

  ngAfterViewInit(): void {
    this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
  }

}
