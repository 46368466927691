import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ElasticsearchService} from "../../services/elasticsearch.service";
import {FiltresComponent} from "../../components/results/filtres/filtres.component";
import {Filtre, GofElement, KeyValue, SearchItem, PageEvent} from "../../model/util.model";
import {TREE_FILTRES} from "../../constants/filtres.constants";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
  BREADCRUMB_THREAD, GOF_FILTERS,
  GOF_LANGUAGE,
  GOF_SEARCH,
  PATH_ACCUEIL,
  PATH_RESULTATS,
  SEARCH_BY_KEYWORDS
} from "../../constants/conf.constants";
import {ES_FIELDS, TYPES_OBJET} from "../../constants/utils.constants";
import {getLastFilters, getLastSearch} from "../../utils/storage.utils";
import {GlobalStateService} from "../../services/global-state.service";
import {displayFooter, hideFooter} from "../../utils/style.utils";
import {UtilService} from "../../services/util.service";

@Component({
  selector: "app-resultats",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./resultats.component.html",
  styleUrls: ["./resultats.component.scss"]
})
export class ResultatsComponent implements OnInit {

  @ViewChild(FiltresComponent) advancedFiltersComponent: FiltresComponent;
  @ViewChild("results") results: ElementRef;

  filtres: Filtre[] = TREE_FILTRES;

  formationCount: number;
  displayFormation: boolean;
  formationToggle: boolean = true;
  enseignementCount: number;
  displayEnseignement: boolean;
  enseignementToggle: boolean = true;
  cursusEnrichiCount: number;
  displayCursusEnrichi: boolean;
  cursusEnrichiToggle: boolean = true;
  parcoursTypeCount: number;
  displayParcoursType: boolean;
  parcoursTypeToggle: boolean = true;

  gofElement: GofElement;
  lastsearch: SearchItem;

  noResultFiltres: Map<string, string[]>;

  documents: any[] = [];
  filteredDocs: any[];
  paginedDocs: any[];

  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 15, 20];

  typeObjet = TYPES_OBJET;

  constructor(private elasticsearchService: ElasticsearchService,
              private translateService: TranslateService,
              private utilService: UtilService,
              private globalStateService: GlobalStateService,
              private router: Router,
              private route: ActivatedRoute) {
    this.globalStateService.subscribe(GOF_LANGUAGE, () => {
      this.utilService.setHtmlHead("page.resultats", "universite_de_bordeaux_description", PATH_RESULTATS, "");
    });

    this.globalStateService.subscribe(SEARCH_BY_KEYWORDS, documents => {
      hideFooter();
      this.lastsearch = getLastSearch();
      if (!this.lastsearch) {
        this.router.navigate([PATH_ACCUEIL]);
      }
      this.loadResults(documents);
    });
  }

  ngOnInit(): void {
    this.utilService.setHtmlHead("page.resultats", "universite_de_bordeaux_description", PATH_RESULTATS, "");

    this.route.queryParams.subscribe(params => {
      hideFooter();

      let value = null;
      let field = null;

      if (params.value || params.field) {  // recherche par les paramètres de l'url
        value = params.value?.replace(/_/gi, " ");
        field = params.field;
        this.lastsearch = new SearchItem(value, field);

        sessionStorage.setItem(GOF_SEARCH, JSON.stringify([this.lastsearch]));
        this.elasticsearchService.search(value, field).subscribe(documents => this.loadResults(documents));

      } else if (!this.documents || this.documents.length === 0) { // actions navigateur (reload, previous, next)
        this.lastsearch = getLastSearch();
        if(getLastFilters()){
          this.filtres = getLastFilters();
        }

        if (!this.lastsearch) {
          this.router.navigate([PATH_ACCUEIL]);
        } else {
          this.elasticsearchService
            .search(this.lastsearch.value, this.lastsearch.field, this.lastsearch.filters)
            .subscribe(documents => this.loadResults(documents));
        }
      }

      sessionStorage.setItem(BREADCRUMB_THREAD, JSON.stringify([]));
    });
  }

  onPageEvent(pageEvent: PageEvent): void {
    setTimeout(() => {
      this.results.nativeElement.scrollIntoView({behavior: "smooth"});
    }, 100);

    const start = pageEvent.pageIndex * pageEvent.pageSize;
    this.paginedDocs = [];
    if (this.filteredDocs) {
      this.paginedDocs = this.filteredDocs.slice(start, start + pageEvent.pageSize);
    }
  }

  onFilterEvent(filteredDocs: any[]): void {
    sessionStorage.setItem(GOF_FILTERS, JSON.stringify(this.filtres));
    this.filteredDocs = filteredDocs;
    this.initTypologies()
    this.loadPaginedDocs();
  }

  loadResults(documents: any[]): void {
    this.loadNoResultFilter();
    this.initFiltres().then(() => {
      this.documents = documents;
      this.filteredDocs = documents;
      this.initTypologies()
      this.loadPaginedDocs();
      this.results.nativeElement.scrollIntoView({behavior: "smooth"});
      displayFooter();
    });
  }

  removeSearchCriteria(option: KeyValue) {
    option.value = false;
    this.elasticsearchService.search(this.lastsearch.value, this.lastsearch.field, this.filtres)
      .subscribe(res => this.onFilterEvent(res));
  }

  toggleTypologieFilter(type_objet: string) {
    switch (type_objet) {
      case "formation":
        this.formationToggle = !this.formationToggle;
        break;
      case "enseignement":
        this.enseignementToggle = !this.enseignementToggle;
        break;
      case "cursus-enrichi":
        this.cursusEnrichiToggle = !this.cursusEnrichiToggle;
        break;
      case "parcours-type":
        this.parcoursTypeToggle = !this.parcoursTypeToggle;
        break;
    }

    this.filtres.find(f => f.field === ES_FIELDS.TYPE_OBJET).options = [
      new KeyValue('formation', this.formationToggle),
      new KeyValue('enseignement', this.enseignementToggle),
      new KeyValue('cursus-enrichi', this.cursusEnrichiToggle),
      new KeyValue('parcours-type', this.parcoursTypeToggle)
    ]
    this.elasticsearchService.search(this.lastsearch.value, this.lastsearch.field, this.filtres)
      .subscribe(res => this.onFilterEvent(res));
  }

  private loadPaginedDocs(): void {
    if (this.filteredDocs) {
      this.paginedDocs = this.filteredDocs.slice(0, this.pageSize);
    }
  }

  private initTypologies(): void {
    this.formationCount = this.filteredDocs?.filter(doc => doc['type_objet'] === "formation").length;
    this.displayFormation = this.documents?.filter(doc => doc['type_objet'] === "formation").length > 0;
    this.enseignementCount = this.filteredDocs?.filter(doc => doc['type_objet'] === "enseignement").length;
    this.displayEnseignement = this.documents?.filter(doc => doc['type_objet'] === "enseignement").length > 0;
    this.cursusEnrichiCount = this.filteredDocs?.filter(doc => doc['type_objet'] === "cursus-enrichi").length;
    this.displayCursusEnrichi = this.documents?.filter(doc => doc['type_objet'] === "cursus-enrichi").length > 0;
    this.parcoursTypeCount = this.filteredDocs?.filter(doc => doc['type_objet'] === "parcours-type").length;
    this.displayParcoursType = this.documents?.filter(doc => doc['type_objet'] === "parcours-type").length > 0;
  }

  private initFiltres(): Promise<void> {
    return new Promise<void>(resolve => {
      const promises: Promise<any>[] = [];
      this.filtres.forEach(filtre => promises.push(this.getFiltreOptions(filtre)));
      Promise.all(promises).then(() => {
        // supprimer l'option non du filtre Ouverture à la mobilité ***
        this.translateService.get("global.yes").subscribe(res => this.filtres.filter(f => f.field === ES_FIELDS.OUVERTURE_MOBILITE)[0].options = [{
          key: res,
          value: false
        }]);
        resolve();
      });
    });
  }

  private getFiltreOptions(filtre: Filtre): Promise<void> {
    return new Promise<void>(resolve => {
      const lastSearchFilters: Filtre = this.lastsearch.filters?.find(f => f.field === filtre.field);

      this.elasticsearchService.getOptions(this.lastsearch.value, this.lastsearch.field, filtre.field, this.lastsearch.filters)
        .subscribe(options => {
          filtre.options = options.map(o =>
            new KeyValue(
              o,
              filtre?.options?.filter(opt => opt.key === o).length > 0 ?
                filtre.options.filter(opt => opt.key === o)[0].value :
                lastSearchFilters?.options?.filter(opt => opt.key === o).length > 0 ?
                  lastSearchFilters.options.filter(opt => opt.key === o)[0].value : false
            )
          );
          resolve();
        });
    });
  }

  private loadNoResultFilter(): void {
    this.noResultFiltres = new Map();
    if (this.lastsearch.value && this.lastsearch.value != "") {
      this.noResultFiltres.set("resultats.notif.votre_recherche", [this.lastsearch.value]);
    }
    this.lastsearch?.filters?.forEach(filtre => {
      const selectOptions = filtre.options.filter(o => o.value);
      if (selectOptions.length > 0 && filtre.field !== ES_FIELDS.TYPE_OBJET) {
        this.noResultFiltres.set("resultats.notif." + filtre.field, selectOptions.map(o => o.key));
      }
    });
  }

}
