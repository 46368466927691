<div class="layout-with-left-col">
  <div class="layout-left-col">
    <div class="layout-left-col-inner">
      <div class="layout-left-col-text">
        {{'page.par_composante' | translate}}
      </div>
    </div>
  </div>

  <div class="layout-main">
    <section class="main-inner">
      <div class="container">

        <app-breadcrumb-thread [thread]="thread"></app-breadcrumb-thread>

        <div class="content-part">
          <h1>{{'page.par_composante' | translate}}</h1>
        </div>

        <div class="content-part">
          <div class="margin-large margin-lg-xlarge">
            <app-loader *ngIf="menu?.length === 0"></app-loader>

            <div class="accordion-panels accordion-panels-outer">

              <div class="accordion-panel" *ngFor="let m1 of menu, let index1 = index">
                <div class="accordion-panel-header">
                  <h2 class="accordion-panel-title">
                    {{m1.intitule}}
                  </h2>
                  <button data-toggle="collapse" type="button"
                          [attr.data-target]="'#accordion-composantes-' + index1"
                          [attr.aria-controls]="'accordion-composantes-' + index1"
                          class="accordion-panel-toggler collapsed"
                          aria-expanded="false"
                          title="Ouvrir / fermer le panneau"
                          aria-label="Ouvrir / fermer le panneau">
                    <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                  </button>
                </div>

                <div [id]="'accordion-composantes-' + index1" class="accordion-panel-body collapse">
                  <div class="accordion-panel-body-inner">
                    <div class="margin-large margin-lg-xlarge">
                      <div class="accordion-panels">

                        <div class="accordion-panel" *ngFor="let m2 of m1.sousmenus ; let index2 = index">
                          <div class="accordion-panel-header">
                            <h3 class="accordion-panel-title">
                              {{m2.intitule}}
                            </h3>
                            <button data-toggle="collapse"
                                    type="button"
                                    [attr.data-target]="'#accordion-composantes-' + index1 + '-' + index2"
                                    [attr.aria-controls]="'accordion-composantes-' + index1 + '-' + index2"
                                    class="accordion-panel-toggler collapsed" aria-expanded="false"
                                    title="Ouvrir / fermer le panneau"
                                    aria-label="Ouvrir / fermer le panneau"
                                    (click)="getNiveau3(m1.intitule, m2)">
                              <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                            </button>
                          </div>

                          <div [id]="'accordion-composantes-' + index1 + '-' + index2"
                               class="accordion-panel-body collapse"
                               [hidden]="!m2.sousmenus">
                            <div class="accordion-panel-body-inner">
                              <div class="margin-large margin-lg-xlarge">
                                <div class="accordion-panels">

                                  <div class="accordion-panel" *ngFor="let m3 of m2.sousmenus ; let index3 = index">
                                    <div class="accordion-panel-header">
                                      <h3 class="accordion-panel-title">
                                        {{m3.intitule}}
                                      </h3>
                                      <button data-toggle="collapse"
                                              type="button"
                                              [attr.data-target]="'#accordion-composantes-' + index1 + '-' + index2  + '-' + index3"
                                              [attr.aria-controls]="'accordion-composantes-' + index1 + '-' + index2  + '-' + index3"
                                              class="accordion-panel-toggler collapsed" aria-expanded="false"
                                              title="Ouvrir / fermer le panneau"
                                              aria-label="Ouvrir / fermer le panneau"
                                              (click)="getNiveau4(m1.intitule, m2.intitule, m3)">
                                        <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                                      </button>
                                    </div>

                                    <div [id]="'accordion-composantes-' + index1 + '-' + index2 + '-' + index3"
                                         class="accordion-panel-body collapse"
                                         [hidden]="!m3.formationDocs?.length > 0">
                                      <div class="accordion-panel-body-inner">
                                        <ul>
                                          <li *ngFor="let formation of m3.formationDocs">
                                            <a href [routerLink]="'/' + pathDetailFormation"
                                               [queryParams]="{type: formation.type_objet, id: formation.id}"
                                               (click)="onSelected(formation)">{{formation.intitule}}</a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </section>
  </div>

</div>
