import {Injectable} from '@angular/core';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {PanelPresentation, ParcoursLibre} from '../../model/presentation.model';
import {FormationResourceService} from '../resources/formation-resource.service';
import {DispositifInsertionResourceService} from '../resources/dispositif-insertion-resource.service';
import {LocalisationResourceService} from '../resources/localisation-resource.service';
import {SuiviADistanceResourceService} from '../resources/suivi-a-distance-resource.service';
import {InscriptionResourceService} from '../resources/inscription-resource.service';
import {RoleResourceService} from '../resources/role-resource.service';
import {LienSiResourceService} from '../resources/lien-si-resource.service';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {ClassificationsResourceService} from '../resources/classifications-resource.service';
import {ObjetFormationResourceService} from '../resources/objet-formation-resource.service';
import {GOF_PROFILE} from '../../constants/conf.constants';
import {CookieService} from 'ngx-cookie-service';
import {TranslateService} from '@ngx-translate/core';
import {RefTypePortage} from '../../constants/referentiel/ref.constants';
import {getVersion} from '../../utils/version.utils';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';

@Injectable({
  providedIn: 'root'
})
export class FormationPresentationService {

  panelPresentation: PanelPresentation = new PanelPresentation();

  constructor(private formationResourceService: FormationResourceService,
              private classificationResourceService: ClassificationsResourceService,
              private dispositifInsertionResourceService: DispositifInsertionResourceService,
              private objetFormationResourceService: ObjetFormationResourceService,
              private roleResourceService: RoleResourceService,
              private localisationResourceService: LocalisationResourceService,
              private lienSiResourceService: LienSiResourceService,
              private suiviADistanceResourceService: SuiviADistanceResourceService,
              private inscriptionResourceService: InscriptionResourceService,
              private cookieService: CookieService,
              private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelPresentation> {
    return new Promise<PanelPresentation>(resolve => {
      Promise.all([
        this.getFormation(gofElement),
        this.getCompetences(gofElement),
        this.getDisciplines(gofElement),
        this.getDomaines(gofElement),
        this.getResponsables(gofElement),
        this.getlocalisations(gofElement),
        this.getSuivisADistance(gofElement),
        this.getInscriptions(gofElement),
        this.getLienSi(gofElement),
        this.getCursus(gofElement
        )]).then(() => resolve(this.panelPresentation));
    });
  }

  private getCursus(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.objetFormationResourceService.getCursus(gofElement).subscribe(cursusList => {
        this.panelPresentation.cursusEnrichi = cursusList.map(c => new ObjectAffichage(c.objetId, c.lib, TYPES_OBJET.CURSUS_ENRICHI));
        resolve();
      },
        () => resolve());
    });
  }

  private getFormation(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.formationResourceService.getFormation(gofElement).subscribe(formation => {
        if (formation != null) {
          this.panelPresentation.refObject = formation;
          this.getCurrentVersion(formation);
          this.panelPresentation.objetId = gofElement.id;
          this.panelPresentation.annee = formation.annee;
          this.panelPresentation.typeDiplomeId = formation.type.typeDiplome.id;
          this.panelPresentation.niveauEntreeId = formation.type.niveauEntree?.id;
          this.panelPresentation.niveauSortieId = formation.type.niveauSortie?.id;
          this.panelPresentation.duree = formation.duree;
          this.panelPresentation.typeDureeId = formation.typeDuree.id;
          this.panelPresentation.credits = formation.ects;
          this.panelPresentation.composanteIds = formation.structures?.filter(s => s.typePortage.id === RefTypePortage.COMPOSANTE_PEDAGOGIQUE).map(s => s.structure.id);
          this.panelPresentation.sniIds = formation.structures?.filter(s => s.typePortage.id === RefTypePortage.SNI_PORTEUSE).map(s => s.structure.id);
          this.panelPresentation.parcoursType = formation.parcoursType?.map(p => new ObjectAffichage(p.objetId, p.lib, TYPES_OBJET.PARCOURS_TYPE));
          this.panelPresentation.parcoursLibre = formation.parcoursLibre?.map(p => new ParcoursLibre(
            p.objetId,
            p.lib,
            TYPES_OBJET.PERIODE,
            p.ects,
            p.objectif,
            p.debut,
            p.fin,
            p.duree + ' ' + p.typeDuree.dureeLib + '(s)',
            p.typeDuree
          ));
        }
        resolve();
      },
        () => resolve());
    });
  }

  private getCurrentVersion(formation: any): void {
    this.translateService.get(typesDiplomeTrad.get(formation.type.typeDiplome.id)).subscribe(typeDiplome => {
      this.panelPresentation.intitule = typeDiplome + ' : ' + formation.diplome.lib;
    });
    this.panelPresentation.objectif = formation.objectif;

    if (formation.editos?.length > 0) {
      if (formation.editos.filter(e => e.typePublic.id === +this.cookieService.get(GOF_PROFILE)).length > 0) {
        // this.panelPresentation.intitule = formation.editos.filter(e => e.typePublic.id === +this.cookieService.get(GOF_PROFILE))[0].lib;
        this.panelPresentation.objectif = formation.editos.filter(e => e.typePublic.id === +this.cookieService.get(GOF_PROFILE))[0].objectif;
      }
    } else if (formation.traductions?.length > 0) {
      if (formation.traductions.filter(t => t.langue.code === this.translateService.currentLang).length > 0) {
        // this.panelPresentation.intitule = formation.traductions.filter(t => t.langue.code === this.translateService.currentLang)[0].lib;
        this.panelPresentation.objectif = formation.traductions.filter(t => t.langue.code === this.translateService.currentLang)[0].objectif;
      }
    }
  }

  private getCompetences(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.formationResourceService.getCompetences(gofElement).subscribe(res => {
        this.panelPresentation.competences = res
          .filter(s => !s.isPrerequis && s.isValid)
          .map(c => c.competence.lib);
        resolve();
      },
        () => resolve());
    });
  }

  private getResponsables(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.roleResourceService.getRolesForObjet(gofElement).subscribe(res => {
        this.panelPresentation.responsables = res.map(r => r.user.prenom + ' ' + r.user.nom);
        resolve();
      },
        () => resolve());
    });
  }

  private getlocalisations(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.localisationResourceService.getLocalisationsFormation(gofElement).subscribe(res => {
        this.panelPresentation.siteIds = res.filter(l => l.isValid).map(c => c.site?.id).filter((elem, index, self) => index === self.indexOf(elem));
        resolve();
      },
        () => resolve());
    });
  }

  private getSuivisADistance(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.suiviADistanceResourceService.getSuiviADistance(gofElement).subscribe(res => {
        this.panelPresentation.suiviADistanceId = res?.type?.id;
        resolve();
      },
        () => resolve());
    });
  }

  private getInscriptions(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.inscriptionResourceService.getTypesInscription(gofElement).subscribe(res => {
        this.panelPresentation.typeInscriptionIds = res.map(i => i.type?.id).filter((elem, index, self) => index === self.indexOf(elem));
        resolve();
      },
        () => resolve());
    });
  }

  private getLienSi(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.lienSiResourceService.getLienSi(gofElement).subscribe(res => {
        this.panelPresentation.codeSI = res.map(l => l.codeSI);
        resolve();
      },
        () => resolve());
    });
  }

  private getDisciplines(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.classificationResourceService.getDisciplines(gofElement).subscribe(res => {
        this.panelPresentation.disciplines = res.map(d => getVersion(gofElement, d));
        resolve();
      },
        () => resolve());
    });
  }

  private getDomaines(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.classificationResourceService.getDomaines(gofElement).subscribe(res => {
        this.panelPresentation.domaines = res.map(d => getVersion(gofElement, d));
        resolve();
      },
        () => resolve());
    });
  }


}
