import {CollectionViewer, DataSource, SelectionChange} from '@angular/cdk/collections';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {FlatTreeControl} from '@angular/cdk/tree';
import {map} from 'rxjs/operators';
import {ObjectAffichage} from './util.model';

export class PanelContenu {
  public objetFormation: DynamicFlatNode;
  public parcoursType: ObjectAffichage;
  public noeuds?: DynamicFlatNode[];

  isDisplayed(): boolean {
    return this.noeuds != null && this.noeuds.length > 0;
  }
}

export class DynamicFlatNode {
  constructor(
    public name?: any,
    public type?: string,
    public level?: number,
    public expandable?: boolean,
    public clickable?: boolean,
    public isLoading = false,
    public objetId?: number,
    public supId?: number,
    public site?: string,
    public siteId?: number,
    public description?: string,
    public temporalite?: string,
    public ects?: number,
    public ensNiveau?: string,
    public ensNiveauId?: number,
    public ensTypologie?: string,
    public ensTypologieId?: number,
    public modalite?: string,
    public children ?: DynamicFlatNode[]
  ) {
  }
}

export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  constructor(private treeControl: FlatTreeControl<DynamicFlatNode>,
              private service: any,
              private annee: number) {
  }

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: DynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>): void {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean): void {
    node.isLoading = true;
    setTimeout(() => {
      this.service.getChildren(node, this.annee).then(children => {
        const index = this.data.indexOf(node);
        if (!children || index < 0) { // If no children, or cannot find the node, no op
          return;

        } else if (expand) {
          this.data.splice(index + 1, 0, ...children);

        } else {
          let count = 0;
          for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {
          }
          this.data.splice(index + 1, count);
        }

        // notify the change
        this.dataChange.next(this.data);
        node.isLoading = false;
      });
    }, 1000);
  }
}
