import {Injectable} from '@angular/core';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {PanelPresentation} from '../../model/presentation.model';
import {SynchroResourceService} from '../resources/synchro-resource.service';
import {PeriodeResourceService} from '../resources/periode-resource.service';
import {RoleResourceService} from '../resources/role-resource.service';
import {LocalisationResourceService} from '../resources/localisation-resource.service';
import {StructuresResourceService} from '../resources/structures-resource.service';
import {TranslateService} from '@ngx-translate/core';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {Router} from '@angular/router';
import {GOF_PROFILE} from '../../constants/conf.constants';
import {CookieService} from 'ngx-cookie-service';
import {RefTypePortage} from '../../constants/referentiel/ref.constants';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';

@Injectable({
  providedIn: 'root'
})
export class ParcoursTypePresentationService {

  panelPresentation: PanelPresentation = new PanelPresentation();

  constructor(private synchroResourceService: SynchroResourceService,
              private periodeResourceService: PeriodeResourceService,
              private roleResourceService: RoleResourceService,
              private localisationResourceService: LocalisationResourceService,
              private structuresResourceService: StructuresResourceService,
              private cookieService: CookieService,
              private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelPresentation> {
    return new Promise<PanelPresentation>(resolve => {
      Promise.all([
        this.getParcoursType(gofElement),
      ])
        .then(() => resolve(this.panelPresentation));
    });
  }

  private getParcoursType(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.synchroResourceService.getObjet(gofElement).subscribe(parcours => {
        if (parcours !== null) {
          this.panelPresentation.refObject = parcours;
          this.getCurrentIntitule(parcours);
          this.panelPresentation.objetId = parcours.objetId;
          this.panelPresentation.intitule = parcours.lib;
          this.panelPresentation.formationsPorteuses = [];
          parcours.formations?.forEach(f => {
            this.translateService.get(typesDiplomeTrad.get(f.type.typeDiplome.id))
              .subscribe(typeDiplome => this.panelPresentation.formationsPorteuses.push(new ObjectAffichage(f.objetId, typeDiplome + ' : ' + f.diplome.lib, TYPES_OBJET.FORMATION)));
          });
          if (parcours.formations.length > 0) {
            this.panelPresentation.annee = parcours.formations[0].annee;
            this.panelPresentation.niveauEntreeId = parcours.formations[0].type.niveauEntree.id;
            this.panelPresentation.niveauSortieId = parcours.formations[0].type.niveauSortie.id;
            this.panelPresentation.duree = parcours.formations[0].duree;
            this.panelPresentation.typeDureeId = parcours.formations[0].typeDuree.id;
            this.panelPresentation.credits = parcours.formations[0].ects;
            this.panelPresentation.composanteIds = parcours.formations[0].structures?.filter(s => s.typePortage.id === RefTypePortage.COMPOSANTE_PEDAGOGIQUE).map(s => s.structure.id);
            this.panelPresentation.sniIds = parcours.formations[0].structures?.filter(s => s.typePortage.id === RefTypePortage.SNI_PORTEUSE).map(s => s.structure.id);
          }

          if (parcours.idsPeriodesPorteuses.length > 0) {
            Promise.all([
              this.getPeriodeInfos({
                id: parcours.idsPeriodesPorteuses[0],
                typeObjet: TYPES_OBJET.PERIODE,
                annee: gofElement.annee,
                typePublic: gofElement.typePublic,
                langue: gofElement.langue
              }),
              this.getlocalisations({
                id: parcours.formations.length > 0 ? parcours.formations[0].objetId : parcours.idPeriodePorteuse,
                typeObjet: TYPES_OBJET.FORMATION,
                annee: gofElement.annee,
                typePublic: gofElement.typePublic,
                langue: gofElement.langue
              })
            ]).then(() => resolve());

          } else {
            resolve();
          }
        } else {
          resolve();
        }
      },
        () => resolve());
    });
  }


  private getPeriodeInfos(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.periodeResourceService.getPeriode(gofElement).subscribe(periodePorteuse => {
        if (periodePorteuse) {
          this.getCurrentObjectif(periodePorteuse);
          this.panelPresentation.isTransitoire = periodePorteuse.isTransitoire;

          Promise.all([
            this.getResponsables(gofElement),
            this.getComposantesPedagogiques(gofElement)
          ])
            .then(() => resolve());
        }
      },
        () => resolve());
    });
  }

  private getlocalisations(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.localisationResourceService.getLocalisationsFormation(gofElement).subscribe(res => {
        this.panelPresentation.siteIds = res.filter(l => l.isValid).map(c => c.site.id).filter((elem, index, self) => index === self.indexOf(elem));
        resolve();
      },
        () => resolve());
    });
  }

  private getCurrentIntitule(parcours: any): void {
    if (parcours.traductions?.length > 0) {
      if (parcours.traductions.filter(t => t.langue.code === this.translateService.currentLang).length > 0) {
        this.panelPresentation.intitule = parcours.traductions.filter(t => t.langue.code === this.translateService.currentLang)[0].lib;
      }
    } else if (parcours.editos?.length > 0) {
      if (parcours.editos.filter(e => e.typePublic.id === +this.cookieService.get(GOF_PROFILE)).length > 0) {
        this.panelPresentation.intitule = parcours.editos.filter(e => e.typePublic.id === +this.cookieService.get(GOF_PROFILE))[0].lib;
      }
    }
  }

  private getCurrentObjectif(periodePorteuse: any): void {
    this.panelPresentation.objectif = periodePorteuse.objectif;
    if (!this.panelPresentation.objectif || this.panelPresentation.objectif.trim() === '') {
      this.panelPresentation.objectif = periodePorteuse.formations[0].objectif;
    }

    if (periodePorteuse.editos?.length > 0) {
      if (periodePorteuse.editos.filter(e => e.typePublic.id === +this.cookieService.get(GOF_PROFILE)).length > 0) {
        this.panelPresentation.objectif = periodePorteuse.editos.filter(e => e.typePublic.id === +this.cookieService.get(GOF_PROFILE))[0].objectif;
      }
    } else if (periodePorteuse.traductions?.length > 0) {
      if (periodePorteuse.traductions.filter(t => t.langue.code === this.translateService.currentLang).length > 0) {
        this.panelPresentation.objectif = periodePorteuse.traductions.filter(t => t.langue.code === this.translateService.currentLang)[0].objectif;
      }
    }
  }

  private getResponsables(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.roleResourceService.getRolesForObjet(gofElement).subscribe(res => {
        this.panelPresentation.responsables = res?.map(r => r.user.prenom + ' ' + r.user.nom);
        resolve();
      },
        () => resolve());
    });
  }

  private getComposantesPedagogiques(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.structuresResourceService.getStructures(gofElement).subscribe(res => {
        this.panelPresentation.composanteIds = res.structures?.filter(s => s.typePortage.id === RefTypePortage.COMPOSANTE_PEDAGOGIQUE).map(s => s.structure.id);
        this.panelPresentation.sniIds = res.structures?.filter(s => s.typePortage.id === RefTypePortage.SNI_PORTEUSE).map(s => s.structure.id);
        resolve();
      },
        () => resolve());
    });
  }
}

