<div #scroll id="search-results" class="content-part search-results-main">

  <div class="row row-flex margin-container-section">

    <div class="layout-col-sm-large layout-col-lg-small col-xs-12 col-lg-3 margin-section">
      <app-filtres
        [documents]="documents"
        [filtres]="filtres"
        [fromCatalogueInternational]="true"
        (filterEmitter)="onFilterEvent($event)"
        *ngIf="documents?.length > 0">
      </app-filtres>
    </div>

    <div class="layout-col-sm-large layout-col-lg-medium col-xs-12 col-lg-9 margin-section">

      <div class="margin-container-section">
        <div class="margin-section">
          <div class="margin-large" *ngIf="filteredDocs?.length > 0">
            <p role="status" tabindex="-1">
              {{ 'resultats.notif.resultats_trouves' | translate:{count: filteredDocs.length} }}
            </p>
          </div>

          <p role="status" tabindex="-1" *ngIf="lastsearch && filteredDocs?.length === 0">
            <strong>{{ 'resultats.notif.aucun_resultats' | translate }}</strong>
          </p>


          <a class="anchor-results" #results></a>
          <div class="search-results-list">
            <ul class="list-unstyled margin-container-large margin-container-lg-xlarge">
              <li class="margin-large margin-lg-xlarge" *ngFor="let document of paginedDocs">
                <app-carte-enseignement [document]="document"></app-carte-enseignement>
              </li>
            </ul>
          </div>
        </div>

        <div class="margin-section">
          <app-paginateur [length]="filteredDocs.length"
                          [pageSize]="pageSize"
                          [pageSizeOptions]="pageSizeOptions"
                          (pageEmitter)="onPageEvent($event)"
                          *ngIf="paginedDocs?.length > 0"></app-paginateur>
        </div>

      </div>
    </div>

  </div>
</div>
