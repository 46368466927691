import {encodedEmail} from '../utils/string.utils';

export class PanelContacts {
  constructor(public id?: number,
              public contacts?: Contact[]) {
  }

  isDisplayed(): boolean {
    return this &&
      this.contacts?.length > 0;
  }
}

export class Contact {
  constructor(public id?: number,
              public lib?: string,
              public nom?: string,
              public mail?: string,
              public telephone?: string,
              public adresse?: string,
              public ville?: string,
              public cp?: string,
              public href?: string) {
    if (this.mail) {
      this.href = encodedEmail(this.mail);
    }
  }
}

