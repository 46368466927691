<div class="margin-large margin-lg-xlarge" *ngIf="parcoursLibre.objectif?.length > 0">
  <div [innerHTML]="parcoursLibre.objectif" *ngIf="parcoursLibre.objectif"></div>
</div>

<div class="margin-large margin-lg-xlarge" *ngIf="contenu">
  <div class="accordion-panels">

    <div class="accordion-panel" *ngFor="let node of contenu.noeuds ; let index = index">
      <div class="accordion-panel-header">
        <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
          <h3 class="accordion-panel-title" [id]="'label_node_' + index"
          >{{node.name}}</h3>
        </div>
        <button data-toggle="collapse" type="button"
                aria-expanded="false"
                [attr.data-target]="'#node-' + index"
                [attr.aria-controls]="'node-' + index"
                [attr.aria-describedby]="'label_node_' + index"
                class="accordion-panel-toggler collapsed"
                (click)="getChildren(node)">
          <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
        </button>
      </div>

      <div [id]="'node-' + index" class="accordion-panel-body collapse" [hidden]="!node.children">
        <div class="accordion-panel-body-inner">
          <div class="margin-large margin-lg-xlarge">
            <div class="accordion-panels course-detail-accordion">

              <div class="accordion-panel margin-xsmall padding-container" *ngFor="let child of node.children">
                <div class="accordion-panel-header">
                  <div class="accordion-panel-title">
                    <div class="row row-flex margin-container-xsmall">
                      <div class="col-xs-12 col-sm-6 col-lg-4 margin-xsmall course-detail-accordion-sub-title">
                        <a href [routerLink]="'/' + pathDetailFormation"
                           [queryParams]="{type: child.type, id: child.objetId}"
                           *ngIf="child.clickable">{{child.name}}</a>
                        <span *ngIf="!child.clickable">{{child.name}}</span>
                      </div>
                      <div class="col-xs-4 col-sm-2 col-lg-2 margin-xsmall course-detail-accordion-sub-data">
                        <span *ngIf="child.ects">{{child.ects}} {{'fiche.programme.tree.credits' | translate}}</span>
                      </div>
                      <div class="col-xs-4 col-sm-2 col-lg-2 margin-xsmall course-detail-accordion-sub-data">
                        <span attr.aria-label="typologie {{child.ensTypologie}}"
                              [ngClass]="getEnsTypologieClass(child.ensTypologieId)"
                              *ngIf="child.ensTypologie">{{ensTypologieTrad.get(child.ensTypologieId) | translate}}</span>
                      </div>
                      <div class="col-xs-4 col-sm-2 col-lg-2 margin-xsmall course-detail-accordion-sub-data">
                        <span attr.aria-label="niveau {{child.ensTypologie}"
                              class="sr-only">{{child.ensTypologie}}</span>
                        <span [ngClass]="getEnsNiveauClass(child.ensNiveauId)"
                              *ngIf="child.ensNiveau">{{getEnsNiveauCharCode(child.ensNiveauId)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
