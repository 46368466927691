<ng-container *ngIf="display">
<div class="margin-large margin-lg-xlarge" *ngFor="let contenu of contenus">
  <h3>{{contenu.objetFormation.name}}</h3>
  <div class="accordion-panels course-detail-accordion">
    <!--NIVEAU 1 : LOCALISATION OU LISTE-->
    <div class="accordion-panel margin-xsmall padding-container"
         *ngFor="let niv1 of contenu.noeuds ;  let index1 = index">
      <!--header niv1 -->
      <div class="accordion-panel-header">
        <div class="col-12 margin-xsmall course-detail-accordion-sub-title">
          <div class="col-lg-4 col-sm-5 col-xs-10 margin-xsmall">
            <h4 class="accordion-panel-title" [id]="'label_niv_' + index1">
            <span class="tooltip">
              <span *ngIf="niv1.name">{{niv1.name}}</span>
              <span class="tooltip-text" *ngIf="niv1.modalite">{{niv1.modalite}}</span>
            </span>
            </h4>
          </div>
          <div class="col-lg-1 col-sm-1 col-xs-2 margin-xsmall course-detail-accordion-sub-data"
               *ngIf="niv1.temporalite"> {{typeTemp + niv1.temporalite}}</div>
          <div class="col-lg-7 col-sm-6 margin-xsmall course-detail-accordion-sub-data no-description"
               *ngIf="niv1.description">{{niv1.description}}</div>
        </div>
        <button data-toggle="collapse" type="button"
                [attr.data-target]="'#niv-' + index1"
                [attr.aria-controls]="'niv-' + index1"
                [attr.aria-describedby]="'label_niv_' + index1"
                class="accordion-panel-toggler collapsed"
                aria-expanded="false">
          <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
        </button>
      </div>

      <div [id]="'niv-' + index1" class="accordion-panel-body collapse">
        <div class="accordion-panel-body-inner">

          <!--NIVEAU 2 : ENSEIGNEMENTS OU LISTES-->
          <div class="accordion-panels course-detail-accordion">

            <ng-container *ngFor="let niv2 of niv1.children ;  let index2 = index">
              <!--Enseignements-->
              <div class="accordion-panel margin-xsmall padding-container"
                   *ngIf="niv2.type === typeObjetEnum.ENSEIGNEMENT">
                <div class="accordion-panel-header" [ngClass]="getPaddingClass(niv2.level)">
                  <div class="accordion-panel-title">
                    <div class="row row-flex margin-container-xsmall">
                      <div class="col-lg-8 col-sm-8 col-xs-12 margin-xsmall course-detail-accordion-sub-title">
                        <a href [routerLink]="'/' + PathDetailFormation"
                           [queryParams]="{type: niv2.type, id: niv2.objetId}"
                           *ngIf="niv2.clickable">{{niv2.name}}</a>
                        <span *ngIf="!niv2.clickable">{{niv2.name}}</span>
                      </div>
                      <div class="col-lg-2 col-sm-2 col-xs-4 margin-xsmall course-detail-accordion-sub-data"
                           *ngIf="niv2.ects">
                        {{niv2.ects}} {{'fiche.programme.tree.credits' | translate}}
                      </div>
                      <div class="col-lg-2 col-sm-2 col-xs-4 margin-xsmall course-detail-accordion-sub-data"
                           *ngIf="niv2.ensTypologieId">
                        <span
                          [ngClass]="getEnsTypologieClass(niv2.ensTypologieId)">{{ensTypologieTrad.get(niv2.ensTypologieId) | translate }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Liste enseignements-->
              <div class="accordion-panel accordion-panel-open margin-xsmall padding-container"
                   *ngIf="niv2.type === typeObjetEnum.LISTE_ENSEIGNEMENTS">
                <div class="accordion-panel-header" [ngClass]="getPaddingClass(niv2.level)">
                  <div class="col-12 margin-xsmall course-detail-accordion-sub-title">
                    <div class="col-lg-4 col-sm-5 col-xs-10 margin-xsmall">
                      <h4 class="accordion-panel-title" [id]="'label_niv_' + index1 + '-' + index2">
                        <a href [routerLink]="'/' + PathDetailFormation"
                           [queryParams]="{type: niv2.type, id: niv2.objetId}"
                           *ngIf="niv2.clickable">{{niv2.name}}</a>
                        <span class="tooltip" *ngIf="!niv2.clickable">{{niv2.name}}
                          <span class="tooltip-text" *ngIf="niv2.modalite">{{niv2.modalite}}</span>
                      </span>
                      </h4>
                    </div>
                    <div class="col-lg-1 col-sm-1 col-xs-2 margin-xsmall course-detail-accordion-sub-data"
                         *ngIf="niv2.temporalite"> {{typeTemp + niv2.temporalite}}</div>
                    <div class="col-lg-7 col-sm-6 margin-xsmall course-detail-accordion-sub-data no-description"
                         *ngIf="niv2.description">{{niv2.description}}</div>
                  </div>
                  <button data-toggle="collapse" type="button"
                          aria-expanded="true"
                          [attr.data-target]="'#niv-' + index1 + '-' + index2"
                          [attr.aria-controls]="'niv-' + index1 + '-' + index2"
                          [attr.aria-describedby]="'label_niv_' + index1 + '-' + index2"
                          class="accordion-panel-toggler">
                    <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                  </button>
                </div>

                <div [id]="'niv-' + index1 + '-' + index2" class="accordion-panel-body collapse in">
                  <div class="accordion-panel-body-inner">

                    <!--NIVEAU 3 : ENSEIGNEMENTS OU LISTES-->
                    <div class="accordion-panels course-detail-accordion">
                      <ng-container *ngFor="let niv3 of niv2.children ;  let index3 = index">
                        <!--Enseignements-->
                        <div class="accordion-panel margin-xsmall padding-container"
                             *ngIf="niv3.type === typeObjetEnum.ENSEIGNEMENT">
                          <div class="accordion-panel-header" [ngClass]="getPaddingClass(niv3.level)">
                            <div class="accordion-panel-title">
                              <div class="row row-flex margin-container-xsmall">
                                <div
                                  class="col-lg-8 col-sm-8 col-xs-12 margin-xsmall course-detail-accordion-sub-title">
                                  <a href [routerLink]="'/' + PathDetailFormation"
                                     [queryParams]="{type: niv3.type, id: niv3.objetId}"
                                     *ngIf="niv3.clickable">{{niv3.name}}</a>
                                  <span *ngIf="!niv3.clickable">{{niv3.name}}</span>
                                </div>
                                <div class="col-lg-2 col-sm-2 col-xs-4 margin-xsmall course-detail-accordion-sub-data">
                                  <span
                                    *ngIf="niv3.ects">{{niv3.ects}} {{'fiche.programme.tree.credits' | translate}}</span>
                                  <span *ngIf="niv3.ensTypologieId"
                                        [ngClass]="getEnsTypologieClass(niv3.ensTypologieId)">
                                    {{ ensTypologieTrad.get(niv3.ensTypologieId) | translate }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--Listes enseignements-->
                        <div class="accordion-panel accordion-panel-open margin-xsmall"
                             *ngIf="niv3.type === typeObjetEnum.LISTE_ENSEIGNEMENTS">
                          <div class="accordion-panel-header" [ngClass]="getPaddingClass(niv3.level)">
                            <div class="col-12 margin-xsmall course-detail-accordion-sub-title">
                              <div class="col-lg-4 col-sm-5 col-xs-10 margin-xsmall">
                                <h4 class="accordion-panel-title"
                                    [id]="'label_niv_' + index1 + '-' + index2+ '-' + index3">
                                  <a href [routerLink]="'/' + PathDetailFormation"
                                     [queryParams]="{type: niv3.type, id: niv3.objetId}"
                                     *ngIf="niv3.clickable">{{niv3.name}}</a>
                                  <span class="tooltip" *ngIf="!niv3.clickable">{{niv3.name}}
                                    <span class="tooltip-text" *ngIf="niv3.modalite">{{niv3.modalite}}</span>
                                </span>
                                </h4>
                              </div>
                              <div class="col-lg-1 col-sm-1 col-xs-2 margin-xsmall course-detail-accordion-sub-data"
                                   *ngIf="niv3.temporalite"> {{typeTemp + niv3.temporalite}}</div>
                              <div
                                class="col-lg-7 col-sm-6 margin-xsmall course-detail-accordion-sub-data no-description"
                                *ngIf="niv3.description">{{niv3.description}}</div>
                            </div>
                            <button data-toggle="collapse" type="button"
                                    aria-expanded="true"
                                    [attr.data-target]="'#niv-' + index1 + '-' + index2  + '-' + index3"
                                    [attr.aria-controls]="'niv-' + index1 + '-' + index2  + '-' + index3"
                                    [attr.aria-describedby]="'label_niv_' + index1 + '-' + index2+ '-' + index3"
                                    class="accordion-panel-toggler">
                              <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                            </button>
                          </div>

                          <div [id]="'niv-' + index1 + '-' + index2 + '-' + index3"
                               class="accordion-panel-body collapse in">
                            <div class="accordion-panel-body-inner">

                              <!--NIVEAU 4 : ENSEIGNEMENTS-->
                              <div class="accordion-panels course-detail-accordion">
                                <div class="accordion-panel margin-xsmall padding-container"
                                     *ngFor="let niv4 of niv3.children">
                                  <div class="accordion-panel-header" [ngClass]="getPaddingClass(niv4.level)">
                                    <div class="accordion-panel-title">
                                      <div class="row row-flex margin-container-xsmall">
                                        <div
                                          class="col-lg-8 col-sm-8 col-xs-12 margin-xsmall course-detail-accordion-sub-title">
                                          <a href [routerLink]="'/' + PathDetailFormation"
                                             [queryParams]="{type: niv4.type, id: niv4.objetId}"
                                             *ngIf="niv4.clickable">{{niv4.name}}</a>
                                          <span *ngIf="!niv4.clickable">{{niv4.name}}</span>
                                        </div>
                                        <div
                                          class="col-lg-2 col-sm-2 col-xs-4 margin-xsmall course-detail-accordion-sub-data"
                                          *ngIf="niv4.ects">
                                          {{niv4.ects}} {{'fiche.programme.tree.credits' | translate}}
                                        </div>
                                        <div
                                          class="col-lg-2 col-sm-2 col-xs-4 margin-xsmall course-detail-accordion-sub-data"
                                          *ngIf="niv4.ensTypologieId">
                                          <span [ngClass]="getEnsTypologieClass(niv4.ensTypologieId)">
                                            {{ensTypologieTrad.get(niv4.ensTypologieId) | translate}}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!--End niveau 4 -->

                            </div>
                          </div>

                        </div>

                      </ng-container>

                    </div>
                    <!-- End niv 3-->

                  </div>
                </div>
              </div>

            </ng-container>

          </div>
          <!-- End niv 2-->

        </div>
      </div>
    </div>
    <!-- End niv 1  -->

  </div>
</div>
</ng-container>
