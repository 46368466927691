export class PanelInformations {
  constructor(public id?: number,
              public adossementRecherche?: Recherche,
              public dispositifsAideReussite?: Dispositif[],
              public enseignementPro?: EnseignementPro,
              public stage?: string,
              public dispositifsEngagementEtudiant?: Dispositif[],
              public partenariats?: Partenariat[],
              public partenariatsInternationaux?: Partenariat[],
              public hasDispo?: boolean,
              public dispositifInternational?: string,
  ) {
  }

  isDisplayed(): boolean {
    return this && (
      this.hasDispo
      || this.dispositifsAideReussite?.length > 0
      || this.adossementRecherche != null
      || (this.enseignementPro && this.enseignementPro.rolePro != null)
      || this.partenariatsInternationaux?.length > 0
    );
  }
}

export class Dispositif {
  constructor(
    public intitule?: string,
    public details?: string,
    public type?: string,
    public nbEcts?: number
  ) {
  }
}

export class Recherche {
  constructor(
    public modalite?: string,
    public laboratoires?: string[]
  ) {
  }
}

export class Partenariat {
  constructor(
    public typeId?: number,
    public modalite?: string,
    public typeConvention?: string,
    public etablissements?: string[],
    public etatConventionId?: number
  ) {
  }
}

export class EnseignementPro {
  partPro: number;
  rolePro: string;
  entreprises: string[];
}

