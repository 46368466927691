import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Filtre, KeyValue, SearchItem} from "../../../model/util.model";
import {ElasticsearchService} from "../../../services/elasticsearch.service";
import {Router} from "@angular/router";
import {
  CLOSE_EVENT, GOF_INTERNATIONAL_CATALOGUE,
  GOF_SEARCH,
  PATH_CATALOGUE_INTERNATIONAL,
  PATH_RESULTATS,
  SEARCH_BY_KEYWORDS
} from "../../../constants/conf.constants";
import {ES_FIELDS, LANGUES, TYPES_OBJET} from "../../../constants/utils.constants";
import {GlobalStateService} from "../../../services/global-state.service";
import {normalizeByReplace} from "../../../utils/string.utils";
import {filtresCatalogueInternational} from "../../../constants/filtres.constants";

@Component({
  selector: "app-recherche-par-mots-cles",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./recherche-par-mots-cles.component.html",
  styleUrls: ["./recherche-par-mots-cles.component.scss"]
})
export class RechercheParMotsClesComponent implements OnInit{

  diplomes: KeyValue[];
  disciplines: KeyValue[];
  niveauxEntree: KeyValue[];

  motscles = new FormControl(null);
  displayWarning = false;
  isCatalogueInternational: boolean;


  isDiplomeExpanded = false;
  isDisciplinesExpanded = false;
  isNiveauxEntreeExpanded = false;
  isMobiliteExpanded = false;

  langues = LANGUES;

  constructor(private elasticsearchService: ElasticsearchService,
              private globalStateService: GlobalStateService,
              public router: Router) {

    this.globalStateService.subscribe(GOF_INTERNATIONAL_CATALOGUE, res => {
      this.isCatalogueInternational = JSON.parse(res);
    });

    this.globalStateService.subscribe(CLOSE_EVENT, () => {
      this.isDiplomeExpanded = false;
      this.isDisciplinesExpanded = false;
      this.isNiveauxEntreeExpanded = false;
    });
  }

  ngOnInit(): void {
       this.init();
    }

  getCount(field: KeyValue[]): number {
    return field?.filter(kv => kv.value).length;
  }

  openDiplomes() {
    this.isDisciplinesExpanded = false;
    this.isNiveauxEntreeExpanded = false;
    this.isMobiliteExpanded = false;
    this.isDiplomeExpanded = !this.isDiplomeExpanded;
  }

  openDisciplines() {
    this.isDiplomeExpanded = false;
    this.isNiveauxEntreeExpanded = false;
    this.isMobiliteExpanded = false;
    this.isDisciplinesExpanded = !this.isDisciplinesExpanded;
  }

  openNiveauxEntree() {
    this.isDiplomeExpanded = false;
    this.isDisciplinesExpanded = false;
    this.isMobiliteExpanded = false;
    this.isNiveauxEntreeExpanded = !this.isNiveauxEntreeExpanded;
  }

  closeAllFilters() {
    this.isDiplomeExpanded = false;
    this.isDisciplinesExpanded = false;
    this.isNiveauxEntreeExpanded = false;
  }

  onInputChange() {
    if (this.isCatalogueInternational && this.motscles.value?.length === 0) {
      this.validate();
    }
  }

  validate(): void {
    this.closeAllFilters();
    if (this.isInvalidForm() && !this.isCatalogueInternational) {
      this.displayWarning = true;
      return;
    }

    let value: any = this.motscles.value;
    if (value && value.trim().length === 0) {
      value = null;
    }

    let filtres: Filtre[] = this.getFiltres();
    let redirection: string = PATH_RESULTATS;
    if (this.isCatalogueInternational) {
      filtres = filtresCatalogueInternational;
      redirection = PATH_CATALOGUE_INTERNATIONAL;
    }

    sessionStorage.setItem(GOF_SEARCH, JSON.stringify([new SearchItem(value, null, filtres)]));

    this.elasticsearchService
      .search(value, null, filtres)
      .subscribe(documents => {
        this.displayWarning = false;
        this.router.navigate([redirection]).then(() => this.globalStateService.notifyDataChanged(SEARCH_BY_KEYWORDS, documents));
      });
  }

  isInvalidForm(): boolean {
    return this.disciplines?.filter(d => d.value).length === 0
      && this.diplomes?.filter(d => d.value).length === 0
      && this.niveauxEntree?.filter(d => d.value).length === 0
      && (!this.motscles.value || this.motscles.value.trim() === "");
  }

  normalize(s: string): string {
    return normalizeByReplace(s, "-");
  }

  init(): void {
    this.elasticsearchService.getOptions(null, null, ES_FIELDS.TYPE_DIPLOME)
      .subscribe(diplomes => this.diplomes = diplomes.map(i => new KeyValue(i, false)));
    this.elasticsearchService.getOptions(null, null, ES_FIELDS.DISCIPLINES)
      .subscribe(disciplines => this.disciplines = disciplines.map(i => new KeyValue(i, false)));
    this.elasticsearchService.getOptions(null, null, ES_FIELDS.NIVEAU_ENTREE)
      .subscribe(niveauxEntree => this.niveauxEntree = niveauxEntree.map(i => new KeyValue(i, false)));
  }

  private getFiltres(): Filtre[] {
    const filtres: Filtre[] = [];

    const typeFormation: Filtre = {
      field: ES_FIELDS.TYPE_OBJET,
      options: [
        {key: TYPES_OBJET.FORMATION, value: true},
        {key: TYPES_OBJET.CURSUS_ENRICHI, value: true},
        {key: TYPES_OBJET.PARCOURS_TYPE, value: true},
        {key: TYPES_OBJET.ENSEIGNEMENT, value: true}
      ],
      enfants: []
    };
    filtres.push(typeFormation);

    const discipline: Filtre = {
      field: ES_FIELDS.DISCIPLINES,
      options: this.disciplines,
      enfants: []
    };
    filtres.push(discipline);

    const diplome: Filtre = {
      field: ES_FIELDS.TYPE_DIPLOME,
      options: this.diplomes,
      enfants: []
    };
    filtres.push(diplome);

    const niveauEntree: Filtre = {
      field: ES_FIELDS.NIVEAU_ENTREE,
      options: this.niveauxEntree,
      enfants: []
    };
    filtres.push(niveauEntree);

    return filtres;
  }
}
