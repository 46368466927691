<!-- FORMATIONS PORTEUSES -->
<ng-container *ngIf="rebonds.formationsPorteuses?.length > 0">
  <h2 class="course-detail-card-title">{{'fiche.rebonds.formations_porteuses' | translate}}</h2>

  <div class="course-detail-card-data">
    <ul class="list-unstyled margin-container-xsmall">
      <li class="margin-xsmall" *ngFor="let obj of rebonds.formationsPorteuses">
        <a href [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: obj.typeObjet, id: obj.id}">{{obj.intitule}}</a>
      </li>
    </ul>
  </div>
</ng-container>

<!-- FORMATIONS MUTUALISEES -->
<div class="course-detail-card" *ngIf="rebonds.formationsMutualiseesPorteuses?.length > 0">
  <h2 class="course-detail-card-title">{{'fiche.rebonds.formations_mutualisées' | translate}}</h2>

  <div class="course-detail-card-data">
    <ul class="list-unstyled margin-container-xsmall">
      <li class="margin-xsmall" *ngFor="let obj of rebonds.formationsMutualiseesPorteuses">
        <a href [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: obj.typeObjet, id: obj.id}">{{obj.intitule}}</a>
      </li>
    </ul>
  </div>
</div>

<!--CURSUS ENRICHI -->
<div class="course-detail-card" *ngIf="rebonds.cursusEnrichisPortes?.length > 0">
  <h2 class="course-detail-card-title">{{'fiche.rebonds.cursus_enrichis_portes' | translate}}</h2>

  <div class="course-detail-card-data">
    <ul class="list-unstyled margin-container-xsmall">
      <li class="margin-xsmall" *ngFor="let obj of rebonds.cursusEnrichisPortes">
        <a href [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: obj.typeObjet, id: obj.id}">{{obj.intitule}}</a>
      </li>
    </ul>
  </div>
</div>

<!--CMI -->
<div class="course-detail-card" *ngIf="rebonds.cmisAssocies?.length > 0">
  <h2>{{'fiche.rebonds.cmi_associes' | translate}}</h2>

  <div class="course-detail-card-data">
    <ul class="list-unstyled margin-container-xsmall">
      <li class="margin-xsmall" *ngFor="let obj of rebonds.cmisAssocies">
        <a href [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: obj.typeObjet, id: obj.id}">{{obj.intitule}}</a>
      </li>
    </ul>
  </div>
</div>

<!-- PERIODES PORTEES -->
<div class="course-detail-card" *ngIf="rebonds.periodesPortees?.length > 0 && !fromCatalogueInternational">
  <h2 class="course-detail-card-title">{{'fiche.rebonds.periodes_portees' | translate}}</h2>

  <div class="course-detail-card-data">
    <ul class="list-unstyled margin-container-xsmall">
      <li class="margin-xsmall" *ngFor="let obj of rebonds.periodesPortees">
        <a href [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: obj.typeObjet, id: obj.id}">{{obj.intitule}}</a>
      </li>
    </ul>
  </div>
</div>

<!-- PERIODES PRECEDENTES -->
<div class="course-detail-card" *ngIf="rebonds.periodesPrecedentes?.length > 0">
  <h2 class="course-detail-card-title">{{'fiche.rebonds.periodes_precedentes' | translate}}</h2>

  <div class="course-detail-card-data">
    <ul class="list-unstyled margin-container-xsmall">
      <li class="margin-xsmall" *ngFor="let obj of rebonds.periodesPrecedentes">
        <a href [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: obj.periode.typeObjet, id: obj.periode.id}">{{obj.periode.intitule}}</a>
      </li>
    </ul>
  </div>
</div>

<!-- PERIODES SUIVANTES -->
<div class="course-detail-card" *ngIf="rebonds.periodesSuivantes?.length > 0">
  <h2 class="course-detail-card-title">{{'fiche.rebonds.periodes_suivantes' | translate}}</h2>
  <div class="course-detail-card-data">
    <ul class="list-unstyled margin-container-xsmall">
      <li class="margin-xsmall" *ngFor="let obj of rebonds.periodesSuivantes">
        <a href [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: obj.periode.typeObjet, id: obj.periode.id}">{{obj.periode.intitule}}</a>
      </li>
    </ul>
  </div>
</div>

<!-- ENSEIGNEMENTS PREREQUIS -->
<div class="course-detail-card" *ngIf="rebonds.enseignementsPrequis?.length > 0">
  <h2 class="course-detail-card-title">{{'fiche.rebonds.enseignements_prerequis' | translate}}</h2>

  <div class="course-detail-card-data">
    <ul class="list-unstyled margin-container-xsmall">
      <li class="margin-xsmall" *ngFor="let obj of rebonds.enseignementsPrequis">
        <a href [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: obj.typeObjet, id: obj.id}">{{obj.intitule}}</a>
      </li>
    </ul>
  </div>
</div>

<!-- ENSEIGNEMENTS POSTREQUIS -->
<div class="course-detail-card" *ngIf="rebonds.enseignementsPostrequis?.length > 0">
  <h2 class="course-detail-card-title">{{'fiche.rebonds.enseignements_postrequis' | translate}}</h2>

  <div class="course-detail-card-data">
    <ul class="list-unstyled margin-container-xsmall">
      <li class="margin-xsmall" *ngFor="let obj of rebonds.enseignementsPostrequis">
        <a href [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: obj.typeObjet, id: obj.id}">{{obj.intitule}}</a>
      </li>
    </ul>
  </div>
</div>

<!-- PARCOURS TYPE -->
<div class="course-detail-card" *ngIf="rebonds.parcoursTypeAssocies?.length > 0">
  <h2 class="course-detail-card-title">{{'fiche.rebonds.autres_parcours' | translate}}</h2>

  <div class="course-detail-card-data">
    <ul class="list-unstyled margin-container-xsmall">
      <li class="margin-xsmall" *ngFor="let obj of rebonds.parcoursTypeAssocies">
        <a href [routerLink]="'/' + pathDetailFormation"
           [queryParams]="{type: obj.typeObjet, id: obj.id}">{{obj.intitule}}</a>
      </li>
    </ul>
  </div>
</div>


