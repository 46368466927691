<app-loader *ngIf="!display"></app-loader>

<div *ngIf="display">
  <div id="course-detail-nav-container"
       class="content-part content-part-bg content-part-bg-light course-detail-nav-container">
    <nav class="course-detail-nav" aria-label="Navigation interne" role="navigation">
      <div class="course-detail-nav-inner">
        <ul class="list-unstyled">
          <li *ngIf="contenus[0]?.isDisplayed()">
            <a appAnchor
               id="#programme"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.programme.title' | translate}}"
               target="#programmeAnchor">{{'fiche.programme.title' | translate}}</a>
          </li>
          <li *ngIf="acces?.isDisplayed()">
            <a appAnchor
               id="#admission"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.admission.title' | translate}}"
               target="#admissionAnchor">{{'fiche.admission.title' | translate}}</a>
          </li>
          <li *ngIf="presentation?.objectif?.length > 0 || competences?.isDisplayed() || debouches?.isDisplayed()">
            <a appAnchor
               id="#en-savoir-plus"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.en_savoir_plus.title' | translate}}"
               target="#enSavoirPlusAnchor">{{'fiche.en_savoir_plus.title' | translate}}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <!-- PROGRAMME -->
  <div class="anchor" id="programmeAnchor"></div>
  <div id="programme" class="content-part content-part-no-pad-top">
    <div class="course-detail-top margin-section">

      <div class="row row-flex row-gutters-xlarge margin-container-large">
        <div
          class="layout-col-sm-large layout-col-lg-medium col-xs-12 col-lg-8 margin-large">

          <div class="margin-section">
            <h2>{{'fiche.programme.title' | translate}}</h2>
          </div>

          <app-loader *ngIf="contenuLoading"></app-loader>

          <app-programme-arbo [contenus]="contenus"
                              [typeTemp]="typeTemp"
                              [gofElement]="gofElement"
                              *ngIf="!contenuLoading"></app-programme-arbo>
        </div>

        <div class="layout-col-sm-large layout-col-lg-small col-xs-12 col-lg-4 margin-large course-detail-top-side">
          <app-fiche-column [gofElement]="gofElement"
                            [presentation]="presentation"
                            [contacts]="contacts"
                            [rebonds]="rebonds">
          </app-fiche-column>
        </div>

      </div>
    </div>
  </div>

  <!-- ADMISSION -->
  <div class="anchor" id="admissionAnchor"></div>
  <div id="admission" class="content-part content-part-no-pad-top" *ngIf="acces?.isDisplayed()">
    <div class="margin-section">
      <h2>{{'fiche.admission.title' | translate}}</h2>
    </div>

    <app-acces [acces]="acces"></app-acces>
  </div>

  <!-- EN SAVOIR PLUS -->
  <div class="anchor" id="enSavoirPlusAnchor"></div>
  <div id="en-savoir-plus" class="content-part content-part-no-pad-top" *ngIf="presentation?.objectif?.length > 0 ||
                                  competences?.isDisplayed() ||
                                  debouches?.isDisplayed()">
    <div class="margin-section">
      <h2>{{'fiche.en_savoir_plus.title' | translate}}</h2>
    </div>

    <!-- objectif -->
    <div class="margin-large margin-lg-xlarge" *ngIf="presentation?.objectif?.length > 0">
      <h3>{{'fiche.presentation.objectifs' | translate}}</h3>
      <div [innerHTML]="presentation.objectif"></div>
    </div>

    <!-- compétences -->
    <app-competences [competences]="competences" *ngIf="competences?.isDisplayed()"></app-competences>

    <!-- internationalisation -->
    <div class="margin-large margin-lg-xlarge" *ngIf="internationalisation?.isDisplayed()">
      <h3>{{'fiche.international.title' | translate}}</h3>
      <app-internationalisation [internationalisation]="internationalisation"></app-internationalisation>
    </div>

    <!-- debouches -->
    <app-debouches [debouches]="debouches" *ngIf="debouches?.isDisplayed()"></app-debouches>
  </div>
</div>

