import {Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from '@angular/core';
import {GofElement} from '../../../model/util.model';
import {PanelPresentation} from '../../../model/presentation.model';
import {PanelRebonds} from '../../../model/rebonds.model';
import {PanelActivites} from '../../../model/activites.model';
import {PanelAcquis} from '../../../model/acquis.model';
import {PanelContacts} from '../../../model/contacts.model';
import {PanelModalitesAcquisition} from '../../../model/modalites-acquisition.model';
import {LazyLoadService} from '../../../services/lazy-load.service';
import {EnseignementAcquisService} from '../../../services/acquis/enseignement-acquis.service';
import {EnseignementActivitesPedagogiquesService} from '../../../services/activites-pedagogiques/enseignement-activites-pedagogiques.service';
import {EnseignementContactsService} from '../../../services/contacts/enseignement-contacts.service';
import {EnseignementModalitesAcquisitionService} from '../../../services/modalites-acquisition/enseignement-modalites-acquisition.service';
import {EnseignementRebondsService} from '../../../services/rebonds/enseignement-rebonds.service';

@Component({
  selector: 'app-fiche-enseignement',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './fiche-enseignement.component.html',
  styleUrls: ['./fiche-enseignement.component.scss']
})
export class FicheEnseignementComponent implements OnChanges {

  @Input() gofElement: GofElement;
  @Input() presentation: PanelPresentation;
  @Input() fromInternationalCatalogue: boolean;

  @Output() loadedEmitter: EventEmitter<void> = new EventEmitter<void>();

  acquis: PanelAcquis;
  activitesPedagogiques: PanelActivites = new PanelActivites();
  modalitesAcquisition: PanelModalitesAcquisition = new PanelModalitesAcquisition();
  contacts: PanelContacts = new PanelContacts();
  rebonds: PanelRebonds = new PanelRebonds();

  display = false;

  constructor(private acquisService: EnseignementAcquisService,
              private activitesPedagogiquesService: EnseignementActivitesPedagogiquesService,
              private contactsService: EnseignementContactsService,
              private modalitesAcquisitionService: EnseignementModalitesAcquisitionService,
              private rebondsService: EnseignementRebondsService,
              private lazyLoadScriptServiceService: LazyLoadService
  ) {
  }

  ngOnChanges(): void {
    Promise.all([
      this.getAcquis(),
      this.getActivitesPedagogiques(),
      this.getContacts(),
      this.getModalitesAcquisition(),
      this.getRebonds()
    ]).then(() => {
      this.display = true;
      this.lazyLoadScriptServiceService.injectScript('/assets/js/course-detail.js');
      this.loadedEmitter.emit();
    });
  }

  private getAcquis(): Promise<void> {
    return new Promise<void>(resolve => {
      this.acquisService.getPanel(this.gofElement).then(res => {
        this.acquis = res;
        resolve();
      });
    });
  }

  private getActivitesPedagogiques(): Promise<void> {
    return new Promise<void>(resolve => {
      this.activitesPedagogiquesService.getPanel(this.gofElement).then(res => {
        this.activitesPedagogiques = res;
        resolve();
      });
    });
  }

  private getContacts(): Promise<void> {
    return new Promise<void>(resolve => {
      this.contactsService.getPanel(this.gofElement).then(res => {
        this.contacts = res;
        resolve();
      });
    });
  }

  private getModalitesAcquisition(): Promise<void> {
    return new Promise<void>(resolve => {
      this.modalitesAcquisitionService.getPanel(this.gofElement, this.presentation.refObject).then(res => {
        this.modalitesAcquisition = res;
        resolve();
      });
    });
  }

  private getRebonds(): Promise<void> {
    return new Promise<void>(resolve => {
      this.rebondsService.getPanel(this.gofElement, this.presentation.refObject).then(res => {
        this.rebonds = res;
        resolve();
      });
    });
  }

}
