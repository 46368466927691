<div class="wrapper">
  <div class="home-key-numbers">
    <div class="home-key-numbers-wrapper">
      <div class="wrapper">
        <div class="home-key-numbers-inner">
          <ul>
            <li>
              <div class="home-key-numbers-item">
                <strong class="home-key-numbers-item-number countup"
                        appAnimatedDigit [digit]="nbFormations">{{ nbFormations}}</strong>
                <p class="home-key-numbers-item-title">{{ 'home.formations' | translate }}</p>
                <p class="home-key-numbers-item-subtitle"></p>
              </div>
            </li>
            <li>
              <div class="home-key-numbers-item">
                <strong class="home-key-numbers-item-number countup"
                        appAnimatedDigit [digit]="nbCursusEnrichis">{{ nbCursusEnrichis }}</strong>
                <p class="home-key-numbers-item-title">{{ 'home.cursus_enrichis' | translate }}</p>
                <p class="home-key-numbers-item-subtitle"></p>
              </div>
            </li>
            <li>
              <div class="home-key-numbers-item">
                <strong class="home-key-numbers-item-number countup"
                        appAnimatedDigit [digit]="nbEnseignements">{{ nbEnseignements }}</strong>
                <p class="home-key-numbers-item-title">{{ 'home.enseignements' | translate }}</p>
                <p class="home-key-numbers-item-subtitle"></p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>



