import {FormationDoc} from './esDoc.model';

export class KeyValue {
  constructor(public key?: any,
              public value?: any) {
  }
}

export class ObjectAffichage {
  constructor(public id?: number,
              public intitule?: string,
              public typeObjet?: string) {
  }
}

export class GofElement {
  constructor(public typeObjet?: string,
              public id?: number,
              public annee?: number,
              public typePublic?: string,
              public langue?: string) {
  }
}

export class Filtre {
  constructor(public lib?: string,
              public field?: string,
              public options?: KeyValue[],
              public enfants?: KeyValue[]) {
  }
}

export class ContactRequest {
  constructor(public nom: string,
              public prenom: string,
              public email: string,
              public message: string) {
  }
}

export class SearchItem {
  constructor(public value?: string,
              public field?: string,
              public filters?: Filtre[]) {
  }
}

export class PathItem {
  constructor(public lib: string,
              public routerlink: string[],
              public queryParams?: any) {
  }
}

export class Menu {
  constructor(public intitule: string,
              public formationDocs: FormationDoc[],
              public sousmenus: Menu[]) {
  }
}

export interface PageEvent {
  pageIndex: number;
  pageSize: number;
  length: number;
}


export interface HtmlHead {
  title: string;
  description: string;
  url: string;
  params:string;
}
