import {ObjectAffichage} from './util.model';

export class PanelRebonds {
  constructor(public id?: number,
              public formationsPorteuses?: ObjectAffichage[],
              public formationsMutualiseesPorteuses?: ObjectAffichage[],
              public cursusEnrichisPortes?: ObjectAffichage[],
              public cmisAssocies?: ObjectAffichage[],
              public parcoursTypeAssocies?: ObjectAffichage[],
              public periodesPortees?: ObjectAffichage[],
              public periodesPrecedentes?: Lien[],
              public periodesSuivantes?: Lien[],
              public enseignementsPrequis?: ObjectAffichage[],
              public enseignementsPostrequis?: ObjectAffichage[]
  ) {
  }

  isDisplayed(): boolean {
    return this && (
      this.formationsPorteuses?.length > 0
      || this.formationsMutualiseesPorteuses?.length > 0
      || this.cursusEnrichisPortes?.length > 0
      || this.cmisAssocies?.length > 0
      || this.parcoursTypeAssocies?.length > 0
      || this.periodesPortees?.length > 0
      || this.periodesPrecedentes?.length > 0
      || this.periodesSuivantes?.length > 0
      || this.enseignementsPrequis?.length > 0
      || this.enseignementsPostrequis?.length > 0

    );
  }
}

export class Lien {
  constructor(public periode?: ObjectAffichage,
              public formationsAssociees?: ObjectAffichage[]) {
  }
}
