import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AccueilComponent} from './pages/accueil/accueil.component';
import {ResultatsComponent} from './pages/resultats/resultats.component';
import {FicheComponent} from './pages/fiche/fiche.component';
import {
  PATH_ACCUEIL,
  PATH_DETAIL_FORMATION, PATH_PAR_COMPOSANTES, PATH_PAR_DIPLOMES, PATH_PAR_DISCIPLINES,
  PATH_RESULTATS, PATH_MA_SELECTION, PATH_PLAN_DU_SITE, PATH_CATALOGUE_INTERNATIONAL,
} from './constants/conf.constants';
import {ParDiplomesComponent} from './pages/par-diplomes/par-diplomes.component';
import {ParDisciplinesComponent} from './pages/par-disciplines/par-disciplines.component';
import {ParComposantesComponent} from './pages/par-composantes/par-composantes.component';
import {MaSelectionComponent} from './pages/ma-selection/ma-selection.component';
import {PlanDuSiteComponent} from './pages/plan-du-site/plan-du-site.component';
import {InternationalCoursesCatalogueComponent} from './pages/international-courses-catalogue/international-courses-catalogue.component';


const routes: Routes = [
  {path: PATH_ACCUEIL, component: AccueilComponent, runGuardsAndResolvers: 'always'},
  {path: PATH_RESULTATS, component: ResultatsComponent, runGuardsAndResolvers: 'always'},
  {path: PATH_PAR_DIPLOMES, component: ParDiplomesComponent, runGuardsAndResolvers: 'always'},
  {path: PATH_PAR_DISCIPLINES, component: ParDisciplinesComponent, runGuardsAndResolvers: 'always'},
  {path: PATH_PAR_COMPOSANTES, component: ParComposantesComponent, runGuardsAndResolvers: 'always'},
  {path: PATH_DETAIL_FORMATION, component: FicheComponent, runGuardsAndResolvers: 'always'},
  {path: PATH_MA_SELECTION, component: MaSelectionComponent, runGuardsAndResolvers: 'always'},
  {path: PATH_PLAN_DU_SITE, component: PlanDuSiteComponent, runGuardsAndResolvers: 'always'},
  {path: PATH_CATALOGUE_INTERNATIONAL, component: InternationalCoursesCatalogueComponent, runGuardsAndResolvers: 'always'},
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 64],
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
