import { Component, OnInit } from '@angular/core';
import {ElasticsearchService} from '../../../services/elasticsearch.service';
import {TYPES_OBJET} from '../../../constants/utils.constants';

@Component({
  selector: 'app-home-key-numbers',
  templateUrl: './home-key-numbers.component.html',
  styleUrls: ['./home-key-numbers.component.scss']
})
export class HomeKeyNumbersComponent implements OnInit {

  nbFormations = 0;
  nbEnseignements = 0;
  nbCursusEnrichis = 0;

  constructor(private elasticsearchService: ElasticsearchService) { }

  ngOnInit(): void {
    this.elasticsearchService.search().subscribe(res => {
        this.nbFormations = res.filter(d => d.type_objet === TYPES_OBJET.FORMATION).length;
        this.nbCursusEnrichis = res.filter(d => d.type_objet === TYPES_OBJET.CURSUS_ENRICHI).length;
        this.nbEnseignements = res.filter(d => d.type_objet === TYPES_OBJET.ENSEIGNEMENT).length;
    });
  }

}
