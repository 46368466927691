<app-loader *ngIf="!display"></app-loader>

<div *ngIf="display">
  <div id="course-detail-nav-container"
       class="content-part content-part-bg content-part-bg-light course-detail-nav-container">
    <nav class="course-detail-nav" aria-label="Navigation interne" role="navigation">
      <div class="course-detail-nav-inner">
        <ul class="list-unstyled">
          <li *ngIf="presentation.objectif?.length > 0">
            <a appAnchor
               id="#presentation"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.presentation.title' | translate}}"
               target="#presentationAnchor">{{'fiche.presentation.title' | translate}}</a>
          </li>
          <li *ngIf="activitesPedagogiques?.isDisplayed()">
            <a appAnchor
               id="#activites-pedagogiques"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.activites_pedagogiques.title' | translate}}"
               target="#activitesPedagogiquesAnchor">{{'fiche.activites_pedagogiques.title' | translate}}</a>
          </li>
          <li *ngIf="modalitesAcquisition?.isDisplayed()">
            <a appAnchor
               id="#modalites-acquisition"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.modalites_acquisition.title' | translate}}"
               target="#modalitesAcquisitionAnchor">{{'fiche.modalites_acquisition.title' | translate}}</a>
          </li>
          <li *ngIf="acquis?.isDisplayed()">
            <a appAnchor
               tabindex="0"
               id="#acquis"
               attr.aria-label="aller à la rubrique {{'fiche.acquis.title' | translate}}"
               target="#acquisAnchor">{{'fiche.acquis.title' | translate}}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <!-- PRESENTATION -->
  <div class="anchor" id="presentationAnchor"></div>
  <div id="presentation" class="content-part content-part-no-pad-top" *ngIf="presentation.objectif?.length > 0">

    <div class="course-detail-top margin-section">
      <div class="row row-flex row-gutters-xlarge margin-container-large">
        <div
          class="layout-col-sm-large layout-col-lg-medium col-xs-12 col-lg-8 margin-large course-detail-top-main course-detail-top-main-expandable">

          <div class="course-detail-top-main-outer">
            <div class="course-detail-top-main-inner">

              <div class="margin-large margin-lg-xlarge">
                <h2>{{'fiche.presentation.title' | translate}}</h2>
              </div>

              <div class="margin-large margin-lg-xlarge" *ngIf="presentation.objectif?.length > 0">
                <h3>{{'fiche.presentation.objectifs' | translate}}</h3>
                <div [innerHTML]="presentation.objectif"></div>
              </div>
            </div>
          </div>

          <p class="course-detail-top-main-expand-controls">
            <button type="button" class="course-detail-top-main-expand-button">
            <span class="course-detail-top-main-expand-button-text">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
                <path
                  d="M16 2.267c-7.585 0-13.733 6.149-13.733 13.733s6.149 13.733 13.733 13.733c7.585 0 13.733-6.149 13.733-13.733v0c-0.009-7.581-6.152-13.724-13.732-13.733h-0.001zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16v0c0-8.837 7.163-16 16-16v0z"></path>
                <path d="M17.133 24h-2.267v-16h2.267z"></path>
                <path d="M8 17.133v-2.267h16v2.267z"></path>
              </svg>
              {{'fiche.presentation.afficher_plus' | translate}}
            </span>
              <span
                class="course-detail-top-main-expand-button-text course-detail-top-main-expand-button-text-expanded">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
                <path
                  d="M16 2.267c-7.585 0-13.733 6.149-13.733 13.733s6.149 13.733 13.733 13.733c7.585 0 13.733-6.149 13.733-13.733v0c-0.009-7.581-6.152-13.724-13.732-13.733h-0.001zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16v0c0-8.837 7.163-16 16-16v0z"></path>
                <path d="M8 17.133v-2.267h16v2.267z"></path>
              </svg>
                {{'fiche.presentation.afficher_moins' | translate}}
            </span>
            </button>
          </p>
        </div>

        <div class="layout-col-sm-large layout-col-lg-small col-xs-12 col-lg-4 margin-large course-detail-top-side">
          <app-fiche-column [gofElement]="gofElement"
                            [presentation]="presentation"
                            [fromInternationalCatalogue]="fromInternationalCatalogue"
                            [contacts]="contacts"
                            [rebonds]="rebonds">
          </app-fiche-column>
        </div>
      </div>

    </div>
  </div>

  <!-- ACTIVITES PEDAGOGIQUES -->
  <div class="anchor" id="activitesPedagogiquesAnchor"></div>
  <div id="activites-pedagogiques" class="content-part content-part-no-pad-top" *ngIf="activitesPedagogiques?.isDisplayed()">
    <div class="margin-section">
      <h2>{{'fiche.activites_pedagogiques.title' | translate}}</h2>
    </div>

    <app-activites-pedagogiques [activitesPedagogiques]="activitesPedagogiques"></app-activites-pedagogiques>
  </div>

  <!-- MODALITES ACQUISITION -->
  <div class="anchor" id="modalitesAcquisitionAnchor" *ngIf="!fromInternationalCatalogue"></div>
  <div id="modalites-acquisition" class="content-part content-part-no-pad-top" *ngIf="modalitesAcquisition?.isDisplayed()">
    <div class="margin-section">
      <h2>{{'fiche.modalites_acquisition.title' | translate}}</h2>
    </div>

    <app-modalites-acquisition [modalitesAcquisition]="modalitesAcquisition"></app-modalites-acquisition>
  </div>

  <!-- ACQUIS -->
  <div class="anchor" id="acquisAnchor"></div>
  <div id="acquis" class="content-part content-part-no-pad-top" *ngIf="acquis?.isDisplayed()">
    <div class="margin-section">
      <h2>{{'fiche.acquis.title' | translate}}</h2>
    </div>

    <app-acquis [acquis]="acquis"></app-acquis>
  </div>
</div>
