import {ObjectAffichage} from './util.model';

export class PanelAcces {
  constructor(public inscriptions?: Map<number, ModalitesInscription[]>,
              public diplomeAcces?: ObjectAffichage[]) {
  }


  isDisplayed(): boolean {
    return this && (
      this.diplomeAcces?.length > 0
      || this.inscriptions?.size > 0
    );
  }
}

export class ModalitesInscription {
  constructor(public id?: number,
              public typeInscriptionId?: number,
              public libelle?: string,
              public modalite?: string,
              public titresAcces?: string,
              public seuil?: number,
              public url?: string,
              public infosComplementaires?: string) {
  }
}
