import {AbstractDocument} from '../model/esDoc.model';

export function getResume(html: string): string {
  if (!html) {
    return null;
  }
  const resume = html.replace(/(<([^>]+)>)/ig, '');
  const pointIndex = resume.indexOf('.');
  if (pointIndex !== -1) {
    return resume.substr(0, pointIndex + 1);
  } else if (resume.length > 250) {
    return resume.substring(0, 250) + '[...]';
  } else {
    return resume;
  }
}

export function getDOMId(document: AbstractDocument): string {
  return document.type_objet + '-' + document.id;
}


export function getDOMId_Bis(typeObjet:string, id:number): string {
  return typeObjet + '-' + id;
}

