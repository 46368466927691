import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Menu} from '../model/util.model';
import {FormationDoc} from '../model/esDoc.model';

@Injectable({
  providedIn: 'root'
})
export class ParDisciplineService {
  constructor(protected http: HttpClient, private translateService: TranslateService) {
  }

  getNiveau1(): Observable<Menu[]> {
    return this.http.get<Menu[]>('/par-disciplines/niv-1/' + this.translateService.currentLang);
  }

  getNiveau2(discipline: string): Observable<Menu[]> {
    return this.http.get<Menu[]>('/par-disciplines/niv-2/' + this.translateService.currentLang + '/' + discipline);
  }

  getNiveau3(discipline: string, typeFormation: string): Observable<FormationDoc[]> {
    return this.http.get<FormationDoc[]>('/par-disciplines/niv-3/' + this.translateService.currentLang + '/' + discipline + '/' + typeFormation);
  }
}
