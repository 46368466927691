import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigAssetLoaderService} from '../../configAssetLoader.service';
import {GofElement} from '../../model/util.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnseignementResourceService {

  refFormUrl: string;

  constructor(protected http: HttpClient, private configAssetLoaderService: ConfigAssetLoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.refFormUrl = res.refFormUrl + '/enseignements');
  }

  getEnseignement(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/' + gofElement.id, {params});
  }

  getAcquis(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/' + gofElement.id + '/acquis', {params});
  }

  getMea(evalEnsId: number): Observable<any> {
    return this.http.get<any>(this.refFormUrl + '/mea/' + evalEnsId);
  }

  getReglesobt(evalEnsId: number, gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/reglesobt/' + evalEnsId, {params});
  }
}
