import {PanelContenuHisto} from '../model/contenu-histo.model';
import {RefTypeDuree} from '../constants/referentiel/ref.constants';

export function getAllDurees(panelContenuHisto: PanelContenuHisto): string[] {
  switch (panelContenuHisto.typeDuree) {
    case RefTypeDuree.SEMESTRE :
      return Array.from({length: panelContenuHisto.duree}, (_, i) => 'S' + (i + 1)).reverse();
    case RefTypeDuree.ANNEE :
      return Array.from({length: panelContenuHisto.duree}, (_, i) => 'A' + (i + 1)).reverse();
    default :
      return [];
  }
}

export function getTypeDuree(panelContenuHisto: PanelContenuHisto, isPluriel: boolean): string {
  switch (panelContenuHisto.typeDuree) {
    case RefTypeDuree.SEMESTRE :
      return 'semestre' + (isPluriel ? 's' : '');
    case RefTypeDuree.ANNEE :
      return 'annee' + (isPluriel ? 's' : '');
    case RefTypeDuree.HEURE :
      return 'heure' + (isPluriel ? 's' : '');
    default :
      return '';
  }
}
