<!-- BANNIERE -->
<div class="container" [class.banner-info-is-shown]="displayBanner" role="status">
  <div class="banner-info">
    <div class="banner-info-outer">
      <div class="banner-info-inner">
        <button type="button" class="banner-info-close" title="Masquer le bandeau" aria-label="Masquer le bandeau"
                (click)="displayBanner = false;">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
            <path
              d="M32.042 3.493c0 0-28.55 28.55-28.55 28.55s-3.535-3.535-3.535-3.535c0 0 28.55-28.55 28.55-28.55z"></path>
            <path d="M3.493-0.043c0 0 28.55 28.55 28.55 28.55l-3.535 3.535c0 0-28.55-28.55-28.55-28.55z"></path>
          </svg>
        </button>

        <div class="banner-info-content">
          <div class="banner-info-text">
            <p class="banner-info-text-title">Offre en cours de modification</p>
            <p>Les informations concernant le contenu des enseignements peuvent être en cours de modification</p>
          </div>
          <!--          <div class="banner-info-link">-->
          <!--            <p class="btns">-->
          <!--              <a href="#" class="btn">Consultez toutes les informations utiles</a>-->
          <!--            </p>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>

<app-loader *ngIf="!display"></app-loader>

<div class="layout-with-left-col" *ngIf="display">

  <div class="layout-left-col">
    <div class="layout-left-col-inner">
      <div class="layout-left-col-text">
        {{ typesObjetTrad.get(gofElement.typeObjet) | translate }}
      </div>
    </div>
  </div>

  <div class="layout-main" *ngIf="presentation">
    <section class="main-inner">
      <div class="container">

        <app-breadcrumb-thread [thread]="thread"></app-breadcrumb-thread>

        <select class="select-like" id="year-field" aria-label="Sélection de l'année de formation"
                [formControl]="yearFormControl"
                (change)="onAnneeChanged()" *ngIf="openYears?.length > 1">
          <option [ngValue]="annee" *ngFor="let annee of openYears">{{ annee + '-' + (annee + 1) }}</option>
        </select>

        <div class="content-part">
          <div class="course-detail-header">
            <div class="course-detail-header-top">
              <div class="course-detail-title-container">
                <div class="course-detail-title-container-inner">
                  <p class="course-detail-domain" *ngIf="[typesObjet.FORMATION.valueOf(), typesObjet.PARCOURS_TYPE.valueOf()].includes(gofElement.typeObjet)
                        && presentation.domaines?.length > 0">
                  <span class="themes-list-item">
                    <ng-container *ngFor="let domaine of presentation.domaines; let last = last">
                      <span>{{ domaine }}</span><span *ngIf="!last">{{ ', ' }}</span>
                    </ng-container>
                  </span>
                  </p>

                  <h1 [id]="getDomId(document)" class="course-detail-title"> {{ presentation.intitule }}</h1>
                </div>

                <div class="course-detail-badge" *ngIf="gofElement.typeObjet === typesObjet.FORMATION">
                  <ng-container [ngSwitch]="presentation.typeDiplomeId">
                    <img *ngSwitchCase="typesDiplome.DUT"
                         class="badge"
                         src="./assets/img/badges/DL_02_DUT.png"
                         srcset="./assets/img/badges/DL_02_DUT.png 2x"
                         alt="Diplôme univeritaire de technologie contrôlé par l'État" />
                    <img *ngSwitchCase="typesDiplome.LICENCE"
                         class="badge" src="./assets/img/badges/DL_01_DNL.png"
                         srcset="./assets/img/badges/DL_01_DNL.png 2x"
                         alt="Diplôme national de licence contrôlé par l'État" />
                    <img *ngSwitchCase="typesDiplome.LICENCE_PRO"
                         class="badge" src="./assets/img/badges/DL_02_LP.png"
                         srcset="./assets/img/badges/DL_02_LP.png 2x"
                         alt="Diplôme national de licence professionnelle contrôlé par l'État" />
                    <img *ngSwitchCase="typesDiplome.MASTER"
                         class="badge"
                         src="./assets/img/badges/DL_01_DNM.png"
                         srcset="./assets/img/badges/DL_01_DNM.png 2x"
                         alt="Diplôme national de master contrôlé par l'État" />
                    <img *ngSwitchCase="typesDiplome.DOCTORAT"
                         class="badge"
                         src="./assets/img/badges/DL_01_DND.png"
                         srcset="./assets/img/badges/DL_01_DND.png 2x"
                         alt="Diplôme national de doctorat contrôlé par l'État" />
                  </ng-container>
                </div>
              </div>

              <div class="course-detail-header-bottom">
                <p class="course-detail-discipline"
                   *ngIf="gofElement.typeObjet === typesObjet.FORMATION && presentation.disciplines?.length > 0">
                <span class="themes-list-item">
                  <ng-container *ngFor="let discipline of presentation.disciplines; let last = last">
                    <span>{{ discipline }}</span><span *ngIf="!last">{{ ', ' }}</span>
                  </ng-container>
                </span>
                </p>

                <p class="course-detail-discipline"
                   *ngIf="gofElement.typeObjet === typesObjet.PARCOURS_TYPE && presentation.formationsPorteuses?.length > 0">
                  <span class="themes-list-item">
                  <ng-container *ngFor="let formation of presentation.formationsPorteuses; let last = last">
                    <a href [routerLink]="'/' + pathDetailFormation"
                       [queryParams]="{type: formation.typeObjet, id: formation.id}">
                        {{ formation.intitule }}</a><span *ngIf="!last">{{ ', ' }}</span>
                  </ng-container>
                  </span>
                </p>

                <div class="course-detail-actions" *ngIf="document">
                  <app-ajouter-favori [class]="'course-detail-action'" [document]="document"></app-ajouter-favori>
                </div>
              </div>
            </div>

            <div class="course-detail-data">
              <ul class="list-unstyled row-flex margin-container-small">
                <li class="col-xs-12 col-sm-6 col-lg-3 margin-small"
                    *ngIf="[typesObjet.CURSUS_ENRICHI.valueOf(), typesObjet.PARCOURS_TYPE.valueOf(), typesObjet.FORMATION.valueOf(), typesObjet.PERIODE.valueOf()].includes(gofElement.typeObjet) && presentation.duree">
                  <div class="course-detail-data-item">
                    <span class="course-detail-data-icon"><svg xmlns="http://www.w3.org/2000/svg" width="32"
                                                               height="32" viewBox="0 0 32 32" aria-hidden="true">
														<path
                              d="M16 3.36c6.981 0 12.64 5.659 12.64 12.64 0 3.49-1.415 6.65-3.702 8.938v0c-2.281 2.249-5.415 3.638-8.874 3.638-6.981 0-12.64-5.659-12.64-12.64 0-3.459 1.389-6.593 3.64-8.875l-0.001 0.001c2.275-2.287 5.425-3.702 8.905-3.702 0.011 0 0.023 0 0.034 0h-0.002zM16 27.36c0 0 0 0 0 0 6.274 0 11.36-5.086 11.36-11.36 0-3.137-1.272-5.977-3.327-8.033v0c-2.056-2.056-4.896-3.327-8.033-3.327-6.274 0-11.36 5.086-11.36 11.36 0 3.137 1.272 5.977 3.327 8.033v0c2.044 2.055 4.875 3.327 8.002 3.327 0.011 0 0.022 0 0.033-0h-0.002z"></path>
														<path
                              d="M23 16.64h-7c-0.353 0-0.64-0.287-0.64-0.64v0-7c0-0.353 0.287-0.64 0.64-0.64s0.64 0.287 0.64 0.64v0 6.36h6.36c0.353 0 0.64 0.287 0.64 0.64s-0.287 0.64-0.64 0.64v0z"></path>
													</svg></span>
                    <p class="course-detail-data-text">
                      <span class="course-detail-data-label">{{ 'fiche.details.duree' | translate }}</span>
                      <span
                        class="course-detail-data-value">{{ presentation.duree }} {{ typesDureeTrad.get(presentation.typeDureeId) | translate }}{{ presentation.duree > 1 ? 's' : '' }}</span>
                    </p>
                  </div>
                </li>

                <li class="col-xs-12 col-sm-6 col-lg-3 margin-small"
                    *ngIf="[typesObjet.CURSUS_ENRICHI.valueOf(), typesObjet.PARCOURS_TYPE.valueOf(), typesObjet.FORMATION.valueOf(), typesObjet.ENSEIGNEMENT.valueOf(), typesObjet.PERIODE.valueOf()].includes(gofElement.typeObjet) && presentation.credits">
                  <div class="course-detail-data-item">
												<span class="course-detail-data-icon"><svg xmlns="http://www.w3.org/2000/svg" width="32"
                                                                   height="32" viewBox="0 0 32 32" aria-hidden="true">
														<path
                              d="M16.4 1.76c5.873 0.004 10.633 4.766 10.633 10.64 0 2.936-1.189 5.595-3.113 7.52l0-0c-1.899 1.788-4.465 2.887-7.287 2.887-5.876 0-10.64-4.764-10.64-10.64 0-2.822 1.099-5.388 2.892-7.292l-0.005 0.005c1.915-1.927 4.567-3.12 7.498-3.12 0.008 0 0.015 0 0.023 0h-0.001zM16.4 21.76c5.169-0 9.36-4.191 9.36-9.36 0-2.585-1.048-4.925-2.741-6.618v0c-1.698-1.718-4.054-2.781-6.658-2.781-5.169 0-9.36 4.191-9.36 9.36 0 2.604 1.064 4.96 2.78 6.657l0.001 0.001c1.684 1.694 4.016 2.742 6.593 2.742 0.009 0 0.018 0 0.027-0h-0.001z"></path>
														<path
                              d="M16.4 5.76c3.667 0 6.64 2.973 6.64 6.64s-2.973 6.64-6.64 6.64c-3.667 0-6.64-2.973-6.64-6.64v0c0.004-3.666 2.974-6.636 6.64-6.64h0zM16.4 17.76c2.96 0 5.36-2.4 5.36-5.36s-2.4-5.36-5.36-5.36c-2.96 0-5.36 2.4-5.36 5.36v0c0.004 2.959 2.401 5.356 5.36 5.36h0z"></path>
														<path
                              d="M10.038 30.753c-0.353 0-0.64-0.287-0.64-0.64v0-9.222c0-0.353 0.287-0.64 0.64-0.64s0.64 0.287 0.64 0.64v8.226l5.114-2.35c0.078-0.037 0.17-0.058 0.267-0.058s0.189 0.022 0.271 0.060l-0.004-0.002 5.12 2.35v-8.23c0-0.353 0.287-0.64 0.64-0.64s0.64 0.287 0.64 0.64v9.226c0 0 0 0 0 0 0 0.353-0.287 0.64-0.64 0.64-0.097 0-0.189-0.022-0.271-0.060l0.004 0.002-5.76-2.645-5.754 2.645c-0.078 0.037-0.17 0.058-0.267 0.058-0 0-0 0-0 0v0z"></path>
													</svg></span>
                    <p class="course-detail-data-text">
                      <span class="course-detail-data-label">{{ 'fiche.details.credits' | translate }}</span>
                      <span class="course-detail-data-value"
                            *ngIf="presentation.credits === '0'">{{ 'fiche.presentation.non_creditant' | translate }}</span>
                      <span class="course-detail-data-value"
                            *ngIf="presentation.credits !== '0'">{{ presentation.credits }}</span>
                    </p>
                  </div>
                </li>

                <li class="col-xs-12 col-sm-6 col-lg-3 margin-small"
                    *ngIf="[typesObjet.CURSUS_ENRICHI.valueOf(), typesObjet.PARCOURS_TYPE.valueOf(), typesObjet.PERIODE.valueOf()].includes(gofElement.typeObjet) && presentation.formationsPorteuses">
                  <div class="course-detail-data-item">
                    <span class="course-detail-data-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                           aria-hidden="true">
                        <path
                          d="M15.899 20.514c-0.11-0-0.214-0.028-0.304-0.077l0.003 0.002-14.906-7.95c-0.203-0.11-0.339-0.322-0.339-0.565s0.136-0.455 0.336-0.563l0.003-0.002 14.906-7.95c0.087-0.047 0.191-0.075 0.301-0.075s0.214 0.028 0.305 0.077l-0.003-0.002 14.905 7.95c0.203 0.11 0.339 0.322 0.339 0.565s-0.136 0.455-0.336 0.563l-0.003 0.002-14.906 7.95c-0.087 0.047-0.191 0.075-0.301 0.075v0zM2.354 11.924l13.546 7.224 13.546-7.224-13.546-7.224z"></path>
                        <path
                          d="M23.352 30.45c-0.353 0-0.64-0.287-0.64-0.64v0-13.528l-7.114-3.794c-0.203-0.11-0.339-0.322-0.339-0.565 0-0.354 0.287-0.64 0.64-0.64 0.11 0 0.214 0.028 0.305 0.077l-0.003-0.002 7.453 3.974c0.203 0.11 0.339 0.321 0.339 0.565v0 13.912c0 0 0 0.001 0 0.001 0 0.353-0.287 0.64-0.64 0.64-0 0-0.001 0-0.001 0h0z"></path>
                        <path
                          d="M15.899 26.973c-0.064 0.001-0.14 0.002-0.216 0.002-2.962 0-5.726-0.847-8.063-2.312l0.064 0.037c-1.378-0.862-2.545-1.916-3.504-3.14l-0.022-0.029c-0.203-0.269-0.325-0.609-0.326-0.977v-6.771c0-0.353 0.287-0.64 0.64-0.64s0.64 0.287 0.64 0.64v0 6.771c0 0 0 0 0 0.001 0 0.079 0.026 0.152 0.070 0.211l-0.001-0.001c0.886 1.129 1.94 2.077 3.132 2.823l0.051 0.030c2.083 1.307 4.615 2.082 7.329 2.082 0.072 0 0.145-0.001 0.217-0.002l-0.011 0c0.061 0.001 0.134 0.002 0.206 0.002 2.713 0 5.245-0.775 7.387-2.116l-0.058 0.034c1.244-0.776 2.298-1.725 3.164-2.828l0.020-0.026c0.043-0.057 0.069-0.13 0.069-0.208 0-0 0-0 0-0.001v0-6.774c0-0.353 0.287-0.64 0.64-0.64s0.64 0.287 0.64 0.64v6.773c-0 0.369-0.123 0.709-0.329 0.983l0.003-0.004c-0.982 1.253-2.149 2.307-3.471 3.137l-0.056 0.033c-2.273 1.427-5.036 2.274-7.997 2.274-0.077 0-0.153-0.001-0.229-0.002l0.012 0z"></path>
                      </svg>
                    </span>
                    <p class="course-detail-data-text">
                      <span class="course-detail-data-label">{{ 'fiche.details.formations' | translate }}</span>
                      <span class="course-detail-data-value" *ngFor="let formation of presentation.formationsPorteuses">
                        <a href [routerLink]="'/' + pathDetailFormation"
                           [queryParams]="{type: formation.typeObjet, id: formation.id}">
                        {{ formation.intitule }}</a><br />
                      </span>
                    </p>
                  </div>
                </li>

                <li class="col-xs-12 col-sm-6 col-lg-3 margin-small"
                    *ngIf="[typesObjet.FORMATION.valueOf(), typesObjet.ENSEIGNEMENT.valueOf()].includes(gofElement.typeObjet) && (presentation.composanteIds?.length > 0 || presentation.sniIds?.length > 0)">
                  <div class="course-detail-data-item">
												<span class="course-detail-data-icon"><svg xmlns="http://www.w3.org/2000/svg" width="32"
                                                                   height="32" viewBox="0 0 32 32" aria-hidden="true">
														<path
                              d="M27 27.68c-0.376 0-0.68-0.304-0.68-0.68v0-12.56c0-0.001 0-0.002 0-0.003 0-0.094-0.040-0.178-0.105-0.237l-0-0-10-9.092c-0.057-0.052-0.132-0.083-0.215-0.083s-0.159 0.032-0.215 0.083l0-0-10 9.092c-0.064 0.059-0.105 0.143-0.105 0.237 0 0.001 0 0.002 0 0.003v-0 12.56c0 0.376-0.304 0.68-0.68 0.68s-0.68-0.304-0.68-0.68v0-12.56c0-0 0-0 0-0 0-0.492 0.211-0.934 0.548-1.242l0.001-0.001 9.999-9.090c0.297-0.272 0.694-0.439 1.13-0.439s0.833 0.167 1.131 0.44l-0.001-0.001 10 9.092c0.338 0.308 0.55 0.751 0.55 1.243 0 0 0 0 0 0v-0 12.56c-0.001 0.374-0.304 0.677-0.678 0.678h-0z"></path>
														<path
                              d="M30 27.68h-28c-0.376 0-0.68-0.304-0.68-0.68v0c0-0.376 0.304-0.68 0.68-0.68v0h28c0.376 0 0.68 0.304 0.68 0.68v0c0 0.376-0.304 0.68-0.68 0.68v0z"></path>
														<path
                              d="M18.999 27.679c-0.376 0-0.68-0.304-0.68-0.68v0-7c0-0.177-0.143-0.32-0.32-0.32v0h-4c-0.177 0-0.32 0.143-0.32 0.32v0 7c0 0.376-0.304 0.68-0.68 0.68s-0.68-0.304-0.68-0.68v0-7c0-0.928 0.752-1.68 1.68-1.68v0h4c0.928 0 1.68 0.752 1.68 1.68v0 7c0 0.376-0.304 0.68-0.68 0.68v0z"></path>
													</svg></span>
                    <p class="course-detail-data-text">
                      <span class="course-detail-data-label">{{ 'fiche.details.composantes' | translate }}</span>
                      <span class="course-detail-data-value"
                            *ngFor="let structureId of presentation.composanteIds">{{ structuresTrad.get(structureId) | translate }}
                        <br /></span>
                      <span class="course-detail-data-value"
                            *ngFor="let structureId of presentation.sniIds">{{ structuresTrad.get(structureId) | translate }}
                        <br /></span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <ng-container [ngSwitch]="gofElement.typeObjet">
          <app-fiche-formation *ngSwitchCase="typesObjet.FORMATION"
                               [gofElement]="gofElement"
                               [presentation]="presentation"
                               (loaded)="onLoaded()"></app-fiche-formation>
          <app-fiche-cursus-enrichi *ngSwitchCase="typesObjet.CURSUS_ENRICHI"
                                    [gofElement]="gofElement"
                                    [presentation]="presentation"
                                    (loaded)="onLoaded()"></app-fiche-cursus-enrichi>
          <app-fiche-enseignement *ngSwitchCase="typesObjet.ENSEIGNEMENT"
                                  [gofElement]="gofElement"
                                  [presentation]="presentation"
                                  [fromInternationalCatalogue]="fromInternationalCatalogue"
                                  (loadedEmitter)="onLoaded()"></app-fiche-enseignement>
          <app-fiche-parcours-type *ngSwitchCase="typesObjet.PARCOURS_TYPE"
                                   [gofElement]="gofElement"
                                   [presentation]="presentation"
                                   (loaded)="onLoaded()"></app-fiche-parcours-type>
          <app-fiche-periode *ngSwitchCase="typesObjet.PERIODE"
                             [gofElement]="gofElement"
                             [presentation]="presentation"
                             (loaded)="onLoaded()"></app-fiche-periode>
        </ng-container>

      </div>
    </section>
  </div>

</div>
