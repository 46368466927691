import {Injectable} from '@angular/core';
import {EXPIRE_COOKIES, GOF_COOKIES} from '../constants/conf.constants';
import {CookieService} from 'ngx-cookie-service';
import {MatomoTracker} from '@ngx-matomo/tracker';

@Injectable({
  providedIn: 'root'
})
export class GestionCookiesService {

  constructor(private cookieService: CookieService,
              private tracker: MatomoTracker) {
  }

   storeCookies(cookies:any) {
    this.cookieService.set(GOF_COOKIES, JSON.stringify(cookies),
      {
        expires: EXPIRE_COOKIES,
        sameSite: 'Strict',
      });
  }

   refuseAllCookies() {
    this.cookieService.delete(GOF_COOKIES);
  }

   acceptMatomo(): void {
    this.tracker.forgetUserOptOut();
  }

   refuseMatomo(): void {
    this.tracker.optUserOut();
  }
}
