<footer class="footer" role="contentinfo">
  <div class="container">
    <div class="footer-top">
      <div class="footer-logo">
        <a href="https://www.u-bordeaux.fr">
          <picture [attr.aria-label]="univHomeLink">
            <source
              srcset="assets/img/logo-universite-bordeaux-w-200x62.png, assets/img/logo-universite-bordeaux-w-400x124.png 2x"
              media="(min-width: 1200px)"/>
            <img loading="lazy" decoding="async" src="assets/img/logo-universite-bordeaux-w-135x42.png"
                 srcset="assets/img/logo-universite-bordeaux-w-270x84.png 2x" width="135" height="42"
                 [alt]="univHomeLink"/>
          </picture>
        </a>
      </div>
      <div class="footer-address">
        <p>351 cours de la libération CS1004<br/>
          33405 Talence CEDEX</p>
      </div>
      <div class="footer-tel">
        <p>Tél : +33 (0)5 40 00 60 00</p>
      </div>
    </div>
    <nav class="footer-links" aria-label="Pied de page" role="navigation">
      <ul class="list-unstyled">
        <li>
          <a [href]=accessibliteUrl>
            {{'footer.accessibilite' | translate | uppercase}}
          </a>
        </li>
        <li>
          <button type="button"
                  (click)="openCookiesManagement()">{{'footer.gestion_cookies' | translate | uppercase}}</button>
        </li>
        <li><a [href]="donneesPersonnellesUrl">{{'footer.rgpd' | translate | uppercase}}</a></li>
        <li><a [href]="mentionsLegalesUrl"
               class="small">{{'footer.mentions_legales' | translate | uppercase}}</a></li>
        <li><a href [routerLink]="'/' + pathPlanDuSite"
               class="small">{{'footer.plan_du_site' | translate | uppercase}}</a></li>
        <li><span class="small">{{ 'Version ' + version | uppercase}}</span></li>
      </ul>
    </nav>
  </div>
</footer>
