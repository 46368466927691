import {AfterViewInit, Component, Input, OnChanges, ViewEncapsulation} from '@angular/core';
import {PanelContenuHisto} from '../../../../model/contenu-histo.model';
import {getAllDurees, getTypeDuree} from '../../../../utils/contenu-histo.utils';
import {ObjectAffichage} from '../../../../model/util.model';
import {PATH_DETAIL_FORMATION} from '../../../../constants/conf.constants';
import {AppComponent} from '../../../../app.component';

import {TYPES_OBJET} from '../../../../constants/utils.constants';
import {Router} from '@angular/router';
import {LazyLoadService} from '../../../../services/lazy-load.service';
import {RefTypeDuree, RefTypePeriode} from '../../../../constants/referentiel/ref.constants';

@Component({
  selector: 'app-programme-schema',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './programme-schema.component.html',
  styleUrls: ['./programme-schema.component.scss']
})
export class ProgrammeSchemaComponent implements OnChanges, AfterViewInit {

  @Input() libelleMention: string;
  @Input() contenuHisto: PanelContenuHisto;

  typeDuree = RefTypeDuree;
  periodeSpecialisation = RefTypePeriode.PERIODE_DE_SPECIALISATION;
  anneeEnCours: number;

  pathDetailFormation = PATH_DETAIL_FORMATION;
  typeObjet = TYPES_OBJET;

  constructor(private router: Router,
              private lazyLoadScriptServiceService: LazyLoadService) {
  }

  ngOnChanges(): void {
    this.anneeEnCours = AppComponent.CURRENT_YEAR;
  }

  ngAfterViewInit(): void {
    this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
  }

  onObjectSelected(objectAffichage: ObjectAffichage): void {
    if (objectAffichage.id && objectAffichage.typeObjet) {
      this.router.navigate([PATH_DETAIL_FORMATION], {queryParams: {type: objectAffichage.typeObjet, id: objectAffichage.id}});
    }
  }

  getAllDurees(): string[] {
    return getAllDurees(this.contenuHisto);
  }

  getTypeDureeLibelle(isPluriel:boolean):string{
    return getTypeDuree(this.contenuHisto, isPluriel);
  }

}
