import {Injectable} from '@angular/core';
import {GofElement, ObjectAffichage} from '../../model/util.model';
import {PanelAcces, ModalitesInscription} from '../../model/acces.model';
import {InscriptionResourceService} from '../resources/inscription-resource.service';
import {DiplomeResourceService} from '../resources/diplome-resource.service';
import {TYPES_OBJET} from '../../constants/utils.constants';
import {typesDiplomeTrad} from '../../constants/translationMaps/trad.constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FormationAccesService {

  panelAcces: PanelAcces = new PanelAcces();

  constructor(private inscriptionResourceService: InscriptionResourceService,
              private diplomeResourceService: DiplomeResourceService,
              private translateService: TranslateService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelAcces> {
    return new Promise<PanelAcces>(resolve => {
      Promise.all([
        this.getInscriptions(gofElement),
        this.getDiplomesAcces(gofElement)
      ])
        .then(() => resolve(this.panelAcces));
    });
  }

  private getInscriptions(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      const inscriptions: Map<number, ModalitesInscription[]> = new Map();
      this.inscriptionResourceService.getTypesInscription(gofElement).subscribe(types => {
        types.forEach(t => {
          if (t?.type) {
            inscriptions.set(t.type.id, []);
          }
        });

        this.inscriptionResourceService.getModalitesInscription(gofElement).subscribe(modalites => {
          modalites.forEach(mod => {
            const arrayMod = inscriptions.get(mod?.typeInscription?.id);
            const modalitesInscription: ModalitesInscription = {
              typeInscriptionId: mod.typeInscription?.id,
              libelle: mod.intitule,
              modalite: mod.modalite,
              seuil: mod.seuil,
              titresAcces: mod.titresAcces,
              url: mod.url,
              infosComplementaires: mod.infosComplementaires
            };

            this.getCurrentVersion(modalitesInscription, mod, gofElement);
            if (arrayMod) {
              arrayMod.push(modalitesInscription);
              inscriptions.set(mod.typeInscription?.id, arrayMod);
            }
          });
        });

        this.panelAcces.inscriptions = inscriptions;
        resolve();
      },
        () => resolve());
    });
  }


  private getDiplomesAcces(gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.diplomeResourceService.getDiplomes(gofElement, false).subscribe(res => {
        this.panelAcces.diplomeAcces = res.accesExternes.map(d => new ObjectAffichage(null, d.lib, null));
        res.accesInternes.forEach(d => {
          this.translateService.get(typesDiplomeTrad.get(d.type.typeDiplome.id)).subscribe(typeDiplome => {
            this.panelAcces.diplomeAcces.push(new ObjectAffichage(d.objetId, typeDiplome + ' : ' + d.diplome.lib, TYPES_OBJET.FORMATION));
          });
        });
        resolve();
      },
        () => resolve());
    });
  }

  private getCurrentVersion(modalitesInscription: ModalitesInscription, mod: any, gofElement: GofElement): void {
    if (mod?.traductions?.length > 0) {
      const traductions: any = mod.traductions.filter(t => t.langue.code === gofElement.langue);
      if (traductions.length > 0) {
        modalitesInscription.libelle = traductions[0].intitule;
        modalitesInscription.titresAcces = traductions[0].titresAcces;
        modalitesInscription.modalite = traductions[0].modalite;
        modalitesInscription.infosComplementaires = traductions[0].infosComplementaires;
      }
    }
  }
}
