import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GofElement} from '../../model/util.model';
import {ConfigAssetLoaderService} from '../../configAssetLoader.service';

@Injectable({
  providedIn: 'root'
})
export class FormationResourceService {

  refFormUrl: string;

  constructor(protected http: HttpClient, private configAssetLoaderService: ConfigAssetLoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.refFormUrl = res.refFormUrl + '/formations');
  }

  search(search: string, type: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('type', type);
    return this.http.get<any>(this.refFormUrl + '/recherche', {params});
  }

  getFormation(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/' + gofElement.id, {params});
  }

  getContacts(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('public', String(gofElement.typePublic));
    params = params.append('lang', gofElement.langue);
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/' + gofElement.id + '/contacts', {params});
  }

  getPeriodes(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('public', String(gofElement.typePublic));
    params = params.append('lang', gofElement.langue);
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/' + gofElement.id + '/periodes', {params});
  }

  getEnseignements(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('public', String(gofElement.typePublic));
    params = params.append('lang', gofElement.langue);
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/' + gofElement.id + '/enseignements', {params});
  }

  getCompetences(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('public', String(gofElement.typePublic));
    params = params.append('lang', gofElement.langue);
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/' + gofElement.id + '/competences', {params});
  }

  getRecherche(gofElement: GofElement): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', gofElement.annee);
    return this.http.get<any>(this.refFormUrl + '/' + gofElement.id + '/recherche', {params});
  }
}
