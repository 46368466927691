<button [class]="class" type="button" [attr.aria-pressed]="isFavori" [attr.aria-described-by]="getDOMId()" title="Ajouter/retirer cet élément à ma sélection" (click)="addRemoveToFavorites()">
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true" *ngIf="!isFavori">
    <path
      d="M16 32c-8.837 0-16-7.163-16-16s7.163-16 16-16c8.837 0 16 7.163 16 16v0c0 8.837-7.163 16-16 16v0zM16 1.412c-8.057 0-14.588 6.531-14.588 14.588s6.531 14.588 14.588 14.588c8.057 0 14.588-6.531 14.588-14.588v0c-0.005-8.055-6.534-14.583-14.588-14.588h-0.001zM16.254 23.473v0c-0.009 0-0.020 0-0.030 0-0.356 0-0.678-0.144-0.911-0.377l0 0-6.005-6.033c-0.057-0.054-0.11-0.113-0.157-0.175l-0.003-0.004c-0.716-0.788-1.154-1.84-1.154-2.994 0-1.322 0.575-2.51 1.489-3.327l0.004-0.004c0.801-0.677 1.845-1.088 2.986-1.088 1.31 0 2.494 0.543 3.337 1.417l0.001 0.001 0.442 0.442 0.612-0.612 0.169-0.16c0.791-0.712 1.843-1.147 2.997-1.147 1.323 0 2.512 0.572 3.333 1.482l0.003 0.004c0.677 0.801 1.088 1.845 1.088 2.986 0 1.31-0.543 2.494-1.416 3.337l-0.001 0.001-5.873 5.873c-0.23 0.233-0.55 0.377-0.903 0.377-0.004 0-0.007 0-0.011-0h0.001zM16.668 22.598v0zM12.471 10.88c-0.004-0-0.009-0-0.014-0-0.782 0-1.498 0.281-2.052 0.748l0.005-0.004c-0.617 0.563-1.003 1.371-1.003 2.269 0 0.79 0.298 1.51 0.789 2.054l-0.002-0.003 0.113 0.122 5.948 5.948 5.798-5.798c0.613-0.59 0.995-1.418 0.995-2.335 0-0.785-0.28-1.505-0.745-2.066l0.004 0.005c-0.563-0.617-1.371-1.002-2.268-1.002-0.795 0-1.519 0.302-2.063 0.797l0.003-0.002-0.122 0.113-1.101 1.101c-0.13 0.123-0.305 0.199-0.499 0.199s-0.369-0.076-0.499-0.199l0 0-0.941-0.941c-0.588-0.621-1.418-1.007-2.339-1.007-0.002 0-0.003 0-0.005 0h0z"></path>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true"*ngIf="isFavori">
    <path
      d="M16 32c-8.837 0-16-7.163-16-16s7.163-16 16-16c8.837 0 16 7.163 16 16v0c0 8.837-7.163 16-16 16v0zM16 1.412c-8.057 0-14.588 6.531-14.588 14.588s6.531 14.588 14.588 14.588c8.057 0 14.588-6.531 14.588-14.588v0c-0.005-8.055-6.534-14.583-14.588-14.588h-0.001zM16.254 23.473v0c-0.009 0-0.020 0-0.030 0-0.356 0-0.678-0.144-0.911-0.377l0 0-6.005-6.033c-0.057-0.054-0.11-0.113-0.157-0.175l-0.003-0.004c-0.716-0.788-1.154-1.84-1.154-2.994 0-1.322 0.575-2.51 1.489-3.327l0.004-0.004c0.801-0.677 1.845-1.088 2.986-1.088 1.31 0 2.494 0.543 3.337 1.417l0.001 0.001 0.442 0.442 0.612-0.612 0.169-0.16c0.791-0.712 1.843-1.147 2.997-1.147 1.323 0 2.512 0.572 3.333 1.482l0.003 0.004c0.677 0.801 1.088 1.845 1.088 2.986 0 1.31-0.543 2.494-1.416 3.337l-0.001 0.001-5.873 5.873c-0.23 0.233-0.55 0.377-0.903 0.377-0.004 0-0.007 0-0.011-0h0.001zM16.668 22.598v0z"></path>
  </svg>
</button>
