import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { EditoService } from "../../services/edito.service";
import { SafeHtml } from "@angular/platform-browser";
import {BREADCRUMB_THREAD, GOF_FILTERS, GOF_LANGUAGE, GOF_SEARCH, PATH_ACCUEIL} from "../../constants/conf.constants";
import { GlobalStateService } from "../../services/global-state.service";
import { UtilService } from "../../services/util.service";

@Component({
  selector: "app-accueil",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./accueil.component.html",
  styleUrls: ["./accueil.component.scss"]
})
export class AccueilComponent implements OnInit {

  editoHtml: SafeHtml;

  constructor(private utilService: UtilService,
              private globalStateService: GlobalStateService,
              private editoService: EditoService) {
    this.globalStateService.subscribe(GOF_LANGUAGE, () => {
      this.utilService.setHtmlHead("page.accueil", "universite_de_bordeaux_description", PATH_ACCUEIL, "");
    });
  }

  ngOnInit(): void {
    this.utilService.setHtmlHead("page.accueil", "universite_de_bordeaux_description", PATH_ACCUEIL, "");

    this.editoService.gethtml().subscribe(res => {
      this.editoHtml = res;
    });

    // init Storage datas
    sessionStorage.setItem(GOF_SEARCH, JSON.stringify([]));
    sessionStorage.removeItem(GOF_FILTERS)
    sessionStorage.setItem(BREADCRUMB_THREAD, JSON.stringify([]));
  }
}

