import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {from, lastValueFrom, Observable} from 'rxjs';
import {HttpInterceptor} from './http.interceptor';
import {ConfigAssetLoaderService} from './configAssetLoader.service';
import {AccueilComponent} from './pages/accueil/accueil.component';
import {ResultatsComponent} from './pages/resultats/resultats.component';
import {FicheComponent} from './pages/fiche/fiche.component';
import {ToastrModule} from 'ngx-toastr';
import {ElasticsearchService} from './services/elasticsearch.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GlobalStateService} from './services/global-state.service';
import {FiltresComponent} from './components/results/filtres/filtres.component';
import {AccesComponent} from './components/rubriques/acces/acces.component';
import {AcquisComponent} from './components/rubriques/acquis/acquis.component';
import {ActivitesPedagogiquesComponent} from './components/rubriques/activites-pedagogiques/activites-pedagogiques.component';
import {CompetencesComponent} from './components/rubriques/competences/competences.component';
import {DebouchesComponent} from './components/rubriques/debouches/debouches.component';
import {InformationsComponent} from './components/rubriques/informations/informations.component';
import {ModalitesAcquisitionComponent} from './components/rubriques/modalites-acquisition/modalites-acquisition.component';
import {RebondsComponent} from './components/rubriques/rebonds/rebonds.component';
import {MatTreeModule} from '@angular/material/tree';
import {BlocsCompetenceComponent} from './components/rubriques/blocs-competence/blocs-competence.component';
import {ProgrammeArboComponent} from './components/rubriques/programme/programme-arbo/programme-arbo.component';
import {ProgrammeSchemaComponent} from './components/rubriques/programme/programme-schema/programme-schema.component';
import {FooterComponent} from './layout/footer/footer.component';
import {HeaderComponent} from './layout/header/header.component';
import {RechercheParMotsClesComponent} from './components/recherche/recherche-par-mots-cles/recherche-par-mots-cles.component';
import {AnimatedDigitDirective} from './directives/animated-digit.directive';
import {HomeKeyNumbersComponent} from './components/home/home-key-numbers/home-key-numbers.component';
import {BreadcrumbThreadComponent} from './components/breadcrumb-thread/breadcrumb-thread.component';
import {FicheColumnComponent} from './components/fiche/fiche-column/fiche-column.component';
import {FicheFormationComponent} from './components/fiche/fiche-formation/fiche-formation.component';
import {FicheEnseignementComponent} from './components/fiche/fiche-enseignement/fiche-enseignement.component';
import {FicheParcoursTypeComponent} from './components/fiche/fiche-parcours-type/fiche-parcours-type.component';
import {FicheCursusEnrichiComponent} from './components/fiche/fiche-cursus-enrichi/fiche-cursus-enrichi.component';
import {FichePeriodeComponent} from './components/fiche/fiche-periode/fiche-periode.component';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {CarteEnseignementComponent} from './components/results/cartes/carte-enseignement/carte-enseignement.component';
import {CarteFormationComponent} from './components/results/cartes/carte-formation/carte-formation.component';
import {CarteCursusEnrichiComponent} from './components/results/cartes/carte-cursus-enrichi/carte-cursus-enrichi.component';
import {ParDiplomesComponent} from './pages/par-diplomes/par-diplomes.component';
import {ParDisciplinesComponent} from './pages/par-disciplines/par-disciplines.component';
import {ParComposantesComponent} from './pages/par-composantes/par-composantes.component';
import {PaginateurComponent} from './components/paginateur/paginateur.component';
import {MaSelectionComponent} from './pages/ma-selection/ma-selection.component';
import {AjouterFavoriComponent} from './components/actions/ajouter-favori/ajouter-favori.component';
import {TelechargerComponent} from './components/actions/telecharger/telecharger.component';
import {ContactsComponent} from './components/rubriques/contacts/contacts.component';
import {CarteParcoursTypeComponent} from './components/results/cartes/carte-parcours-type/carte-parcours-type.component';
import {ProgrammeParcoursLibreComponent} from './components/rubriques/programme/programme-parcours-libre/programme-parcours-libre.component';
import {PoursuitesEtudesComponent} from './components/rubriques/poursuites-etudes/poursuites-etudes.component';
import {AnchorDirective} from './directives/anchor.directive';
import {MatIconModule} from '@angular/material/icon';
import {InternationalisationComponent} from './components/rubriques/internationalisation/internationalisation.component';
import {RgpdDialogComponent} from './components/rgpd/rgpd-dialog/rgpd-dialog.component';
import {CookieService} from 'ngx-cookie-service';
import {MATOMO_CONFIGURATION, NgxMatomoTrackerModule} from '@ngx-matomo/tracker';
import {NgxMatomoRouterModule} from '@ngx-matomo/router';
import {FocusDirective} from './directives/focus.directive';
import {PlanDuSiteComponent} from './pages/plan-du-site/plan-du-site.component';
import {LoaderService} from './services/loader.service';
import {LoaderComponent} from './components/loader/loader.component';
import {InternationalCoursesCatalogueComponent} from './pages/international-courses-catalogue/international-courses-catalogue.component';
import {UtilService} from './services/util.service';
import {MatDialogContent} from '@angular/material/dialog';
import {DocumentComponent} from "./components/document/document.component";
import {MatChip, MatChipSet} from "@angular/material/chips";

export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AccueilComponent,
    ResultatsComponent,
    FicheComponent,
    HeaderComponent,
    FiltresComponent,
    AccesComponent,
    AcquisComponent,
    ActivitesPedagogiquesComponent,
    CompetencesComponent,
    DebouchesComponent,
    InformationsComponent,
    ModalitesAcquisitionComponent,
    RebondsComponent,
    BlocsCompetenceComponent,
    ProgrammeSchemaComponent,
    ProgrammeArboComponent,
    FooterComponent,
    RechercheParMotsClesComponent,
    AnimatedDigitDirective,
    AnchorDirective,
    HomeKeyNumbersComponent,
    BreadcrumbThreadComponent,
    FicheColumnComponent,
    FicheFormationComponent,
    FicheEnseignementComponent,
    FicheParcoursTypeComponent,
    FicheCursusEnrichiComponent,
    FichePeriodeComponent,
    CarteEnseignementComponent,
    CarteFormationComponent,
    CarteCursusEnrichiComponent,
    ParDiplomesComponent,
    ParDisciplinesComponent,
    ParComposantesComponent,
    PaginateurComponent,
    MaSelectionComponent,
    AjouterFavoriComponent,
    TelechargerComponent,
    ContactsComponent,
    CarteParcoursTypeComponent,
    ProgrammeParcoursLibreComponent,
    PoursuitesEtudesComponent,
    PoursuitesEtudesComponent,
    InternationalisationComponent,
    RgpdDialogComponent,
    FocusDirective,
    PlanDuSiteComponent,
    LoaderComponent,
    InternationalCoursesCatalogueComponent,
    DocumentComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatIconModule,
    MatTreeModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    YouTubePlayerModule,
    NgxMatomoTrackerModule,
    NgxMatomoRouterModule,
    MatDialogContent,
    MatChipSet,
    MatChip,
  ],
  providers: [
    GlobalStateService,
    CookieService,
    LoaderService,
    UtilService,
    ElasticsearchService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigAssetLoaderService) => () => lastValueFrom(configService.loadConfigurations()),
      deps: [ConfigAssetLoaderService],
      multi: true
    },
    {
      provide: MATOMO_CONFIGURATION,
      useValue: {
        trackerUrl: 'https://matomo.u-bordeaux.fr',
        siteId: '2',
        scriptUrl: 'https://matomo.u-bordeaux.fr/matomo.js'
      },
    },
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
