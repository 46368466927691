export enum refStructure {
  universite_de_bordeaux = 6,
  college_sciences_et_technologies = 7,
  unite_de_formation_de_biologie = 8,
  universite_bordeaux_montaigne = 9,
  college_sciences_de_la_sante = 10,
  college_droit_science_politique_economie_et_gestion = 11,
  college_sciences_de_l_homme = 12,
  faculte_d_anthropologie_sociale_ethnologie = 13,
  faculte_de_psychologie = 14,
  faculte_des_sciences_de_l_education_et_de_la_formation = 15,
  faculte_des_sciences_et_techniques_des_activites_physiques_et_sportives = 16,
  faculte_de_sociologie = 17,
  unite_de_formation_de_sciences_chimiques = 18,
  unite_de_formation_d_informatique = 19,
  unite_de_formation_de_mathematiques_et_interactions = 20,
  unite_de_formation_de_physique = 21,
  unite_de_formation_des_sciences_de_l_ingenieur = 22,
  unite_de_formation_des_sciences_de_la_terre_et_environnement = 23,
  departement_licence = 24,
  departement_langues_lettres_et_communication = 25,
  departement_universitaire_des_sciences_d_agen = 26,
  ufr_des_sciences_medicales = 27,
  ufr_des_sciences_pharmaceutiques = 28,
  ufr_des_sciences_odontologiques = 29,
  isped = 30,
  institut_du_thermalisme = 31,
  institut_de_formation_en_psychomotricite = 32,
  ecole_d_orthoptie = 33,
  institut_de_formation_en_soins_infirmiers = 34,
  faculte_de_droit_et_science_politique = 35,
  faculte_d_economie_gestion_et_aes = 36,
  institut_du_travail = 37,
  iae_bordeaux_ecole_universitaire_de_management = 38,
  bordeaux_inp = 39,
  ecole_nationale_superieure_de_cognitique_ensc = 40,
  enseirb_matmeca = 41,
  ensegid = 42,
  enscbp = 43,
  enstbb = 44,
  bordeaux_sciences_agro = 45,
  institut_de_mathematiques_de_bordeaux_imb_umr_5251 = 47,
  institut_des_sciences_moleculaires_ism = 48,
  iut_de_bordeaux_2 = 49,
  college_des_ecoles_doctorales = 50,
  ecole_doctorale_de_mathematiques_et_informatique = 51,
  ecole_doctorale_des_sciences_chimiques = 52,
  ecole_doctorale_des_sciences_de_la_vie_et_de_la_sante = 53,
  ecole_doctorale_des_sciences_physiques_et_de_l_ingenieur = 54,
  ecole_doctorale_droit_sciences_politiques = 55,
  ecole_doctorale_entreprise_economie_et_societe = 56,
  ecole_doctorale_sciences_et_environnements = 57,
  ecole_doctorale_societes_politique_sante_publique = 58,
  centre_de_recherche_appliquees_en_methodes_educative_crame = 59,
  unite_de_formation_de_biologie_college_sante = 60,
  biodiversite_genes_et_communautes_biogeco_umr_1202 = 61,
  centre_de_recherche_paul_pascal_crpp = 62,
  centre_d_etudes_nucleaires_de_bordeaux_gradignan_cenbg = 63,
  centre_lasers_intenses_et_applications_celia = 64,
  chimie_et_biologie_des_membranes_et_des_nanoobjets_cbmn = 65,
  de_la_prehistoire_a_l_actuel_culture_environnement_et_anthropologie_pacea = 66,
  environnements_et_paleoenvironnements_oceaniques_et_continentaux_epoc_umr_5805 = 67,
  federation_de_recherche_lasers_et_plasmas_fr_207 = 68,
  federation_matiere_molle_fed_3297 = 69,
  federation_sciences_archeologiques_bordeaux = 70,
  ingenierie_des_organisations_distribuees = 71,
  institut_de_chimie_de_la_matiere_condensee_de_bordeaux_icmcb = 72,
  institut_de_mecanique_et_d_ingenierie_i2m = 73,
  laboratoire_bordelais_de_recherche_en_informatique_labri_umr_5800 = 75,
  laboratoire_d_astrophysique_de_bordeaux_lab_umr_5804 = 76,
  laboratoire_de_chimie_des_polymeres_organique_lcpo = 77,
  laboratoire_des_composites_thermostructuraux_lcts = 78,
  laboratoire_d_integration_du_materiau_au_systeme_ims = 79,
  laboratoire_ondes_et_matiere_d_aquitaine_loma = 80,
  laboratoire_photonique_numerique_et_nanosciences_lp2n = 81,
  laboratory_of_the_future_lof_umr_5258 = 82,
  sciences_philosophie_humanites_sph_ea_4574 = 84,
  centre_de_recherche_etudes_et_developpement_cred = 85,
  cohabit_fab_lab_techoshop = 86,
  iut_de_bordeaux = 87,
  departement_carrieres_sociales_villes_et_territoires_durables = 88,
  departement_genie_biologique = 89,
  departement_genie_chimique_et_genie_des_procedes = 90,
  departement_genie_civil = 91,
  departement_genie_electronique_et_informatique_industrielle = 92,
  departement_genie_mecanique_et_productique = 93,
  departement_gestion_administrative_et_commerciale_des_organisations = 94,
  departement_gestion_des_entreprises_et_des_administrations = 95,
  departement_management_de_la_logistique_et_des_transports = 96,
  departement_hygiene_securite_environnement = 97,
  departement_informatique = 98,
  departement_mesures_physiques = 99,
  departement_qualite_logistique_industrielle_et_organisation = 100,
  departement_sciences_et_genie_des_materiaux = 101,
  departement_techniques_de_commercialisation_perigueux = 102,
  departement_techniques_de_commercialisation_bordeaux = 103,
  institut_des_sciences_de_la_vigne_et_du_vin_isvv = 104,
  inspe = 106,
  inspe_24 = 107,
  inspe_33 = 108,
  inspe_40 = 109,
  inspe_47 = 110,
  inspe_64 = 111,
  archeovision_shs_3d = 114,
  centre_d_etudes_et_de_recherches_comparatives_sur_les_constitutions_les_libertes_et_l_etat_cerccle = 116,
  centre_emile_durkheim_ced_umr_5116 = 117,
  centre_europeen_de_recherche_en_droit_des_familles_des_assurances_des_personnes_et_de_la_sante_cerfaps = 119,
  centre_de_droit_compare_du_travail_et_de_la_securite_sociale_comptrasec = 120,
  centre_de_recherche_et_de_documentation_europeenne_et_internationale_crdei = 121,
  groupe_de_recherche_en_economie_theorique_et_appliquee_gretha = 123,
  institut_de_recherches_montesquieu_irm = 124,
  institut_du_droit_des_personnes_et_de_l_etat_leon_duguit_ild_ea_7439 = 125,
  institut_de_recherche_en_droit_des_affaires_et_du_patrimoine_irdap = 126,
  institut_de_recherche_en_gestion_des_organisations_irgo = 127,
  institut_de_sciences_criminelles_et_de_la_justice_iscj = 128,
  laboratoire_cultures_education_societes_laces = 129,
  laboratoire_cultures_et_diffusion_des_savoirs_ceds = 130,
  laboratoire_d_epistemologie_et_de_didactiques_disciplinaires_professionnelle_et_comparee_de_bordeaux_lab_e3d = 131,
  laboratoire_de_psychologie_ea_4139 = 132,
  laboratoire_d_analyse_et_de_recherche_en_economie_et_finance_internationales_larefi = 133,
  les_afriques_dans_le_monde_lam = 134,
  passages = 135,
  acides_nucleiques_regulations_naturelles_et_artificielles_arna_inserm_u1212 = 137,
  actions_for_oncogenesis_understanding_and_target_identification_in_oncology_action_umr_1218 = 138,
  bioingenierie_tissulaire_biotis_u1026 = 139,
  biologie_des_maladies_cardiovasculaires_bmc_umr_1034 = 140,
  biologie_du_fruit_et_pathologie_bfp_umr_1332 = 141,
  tbm_core_ums_3427 = 142,
  biotherapies_des_maladies_genetiques_inflammatoires_et_du_cancer_bmgic_umr_1035 = 145,
  departement_bordeaux_neurocampus = 146,
  bordeaux_research_center_for_population_health_bph_umr_1219 = 147,
  bordeaux_research_in_translational_oncology_bariton_umr_1053 = 148,
  centre_de_recherche_cardio_thoracique_de_bordeaux_crctb_umr_1045 = 149,
  centre_de_resonance_magnetique_des_systemes_biologiques_crmsb = 150,
  centre_d_investigation_clinique_de_bordeaux = 151,
  centre_genomique_fontionnelle_de_bordeaux = 152,
  ecophysiologie_et_genomique_fonctionnelle_de_la_vigne_egfv = 154,
  handicap_activite_cognition_sante = 156,
  iins_institut_interdisciplinaire_de_neuroscience = 157,
  imagerie_moleculaire_et_therapies_inovantes_en_oncologie_imotion = 158,
  immunologie_conceptuelle_experimentale_et_translationnelle_immuno_concept = 159,
  institut_des_maladies_neurodegeneratives_imn = 160,
  infections_humaines_a_mycoplasmes_et_a_chlamydiae_ihmc = 161,
  institut_de_biochimie_et_genetique_cellulaires_ibgc = 162,
  institut_de_bioimagerie_ibio = 163,
  institut_de_neurosciences_cognitives_et_integratives_d_aquitaine_incia = 164,
  institut_europeen_de_chimie_et_biologie_iecb = 165,
  laboratoire_de_biogenese_membranaire_lbm_umr_5200 = 166,
  laboratoire_de_l_angiogenese_et_du_microenvironnement_des_cancers_lamc = 167,
  maladies_rares_genetique_et_metabolisme_mrgm = 169,
  microbiologie_fondamentale_et_pathogenicite_mfp = 170,
  neurophysiologie_de_la_synapse_auditive = 172,
  nutrition_et_neurobiologie_integree_nutrineuro = 173,
  sommeil_attention_et_neuropsychiatrie_sanpsy = 175,
  unite_de_recherche_oenologie = 176,
  departement_sciences_et_technologies_pour_la_sante = 177,
  departement_sciences_biologiques_et_medicales = 178,
  departement_sciences_archeologiques = 179,
  departement_sciences_de_l_environnement = 180,
  departement_sante_publique = 181,
  departement_droit_et_transformations_sociales = 182,
  departement_sciences_de_la_matiere_et_du_rayonnement = 183,
  departement_changes_sciences_sociales_des_changements_contemporains = 184,
  departement_sciences_de_l_ingenierie_et_du_numerique = 185,
  departement_evaluation_comportements_organisations_ecor = 186,
  bordeaux_imaging_center_bic_uar_3420_us_004 = 187,
  ecole_des_neurosciences_de_bordeaux = 188,
  neurocentre_magendie_umrs_1215 = 189,
  pole_in_vivo_experimentation = 190,
  intertryp_umr_177_site_de_bordeaux = 191,
  placamat = 192,
  maison_des_sciences_de_l_homme = 193,
  centre_de_formation_d_apprentis_de_l_universite_de_bordeaux = 194,
  unite_d_apprentissage_institut_d_administration_des_entreprises = 195,
  unite_d_apprentissage_institut_universitaire_de_technologie = 196,
  unite_d_apprentissage_metiers_du_droit_de_l_economie_de_la_gestion_et_de_l_administration = 197,
  unite_d_apprentissage_sante = 198,
  unite_d_apprentissage_sciences_et_technologies = 199,
  isvv_institut_des_sciences_de_la_vigne_et_du_vin = 200,
  centre_d_etudes_et_de_recherche_sur_le_droit_administratif_et_la_reforme_d_etat_cerdare = 201,
  groupement_de_recherches_comparatives_en_droit_constitutionnel_administratif_et_politique_greccap = 202,
  etablissements_internationaux = 203,
  universite_mykolas_romeris = 204,
  universite_mohammed_v = 205,
  structures_privees = 206,
  es_banque = 207,
  cfp_beausejour = 208,
  universite_d_antananarivo = 209,
  institut_universitaire_des_sciences_de_readaptation_iusr = 211,
  archeosciences_bordeaux_materiaux_temps_images_et_societes_umr_6034 = 212,
  bordeaux_institute_of_oncology_umr1312 = 213,
  bordeaux_sciences_economiques_umr_6060 = 214,
  infranalytics_fr2054 = 215,
  sante_et_agroecologie_du_vignoble_umr_1065_save = 216,
  mycologie_et_securite_des_aliments_ur_1264_mycsa = 217,
  college_sante = 218,
  sph_sciences_philosophie_humanites_ea_4574 = 219,
  mica_mediation_information_communication_arts_ea_4426 = 220,
  departement_langues_et_culture = 221,
  college_sciences_de_l_homme_2 = 222,
  bureau_des_sports_du_college_sh = 223,
  etablissements_nationaux = 224,
  lycee_montaigne = 225,
  crfcb_mediaquitaine = 227,
  mediaquitaine = 228
}
