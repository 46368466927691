const inseredString = '!---anti#{spam}#---!';

export function formatToCompare(str: string): string {
  let result = null;
  if (str) {
    result = str
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
  return result;
}

export function compare(a: string, b: string): number {
  if (formatToCompare(a) < formatToCompare(b)) {
    return -1;
  }
  if (formatToCompare(a) > formatToCompare(b)) {
    return 1;
  }
  return 0;
}

export function normalize(s: string): string {
  return s.split(' ').join('_');
}

export function normalizeByReplace(s: string, replace: string): string {
  return s
    .split(' ')
    .join(replace).toLowerCase()
    .split('\'')
    .join('').toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export function denormalize(s: string): string {
  return s.split('_').join(' ');
}

export function encodedEmail(s: string): string {
  return 'mailto:' + s.slice(0, 1) + inseredString + s.slice(1);
}

export function decodedEmail(s: string): string {
  return 'mailto:' + s.replace(inseredString, '');
}
