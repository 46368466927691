import {Component, Input} from '@angular/core';
import {Document} from "../../model/document.model";
import {DocumentResourceService} from "../../services/resources/document-resource.service";

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrl: './document.component.scss'
})
export class DocumentComponent {

  @Input() documents:Document[];
  @Input() statistiquesRecrutement:Document;

  constructor(private documentResourceService:DocumentResourceService) {
  }

  download(document: Document): void {
    this.documentResourceService.download(document.documentId);
  }

}
