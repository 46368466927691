import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from "@angular/core";
import { GofElement } from "../../../model/util.model";
import { PanelPresentation } from "../../../model/presentation.model";
import { PanelDebouches } from "../../../model/debouches.model";
import { PanelRebonds } from "../../../model/rebonds.model";
import { PanelAcces } from "../../../model/acces.model";
import { PanelContacts } from "../../../model/contacts.model";
import { PanelCompetences } from "../../../model/competences.model";
import { PanelContenuHisto } from "../../../model/contenu-histo.model";
import { ParcoursTypeSchemaService } from "../../../services/schema/parcours-type-schema.service";
import { LazyLoadService } from "../../../services/lazy-load.service";
import { PanelPoursuitesEtudes } from "../../../model/poursuites-etudes.model";
import { PanelInternationalisation } from "../../../model/internationalisation.model";
import { TYPES_OBJET } from "../../../constants/utils.constants";
import { TranslateService } from "@ngx-translate/core";
import { ParcoursTypeAccesService } from "../../../services/acces/parcours-type-acces.service";
import { ParcoursTypeContactsService } from "../../../services/contacts/parcours-type-contacts.service";
import { ParcoursTypeCompetencesService } from "../../../services/competences/parcours-type-competences.service";
import { ParcoursTypeDebouchesService } from "../../../services/debouches/parcours-type-debouches.service";
import { ParcoursTypeInternationalisationService } from "../../../services/internalionalisation/parcours-type-internationalisation.service";
import { ParcoursTypePoursuitesEtudesService } from "../../../services/poursuites-etudes/parcours-type-poursuites-etudes.service";
import { ParcoursTypeRebondsService } from "../../../services/rebonds/parcours-type-rebonds.service";
import { PanelContenu } from "../../../model/contenu.model";
import { ContenuService } from "../../../services/contenu.service";
import { PeriodeResourceService } from "../../../services/resources/periode-resource.service";
import { GOF_PROFILE } from "../../../constants/conf.constants";
import { CookieService } from "ngx-cookie-service";
import { typesDureeTrad } from "../../../constants/translationMaps/trad.constants";

@Component({
  selector: "app-fiche-parcours-type",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./fiche-parcours-type.component.html",
  styleUrls: ["./fiche-parcours-type.component.scss"]
})
export class FicheParcoursTypeComponent implements AfterViewInit, OnChanges {

  @Input() gofElement: GofElement;
  @Input() presentation: PanelPresentation;

  @Output() loaded: EventEmitter<void> = new EventEmitter<void>();

  gofElementPeriodePorteuse: GofElement;

  acces: PanelAcces;
  contacts: PanelContacts;
  contenus: PanelContenu[];
  contenuHisto: PanelContenuHisto;
  competences: PanelCompetences;
  debouches: PanelDebouches;
  internationalisation: PanelInternationalisation;
  poursuitesEtudes: PanelPoursuitesEtudes;
  rebonds: PanelRebonds;

  display = false;
  schemaLoading: boolean;
  contenuLoading: boolean;
  typeTemp: string;

  constructor(private accesService: ParcoursTypeAccesService,
              private contactsService: ParcoursTypeContactsService,
              private contenuService: ContenuService,
              private competencesService: ParcoursTypeCompetencesService,
              private debouchesService: ParcoursTypeDebouchesService,
              private internationalisationService: ParcoursTypeInternationalisationService,
              private poursuitesEtudesService: ParcoursTypePoursuitesEtudesService,
              private rebondsService: ParcoursTypeRebondsService,
              private schemaService: ParcoursTypeSchemaService,
              private translateService: TranslateService,
              private cookieService: CookieService,
              private periodeResourceService: PeriodeResourceService,
              private lazyLoadScriptServiceService: LazyLoadService) {
  }

  ngAfterViewInit(): void {
    this.getContenuHisto();
  }

  ngOnChanges(): void {
    if (this.presentation.refObject.idPeriodePorteuse) {
      this.gofElementPeriodePorteuse = {
        typeObjet: TYPES_OBJET.PERIODE,
        id: this.presentation.refObject.idPeriodePorteuse,
        annee: this.gofElement.annee,
        typePublic: this.cookieService.get(GOF_PROFILE),
        langue: this.translateService.currentLang
      };

      Promise.all([
        this.getTypeTemp(),
        this.getAcces(),
        this.getCompetences(),
        this.getContacts(),
        this.getContenu(),
        this.getDebouches(),
        this.getInternationalisation(),
        this.getPoursuitesEtudes(),
        this.getRebonds()
      ]).then(() => {
        this.display = true;
        this.lazyLoadScriptServiceService.injectScript("/assets/js/course-detail.js");
        this.loaded.emit();
      });
    } else {
      this.display = true;
      this.loaded.emit();
    }
  }

  getTypeTemp(): Promise<void> {
    return new Promise<void>(resolve => {
      this.translateService.get(typesDureeTrad.get(this.presentation.typeDureeId)).subscribe(res => {
        this.typeTemp = res?.charAt(0).toUpperCase();
        resolve();
      });
    });
  }

  private getAcces(): Promise<void> {
    return new Promise<void>(resolve => {
      this.accesService.getPanel(this.gofElementPeriodePorteuse).then(res => {
        this.acces = res;
        resolve();
      });
    });
  }

  private getCompetences(): Promise<void> {
    return new Promise<void>(resolve => {
      this.competencesService.getPanel(this.gofElement, this.presentation.refObject).then(res => {
        this.competences = res;
        resolve();
      });
    });
  }

  private getContacts(): Promise<void> {
    return new Promise<void>(resolve => {
      this.contactsService.getPanel(this.gofElement, this.presentation.refObject).then(res => {
        this.contacts = res;
        resolve();
      });
    });
  }

  private getContenuHisto(): Promise<void> {
    return new Promise<void>(resolve => {
      this.schemaLoading = true;
      this.schemaService.getPanel(this.gofElement, this.presentation.refObject).then(res => {
        this.schemaLoading = false;
        this.contenuHisto = res;
        resolve();
      });
    });
  }

  private getContenu(): Promise<void> {
    return new Promise<void>(resolve => {
      this.contenuLoading = true;
      const promises = new Array<Promise<PanelContenu>>();
      this.presentation.refObject.idsPeriodesPorteuses.forEach(idPeriodePorteuse => {
        promises.push(this.getPanelContenu(this.getGofElementPeriode(idPeriodePorteuse)));
      });
      Promise.all(promises).then(panels => {
        this.contenuLoading = false;
        this.contenus = panels;
        resolve();
      });
    });
  }

  private getPanelContenu(gofElement: GofElement): Promise<PanelContenu> {
    return new Promise<PanelContenu>(resolve => {
      this.periodeResourceService.getPeriode(gofElement).subscribe(periodePorteuse => {
        this.contenuService.getPanel(gofElement, periodePorteuse)
          .then(res => {
            resolve(res);
          });
      });
    });
  }

  private getDebouches(): Promise<void> {
    return new Promise<void>(resolve => {
      this.debouchesService.getPanel(this.gofElement, this.presentation.refObject).then(res => {
        this.debouches = res;
        resolve();
      });
    });
  }

  private getInternationalisation(): Promise<void> {
    return new Promise<void>(resolve => {
      this.internationalisationService.getPanel(this.gofElementPeriodePorteuse).then(res => {
        this.internationalisation = res;
        resolve();
      });
    });
  }

  private getPoursuitesEtudes(): Promise<void> {
    return new Promise<void>(resolve => {
      this.poursuitesEtudesService.getPanel(this.gofElement).then(res => {
        this.poursuitesEtudes = res;
        resolve();
      });
    });
  }

  private getRebonds(): Promise<void> {
    return new Promise<void>(resolve => {
      this.rebondsService.getPanel(this.gofElement, this.presentation.refObject).then(res => {
        this.rebonds = res;
        resolve();
      });
    });
  }

  private getGofElementPeriode(objetId: number): GofElement {
    return {
      typeObjet: TYPES_OBJET.PERIODE,
      id: objetId,
      annee: this.gofElement.annee,
      typePublic: this.cookieService.get(GOF_PROFILE),
      langue: this.translateService.currentLang
    };
  }


}
