<app-loader *ngIf="!display"></app-loader>

<div *ngIf="display">
  <div id="course-detail-nav-container"
       class="content-part content-part-bg content-part-bg-light course-detail-nav-container">
    <nav id="course-detail-nav" class="course-detail-nav" aria-label="Navigation interne" role="navigation">
      <div id="course-detail-nav-inner" class="course-detail-nav-inner">
        <ul #rubriques class="list-unstyled">
          <li *ngIf="presentation.objectif?.length > 0 ||
                                   presentation.competences?.length > 0 ||
                                   informations.adossementRecherche !== null ||
                                   internationalisation?.isDisplayed() ||
                                   informations.dispositifsEngagementEtudiant?.length > 0 ||
                                   informations.enseignementPro && informations.enseignementPro.rolePro">
            <a appAnchor
               id="#presentation"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.presentation.title' | translate}}"
               target="#presentationAnchor">{{ 'fiche.presentation.title' | translate }}</a>
          </li>
          <li *ngIf="contenuHisto?.isDisplayed() ||
                                   presentation.parcoursType?.length > 0 ||
                                   presentation.cursusEnrichi?.length > 0 ||
                                      presentation.parcoursLibre?.length > 0 ||
                                   blocsCompetence?.isDisplayed()">
            <a appAnchor
               id="#programme"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.programme.title' | translate}}"
               target="#programmeAnchor">{{ 'fiche.programme.title' | translate }}</a>
          </li>
          <li *ngIf="informations.dispositifsAideReussite?.length > 0  || poursuitesEtudes?.isDisplayed()">
            <a appAnchor
               id="#orientation-et-reussite"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.orientation_poursuite_etudes_reussite.title' | translate}}"
               target="#orientationAnchor">{{ 'fiche.orientation_poursuite_etudes_reussite.title' | translate }}</a>
          </li>
          <li *ngIf="debouches?.isDisplayed()">
            <a appAnchor
               id="#et-apres"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.debouches_pro.title' | translate}}"
               target="#debouchesAnchor">{{ 'fiche.debouches_pro.title' | translate }}</a>
          </li>
          <li *ngIf="acces?.isDisplayed()">
            <a appAnchor
               id="#admission"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.admission.title' | translate}}"
               target="#admissionAnchor">{{ 'fiche.admission.title' | translate }}</a>
          </li>
          <li *ngIf="documents?.length > 0">
            <a appAnchor
               id="#en-savoir-plus"
               tabindex="0"
               attr.aria-label="aller à la rubrique {{'fiche.en_savoir_plus.title' | translate}}"
               target="#enSavoirPlusAnchor">{{ 'fiche.en_savoir_plus.title' | translate }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <!-- PRESENTATION -->
  <div class="anchor" tabindex="-1" id=presentationAnchor></div>
  <div id="presentation" class="content-part content-part-no-pad-top" *ngIf="presentation.objectif?.length > 0 ||
                                   presentation.competences?.length > 0 ||
                                   informations.adossementRecherche !== null ||
                                   internationalisation?.isDisplayed() ||
                                   informations.dispositifsEngagementEtudiant?.length > 0 ||
                                   informations.enseignementPro && informations.enseignementPro.rolePro">
    <div class="course-detail-top margin-section">
      <div class="row row-flex row-gutters-xlarge margin-container-large">
        <div
          class="layout-col-sm-large layout-col-lg-medium col-xs-12 col-lg-8 margin-large course-detail-top-main course-detail-top-main-expandable">

          <div class="course-detail-top-main-outer">
            <div class="course-detail-top-main-inner">

              <div class="margin-large margin-lg-xlarge">
                <h2>{{ 'fiche.presentation.title' | translate }}</h2>
              </div>

              <!-- objectif -->
              <div class="margin-large margin-lg-xlarge" *ngIf="presentation.objectif?.length > 0">
                <h3>{{ 'fiche.presentation.objectifs' | translate }}</h3>
                <div [innerHTML]="presentation.objectif"></div>
              </div>

              <!-- competences -->
              <div class="margin-large margin-lg-xlarge" *ngIf="presentation.competences?.length > 0">
                <h3>{{ 'fiche.presentation.competences' | translate }}</h3>
                <ul>
                  <li *ngFor="let competence of presentation.competences">{{ competence }}</li>
                </ul>
              </div>

              <!-- adossement à la recherche -->
              <div class="margin-large margin-lg-xlarge" *ngIf="informations.adossementRecherche !== null">
                <h3>{{ 'fiche.informations.adossement_recherche.title' | translate }}</h3>
                <div [innerHTML]="informations.adossementRecherche.modalite"
                     *ngIf="informations.adossementRecherche.modalite"></div>

                <div *ngIf="informations.adossementRecherche.laboratoires?.length > 0">
                  <h4>{{ 'fiche.informations.adossement_recherche.laboratoires' | translate }}</h4>
                  <ul>
                    <li *ngFor="let labo of informations.adossementRecherche.laboratoires">{{ labo }}</li>
                  </ul>
                </div>
              </div>

              <!-- internationnalisation -->
              <div class="margin-large margin-lg-xlarge" *ngIf="internationalisation?.isDisplayed()">
                <h3>{{ 'fiche.international.title' | translate }}</h3>
                <app-internationalisation [internationalisation]="internationalisation"></app-internationalisation>
              </div>

              <!-- dispositifs engagement étudiants -->
              <div class="margin-large margin-lg-xlarge" *ngIf="informations.dispositifsEngagementEtudiant?.length > 0">
                <h3>{{ 'fiche.informations.dispositif_engagement_etudiant.title' | translate }}</h3>

                <div class="accordion-panels">

                  <div class="accordion-panel"
                       *ngFor="let disp of informations.dispositifsEngagementEtudiant ; let index = index">
                    <div class="accordion-panel-header">
                      <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
                        <h3 class="accordion-panel-title">{{ disp.intitule }}</h3>
                      </div>
                      <button data-toggle="collapse" type="button"
                              [attr.data-target]="'#engagement_etudiant_' + index"
                              [attr.aria-controls]="'engagement_etudiant_' + index"
                              class="accordion-panel-toggler collapsed"
                              aria-expanded="false"
                              title="Ouvrir / fermer le panneau"
                              aria-label="Ouvrir / fermer le panneau">
                        <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
                      </button>
                    </div>

                    <div [id]="'engagement_etudiant_' + index" class="accordion-panel-body collapse">
                      <div class="accordion-panel-body-inner">
                        <div [innerHTML]="disp.details" *ngIf="disp.details"></div>
                        <p *ngIf="disp.nbEcts">
                          <strong>{{ disp.nbEcts }} {{ 'fiche.informations.dispositif_engagement_etudiant.ects' | translate }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <!-- ouverture professionnelle -->
              <div id="ouverture-pro-container" class="margin-large margin-lg-xlarge"
                   *ngIf="informations.enseignementPro?.rolePro || informations.stage">
                <h3>{{ 'fiche.informations.ouverture_professionnelle' | translate }}</h3>

                <h4>{{ 'fiche.informations.enseignements_pro_stages.title' | translate }}</h4>

                <p>
                  <span *ngIf="informations.enseignementPro.rolePro">
                  {{ 'fiche.informations.enseignements_pro_stages.role_pro' | translate }}
                    : <span [innerHTML]="informations.enseignementPro.rolePro"></span></span><br/>

                  <span *ngIf="informations.enseignementPro.partPro">
                    {{ 'fiche.informations.enseignements_pro_stages.part_pro' | translate }}
                    : {{ informations.enseignementPro.partPro + '%' }}</span><br/>

                  <span *ngIf="informations.enseignementPro.entreprises?.length > 0">
                    {{ 'fiche.informations.enseignements_pro_stages.entreprises' | translate }} :
                    <ng-container *ngFor="let entreprise of informations.enseignementPro.entreprises; let last = last">
                      {{ entreprise }}<span *ngIf="!last">{{ ', ' }}</span>
                    </ng-container></span><br/>

                  <span *ngIf="informations.stage">
                    {{ 'fiche.informations.enseignements_pro_stages.stage' | translate }} :
                    <span [innerHTML]="informations.stage"></span></span>
                </p>
              </div>

            </div>
          </div>

          <p class="course-detail-top-main-expand-controls">
            <button type="button"
                    class="course-detail-top-main-expand-button"
                    (click)="displayMore = !displayMore">
                      <span class="course-detail-top-main-expand-button-text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                             aria-hidden="true">
                          <path
                            d="M16 2.267c-7.585 0-13.733 6.149-13.733 13.733s6.149 13.733 13.733 13.733c7.585 0 13.733-6.149 13.733-13.733v0c-0.009-7.581-6.152-13.724-13.732-13.733h-0.001zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16v0c0-8.837 7.163-16 16-16v0z"></path>
                          <path d="M17.133 24h-2.267v-16h2.267z"></path>
                          <path d="M8 17.133v-2.267h16v2.267z"></path>
                        </svg>
                        {{ 'fiche.presentation.afficher_plus' | translate }}
                      </span>
              <span
                class="course-detail-top-main-expand-button-text  course-detail-top-main-expand-button-text-expanded">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
                  <path
                    d="M16 2.267c-7.585 0-13.733 6.149-13.733 13.733s6.149 13.733 13.733 13.733c7.585 0 13.733-6.149 13.733-13.733v0c-0.009-7.581-6.152-13.724-13.732-13.733h-0.001zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16v0c0-8.837 7.163-16 16-16v0z"></path>
                  <path d="M8 17.133v-2.267h16v2.267z"></path>
                </svg>
                {{ 'fiche.presentation.afficher_moins' | translate }}</span>
            </button>
          </p>
        </div>

        <div class="layout-col-sm-large layout-col-lg-small col-xs-12 col-lg-4 margin-large course-detail-top-side">
          <app-fiche-column [gofElement]="gofElement"
                            [presentation]="presentation"
                            [contacts]="contacts"
                            [documents]="documents"
                            [statistiquesRecrutement]="statistiquesRecrutement">
          </app-fiche-column>
        </div>
      </div>

    </div>
  </div>


  <!-- VIDEO -->
  <div class="margin-section" *ngIf="video">
    <div class="content-video content-video-native js-video">
      <youtube-player
        [videoId]="getVideoId()"
        suggestedQuality="highres"
        [height]="videoHeight"
        [width]="innerWidth"
        *ngIf="cookieYoutube()">
      </youtube-player>

      <div *ngIf="!cookieYoutube()" class="cookie">
        {{ 'rgpd.youtube.alert' | translate }}<br>
        {{ 'rgpd.message_1' |translate }}<span (click)="manageCookie()">{{ 'rgpd.message_2' |translate }}</span>
      </div>

    </div>
  </div>

  <!-- PROGRAMME -->
  <div class="anchor" tabindex="-1" id="programmeAnchor"></div>
  <div id="programme" class="content-part content-part-no-pad-top " *ngIf="contenuHisto?.isDisplayed() ||
                                   presentation.parcoursType?.length > 0 ||
                                   presentation.cursusEnrichi?.length > 0 ||
                                   presentation.parcoursLibre?.length > 0 ||
                                   blocsCompetence?.isDisplayed()">
    <div class="margin-section">
      <h2>{{ 'fiche.programme.title' | translate }}</h2>
    </div>

    <!--  parcours-type -->
    <div class="margin-large margin-lg-xlarge" *ngIf="presentation.parcoursType?.length > 0">
      <h3>{{ 'fiche.programme.parcours_type' | translate }}</h3>
      <ul>
        <li *ngFor="let parcours of presentation.parcoursType">
          <a href [routerLink]="'/' + pathDetailFormation"
             [queryParams]="{type: parcours.typeObjet, id: parcours.id}">{{ parcours.intitule }}</a>
        </li>
      </ul>
    </div>

    <!-- cursus enrichis -->
    <div class="margin-large margin-lg-xlarge" *ngIf="presentation.cursusEnrichi?.length > 0">
      <h3>{{ 'fiche.programme.cursus_enrichis' | translate }}</h3>
      <ul>
        <li *ngFor="let cursus of presentation.cursusEnrichi">
          <a href [routerLink]="'/' + pathDetailFormation"
             [queryParams]="{type: cursus.typeObjet, id: cursus.id}">{{ cursus.intitule }}</a>
        </li>
      </ul>
    </div>

    <!-- parcours personnalisé -->
    <div class="margin-large margin-lg-xlarge" *ngIf="presentation.parcoursLibre?.length > 0">
      <h3>{{ 'fiche.programme.parcours_personnalisé' | translate }}</h3>
      <app-programme-parcours-libre
        [parcoursLibre]="presentation.parcoursLibre[0]"></app-programme-parcours-libre>
    </div>

    <!-- schéma -->
    <div class="margin-large margin-lg-xlarge">
      <h3>{{ 'fiche.programme.schema_formation' | translate }}</h3>
      <app-loader *ngIf="schemaLoading"></app-loader>

      <app-programme-schema [libelleMention]="presentation.intitule"
                            [contenuHisto]="contenuHisto"
                            *ngIf="!schemaLoading"></app-programme-schema>
    </div>

    <!-- blocs de compétences -->
    <div class="margin-large margin-lg-xlarge" *ngIf="blocsCompetence?.isDisplayed()">
      <h3>{{ 'fiche.blocs_competence.title' | translate }}</h3>
      <app-blocs-competence [blocsCompetence]="blocsCompetence"></app-blocs-competence>
    </div>
  </div>

  <!-- ORIENTATION, POURSUITE ETUDES & REUSSITE -->
  <div class="anchor" tabindex="0" id="orientationAnchor"></div>
  <div id="orientation-et-reussite" class="content-part content-part-no-pad-top"
       *ngIf="informations.dispositifsAideReussite?.length > 0 || poursuitesEtudes?.isDisplayed()  || statistiquesRecrutement">
    <div class="margin-section">
      <h2>{{ 'fiche.orientation_poursuite_etudes_reussite.title' | translate }}</h2>
    </div>

    <!-- lien insertion professionnel-->
    <div class="margin-large margin-lg-xlarge" *ngIf="statistiquesRecrutement">
      <h3>{{ 'fiche.informations.statistiques_recrutement' | translate }}</h3>
      <a [href]="statistiquesRecrutement.url" target="_blank">{{ statistiquesRecrutement.fileName }}</a>
    </div>

    <!-- Poursuite d'études -->
    <div class="margin-large margin-lg-xlarge" *ngIf="poursuitesEtudes?.isDisplayed()">
      <h3>{{ 'fiche.poursuites_etudes.title' | translate }}</h3>
      <app-poursuites-etudes [poursuitesEtudes]="poursuitesEtudes"></app-poursuites-etudes>
    </div>

    <!-- Aide à la réussite -->
    <div class="margin-large margin-lg-xlarge" *ngIf="informations.dispositifsAideReussite?.length > 0">
      <h3>{{ 'fiche.informations.dispositifs_aide_reussite' | translate }}</h3>
      <app-informations [informations]="informations"></app-informations>
    </div>
  </div>

  <!-- DEBOUCHES PROFESSIONNELS -->
  <div class="anchor" tabindex="-1" id="debouchesAnchor"></div>
  <div id="et-apres" class="content-part content-part-no-pad-top" *ngIf="debouches?.isDisplayed()">
    <div class="margin-section">
      <h2>{{ 'fiche.debouches_pro.title' | translate }}</h2>
    </div>

    <app-debouches [debouches]="debouches"></app-debouches>
  </div>

  <!-- ADMISSION -->
  <div class="anchor" tabindex="0" id="admissionAnchor"></div>
  <div id="admission" class="content-part content-part-no-pad-top" *ngIf="acces?.isDisplayed()">
    <div class="margin-section">
      <h2>{{ 'fiche.admission.title' | translate }}</h2>
    </div>

    <app-acces [acces]="acces"></app-acces>
  </div>

  <!--  EN SAVOIR PLUS -->
  <div class="anchor" tabindex="-1" id="enSavoirPlusAnchor"></div>
  <div id="en-savoir-plus" class="content-part content-part-no-pad-top" *ngIf="documents?.length > 0">
    <div class="margin-section">
      <h2>{{ 'fiche.en_savoir_plus.title' | translate }}</h2>
    </div>

    <div class="margin-large margin-lg-xlarge">
      <div class="content-file-list">
        <app-document [documents]="documents"></app-document>
      </div>
    </div>
  </div>

</div>
