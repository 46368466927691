import { Injectable } from '@angular/core';
import {Contact, PanelContacts} from '../../model/contacts.model';
import {ContactsResourceService} from '../resources/contacts-resource.service';
import {GofElement} from '../../model/util.model';

@Injectable({
  providedIn: 'root'
})
export class EnseignementContactsService {

  panelContacts: PanelContacts = new PanelContacts();

  constructor(private contactsResourceService: ContactsResourceService) {
  }

  getPanel(gofElement: GofElement): Promise<PanelContacts> {
    return new Promise<PanelContacts>(resolve => {
      this.contactsResourceService.getContacts(gofElement).subscribe(res => {
        const contacts: Contact[] = [];
        res.forEach(c => {
          contacts.push({
            id: c.id,
            lib: c.lib,
            nom: c.nom,
            mail: c.mail,
            telephone: c.telephone,
            adresse: c.adresse,
            ville: c.ville,
            cp: c.cp
          });
        });
        this.panelContacts.contacts = contacts;
        resolve(this.panelContacts);
      }, () => {
        resolve(new PanelContacts());
      })
    });
  }
}
