import {ObjectAffichage} from './util.model';

export class PanelActivites {
  constructor(public elementsPedagogiques?: ElementPedagogique[],
              public modulesEnseignement?: ModuleEnseignement[]) {
  }

  isDisplayed(): boolean {
    return this && this.elementsPedagogiques?.length > 0 || this.modulesEnseignement.length > 0;
  }
}

export class ModuleEnseignement {
  constructor(public lib?: string,
              public elementsPedagogiques?: ElementPedagogique[]) {
  }
}

export class ElementPedagogique {
  constructor(public id?: number,
              public lib?: string,
              public typeEnseignementId?: number,
              public objectif?: string,
              public nbHeuresEnseignees?: string,
              public tempsTravailPersonnel?: string,
              public nbSemaine?: string,
              public aDistance?: boolean,
              public modaliteSuivi?: string,
              public langueEnseignementId?: number,
              public enseignementPorteur?: ObjectAffichage,
              public unitesEnseignement?: ObjectAffichage[],
              public formationsMutualisation?: ObjectAffichage[],
              public module?: string,
              public sectionCnu?: string,
              public groupeCnu?: string) {
  }
}
