<div class="accordion-panels">
  <div class="accordion-panel" *ngFor="let dispositif of informations.dispositifsAideReussite ;  let index = index">
    <div class="accordion-panel-header">
      <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
        <h4 class="accordion-panel-title" [id]="'label_reussite_' + index">{{dispositif.intitule}}</h4>
      </div>
      <button data-toggle="collapse" type="button"
              [attr.data-target]="'#aide-reussite-' + index"
              [attr.aria-controls]="'aide-reussite-' + index"
              [attr.aria-describedby]="'label_reussite_' + index"
              class="accordion-panel-toggler collapsed"
              aria-expanded="false">
        <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
      </button>
    </div>

    <div [id]="'aide-reussite-' + index" class="accordion-panel-body collapse">
      <div class="accordion-panel-body-inner">
        <div class="margin-large margin-lg-xlarge">
          <div [innerHTML]="dispositif.details" *ngIf="dispositif.details"></div>
        </div>
      </div>
    </div>
  </div>
</div>


