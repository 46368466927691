import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Menu} from '../model/util.model';
import {FormationDoc} from '../model/esDoc.model';

@Injectable({
  providedIn: 'root'
})
export class ParComposantesService {
  constructor(protected http: HttpClient, private translateService: TranslateService) {
  }

  getNiveau3(sni: string, composante: string): Observable<Menu[]> {
    return this.http.get<Menu[]>('/par-composantes/niv-3/' + this.translateService.currentLang + '/' + sni + '/' + composante);
  }

  getNiveau4(sni: string, composante: string, typeFormation: string): Observable<FormationDoc[]> {
    return this.http.get<FormationDoc[]>('/par-composantes/niv-4/' + this.translateService.currentLang + '/' + sni + '/' + composante + '/' + typeFormation);
  }
}
