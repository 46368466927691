import {Injectable} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {ConfigAssetLoaderService} from '../configAssetLoader.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditoService {

  editoUrl: string;

  constructor(private configAssetLoaderService: ConfigAssetLoaderService,
              private http: HttpClient,
              private sanitizer: DomSanitizer) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => {
      this.editoUrl = res.editoUrl;
    });
  }


  gethtml(): Observable<SafeHtml> {
    return new Observable<SafeHtml>(observer => {
      this.http.get(this.editoUrl, {responseType: 'text' as const}).subscribe(res => {
        observer.next(this.sanitizer.bypassSecurityTrustHtml(res));
        observer.complete();
      }, error => console.error(error));
    });
  }
}
