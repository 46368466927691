import {Injectable} from '@angular/core';
import {EvalEns, Mea, PanelModalitesAcquisition, RegleObtEns} from '../../model/modalites-acquisition.model';
import {GofElement} from '../../model/util.model';
import {EnseignementResourceService} from '../resources/enseignement-resource.service';
import {Competence} from '../../model/competences.model';
import {getVersion} from '../../utils/version.utils';

@Injectable({
  providedIn: 'root'
})
export class EnseignementModalitesAcquisitionService {

  panelModalitesAcquisition: PanelModalitesAcquisition = new PanelModalitesAcquisition();

  constructor(private enseignementResourceService: EnseignementResourceService) {
  }

  getPanel(gofElement: GofElement, enseignement: any): Promise<PanelModalitesAcquisition> {
    return new Promise<PanelModalitesAcquisition>(resolve => {
      if (enseignement.evalEns) {
        Promise.all([
          this.getMEA(gofElement, enseignement.evalEns.id),
          this.getRegleObt(enseignement.evalEns.id, gofElement)
        ])
          .then(() => resolve(this.panelModalitesAcquisition));
      } else {
        resolve(new PanelModalitesAcquisition());
      }
    });

  }

  private getMEA(gofElement: GofElement, evalEnsId: number): Promise<void> {
    return new Promise<void>(resolve => {
      this.enseignementResourceService.getMea(evalEnsId).subscribe(meaList => {
        const mea: Mea[] = [];
        meaList?.forEach(res => {
          const meaUnit: Mea = new Mea();
          meaUnit.id = res.id;
          meaUnit.typeExamen = getVersion(gofElement, res.typeExamen);
          meaUnit.numSession = res.numSession;
          meaUnit.coefficient = res.coefficient;
          meaUnit.coefficient2 = res.coefficient;
          meaUnit.detail = res.detail;
          meaUnit.report2 = res.report2;
          meaUnit.dateValidation = res.dateValidation;
          meaUnit.natureExamen = getVersion(gofElement, res.natureExamen);
          meaUnit.duree = res.duree;
          meaUnit.acquis = [];
          res.acquis.forEach(a => {
            res.acquis.push({
              id: a.id,
              lib: a.lib,
              type: a.type?.id,
              competence: a.competence != null ? new Competence(
                a.competence.id,
                a.competence.lib,
                a.competence.type?.id,
                getVersion(gofElement, a.discipline),
                null
              ) : null,
              activitesPedagogiques: null
            });
          });
          mea.push(meaUnit);
        });
        this.panelModalitesAcquisition.mea = mea;
        resolve();
      },
        () => resolve());
    });
  }

  private getRegleObt(evalEnsId: number, gofElement: GofElement): Promise<void> {
    return new Promise<void>(resolve => {
      this.enseignementResourceService.getReglesobt(evalEnsId, gofElement).subscribe(reglList => {
        if(reglList) {
          const regleObtEns: RegleObtEns[] = [];

          reglList?.forEach(res => {
            const regleObtEnsUnit: RegleObtEns = new RegleObtEns();
            regleObtEnsUnit.id = res.id;
            regleObtEnsUnit.evalEns = res.evalEns != null ? new EvalEns(
              res.evalEns.id,
              res.evalEns.nbSession,
              res.evalEns.noEvalMotif,
              res.evalEns.dateConseil,
              res.evalEns.hasEval,
              res.evalEns.hasModule,
            ) : null;
            regleObtEnsUnit.regle = res.regle;
            regleObtEnsUnit.numSession = res.numSession;
            regleObtEns.push(regleObtEnsUnit);
          });

          this.panelModalitesAcquisition.regleObtEns = regleObtEns;
        }
        resolve();
      },
        () => resolve());
    });
  }
}
