<div class="margin-section">
  <div class="table-scrollable course-detail-careers-table-scrollable">
    <div class="table-scrollable-inner">

      <span class="table-scrollable-arrow-left">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
          <path
            d="M8.665 7.828l2.143 2.268-4.567 4.317h25.759v3.128h-25.806l4.614 4.364-2.143 2.268-8.665-8.164 8.665-8.18z"></path>
        </svg>
      </span>

      <table class="course-detail-careers-table" aria-describedby="caption-summary" role="presentation">
        <tr *ngFor="let duree of getAllDurees(); let rowIndex = index">

          <td class="course-detail-careers-table-th">
            <span class="course-detail-careers-table-line-name">{{ duree }}</span>
          </td>

          <ng-container *ngFor="let parcours of contenuHisto.colonnes">
            <ng-container *ngFor="let cellule of parcours.cellules, let celIndex = index">

              <td [colSpan]="cellule.colspan"
                  [rowSpan]="cellule.rowspan"
                  [class.clickable]="cellule.objectAffichage.id"
                  [class.course-detail-careers-table-td-vertical]="parcours.cellules.length === 1"
                  [ngClass]="parcours.style + ' ' + cellule.style"
                  (click)="onObjectSelected(cellule.objectAffichage.id ? cellule.objectAffichage : parcours.objectAffichage)"
                  *ngIf="cellule.rowIndex === rowIndex">

                <div class="course-detail-careers-table-cell" *ngIf="cellule.style !== 'display-none'">
                  <button class="course-detail-careers-table-cell-button">
                      <span class="course-detail-careers-table-cell-top"
                            *ngIf="parcours.objectAffichage.id && ((cellule.type === periodeSpecialisation && celIndex === 0) || parcours.cellules.length === 1)">
                        <span class="course-detail-career-tag">{{ 'fiche.programme.parcours' | translate }}</span>
                      </span>

                    <span class="course-detail-careers-table-cell-main">
                        <span class="course-detail-careers-table-cell-name">
                          {{ cellule.objectAffichage.intitule }}
                        </span>
                      </span>
                  </button>
                </div>
              </td>

            </ng-container>
          </ng-container>

        </tr>
      </table>
    </div>
  </div>

  <div class="accordion-panels">
    <div class="accordion-panel">
      <div class="accordion-panel-header">
        <div class="col-xs-12 col-sm-7 col-lg-8 margin-xsmall course-detail-accordion-sub-title">
          <strong>{{ 'fiche.programme.caption.title' | translate:{ libelle: libelleMention } }}</strong>
        </div>
        <button data-toggle="collapse" type="button"
                data-target="#caption"
                aria-controls="caption"
                class="accordion-panel-toggler collapsed"
                aria-expanded="false"
                aria-label="afficher / masquer la description du schéma de la formation">
          <span class="accordion-panel-icon accordion-panel-icon-animate"></span>
        </button>
      </div>

      <div id="caption" class="accordion-panel-body collapse">
        <div class="accordion-panel-body-inner">
          <p>{{
              'fiche.programme.caption.summary.header' | translate:{
                formationLibelle: libelleMention,
                typeTemporalite: getTypeDureeLibelle(true),
                nbTemporalite: contenuHisto.duree,
                nbParcours: contenuHisto.colonnes.length
              }
            }}</p>
          <ul *ngFor="let colonne of contenuHisto.colonnes, let i=index">
            <li
              *ngIf="colonne.objectAffichage.id">{{ 'fiche.programme.caption.summary.parcours_1' | translate:{ indexColonne: i + 2 } }}
              <a href [routerLink]="'/' + pathDetailFormation"
                 [queryParams]="{type: colonne.objectAffichage.typeObjet, id: colonne.objectAffichage.id}">{{ colonne.objectAffichage.intitule }}</a>{{ '.' }}
              <span *ngIf="colonne.periodes ?.length > 0">
                {{
                  'fiche.programme.caption.summary.parcours_2' | translate:{
                    periodeLibelle: (colonne.periodes.length > 1 ? 'périodes' : 'période'),
                    nbPeriodes: colonne.periodes.length
                  }
                }}
                <ul>
                  <ng-container *ngFor="let periode of colonne.periodes?.slice().reverse()">
                    <li *ngIf="periode.objetId">
                      <ng-container *ngIf="periode.debut && periode.fin">
                      {{
                          'fiche.programme.caption.summary.periodes' | translate:{
                            typeTemporalite: getTypeDureeLibelle(false),
                            debut: periode.debut,
                            fin: periode.fin
                          }
                        }}
                        </ng-container>
                         <ng-container *ngIf="periode.duree">
                            <a href [routerLink]="'/' + pathDetailFormation"
                               [queryParams]="{type: typeObjet.PERIODE, id: periode.objetId}">{{ periode.lib }}</a>
                           {{
                             'fiche.programme.caption.summary.duree' | translate:{
                               typeTemporalite: getTypeDureeLibelle(periode.duree > 1),
                               duree: periode.duree,
                             }
                           }}
                        </ng-container>

                    </li>
                  </ng-container>
                </ul>
              </span>
            </li>
            <li *ngIf="!colonne.objectAffichage.id">{{
                'fiche.programme.caption.summary.hors_parcours' | translate :{
                  periodeLibelle: (colonne.periodes.length > 1 ? 'périodes' : 'période'),
                  nbPeriodes: colonne.periodes.length
                }
              }}
              <ul>
                <ng-container *ngFor="let periode of colonne.periodes?.slice().reverse()">
                  <li *ngIf="periode.objetId">
                    <ng-container *ngIf="periode.debut && periode.fin">
                      {{
                        'fiche.programme.caption.summary.periodes' | translate:{
                          typeTemporalite: getTypeDureeLibelle(false),
                          debut: periode.debut,
                          fin: periode.fin
                        }
                      }}
                    </ng-container>
                    <ng-container *ngIf="periode.duree">
                      <a href [routerLink]="'/' + pathDetailFormation"
                         [queryParams]="{type: typeObjet.PERIODE, id: periode.objetId}">{{ periode.lib }}</a>
                      {{
                        'fiche.programme.caption.summary.duree' | translate:{
                          typeTemporalite: getTypeDureeLibelle(periode.duree > 1),
                          duree: periode.duree,
                        }
                      }}
                    </ng-container>

                  </li>
                </ng-container>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
