import {AfterViewInit, Directive, ElementRef, Input, OnChanges} from '@angular/core';

@Directive({
  selector: '[appAnimatedDigit]'
})
export class AnimatedDigitDirective implements AfterViewInit, OnChanges {

  @Input() duration: number;
  @Input() digit: number;
  @Input() steps: number;

  constructor(private el: ElementRef) {
  }

  animateCount(): void {
    if (!this.duration) {
      this.duration = 1000;
    }

    if (typeof this.digit === 'number') {
      this.counterFunc(this.digit, this.duration);
    }
  }

  counterFunc(endValue: number, durationMs: number): void {
    if (!this.steps) {
      this.steps = 12;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    const step = () => {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      this.el.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    };

    step();
  }

  ngAfterViewInit(): void {
    if (this.digit) {
      this.animateCount();
    }
  }

  ngOnChanges(): void {
    this.animateCount();
  }

}
