import {AfterViewInit, Component, Input, ViewEncapsulation} from '@angular/core';
import {PanelInformations} from '../../../model/informations.model';
import {LazyLoadService} from '../../../services/lazy-load.service';

@Component({
  selector: 'app-informations',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss']
})
export class InformationsComponent implements AfterViewInit {

  @Input() informations: PanelInformations;

  constructor(private lazyLoadScriptServiceService: LazyLoadService) {
  }

  ngAfterViewInit(): void {
    this.lazyLoadScriptServiceService.injectScript('/assets/js/accordion.js');
  }
}
