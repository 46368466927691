import {GOF_FILTERS, GOF_MA_SELECTION, GOF_SEARCH} from '../constants/conf.constants';
import {AbstractDocument} from '../model/esDoc.model';
import {CookieService} from 'ngx-cookie-service';

export function equals(x: any, y: any): boolean {
  for (const field of Object.keys(y)) {
    if (y.hasOwnProperty(field) && x.hasOwnProperty(field) && y[field] !== x[field]) {
      return false;
    } else if (y.hasOwnProperty(field) && !x.hasOwnProperty(field) && y[field] !== undefined) {
      return false;
    } else if (!y.hasOwnProperty(field) && x.hasOwnProperty(field) && x[field] !== undefined) {
      return false;
    }
  }
  return true;
}

export function addRemoveFavorites(document: AbstractDocument, cookieService: CookieService): boolean {
  let res;

  const selectionIds: number[] = JSON.parse(cookieService.get(GOF_MA_SELECTION));
  const index = selectionIds.findIndex(f => f === document.id);

  if (index > -1) {
    selectionIds.splice(index, 1);
    res = false;

  } else {
    selectionIds.push(document.id);
    res = true;
  }

  cookieService.set(GOF_MA_SELECTION, JSON.stringify(selectionIds));

  return res;
}

export function getLastSearch(): any {
  let res = null;
  const storedList: any[] = JSON.parse(sessionStorage.getItem(GOF_SEARCH));
  if (storedList?.length > 0) {
    res = storedList[0];
  }
  return res;
}

export function getLastFilters(): any {
  let res = null;
  const filtersStr: string = sessionStorage.getItem(GOF_FILTERS);
  if (filtersStr) {
    res = JSON.parse(filtersStr);
  }
  return res;
}
