import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PathItem} from '../../model/util.model';
import {PARAM_TYPE} from '../../constants/conf.constants';
import {typesObjetTrad} from '../../constants/translationMaps/trad.constants';

@Component({
  selector: 'app-breadcrumb-thread',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './breadcrumb-thread.component.html',
  styleUrls: ['./breadcrumb-thread.component.scss']
})
export class BreadcrumbThreadComponent {

  @Input() thread: PathItem[];

  typeObjetTrad = typesObjetTrad;
  paramType = PARAM_TYPE;

  constructor() {
  }


}
