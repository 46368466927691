import { Injectable } from "@angular/core";
import { PanelCompetences, Competence } from "../../model/competences.model";
import { GofElement } from "../../model/util.model";
import { CompetenceResourceService } from "../resources/competence-resource.service";
import { TYPES_OBJET } from "../../constants/utils.constants";
import { getVersion } from "../../utils/version.utils";

@Injectable({
  providedIn: "root"
})
export class ParcoursTypeCompetencesService {

  panelCompetences: PanelCompetences = new PanelCompetences();

  constructor(private competenceResourceService: CompetenceResourceService) {
  }

  getPanel(gofElement: GofElement, parcours: any): Promise<PanelCompetences> {
    return new Promise<PanelCompetences>(resolve => {
      if (parcours.idPeriodePorteuse) {
        this.competenceResourceService.getFormationCompetences({
          id: parcours.idPeriodePorteuse,
          typeObjet: TYPES_OBJET.PERIODE,
          annee: gofElement.annee,
          typePublic: gofElement.typePublic,
          langue: gofElement.langue
        }).subscribe(res => {

            this.panelCompetences.prerequis = res.filter(c => c.isPrerequis)
              .map(c => {
                this.panelCompetences.prerequis.push(
                  new Competence(c.id,
                    c.competence.lib,
                    c.competence.type.id,
                    getVersion(gofElement, c.acquisition)
                  )
                );
              });

            this.panelCompetences.competences = res.filter(c => !c.isPrerequis)
              .map(c => new Competence(
                  c.id,
                  c.competence.lib,
                  c.competence.type.id,
                  getVersion(gofElement, c.acquisition)
                )
              );

            resolve(this.panelCompetences);
          },
          () => resolve(this.panelCompetences));
      } else {
        resolve(new PanelCompetences());
      }
    });
  }
}
