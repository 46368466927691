<div class="content-part content-part-bg search-block">

  <div class="search-block-image img-cover">
    <picture>
      <source srcset="assets/_cache/search_block_1600x512_up/_files/ex_search-block.jpg" media="(min-width: 1200px)"/>
      <source srcset="assets/_cache/search_block_1200x600_up/_files/ex_search-block.jpg" media="(min-width: 768px)"/>
      <img loading="lazy" decoding="async" src="assets/_cache/search_block_768x600_up/_files/ex_search-block.jpg"
           alt=""/>
    </picture>
  </div>

  <div class="search-block-inner">

    <div id="search-block-form" class="search-block-form">
      <h1 class="search-block-form-title">{{'recherche.title.rechercher_formation' | translate}}</h1>

      <form role="search">
        <div class="search-block-form-frame">

          <div class="search-block-form-inner">
            <div class="search-block-form-field">
              <div class="form-row">
                <label for="search-field-1" class="sr-only">{{'recherche.form.mots_cles.label' | translate}}</label>
                <div class="controls">
                  <input id="search-field-1"
                         type="search"
                         [formControl]="motscles"
                         (input)="onInputChange()"
                         placeholder="{{'recherche.form.mots_cles.label' | translate}}"
                         (focus)="closeAllFilters()"/>
                </div>
              </div>
            </div>

            <div class="search-block-form-field search-block-form-field-expandable" *ngIf="!isCatalogueInternational">
              <button id="diplomes"
                      type="button"
                      class="search-block-form-field-details-toggler"
                      aria-controls="diplomes-details"
                      [attr.aria-expanded]="isDiplomeExpanded"
                      (click)="openDiplomes()">
                <span id="search-field-2-label">{{'recherche.form.diplomes.label' | translate}}
                  <span *ngIf="getCount(diplomes) > 0">
                    {{' ('}}<span class="search-block-form-field-counter">{{getCount(diplomes)}}</span>
                    <span class="sr-only">{{'recherche.warn.selected' | translate}}</span>{{')'}}
                  </span>
                </span>
                <span class="search-block-form-field-details-toggler-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
                    <path
                      d="M0.001 9.603c-0-0.005-0-0.012-0-0.018 0-0.879 0.354-1.675 0.928-2.253l-0 0c0.58-0.584 1.383-0.946 2.271-0.946s1.691 0.362 2.271 0.946l0 0 10.523 10.555 10.523-10.555c0.581-0.581 1.384-0.941 2.271-0.941 1.774 0 3.212 1.438 3.212 3.212 0 0.887-0.359 1.69-0.941 2.271l-12.794 12.794c-0.58 0.584-1.383 0.946-2.271 0.946s-1.691-0.362-2.271-0.946l-12.794-12.794c-0.573-0.578-0.928-1.374-0.928-2.252 0-0.007 0-0.013 0-0.020v0.001z"></path>
                  </svg>
                </span>
              </button>

              <div id="diplomes-details" class="search-block-form-field-details"
                   [class.search-block-form-field-details-expanded]="isDiplomeExpanded">
                <div class="form-row" role="group" aria-labelledby="search-field-2-label">
                  <div class="controls checkrads checkboxes" *ngIf="diplomes">

                    <div class="field checkrad checkbox" *ngFor="let item of diplomes">
                      <input [id]="normalize(item.key)" class="pointer" type="checkbox" [name]="item.key"
                             [(ngModel)]="item.value"/>
                      <label [for]="normalize(item.key)" class="pointer">
                        <span class="checkrad-icon checkbox-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                               aria-hidden="true">
                            <path
                              d="M11.967 28.105l-11.966-10.078 4.414-5.243 7.244 6.106 15.588-14.995 4.753 4.938z"></path>
                          </svg>
                        </span>
                        <span class="checkrad-text">{{item.key}}</span>
                      </label>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="search-block-form-field search-block-form-field-expandable" *ngIf="!isCatalogueInternational">
              <button id="disciplines"
                      type="button"
                      class="search-block-form-field-details-toggler"
                      aria-controls="disciplines-details"
                      [attr.aria-expanded]="isDisciplinesExpanded"
                      (click)="openDisciplines()">
                <span id="search-field-3-label">{{'recherche.form.disciplines.label' | translate}}
                  <span *ngIf="getCount(disciplines) > 0">
                    {{' ('}}<span class="search-block-form-field-counter">{{getCount(disciplines)}}</span>
                    <span class="sr-only">{{'recherche.warn.selected' | translate}}</span>{{')'}}
                  </span>
                </span>
                <span class="search-block-form-field-details-toggler-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
                    <path
                      d="M0.001 9.603c-0-0.005-0-0.012-0-0.018 0-0.879 0.354-1.675 0.928-2.253l-0 0c0.58-0.584 1.383-0.946 2.271-0.946s1.691 0.362 2.271 0.946l0 0 10.523 10.555 10.523-10.555c0.581-0.581 1.384-0.941 2.271-0.941 1.774 0 3.212 1.438 3.212 3.212 0 0.887-0.359 1.69-0.941 2.271l-12.794 12.794c-0.58 0.584-1.383 0.946-2.271 0.946s-1.691-0.362-2.271-0.946l-12.794-12.794c-0.573-0.578-0.928-1.374-0.928-2.252 0-0.007 0-0.013 0-0.020v0.001z"></path>
                  </svg>
                </span>
              </button>

              <div id="disciplines-details" class="search-block-form-field-details"
                   [class.search-block-form-field-details-expanded]="isDisciplinesExpanded">
                <div class="form-row" role="group" aria-labelledby="search-field-3-label">
                  <div class="controls checkrads checkboxes" *ngIf="disciplines">

                    <div class="field checkrad checkbox" *ngFor="let item of disciplines">
                      <input [id]="normalize(item.key)" class="pointer" type="checkbox" [name]="item.key"
                             [(ngModel)]="item.value"/>
                      <label [for]="normalize(item.key)" class="pointer">
																<span class="checkrad-icon checkbox-icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                               width="32" height="32"
                                                                               viewBox="0 0 32 32" aria-hidden="true">
																		<path
                                      d="M11.967 28.105l-11.966-10.078 4.414-5.243 7.244 6.106 15.588-14.995 4.753 4.938z"></path>
																	</svg></span>
                        <span class="checkrad-text">{{item.key}}</span>
                      </label>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="search-block-form-field search-block-form-field-expandable" *ngIf="!isCatalogueInternational">
              <button id="niveaux-entree"
                      type="button"
                      class="search-block-form-field-details-toggler"
                      aria-controls="niveaux-entree-details"
                      [attr.aria-expanded]=isNiveauxEntreeExpanded
                      (click)="openNiveauxEntree()">
                <span id="search-field-4-label">{{'recherche.form.niveaux_entree.label' | translate}}
                  <span *ngIf="getCount(niveauxEntree) > 0">
                    {{' ('}}<span class="search-block-form-field-counter">{{getCount(niveauxEntree)}}</span>
                    <span class="sr-only">{{'recherche.warn.selected' | translate}}</span>{{')'}}
                  </span>
                </span>
                <span class="search-block-form-field-details-toggler-icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                               aria-hidden="true">
														<path
                              d="M0.001 9.603c-0-0.005-0-0.012-0-0.018 0-0.879 0.354-1.675 0.928-2.253l-0 0c0.58-0.584 1.383-0.946 2.271-0.946s1.691 0.362 2.271 0.946l0 0 10.523 10.555 10.523-10.555c0.581-0.581 1.384-0.941 2.271-0.941 1.774 0 3.212 1.438 3.212 3.212 0 0.887-0.359 1.69-0.941 2.271l-12.794 12.794c-0.58 0.584-1.383 0.946-2.271 0.946s-1.691-0.362-2.271-0.946l-12.794-12.794c-0.573-0.578-0.928-1.374-0.928-2.252 0-0.007 0-0.013 0-0.020v0.001z"></path>
													</svg>
												</span>
              </button>

              <div id="niveaux-entree-details" class="search-block-form-field-details"
                   [class.search-block-form-field-details-expanded]="isNiveauxEntreeExpanded">
                <div class="form-row" role="group" aria-labelledby="search-field-4-label">
                  <div class="controls checkrads checkboxes" *ngIf="niveauxEntree">

                    <div class="field checkrad checkbox" *ngFor="let item of niveauxEntree">
                      <input [id]="normalize(item.key)" class="pointer" type="checkbox" [name]="item.key"
                             [(ngModel)]="item.value"/>
                      <label [for]="normalize(item.key)" class="pointer">
																<span class="checkrad-icon checkbox-icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                               width="32" height="32"
                                                                               viewBox="0 0 32 32" aria-hidden="true">
																		<path
                                      d="M11.967 28.105l-11.966-10.078 4.414-5.243 7.244 6.106 15.588-14.995 4.753 4.938z"></path>
																	</svg></span>
                        <span class="checkrad-text">{{item.key}}</span>
                      </label>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="search-block-form-submit">
              <p class="btns btns-center">
                <button id="search-submit"
                        type="submit"
                        class="btn btn-brand btn-large"
                        attr.aria-label="{{ 'recherche.title.rechercher_formation' | translate }}"
                        (click)="validate()">
                  {{ 'recherche.form.button' | translate }}
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true"
                       class="btn-icon">
                    <path
                      d="M13.061 26.122c-7.213 0-13.061-5.847-13.061-13.061s5.847-13.061 13.061-13.061c7.213 0 13.061 5.847 13.061 13.061v0c0 7.213-5.847 13.061-13.061 13.061v0zM13.061 4.354c-4.809 0-8.707 3.898-8.707 8.707s3.898 8.707 8.707 8.707c4.809 0 8.707-3.898 8.707-8.707v0c0-4.809-3.898-8.707-8.707-8.707v0zM31.999 28.93l-6.53-6.53-3.069 3.069 6.53 6.53z"></path>
                  </svg>
                </button>
              </p>
            </div>
          </div>
        </div>
      </form>
      <p id="alert-form-invalid" role="status"
         *ngIf="displayWarning && isInvalidForm()">{{'recherche.form.invalid' | translate}}</p>
    </div>
  </div>
</div>
